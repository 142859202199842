import {container} from 'tsyringe';
import {
    IssuingCertificateConformityServiceService
} from '@/pages/issuing-certificate-conformity-service/services/issuing-certificate-conformity-service.service';
import {IGetFileType} from '@/pages/issuing-certificate-conformity-service/types/get-cert-appeal';

const service = container.resolve(IssuingCertificateConformityServiceService);
export const IssuingCertificateConformityServiceStateActions = {
    getCitizenshipCert(context: any, payload: string): void {
        service.getCitizenshipCert(payload).then(res => context.commit('SET_CITIZENSHIP', res));
    },
    getCertFiles(context: any, payload: IGetFileType): void {
        service.getCertFiles(payload).then(res => context.commit('SET_CERT_FILES', res));
    },
    getCertFilesForStep3(context: any, payload: IGetFileType): void {
        service.getCertFiles(payload).then(res => context.commit('SET_CERT_FILES_STEP3', res));
    },
    getCertApplicantInfo(context: any, payload: IGetFileType): any {
       return  service.getCertApplicantInfo(payload).then(res => context.commit('SET_APPLICANT_CERTIFICATE_INFO', res));
    },
    postCertAppeal(context: any, payload: any): any {
        return service.postCertAppeal(payload);
    },
};