import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {store} from '@/store';
import {container} from 'tsyringe';
import {PublicSandbox} from '@/core/layouts/public/sandbox/public.sandbox';
import {locale} from '@/assets/db/db.conf.ts';
import {IFilter} from '@/core/layouts/public/types/filter';
import {count, publish} from 'rxjs/operators';

@Options({
    name: 'search-advanced-component',
})

export default class SearchAdvancedComponent extends Vue {
    private sandbox: PublicSandbox = container.resolve(PublicSandbox);
    public locale$: any = locale;
    public locale: any = null;
    public filter: IFilter = {
        structureId: null,
        categoryId: null,
        paymentRequired: null,
        noPaymentRequired: null,
        authRequired: null,
        noAuthRequired: null,
        allPaymentRequired: false,
        allAuthRequired: false,
    };

    public created(): void {
        this.sandbox.getSearchStructure();
        this.sandbox.getSearchCategories();
        this.locale$.subscribe((res: any) => {
            if (res && res.home) {
                this.locale = res.home.search;
            }
        });
    }

    public checkFilter() {
        let objCount = Object.keys(this.$route.query).length;
        let query = this.$route.query;
        if (objCount) {
            if (query.paymentRequired === null) {
                if (query.allPaymentRequired === 'true') {
                    this.filter.paymentRequired = true;
                    this.filter.noPaymentRequired = true;
                }
                if (query.allPaymentRequired === 'false') {
                    this.filter.paymentRequired = false;
                    this.filter.noPaymentRequired = false;
                }
            } else {
                if (query.paymentRequired === 'true') {
                    this.filter.paymentRequired = true;
                }
                if (query.paymentRequired === 'false') {
                    this.filter.noPaymentRequired = true;
                }
            }
            if (query.authRequired === null) {
                if (query.allAuthRequired === 'true') {
                    this.filter.authRequired = true;
                    this.filter.noAuthRequired = true;
                }
                if (query.allAuthRequired === 'false') {
                    this.filter.authRequired = false;
                    this.filter.noAuthRequired = false;
                }
            } else {
                if (query.authRequired === 'true') {
                    this.filter.authRequired = true;
                }
                if (query.authRequired === 'false') {
                    this.filter.noAuthRequired = true;
                }
            }
            if (query.structureId) {
                this.filter.structureId = Number(query.structureId);
            }
            if (query.categoryId) {
                this.filter.categoryId = Number(query.categoryId);
            }
        }
    }

    public mounted() {
        this.checkFilter();
    }

    public $refs!: {
        selectCategory: {
            reset: () => void,
        };
        selectStructure: {
            reset: () => void,
        };
    };

    get structureSelect(): any {
        return store.getters._getSearchStructure;
    }

    get categorySelect(): any {
        return store.getters._getSearchCategories;
    }


    public definitionMeaning(indexOne: boolean | null, indexTwo: boolean | null, filter: string) {
        if (indexOne && indexTwo || !indexOne && !indexTwo) {
            if (filter === 'payment' && indexOne && indexTwo) {
                this.filter.allPaymentRequired = true;
            }
            if (filter === 'auth' && indexOne && indexTwo) {
                this.filter.allAuthRequired = true;
            }
            return null;
        }
        if (indexOne && !indexTwo) {
            return true;
        }
        if (!indexOne && indexTwo) {
            return false;
        }
    }

    public filterDispatch(): void {
        let filterSearch = {
            structureId: this.filter.structureId,
            categoryId: this.filter.categoryId,
            paymentRequired: this.definitionMeaning(this.filter.paymentRequired, this.filter.noPaymentRequired, 'payment'),
            authRequired: this.definitionMeaning(this.filter.authRequired, this.filter.noAuthRequired, 'auth'),
            filterCheck: true,
            allPaymentRequired: this.filter.allPaymentRequired,
            allAuthRequired: this.filter.allAuthRequired,
        };
        this.$router.replace({name: 'search-results'});
        // @ts-ignore
        this.$router.push({name: 'search-results', query: filterSearch});
        this.$emit('filterSearch', filterSearch);
    }

    public clearFilter(): void {
        this.filter = {
            structureId: null,
            categoryId: null,
            paymentRequired: null,
            noPaymentRequired: null,
            authRequired: null,
            noAuthRequired: null,
            allPaymentRequired: false,
            allAuthRequired: false,
        };
        this.$refs.selectCategory.reset();
        this.$refs.selectStructure.reset();
    }

    public selectStructureId(info: any) {
        this.filter.structureId = info;
    }

    public selectStructure(info: any) {
        this.$refs.selectCategory.reset();
        this.filter.structureId = info;
    }

    public selectCategory(info: any) {
        this.filter.categoryId = info;
    }
}
