import {container, injectable} from 'tsyringe';
import {GET} from '@/packages/VHttp/GET';
import {API} from '@/core/configs/api';
import {SearchTagModel} from '@/core/layouts/public/models/search-tag.model';
import {SearchExampleModel} from '@/core/layouts/public/models/seacrh-example.model';
import {
    SearchSelectCategoriesModel,
} from '@/core/layouts/public/models/seacrh-select-category.model';
import {SearchSelectStructureModel} from '@/core/layouts/public/models/seacrh-select-structure.model';

@injectable()
export class PublicService {
    private _get: GET = container.resolve(GET);

    constructor() {
    }

    getSearchTag(): Promise<any> {
        return this._get.setApi(API.search_tag)
            .requestPromise().then(res => {
                return res.data.keywords.items.map((i: any) => {
                    return new SearchTagModel(i);
                });
            });
    }

    getSearchExample(payload: string): Promise<any> {
        return this._get.setApi(`${API.search_services_key_values}?word=${payload}`)
            .requestPromise().then(res => {
                return res.data.items.map((i: any) => {
                    return new SearchExampleModel(i);
                });
            });
    }

    getSearchStructure(): Promise<any> {
        return this._get.setApi(API.feedbackOrganisations)
            .requestPromise().then(res => {
                return res.data.items.map((i: any) => {
                    return new SearchSelectStructureModel(i);
                });
            });
    }

    getSearchCategories(): Promise<any> {
        return this._get.setApi(API.search_category_key_values)
            .requestPromise().then(res => {
                return res.data.items.map((i: any) => {
                    return new SearchSelectCategoriesModel(i);
                });
            });
    }
}
