import {container, injectable} from 'tsyringe';
import {POST} from '@/packages/VHttp/POST';
import {API, Login} from '@/core/configs/api';
import {GET} from '@/packages/VHttp/GET';
import {SelectPermissionModel} from '@/pages/permission-movement-service/models/select-permission.model';
import {ResponseAddAppealModel} from '@/pages/permission-movement-service/models/response-add-appeal.model';

@injectable()
export class PermissionMovementServiceService {
    private _post: POST = container.resolve(POST);
    private _get: GET = container.resolve(GET);

    getCitizenship(): Promise<any> {
        return this._get.setApi(API.getCitizenship)
            .requestPromise().then(res => {
                return res.data.map((item: any) => {
                    return new SelectPermissionModel(item);
                });
            });
    }

    getDocumentSeriesType(): Promise<any> {
        return this._get.setApi(API.getDocumentSeriesType)
            .requestPromise().then(res => {
                return res.data.map((item: any) => {
                    return new SelectPermissionModel(item);
                });
            });
    }

    organPersonType(): Promise<any> {
        return this._get.setApi(API.organPersonType)
            .requestPromise().then(res => {
                return res.data.map((item: any) => {
                    return new SelectPermissionModel(item);
                });
            });
    }

    getTrailerVehicleType(): Promise<any> {
        return this._get.setApi(API.getTrailerVehicleType)
            .requestPromise().then(res => {
                return res.data.map((item: any) => {
                    return new SelectPermissionModel(item);
                });
            });
    }

    postAddAppeal(payload: any): Promise<any> {
        return this._post.setApi(API.addAppeal)
            .setHeaders({cName: 'permission-movement-service', Authorization: `Bearer ${payload.token}`})
            .setPayload(payload)
            .requestPromise().then(res => {
                return new ResponseAddAppealModel(res.data);
            });
    }

    postAddAppealVehicleInfo(payload: any): Promise<any> {
        return this._post.setApi(API.addAppealVehicleInfo)
            .setHeaders({cName: 'permission-movement-service', Authorization: `Bearer ${payload.token}`})
            .setPayload(payload)
            .requestPromise().then(res => {
                return res;
            });
    }

    postAddAppealFile(payload: any): Promise<any> {
        return this._post.setApi(API.addAppealFile)
            .setHeaders({cName: 'permission-movement-service', Authorization: `Bearer ${payload.token}`})
            .setPayload(payload)
            .requestPromise().then(res => {
                return res;
            });
    }

    postConfirmAppeal(payload: any): Promise<any> {
        return this._post.setApi(API.confirmAppeal)
            .setHeaders({cName: 'permission-movement-service', Authorization: `Bearer ${payload.token}`})
            .setParams({
                appealNumber: payload
            })
            .requestPromise().then(res => {
                return res;
            });
    }
}