export class NumbersTransferredStatusModel {
    public operatorName: string | null = null;
    public isPortability: boolean | null = null;

    constructor(item: any) {
        this._setOperatorName(item);
        this._setIsPortability(item);
    }

    private _setOperatorName({OperatorName}: any): void {
        this.operatorName = OperatorName;
    }

    private _setIsPortability({isPortability}: any): void {
        this.isPortability = isPortability;
    }
}
