import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "table-section" }
const _hoisted_2 = { class: "table-section__thead" }
const _hoisted_3 = { class: "table-section__tbody" }
const _hoisted_4 = { class: "table-section__tfoot" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("table", _hoisted_1, [
    _createElementVNode("thead", _hoisted_2, [
      _renderSlot(_ctx.$slots, "header")
    ]),
    _createElementVNode("tbody", _hoisted_3, [
      _renderSlot(_ctx.$slots, "body")
    ]),
    _createElementVNode("tfoot", _hoisted_4, [
      _renderSlot(_ctx.$slots, "footer")
    ])
  ]))
}