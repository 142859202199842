import {container, injectable} from 'tsyringe';
import {GET} from '@/packages/VHttp/GET';
import {API} from '@/core/configs/api';
import {AllServicesModel, TotalServicesModel} from '@/pages/allservices/models/allservices.model';

@injectable()
export class AllServicesService {
    private _get: GET = container.resolve(GET);

    getServices(payload: { pageNumber: number, pageSize: number }): Promise<any> {
        return this._get.setApi(API.services)
            .setParams(payload)
            .requestPromise().then(res => {
                return  new TotalServicesModel(res.data.services)
            });
    }

}
