import {container, injectable} from 'tsyringe';
import {GET} from '@/packages/VHttp/GET';
import {API} from '@/core/configs/api';
import {PhoneInformation} from '@/pages/information-phone-service/models/phone-information.model';
import {InfoNumberModel} from '@/pages/information-phone-service/models/info-number.model';

@injectable()
export class InformationPhoneServiceService {
    private _get: GET = container.resolve(GET);

    constructor() {
    }

    getNumbers(token: string): Promise<any> {
        return this._get.setApi(API.infoNumberList)
            .setHeaders({cName: 'information-phone-service', Authorization: `Bearer ${token}`})
            .requestPromise().then(res => {
                if (res.data) {
                    return res.data.map((item: any) => {
                        return new InfoNumberModel(item);
                    });
                } else {
                    return [];
                }

            });
    }

    getDetailedInfoService(token: string, params: any): Promise<any> {
        return this._get.setApi(API.detailedInfoService)
            .setHeaders({cName: 'information-phone-service', Authorization: `Bearer ${token}`})
            .setParams(params)
            .requestPromise().then(res => {
                return new PhoneInformation(res.data);
            });
    }

}