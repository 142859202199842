<style src="./search-tag.component.scss" lang="scss"></style>

<template>
  <div class="search__wrapper" v-if="renderComponent">
    <div class="search__tags" v-if="renderComponent">
      <swiper
          slides-per-view="auto"
          :space-between="10"
          :roundLengths="true"
      >
        <swiper-slide
            v-for="(tag,index) in tags"
            :key="index"
            @click="searchTag({
                keywordId:tag.id,
                word:tag.name
              })"
        >
          <div class="search__tag">
            <div
                v-if="isSearchResult"
            >{{ tag.name }}
            </div>
            <router-link
                v-if="!isSearchResult"
                :to="{name:`search-results`, params: {
                keywordId:tag.id,
                word:tag.name
              }, query :{
                keywordId:tag.id,
                word:tag.name
              }
            }"
            >
              {{ tag.name }}
            </router-link>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script src="./search-tag.component.ts"></script>
