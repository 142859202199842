import {IInformationPhoneServiceState} from '@/pages/information-phone-service/store/state';
import {IPhoneInformation} from '@/pages/information-phone-service/types/phone-information';
import {IInfoNumber} from '@/pages/information-phone-service/types/info-number';

export const InformationPhoneServiceStateGetters = {
    _getInformationPhone: (state: IInformationPhoneServiceState): IPhoneInformation => {
        return state.phoneInformation;
    },
    _getInfoNumbers: (state: IInformationPhoneServiceState): IInfoNumber [] | [] => {
        return state.infoNumbers;
    },
};
