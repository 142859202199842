import {container} from 'tsyringe';
import {
    MonthlyCallsInformationServiceService
} from '@/pages/monthly-calls-information-service/servises/monthly-calls-information-service.service';


const service = container.resolve(MonthlyCallsInformationServiceService);

export const MonthlyCallsInformationServiceStateActions = {
    getCurrentReceipt(context: any, payload:any): void {
        service.getCurrentReceipt(payload).then(res => context.commit('SET_CURRENT_RECEIPT', res));
    },
};
