import {container, injectable} from 'tsyringe';
import {GET} from '@/packages/VHttp/GET';
import {API} from '@/core/configs/api';
import {GetNumberModel} from '@/pages/local-talks-service/models/get-number.model';
import {TotalGetCallHistory} from '@/pages/local-talks-service/models/total-get-call-history';

@injectable()
export class LocalTalksServiceService {
    private _get: GET = container.resolve(GET);

    constructor() {
    }

    getCallHistory(payload: any): Promise<any> {
        return this._get.setApi(API.callHistory)
            .setHeaders({cName: 'local-talks-service', Authorization: `Bearer ${payload.token}`})
            .setParams({
                number: payload.number,
                pageNumber: payload.pageNumber,
                pageSize: payload.pageSize
            })
            .requestPromise().then(res => {
                return new TotalGetCallHistory(res.data);
            });
    }

}