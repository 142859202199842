import {ElectronOrders} from '@/pages/information-electronic-orders-service/types/electron-orders';
import {ElectronOrder} from '@/pages/information-electronic-orders-service/models/electron-order.model';

export class TotalElectronOrderModel {
    public totalCountOrders: number = 0;
    public orders: ElectronOrders[] | null = null;

    constructor(item: any) {
        this._setTotalCountOrders(item);
        this._setOrders(item);
    }

    private _setTotalCountOrders({totalRowCount}: any): void {
        if(totalRowCount) {
            this.totalCountOrders = totalRowCount;
        } else  {
            this.totalCountOrders = 0;
        }
    }

    private _setOrders({data}: any): void {
        if (data) {
            this.orders = data.map((item: any) => {
                return new ElectronOrder(item);
            });
        } else {
            this.orders = [];
        }
        // this.orders =  new ElectronOrder(item);
    }

}
