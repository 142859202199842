import {IUserAccountState} from './state.d';
import {UserAccountGetters} from './getters';
import {UserAccountMutations} from './mutations';
import {UserAccountActions} from './actions';


const UserAccountStateInitial: IUserAccountState = {
    // newUserState: {
    //     userContactTypeId: '',
    //     value: '' ,
    //     userId: '',
    //     id: ''
    // },
    userDetails: [{
        userContactTypeId: 1,
        value: '+994',
        userId: null,
        id: null
    },

        {
            userContactTypeId: 2,
            value: '',
            userId: null,
            id: null
        }
    ]


};


export const UserAccountState = {
    state: UserAccountStateInitial,
    getters: UserAccountGetters,
    mutations: UserAccountMutations,
    actions: UserAccountActions
};
