import {IDebt} from '@/pages/information-phone-service/types/debt';
import {DebtModel} from '@/pages/information-phone-service/models/debt.model';
import {IPayments} from '@/pages/information-phone-service/types/payments';
import {PaymentsModel} from '@/pages/information-phone-service/models/payments.model';

export class PhoneInformation {
    public name: string | null = null;
    public address: string | null = null;
    public date: string | null = null;
    public provider: string | null = null;
    public service: string | null = null;
    public junction: string | null = null;
    public debts: IDebt[] = [];
    public payment: IPayments[] = [];

    constructor(item: any) {
        this._setName(item);
        this._setAddress(item);
        this._setDate(item);
        this._setProvider(item);
        this._setService(item);
        this._setJunction(item);
        this._setDebts(item);
        this._setPayment(item);
    }

    /**
     * Set name
     * @param info
     * @private
     */

    private _setName({info}: any): void {
        this.name = info?.name;
    }

    /**
     * Set address
     * @param info
     * @private
     */

    private _setAddress({info}: any): void {
        this.address = `${info?.street} ${info?.address}`;
    }

    /**
     * Set date
     * @param info
     * @private
     */

    private _setDate({info}: any): void {
        this.date = info?.contractDate;
    }

    /**
     * Set provider
     * @param provider
     * @private
     */


    private _setProvider({provider}: any): void {
        this.provider = provider?.name;
    }

    /**
     * Set services
     * @param activeServices
     * @private
     */

    private _setService({activeServices}: any): void {
        this.service = activeServices?.serviceList;

    }

    /**
     * Set junction
     * @param info
     * @private
     */

    private _setJunction({info}: any): void {
        this.junction = info?.organizationName;
    }

    /**
     * Set debts
     * @param debs
     * @private
     */

    private _setDebts({debts}: any): void {
        this.debts = debts?.data?.map((item: any) => {
            return new DebtModel(item);
        });
    }

    /**
     * Set payment
     * @param payment
     * @private
     */

    private _setPayment({payment}: any): void {
        if (payment) {
            this.payment = payment?.data?.map((item: any) => {
                return new PaymentsModel(item);
            });
        } else {
            this.payment = [];
        }

    }

}