import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';

@Options({
    name: 'footer-menu-item-component',
    props: {
        item: {
            type: Object,
            required: false,
        },
    },
})

export default class FooterMenuItemComponent extends Vue {
    public linkClick(id: number) {
        if (this.$route.name === 'information') {
            this.$emit('itemInfo', id);
        }
    }

    get isHome() {
        return this.$route.name;
    }



    public moveToPage(link: string) {
        if (this.$route.name !== 'home') {
            this.$router.push({
                name: 'home',
                params: {scroll: link}
            });
        }
    }

    public scroll(id: string) {
        let element = document.querySelector(`#${id}`);
        if (element) {
            element.scrollIntoView();
        }
    }
}
