import {IHomeState} from '@/pages/home/store/state';
import {IPinnedService} from '@/pages/home/types/pinned-service';
import {IMostUsedService} from '@/pages/home/types/most-used-service';
import {IServiceCategory} from '@/pages/home/types/service-category';

export const HomeStateGetters = {

    _getPinnedServices: (state: IHomeState): IPinnedService[] => {
        return state.pinnedServices;
    },

    _getMostUsedServices: (state: IHomeState): IMostUsedService[] => {
        return state.mostUsedServices;
    },

    _getServiceCategories: (state: IHomeState): IServiceCategory[] => {
        return state.serviceCategories;
    },
};
