import {container} from 'tsyringe';
import {AllServicesService} from '@/pages/allservices/services/allservices.service';

const service = container.resolve(AllServicesService);

export const AllServicesStateActions = {
    getAllServices(context: any, payload: { pageNumber: number, pageSize: number }): void {
        service.getServices(payload).then(res => context.commit('SET_ALL_SERVICES', res));
    },
};
