export class FileTypePermitModel {
    public id: number | null = null;
    public label: string | null = null;
    public description: string | null = null;
    public name: string | null = null;
    public isRequired: boolean | null = null;
    public isSigned: boolean | null = null;
    public file: File | null = null;
    public error: boolean | null = false;
    public uploadLimit: number | null = null;

    constructor(item: any) {
        this._setId(item);
        this._setLabel(item);
        this._setName(item);
        this._setIsRequired(item);
        this._setIsSigned(item);
        this._setDescription(item);
        this._setUploadLimit(item);
    }

    private _setId({fileInputId}: any) {
        this.id = fileInputId;
    }

    private _setLabel({fileInputName}: any) {
        this.label = fileInputName;
    }

    private _setName({fileInputLabel}: any) {
        this.name = fileInputLabel;
    }

    private _setIsRequired({isRequired}: any) {
        this.isRequired = isRequired;
    }

    private _setIsSigned({isSigned}: any) {
        this.isSigned = isSigned;
    }

    private _setDescription({fileInputDescription}: any) {
        this.description = fileInputDescription;
    }

    private _setUploadLimit({fileLimit}: any) {
        this.uploadLimit = fileLimit;
    }
}