<style src="./button.component.scss" lang="scss"></style>
<template>
  <!-- Button-->
  <button :disabled="disabled" class="btn" :class="[buttonColor, {'btn--disabled':disabled}]">
    <slot name="icon"></slot>
    <slot/>
  </button>
</template>
<script src="./button.component.ts"></script>




