import {createRouter, createWebHistory} from 'vue-router';
import routes from './routes';
import {store} from '@/store';

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        return {top: 0};
    },
});

router.beforeEach((to, from, next) => {
    let oldLocal = from.params.local;
    let newLocal = to.params.local;
    if (!newLocal) {
        let lang = store.getters._getLanguage;
        return next({
            name: 'public', params: {
                local: 'az'
            }
        });
    }
    if (newLocal !== 'az') {
        const link = String(to.name);
        return next({
            name: link, params: {
                local: 'az'
            }
        });
    }


    // if (newLocal !== 'az') {
    //     if (oldLocal) {
    //         store.commit('SET_LANGUAGE', oldLocal);
    //     }
    //     // let lang = store.getters._getLanguage;
    //     // return next({
    //     //     name: '404', params: {
    //     //         local: lang
    //     //     }
    //     // });
    // } else {
    //     if (oldLocal && newLocal !== oldLocal) {
    //         store.commit('SET_LANGUAGE', newLocal);
    //     } else {
    //         store.commit('SET_LANGUAGE', newLocal);
    //     }
    return next();
    // }

});

export default router;
