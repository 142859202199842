import {container, injectable} from 'tsyringe';
import {API} from '@/core/configs/api';
import {SelectPermissionModel} from '@/pages/permission-movement-service/models/select-permission.model';
import {POST} from '@/packages/VHttp/POST';
import {GET} from '@/packages/VHttp/GET';
import {ApplicantInfoModel} from '@/pages/appeal-radio-service/models/applicant-info.model';
import {RadioOrganizationNameModel} from '@/pages/appeal-radio-service/models/radio-organization-name.model';

@injectable()
export class AppealRadioServiceService {
    private _post: POST = container.resolve(POST);
    private _get: GET = container.resolve(GET);

    getRadioOrganizationName(payload: any): Promise<any> {
        return this._get.setApi(API.radioOrganizationName)
            .setParams(payload)
            .requestPromise().then(res => {
                return new RadioOrganizationNameModel(res.data);
            });
    }

    getFrequencyUnits(): Promise<any> {
        return this._get.setApi(API.radioFrequencyUnits)
            .setHeaders({cName: 'appeal-radio-service'})
            .requestPromise().then(res => {
                return res.data.map((item: any) => {
                    return new SelectPermissionModel(item);
                });
            });
    }

    getRadioCitizenship(): Promise<any> {
        return this._get.setApi(API.commonCitizenship)
            .setHeaders({cName: 'appeal-radio-service'})
            .requestPromise().then(res => {
                return res.data.items.map((item: any) => {
                    return new SelectPermissionModel(item);
                });
            });
    }

    getRadioDocumentSeriesType(payload: number): Promise<any> {
        return this._get.setApi(`${API.commonDocumentSeriesType}/${payload}`)
            .setHeaders({cName: 'appeal-radio-service'})
            .requestPromise().then(res => {
                return res.data.items.map((item: any) => {
                    return new SelectPermissionModel(item);
                });
            });
    }

    getRadioOrganPersonType(): Promise<any> {
        return this._get.setApi(API.commonApplicantType)
            .setParams({filterId:1})
            .requestPromise().then(res => {
                return res.data.items.map((item: any) => {
                    return new SelectPermissionModel(item);
                });
            });
    }

    getInterferenceTechnologyTypes(): Promise<any> {
        return this._get.setApi(API.radioInterferenceTechnologyTypes)
            .requestPromise().then(res => {
                return res.data.interferenceTechnologyTypes.map((item: any) => {
                    return new SelectPermissionModel(item);
                });
            });
    }

    getInterferenceTypes(): Promise<any> {
        return this._get.setApi(API.radioInterferenceTypes)
            .requestPromise().then(res => {
                return res.data.interferenceTypes.map((item: any) => {
                    return new SelectPermissionModel(item);
                });
            });
    }

    getEffectDurations(): Promise<any> {
        return this._get.setApi(API.radioEffectDurations)
            .requestPromise().then(res => {
                return res.data.effectDurations.map((item: any) => {
                    return new SelectPermissionModel(item);
                });
            });
    }

    postApplicant(payload: any): Promise<any> {
        return this._post.setApi(API.radioApplicant)
            .setHeaders({cName: 'appeal-radio-service', Authorization: `Bearer ${localStorage.getItem('mainToken')}`})
            .setPayload(payload)
            .requestPromise().then(res => {
                return new ApplicantInfoModel(res.data);
            });
    }

    postAppeal(payload: any): Promise<any> {
        return this._post.setApi(API.radioCreateAppeal)
            .setHeaders({cName: 'appeal-radio-service', Authorization: `Bearer ${localStorage.getItem('mainToken')}`})
            .setPayload(payload)
            .requestPromise().then(res => {
                return res;
            });
    }
}