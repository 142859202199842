export class PaymentItemTotalModel {
    public description: number | null = null;
    public totalCalc: number | null = null;
    public totalPay: number | null = null;

    constructor(item: any) {
        this._setDescription(item);
        this._setTotalCalc(item);
        this._setTotalPay(item);
    }

    /**
     * Set description
     * @param description
     * @private
     */

    private _setDescription({description}: any): void {
        this.description = description;
    }

    /**
     * Set totalCalc
     * @param totalCalc
     * @private
     */

    private _setTotalCalc({totalCalc}: any): void {
        this.totalCalc = totalCalc;
    }

    /**
     * Set totalPay
     * @param totalPay
     * @private
     */

    private _setTotalPay({totalPay}: any): void {
        this.totalPay = totalPay;
    }

}
