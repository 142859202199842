import {IServiceState} from '@/pages/services/store/state';


export const ServiceGetters = {
    _getImeiInfo: (state: IServiceState) => {
        return state.imeiInfo;
    },
    _getResponsiveImeiCheck: (state: IServiceState) => {
        return state.responsiveImeiCheck;
    },
    _getFormRegistrationData: (state: IServiceState) => {
        return state.formRegistrationData;
    },
    _getReceiptPayment: (state: IServiceState) => {
        return state.receipt;
    },

    _getDocumentTypes: (state: IServiceState) => {
        return state.documentTypes;
    },

    _getDocumentGroups: (state: IServiceState) => {
        return state.documentGroup;
    },
};
