import {container} from 'tsyringe';
import {
    ReceiptsPaidTelephoneServiceService
} from '@/pages/receipts-paid-telephone-service/services/receipts-paid-telephone-service.service';

const service = container.resolve(ReceiptsPaidTelephoneServiceService);

export const ReceiptsPaidTelephoneServiceStateActions = {
    getReceipts(context: any, payload:any): void {
        service.getReceipts(payload).then(res => context.commit('SET_RECEIPTS', res));
    },
};
