import {IFeedbackSelect} from '@/core/shared/feadback/types/feedback-select';
import {IFeedbackState} from '@/core/shared/feadback/store/state';

export const FeedbackStateGetters = {
    _getFeedbackAppealTypes: (state: IFeedbackState): IFeedbackSelect[] => {
        return state.feedbackAppealTypes;
    },

    _getFeedbackServices: (state: IFeedbackState): IFeedbackSelect[] => {
        return state.feedbackServices;
    },

    _getFeedbackOrganisations: (state: IFeedbackState): IFeedbackSelect[] => {
        return state.feedbackOrganisations;
    },
    _getFeedbackDirections: (state: IFeedbackState): IFeedbackSelect[] => {
        return state.feedbackDirections;
    },
}
