import {Options, Vue} from 'vue-class-component';
import {locale} from '@/assets/db/db.conf.ts';
import {store} from '@/store';
import {IMostUsedService} from '@/pages/home/types/most-used-service';
import {HomeSandbox} from '@/pages/home/sandbox/home.sandbox';
import {container} from 'tsyringe';
import {AuthenticationSandbox} from '@/core/auth/authentication.sandbox';
import ServiceCardComponent from '@/core/shared/service-card/service-card.component.vue';

@Options({
    name: 'most-used-services',
    components: {
        ServiceCardComponent
    }
})
export default class MostUsedServices extends Vue {
    public sandboxAuth: AuthenticationSandbox = container.resolve(AuthenticationSandbox);
    public locale$: any = locale;
    public locale: any = null;
    private sandbox: HomeSandbox = container.resolve(HomeSandbox);

    get mostUsedServices(): IMostUsedService[] {
        return store.getters._getMostUsedServices;
    }

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.home) {
                this.locale = res.home.header;
            }
        });
        this.sandbox.getMostUsedServices();
    }
}
