import {IInformationElectronicOrdersServiceState} from '@/pages/information-electronic-orders-service/store/state';
import {ElectronOrders} from '@/pages/information-electronic-orders-service/types/electron-orders';

export const InformationElectronicOrdersServiceStateGetters = {
    _getOrders: (state: IInformationElectronicOrdersServiceState): ElectronOrders[] => {
        return state.orders;
    },
    _getTotalCountOrders: (state: IInformationElectronicOrdersServiceState): number => {
        return state.totalCountOrders;
    },
};
