import {container, injectable} from 'tsyringe';
import {GET} from '@/packages/VHttp/GET';
import {POST} from '@/packages/VHttp/POST';
import {API} from '@/core/configs/api';
import {FeedbackSelectModel} from '@/core/shared/feadback/models/feedback-select.model';
import {FeedbackPostModel} from '@/core/shared/feadback/models/feedback-post.model';

@injectable()
export class FeedbackService {
    private _get: GET = container.resolve(GET)
    private _post: POST = container.resolve(POST)

    constructor() {
    }

    getFeedbackDirections(): Promise<any> {
        return this._get.setApi(API.feedbackDirections)
            .requestPromise().then(res => {
                return res.data.items.map((i: any) => {
                    return new FeedbackSelectModel(i);
                })
            });
    }

    getFeedbackAppealTypes(): Promise<any> {
        return this._get.setApi(API.feedbackAppealTypes)
            .requestPromise().then(res => {
                return res.data.items.map((i: any) => {
                    return new FeedbackSelectModel(i);
                })
            });
    }

    getFeedbackServices(payload:number): Promise<any> {
        return this._get.setApi(API.feedbackServices)
            .setParams({structureId: payload})
            .requestPromise().then(res => {
                return res.data.items.map((i: any) => {
                    return new FeedbackSelectModel(i);
                })
            });
    }

    getFeedbackOrganisations(): Promise<any> {
        return this._get.setApi(API.feedbackOrganisations)
            .requestPromise().then(res => {
                return res.data.items.map((i: any) => {
                    return new FeedbackSelectModel(i);
                })
            });
    }

    sendFeedback(payload: any): Promise<any> {
        return this._post.setApi(API.feedbackPost)
            .setHeaders({Authorization: `Bearer ${payload.token}`})
            .setPayload(payload.data)
            .requestPromise();
    }
}
