import {IAuthState} from './state';
import jwt_decode from 'jwt-decode';
import {AuthenticationModel} from '@/core/auth/authentication.model';

export const AuthStateMutations = {
    setToken(state: IAuthState, payload: string): void {
        state.token =payload;
        let decoded = jwt_decode(payload);
        state.user = new AuthenticationModel(decoded);
    },
};
