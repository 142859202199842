export class SearchResultsModel {
    public id: number | null = null;
    public name: string | null = null;
    public icon: string | null = null;
    public description: string | null = null;
    public auth: boolean | null = null;
    public payment: boolean | null = null;
    public structure: string | null = null;
    public component: string | null = null;

    constructor(item: any) {
        this._setId(item);
        this._setIcon(item);
        this._setName(item);
        this._setDescription(item);
        this._setAuth(item)
        this._setPayment(item)
        this._setPayment(item)
        this._setStructure(item)
        this._setComponent(item)
    }

    /**
     * Set structure
     * @param serviceStructures
     * @private
     */
    private _setStructure({serviceStructures}: any) {
        this.structure = serviceStructures[0].structure.structureLanguages[0].name;
    }

    /**
     * Set Id
     * @param ID
     * @private
     */
    private _setId({id}: any) {
        this.id = id;
    }

    /**
     * Set icon
     * @param file
     * @private
     */
    private _setIcon({file}: any) {
        this.icon = file.fileUrl;
    }

    /**
     * Set title
     * @param structureLanguages
     * @private
     */
    private _setName({serviceLanguages}: any) {
        this.name = serviceLanguages[0]?.name;
    }

    /**
     * Set description
     * @param structureLanguages
     * @private
     */
    private _setDescription({serviceLanguages}: any) {
        this.description = serviceLanguages[0]?.description;
    }

    /**
     * Set description
     * @param authRequired
     * @private
     */
    private _setAuth({authRequired}: any) {
        this.auth = authRequired;
    }

    /**
     * Set description
     * @param paymentRequired
     * @private
     */
    private _setPayment({paymentRequired}: any) {
        this.payment = paymentRequired;
    }

    private _setComponent({component}: any) {
        this.component = component?.name;
    }
}
