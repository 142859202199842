export class ElectronOrder {
    public orderNumber: string | null = null;
    public postIndex: string | null = null;
    public streetName: string | null = null;
    public addressFirst: string | null = null;
    public addressSecond: string | null = null;
    public selectedPhone: string | null = null;
    public operationType: string | null = null;
    public operationStatus: string | null = null;
    public createDate: string | null = null;
    public isOpen: boolean = false;
    public operationStatusID: number | null = null;

    constructor(item: any) {
        this._setOrderNumber(item);
        this._setPostIndex(item);
        this._setStreetName(item);
        this._setAddressFirst(item);
        this._setAddressSecond(item);
        this._setSelectedPhone(item);
        this._setOperationType(item);
        this._setOperationStatus(item);
        this._setCreateDate(item);
        this._setOperationStatusID(item);
    }

    private _setOrderNumber({orderNumber}: any): void {
        this.orderNumber = orderNumber;
    }

    private _setPostIndex({postIndex}: any): void {
        this.postIndex = postIndex;
    }

    private _setStreetName({streetName}: any): void {
        this.streetName = streetName;
    }

    private _setAddressFirst({address1}: any): void {
        this.addressFirst = address1;
    }

    private _setAddressSecond({address2}: any): void {
        this.addressSecond = address2;
    }

    private _setSelectedPhone({selectedPhone}: any): void {
        this.selectedPhone = selectedPhone;
    }

    private _setOperationType({operationType}: any): void {
        this.operationType = operationType;
    }

    private _setOperationStatus({operationStatus}: any): void {
        this.operationStatus = operationStatus;
    }

    private _setCreateDate({createDate}: any): void {
        this.createDate = createDate;
    }

    private _setOperationStatusID({operationStatusID}: any) {
        this.operationStatusID = operationStatusID;
    }
}
