export class GetStatusMessageModel {
    public code: string | null = null;
    public text: string | null = null;

    constructor(item: any) {
        this._setCode(item);
        this._setText(item);
    }

    /**
     * Set code
     * @param code
     * @private
     */
    private _setCode({code}: any): void {
        this.code = code;
    }

    /**
     * Set text
     * @param text
     * @private
     */

    private _setText({text}: any): void {
        this.text = text;
    }
}