import {IIssuingCertificateConformityServiceState} from '@/pages/issuing-certificate-conformity-service/store/state';
import {IInputPayload} from '@/types/input-payload';
import {IFileType} from '@/pages/issuing-certificate-conformity-service/types/file-type';
import {IUserData} from '@/pages/appeal-radio-service/types/user-data';
import {IApplicantForm} from '@/pages/issuing-certificate-conformity-service/types/applicant-form';

export const IssuingCertificateConformityServiceStateMutations = {
    SET_APPLICANT_CERTIFICATE(state: IIssuingCertificateConformityServiceState, payload: IInputPayload) {
        state.applicantForm[`${payload.type}`] = payload.value;
    },
    SET_APPLICANT_CERTIFICATE_ALL(state: IIssuingCertificateConformityServiceState, payload:IApplicantForm ) {
        state.applicantForm = payload;
    },
    SET_APPLICANT_CERTIFICATE_INFO(state: IIssuingCertificateConformityServiceState, payload: IUserData) {
        state.userInfo = payload;
    },

    SET_CERT_FILES(state: IIssuingCertificateConformityServiceState, payload: IFileType[]) {
        state.filesForCertificate = payload;
    },
    SET_CERT_FILES_STEP3(state: IIssuingCertificateConformityServiceState, payload: IFileType[]) {
        state.filesForStep3 = payload;
    },

    SET_CERTIFICATE__STATUS(state: IIssuingCertificateConformityServiceState, payload: number) {
        state.certificateStatus = payload;
    },

    SET_CERTIFICATE__TYPE(state: IIssuingCertificateConformityServiceState, payload: number) {
        state.certificateType = payload;
    },
};