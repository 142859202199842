import {container} from 'tsyringe';
import {HomeService} from '@/pages/home/services/home.service';

const service = container.resolve(HomeService);

export const HomeStateActions = {

    getPinnedServices(context: any): void {
        service.getPinnedServices().then(res => context.commit('SET_PINNED_SERVICES', res));
    },

    getMostUsedServices(context: any): void {
        service.getMostUsedServices().then(res => context.commit('SET_MOST_USED_SERVICES', res));
    },

    getServiceCategories(context: any): void {
        service.getServiceCategories().then(res => context.commit('SET_SERVICE_CATEGORIES', res));
    },

};
