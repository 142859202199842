import {container} from 'tsyringe';
import {InformationService} from '@/pages/information/services/information.service';

const service = container.resolve(InformationService);

export const InformationStateActions = {
    getInformation(context: any, payload: number): void {
        service.getInformation(payload).then(res => context.commit('SET_INFORMATION', res));
    }
};
