import {IAppealRadioServiceState} from './state';
import {AppealRadioServiceStateGetters} from './getters';
import {AppealRadioServiceStateMutations} from './mutations';
import {AppealRadioServiceStateActions} from './actions';

const AppealRadioServiceStateInitial: IAppealRadioServiceState = {
    radioPersonalInformation: {
        documentNumber: null,
        documentTypeNumber: null,
        fin: null,
        phone: '+994(',
        email: null,
        tin: '',
        organization: '',
        position: '',
        registration: null,
        authorityFile: null,
        legalEntityFullName: null,
        positionLegalEntity: null,
        copyExtractFile: null,
        legalEntityAddress: null,

    },
    radioInformation: {
        duration: null,
        dates: [{
            startDate: new Date(),
            startTime: '00:00',
            endDate: new Date(),
            endTime: '00:00'
        }],
        frequencyBand: null,
        typeTechnology: null,
        obstacleDirection: null,
        natureObstacle: [],
        obstacleArea: [''],
        technicalIndicators: null,
        file: null,
        frequencyUnits: null
    },
    interferenceTechnologyTypes: [],
    interferenceTypes: [],
    durations: [],
    applicantId: null,
    userInfo: null,
    frequencyUnits: [],
    organizationName: null
};

export const AppealRadioServiceState = {
    state: AppealRadioServiceStateInitial,
    getters: AppealRadioServiceStateGetters,
    mutations: AppealRadioServiceStateMutations,
    actions: AppealRadioServiceStateActions

};