import {IUserFeedbackState} from '@/pages/user-feedback/store/state';

export const UserFeedbackGetters = {
    _getFeedBackAll: (state: IUserFeedbackState) => {
        return state.feedBackAll;
    },
    _getTotalCountFeedBackAll: (state: IUserFeedbackState) => {
        return state.totalCountFeedBackAll;
    },
};
