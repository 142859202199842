import {container} from 'tsyringe';
import {
    PermissionMovementServiceService
} from '@/pages/permission-movement-service/services/permission-movement-service.service';

const service = container.resolve(PermissionMovementServiceService);
export const PermissionMovementServiceStateActions = {
    getCitizenship(context: any): void {
        service.getCitizenship().then(res => context.commit('SET_CITIZENSHIP', res));
    },
    getDocumentSeriesType(context: any): void {
        service.getDocumentSeriesType().then(res => context.commit('SET_DOCUMENT_SERIES_TYPE', res));
    },
    organPersonType(context: any): void {
        service.organPersonType().then(res => context.commit('SET_ORGAN_PERSON_TYPE', res));
    },
    getTrailerVehicleType(context: any): void {
        service.getTrailerVehicleType().then(res => context.commit('SET_TRAILER_VEHICLE_TYPE', res));
    },
    postAddAppeal(context: any, payload: any): any {
        return service.postAddAppeal(payload).then(res => context.commit('SET_APPEAL', res));
    },
    postAddAppealVehicleInfo(context: any, payload: any): any {
        return service.postAddAppealVehicleInfo(payload);
    },

    postAddAppealFile(context: any, payload: any): any {
        return service.postAddAppealFile(payload);
    },

    postConfirmAppeal(context: any, payload: any): any {
        return service.postConfirmAppeal(payload);
    },
};