import {container, injectable} from 'tsyringe';
import {API} from '@/core/configs/api';
import {GET} from '@/packages/VHttp/GET';
import {TotalCountFeedbacksModel} from '@/pages/user-feedback/models/total-count-feedbacks.model';

@injectable()
export class UserFeedbackService {
    private _get: GET = container.resolve(GET);

    constructor() {
    }

    getFeedBackAll(payload: any): Promise<any> {
        return this._get.setApi(API.feedbackGetAll)
            .setHeaders({Authorization: `Bearer ${payload.token}`})
            .setParams({
                pageSize: payload.pageSize,
                pageNumber: payload.pageNumber,
                word: payload.word,
            })
            .requestPromise().then(res => {
                return new TotalCountFeedbacksModel(res.data.feedbacks);
            });
    }
}
