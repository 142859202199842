import {container, injectable} from 'tsyringe';
import {GET} from '@/packages/VHttp/GET';
import {API} from '@/core/configs/api';
import {FooterDataModel} from '@/core/layouts/public/components/footer/models/foter-data.model';

@injectable()
export class FooterService {
    private _get: GET = container.resolve(GET)

    constructor() {
    }

    getFooterModalData(): Promise<any> {
        return this._get.setApi(API.contentCategories).setParams({
            PageNumber: 1,
            PageSize: 1000
        })
            .requestPromise().then(res => {
                return res.data.contents.items.map((i: any) => {
                    return new FooterDataModel(i);
                })
            });
    }
}
