
      import {IFooterState} from './state'
      import {FooterStateGetters} from './getters'
      import {FooterStateMutations} from './mutations'
      import {FooterStateActions} from './actions'

      const FooterStateInitial: IFooterState = {
          contents: []
      }

      export const FooterState = {
        state: FooterStateInitial,
        getters: FooterStateGetters,
        mutations: FooterStateMutations,
        actions: FooterStateActions
    }
