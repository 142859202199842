export class OrganisationContactModel {
    public id: number | null = null;
    public name: string | null = null;
    public value: string | null = null;

    constructor(item: any) {
        this._setId(item);
        this._setName(item);
        this._setValue(item);

    }

    /**
     * Set Id
     * @param ID
     * @private
     */
    private _setId({id}: any) {
        this.id = id;
    }


    /**
     * set name
     * @param structureContactType
     * @private
     */
    private _setName({structureContactType}: any) {
        this.name = structureContactType?.structureContactTypeLanguages[0]?.name;
    }

    /**
     * set value
     * @param value
     * @private
     */
    private _setValue({value}: any) {
        this.value = value;
    }
}
