export class InformationModel {
    public id: number | null = null;
    public title: string | null = null;
    public subTitle: string | null = null;
    public contentHTML: string | null = null;

    constructor(item: any) {
        this._setId(item);
        this._setTitle(item);
        this._setSubTitle(item);
        this._setContentHTML(item);

    }

    /**
     * Set Id
     * @param id
     * @private
     */

    private _setId({id}: any): void {
        this.id = id;
    }

    /**
     * Set title
     * @param contentLanguages
     * @private
     */

    private _setTitle({contentLanguages}: any): void {
        this.title = contentLanguages[0].title;
    }

    /**
     * Set subTitle
     * @param contentLanguages
     * @private
     */

    private _setSubTitle({contentLanguages}: any): void {
        this.subTitle = contentLanguages[0].subTitle;
    }

    /**
     * Set contentHTML
     * @param contentLanguages
     * @private
     */

    private _setContentHTML({contentLanguages}: any): void {
        this.contentHTML = contentLanguages[0].contentHTML;
    }
}
