import {container, injectable} from 'tsyringe';
import {map} from 'rxjs/operators';
import {GET} from "@/packages/VHttp/GET";
import {API} from "@/core/configs/api";
import {PrivacyDataModel} from "@/pages/privacy/models/privacy-data-model";

@injectable()
export class PrivacyService {
    private _get: GET = container.resolve(GET)

    constructor() {
    }

    getPrivacyContentData(): Promise<any> {
        return this._get.setApi(API.contentCategories).setParams({
            PageNumber: 1,
            PageSize: 1000
        })
            .requestPromise().then(res => {
                return res.data.contents.items.map((i: any) => {
                    return new PrivacyDataModel(i);
                })
            });
    }
}
