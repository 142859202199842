import {container, injectable} from 'tsyringe';
import {API} from '@/core/configs/api';
import {GET} from '@/packages/VHttp/GET';
import {PostTrackingResponsiveModel} from '@/pages/postal-tracking-service/models/post-tracking-responsive.model';

@injectable()
export class PostalTrackingServiceService {
    private _get: GET = container.resolve(GET);

    constructor() {
    }

    getPostTracking(payload: any): Promise<any> {
        return this._get.setApi(API.postTracking)
            .setHeaders({cName: 'postal-tracking-service', Authorization: `Bearer ${payload.token}`})
            .setParams({
                trackingNumber: payload.trackingNumber,
            })
            .requestPromise().then(res => {
                return new PostTrackingResponsiveModel(res.data);
            });
    }

}