import {container} from 'tsyringe';
import {
    InformationElectronicOrdersServiceService
} from '@/pages/information-electronic-orders-service/servises/information-electronic-orders-service.service';

const service = container.resolve(InformationElectronicOrdersServiceService);
export const InformationElectronicOrdersServiceStateActions = {
    getElectronOrders(context: any, payload: any): void {
        service.getElectronOrders(payload).then(res => context.commit('SET_ORDERS', res));
    },
};
