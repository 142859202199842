export class UserDataModel {
    public name: null | string = null;
    public surname: null | string = null;
    public fatherName: null | string = '';
    public pin: null | string = null;
    public birthDate: null | string = null;
    public image: null | string = null;
    public address: null | string = null;
    public document: null | string = null;

    constructor(data: any) {
        this._setName(data);
        this._setSurname(data);
        this._setFatherName(data);
        this._setPin(data);
        this._setBirthDate(data);
        this._setImage(data);
        this._setAddress(data);
        this._setDocument(data);
    }

    private _setName({Name}: any) {
        this.name = Name;
    }

    private _setSurname({Surname}: any) {
        this.surname = Surname;
    }

    private _setFatherName({Patronymic}: any) {

        if (Patronymic) {
            this.fatherName = Patronymic;
        }
    }

    private _setPin({PIN}: any) {
        this.pin = PIN;
    }

    private _setBirthDate({BirthDate}: any) {
        // this.birthDate = dataFormat(userData?.BirthDate);
        this.birthDate = BirthDate?.split('T')[0];
    }


    private _setImage({Image}: any) {
        this.image = Image;
    }

    private _setAddress({RegisteredAddressDetail}: any) {
        this.address = RegisteredAddressDetail?.Address;
    }

    private _setDocument({DocumentNumber}: any) {
        this.document = DocumentNumber;
    }

}