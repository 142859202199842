import {container} from 'tsyringe';
import {FaqService} from "@/pages/faq/services/faq.service";

const service = container.resolve(FaqService);

export const FaqStateActions = {
    getFaqs(context: any): void {
        service.getFaqs().then(res => context.commit('SET_FAQS', res));
    },
}
