import {container} from 'tsyringe';
import {PublicService} from "@/core/layouts/public/services/public.service";

const service = container.resolve(PublicService);

export const PublicStateActions = {
    getSearchTag(context: any): void {
        service.getSearchTag().then(res => context.commit('SET_Search_Tag', res));
    },
    getSearchExample(context: any, payload: string): void {
        service.getSearchExample(payload).then(res => context.commit('SET_SEARCH_EXAMPLE', res));
    },
    getSearchStructure(context: any): void {
        service.getSearchStructure().then(res => context.commit('SET_SEARCH_STRUCTURE', res));
    },
    getSearchCategories(context: any, payload:number): void {
        service.getSearchCategories().then(res => context.commit('SET_SEARCH_CATEGORIES', res));
    },

}
