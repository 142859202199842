import {ISearchSelect} from '@/core/layouts/public/types/search-select-structure';
import {
    IRegistrationRadioElectronicDevicesServiceState
} from '@/pages/registration-radio-electronic-devices-service/store/state';
import {PayloadForm} from '@/pages/permission-movement-service/types/payload-form';

export const RegistrationRadioElectronicDevicesServiceStateMutations = {
    SET_FILE_TYPES_KEY_VALUES(state: IRegistrationRadioElectronicDevicesServiceState, payload: ISearchSelect[]) {
        state.fileTypesKeyValues = payload;
    },

    SET_APPEAL_TECHNOLOGY_KEY_VALUES(state: IRegistrationRadioElectronicDevicesServiceState, payload: ISearchSelect[]) {
        state.appealTechnologyKeyValues = payload;
    },

    SET_POST_CREATE_RED_APPEAL(state: IRegistrationRadioElectronicDevicesServiceState, payload: PayloadForm) {
        state.postCreateRedAppeal[`${payload.type}`] = payload.value;
    },

    SET_ARRAY_RED_FILES(state: IRegistrationRadioElectronicDevicesServiceState, payload: any) {
        payload.forEach((item: any) => {
            state.postCreateRedAppeal.redAppealFileRequest?.push({
                redAppealFileTypeId: item.id,
                file: null,
                label: item.label
            });
        });
    },

    SET_ITEM_RED_FILES(state: IRegistrationRadioElectronicDevicesServiceState, payload: any) {
        state.postCreateRedAppeal.redAppealFileRequest[payload.index].redAppealFileTypeId = payload.redAppealFileTypeId
        state.postCreateRedAppeal.redAppealFileRequest[payload.index].file = payload.file
    },
};