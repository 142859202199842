<style src="./box.component.scss" lang="scss"></style>

<template>
    <v-card class="h-100">
      <div class="box d-flex" :class="[`box--${direction} align-${align}`]">
        <div class="box__icon" :class="{'mb-15': direction === 'column'}">
          <img :src="require(`@/assets/images/icons/${icon}`)" alt="icon" v-if="staticIcon">
          <img :src="icon" alt="icon" v-else>
          <div class="box__registration" v-if="registration">
            {{locale.registered}}
          </div>
        </div>
        <div :class="(direction === 'row')?`ml-${marginLeft/4}`:'mt-4'" class="box__wrapper">
          <div :class="[{'box--bold': boldTitle},`box--font-${titleSize}`]" class="box__title">
            {{ title }}
          </div>
          <div :class="[{'box--bold': boldDesc},`box--font-${descSize}`,descClass]" class="box__description">
            <slot></slot>
          </div>
        </div>
      </div>
    </v-card>
</template>

<script src="./box.component.ts"></script>
