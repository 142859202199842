import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import SearchComponent from '@/core/layouts/public/components/search/search.component.vue';
import {container} from 'tsyringe';
import {DbService} from '@/assets/db/db.service.ts';
import {AuthenticationSandbox} from '@/core/auth/authentication.sandbox';
import {store} from '@/store';
import {locale} from '@/assets/db/db.conf.ts';
import MobileMenuComponent from '@/core/layouts/public/components/mobile-menu/mobile-menu.component.vue';

@Options({
    name: 'header-component',
    components: {
        SearchComponent,
        MobileMenuComponent,
    },
    props: {
        isHome: {
            type: Boolean,
            required: true,
        },
        isVisibleSearch: {
            type: Boolean,
        },
    }
})

export default class HeaderComponent extends Vue {
    private localizationService = container.resolve(DbService);
    public locale$: any = locale;
    public locale: any = null;
    public sandbox: AuthenticationSandbox = container.resolve(AuthenticationSandbox);
    private store = store;
    public isVisibleSearch = true;
    public isVisibleMobile = false;


    get userInfo() {
        return this.store.getters.getUser;
    }

    get userPhoto() {
        return this.store.getters.getPhoto;
    }

    public toggleMobile() {
        this.isVisibleMobile = !this.isVisibleMobile;
        if (this.isVisibleMobile) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
    }

    public closeMobileMenu() {
        this.isVisibleMobile = false;
        document.body.style.overflow = '';

    }

    public setLocale($event: any): void {
        this.localizationService.setNewLang($event.target.value);
    }

    public login(): void {
        this.sandbox.getSSOToken();
    }

    public logout(): void {
        this.sandbox.logout();
    }

    public created() {
        this.locale$.subscribe((res: any) => {
            if (res && res.home) {
                this.locale = res.home.header;
            }
        });
    }

}
