import {IAppealRadioServiceState} from '@/pages/appeal-radio-service/store/state';
import {
    IRegistrationRadioElectronicDevicesServiceState
} from '@/pages/registration-radio-electronic-devices-service/store/state';

export const RegistrationRadioElectronicDevicesServiceStateGetters = {
    _getFileTypesKeyValues: (state: IRegistrationRadioElectronicDevicesServiceState) => {
        return state.fileTypesKeyValues;
    },
    _getAppealTechnologyKeyValues: (state: IRegistrationRadioElectronicDevicesServiceState) => {
        return state.appealTechnologyKeyValues;
    },
    _getPostCreateRedAppeal: (state: IRegistrationRadioElectronicDevicesServiceState) => {
        return state.postCreateRedAppeal;
    },
};