import {IImeiCheckServiceState} from '@/pages/imei-check-service/store/state';
import {ImeiCheckServiceStateMutations} from '@/pages/imei-check-service/store/mutations';
import {ImeiCheckServiceStateGetters} from '@/pages/imei-check-service/store/getters';
import {ImeiCheckServiceStateActions} from '@/pages/imei-check-service/store/actions';


const ImeiCheckServiceStateInitial: IImeiCheckServiceState = {
    statusMessage: {
        code: '',
        text: '',
    },
};

export const ImeiCheckServiceState = {
    state: ImeiCheckServiceStateInitial,
    getters: ImeiCheckServiceStateGetters,
    mutations: ImeiCheckServiceStateMutations,
    actions: ImeiCheckServiceStateActions
};
