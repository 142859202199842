import {IFooterState} from "@/core/layouts/public/components/footer/store/state";
import {IContentCategory} from "@/core/layouts/public/types/footer-modal-data";

export const FooterStateGetters = {
    _getAbout: (state: IFooterState): IContentCategory => {
        return state.contents[1];
    },
    _getSecurityPolicyData: (state: IFooterState): IContentCategory => {
        return state.contents[0];
    },
}
