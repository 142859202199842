import {IPublicState} from '@/core/layouts/public/store/state';
import {ISearchTag} from '@/core/layouts/public/types/search-tag';
import {ISearchExample} from '@/core/layouts/public/types/search-example';
import {ISearchSelect} from '@/core/layouts/public/types/search-select-structure';


export const PublicStateMutations = {
    SET_Search_Tag(state: IPublicState, payload: ISearchTag[]): void {
        state.searchTags = payload;
    },
    SET_SEARCH_EXAMPLE(state: IPublicState, payload: ISearchExample[]): void {
        state.searchExample = payload;
    },
    SET_SEARCH_STRUCTURE(state: IPublicState, payload: ISearchSelect[]): void {
        state.searchStructure = payload;
    },
    SET_SEARCH_CATEGORIES(state: IPublicState, payload: ISearchSelect[]): void {
        state.searchCategories = payload;
    },

}
