export class MobileNumberModel {
    public number: string | null = null;
    public operator: string | null = null;

    constructor(item: any) {
        this._setNumber(item);
        this._setOperator(item);
    }

    /**
     * Set number
     * @param phone
     * @private
     */

    private _setNumber({phone}: any): void {
        this.number = phone;
    }

    /**
     * Set operator
     * @param operatorName
     * @private
     */

    private _setOperator({operatorName}: any): void {
        this.operator = operatorName;
    }
}