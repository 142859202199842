import {container} from 'tsyringe';
import {
    SearchOfficesClassifierServiceService
} from '@/pages/search-offices-classifier-service/services/search-offices-classifier-service.service';

const service = container.resolve(SearchOfficesClassifierServiceService);

export const SearchOfficesClassifierServiceStateActions = {
    getOrganizationType(context: any): void {
        service.getOrganizationType().then(res => context.commit('SET_ORGANISATION_TYPE', res));
    },
    getOrganizationSubtype(context: any, payload: any): void {
        service.getOrganizationSubtype(payload).then(res => context.commit('SET_ORGANISATION_SUBTYPE', res));
    },
    getOrganizationInfo(context: any, payload: any): void {
        service.getOrganizationInfo(payload).then(res => context.commit('SET_ORGANISATION_INFO', res));
    }
};
