import {IStreetSearchServiceState} from './state.d';
import {StreetSearchServiceGetters} from './getters';
import {StreetSearchServiceMutations} from './mutations';
import {StreetSearchServiceActions} from './actions';

const StreetSearchServiceStateInitial: IStreetSearchServiceState = {
    streetByPostIndex: [],
    streetByName: [],
    totalCountStreetByPostIndex: 0,
    totalCountStreetByName: 0,
    streetCity: []
};

export const StreetSearchServiceState = {
    state: StreetSearchServiceStateInitial,
    getters: StreetSearchServiceGetters,
    mutations: StreetSearchServiceMutations,
    actions: StreetSearchServiceActions
};
