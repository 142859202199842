import {IRegistrationRadioElectronicDevicesServiceState} from './state';
import {RegistrationRadioElectronicDevicesServiceStateGetters} from './getters';
import {RegistrationRadioElectronicDevicesServiceStateMutations} from './mutations';
import {RegistrationRadioElectronicDevicesServiceStateActions} from './actions';

const RegistrationRadioElectronicDevicesServiceStateInitial: IRegistrationRadioElectronicDevicesServiceState = {
    fileTypesKeyValues: [],
    appealTechnologyKeyValues: [],
    postCreateRedAppeal: {
        redAppealTechnologyId: null,
        applicantId: null,
        redAppealFileRequest: []
    }
};

export const RegistrationRadioElectronicDevicesServiceState = {
    state: RegistrationRadioElectronicDevicesServiceStateInitial,
    getters: RegistrationRadioElectronicDevicesServiceStateGetters,
    mutations: RegistrationRadioElectronicDevicesServiceStateMutations,
    actions: RegistrationRadioElectronicDevicesServiceStateActions

};