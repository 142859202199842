import {container} from 'tsyringe';
import {RecordKeepingService} from '@/pages/record-keeping-service/services/record-keeping.service';

const service = container.resolve(RecordKeepingService);
export const RecordKeepingStateActions = {

    postIspAppeal(context: any, payload: any): any {
       return  service.postIspAppeal(payload);
    },
    putIspAppeal(context: any, payload: any): any {
        return  service.putIspAppeal(payload);
    },

    changeAppealStatus(context: any, payload: string): any {
       return  service.changeAppealStatus(payload)
    },

    getAppeal(context: any, payload: string): void {
        service.getAppeal(payload).then(res => context.commit('SET_APPEAL_ISP', res));
    },

    putAppeal(context: any): void {
        service.putAppeal();
    },

    getActivityAddresses(context: any): void {
        service.getActivityAddresses().then(res => context.commit('SET_ACTIVITY_ADDRESSES', res));
    },

    getActivityScopes(context: any): void {
        service.getActivityScopes().then(res => context.commit('SET_ACTIVITY_SCOPES', res));
    },

    getActivityTypes(context: any): void {
        service.getActivityTypes().then(res => context.commit('SET_ACTIVITY_TYPES', res));
    },

    getAppealStatuses(context: any): void {
        service.getAppealStatuses().then(res => context.commit('SET_APPEAL_STATUSES', res));
    },

    getInternetTelecommunicationsServiceTypes(context: any): void {
        service.getInternetTelecommunicationsServiceTypes()
            .then(res => context.commit('SET_INTERNET_TELECOMMUNICATIONS_SERVICE_TYPES', res));
    },

};