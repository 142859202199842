import {Options, Vue} from 'vue-class-component';
import {locale} from '@/assets/db/db.conf.ts';
import {HomeSandbox} from '@/pages/home/sandbox/home.sandbox';
import {container} from 'tsyringe';
import {store} from '@/store';
import {IPinnedService} from '@/pages/home/types/pinned-service';
import choiceRouter from '@/core/helpers/routers';
import {AuthenticationSandbox} from '@/core/auth/authentication.sandbox';
import ServiceCardComponent from '@/core/shared/service-card/service-card.component.vue';

@Options({
    name: 'new-services',
    components: {
        ServiceCardComponent,
    }
})
export default class NewServicesComponent extends Vue {
    public sandboxAuth: AuthenticationSandbox = container.resolve(AuthenticationSandbox);
    locale$: any = locale;
    locale: any = null;
    private sandbox: HomeSandbox = container.resolve(HomeSandbox);

    get pinnedServices(): IPinnedService[] {
        return store.getters._getPinnedServices;
    }

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.home) {
                this.locale = res.home.header;
            }
        });
        this.sandbox.getPinnedServices();
    }

    public defineService(id: number): void {
        // return choiceRouter(id);
    }
}
