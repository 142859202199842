import {IStreetSearchServiceState} from '@/pages/street-search-service/store/state';
import {IStreet} from '@/pages/street-search-service/types/street';
import {ISearchSelect} from '@/core/layouts/public/types/search-select-structure';

export const StreetSearchServiceGetters = {
    _getStreetByPostIndex: (state: IStreetSearchServiceState): IStreet[] => {
        return state.streetByPostIndex;
    },
    _getStreetByName: (state: IStreetSearchServiceState): IStreet[] => {
        return state.streetByName;
    },

    _getTotalCountStreetByPostIndex: (state: IStreetSearchServiceState): number => {
        return state.totalCountStreetByPostIndex;
    },
    _getTotalCountStreetByName: (state: IStreetSearchServiceState): number => {
        return state.totalCountStreetByName;
    },
    _getStreetCity: (state: IStreetSearchServiceState): ISearchSelect[] => {
        return state.streetCity;
    },

};
