import {createStore} from 'vuex';
// import createPersistedState from 'vuex-persistedstate';
import {AuthState} from '@/core/auth/store';
import {HomeState} from '@/pages/home/store';
import {AboutState} from '@/pages/about/store';
import {OrganisationsState} from '@/pages/organisations/store';
import {PublicState} from '@/core/layouts/public/store';
import {SearchResultsState} from '@/pages/search-results/store';
import {ICommonState} from './state';
import {CommonGetters} from '@/store/getters';
import {CommonMutation} from '@/store/mutations';
import {FooterState} from '@/core/layouts/public/components/footer/store';
import {AllServicesState} from '@/pages/allservices/store';
import {FaqState} from '@/pages/faq/srote';
import {CategoriesState} from '@/pages/categories/store';
import {PrivacyState} from '@/pages/privacy/store';
import {ServiceState} from '@/pages/services/store';
import {InformationState} from '@/pages/information/store';
import {LocalTalksServiceState} from '@/pages/local-talks-service/store';
import {InformationPhoneServiceState} from '@/pages/information-phone-service/store';
import {ImeiTariffServiceState} from '@/pages/imei-tariff-service/store';
import {ImeiCheckServiceState} from '@/pages/imei-check-service/store';
import {ListMobileNumbersServiceState} from '@/pages/list-mobile-numbers-service/store';
import {StreetSearchServiceState} from '@/pages/street-search-service/store';
import {FeedbackState} from '@/core/shared/feadback/store';
import {CommonActions} from '@/store/actions';
import {PostalTrackingServiceState} from '@/pages/postal-tracking-service/store';
import {SearchPhoneNumberServiceState} from '@/pages/search-phone-number-service/store';
import {IServiceHeader} from '@/types/service-header';
import {IServiceHeaderFile} from '@/types/service-header-file';
import {IServiceHeaderUrl} from '@/types/service-header-url';
import {UserAccountState} from '@/pages/user-account/store';
import {UserFeedbackState} from '@/pages/user-feedback/store';
import {UserApplyState} from '@/pages/user-apply/store';
import {ReceiptsPaidTelephoneServiceState} from '@/pages/receipts-paid-telephone-service/store';
import {SearchOfficesClassifierServiceState} from '@/pages/search-offices-classifier-service/store';
import {NumbersTransferredServiceState} from '@/pages/numbers-transferred-service/store';
import {DetailedInformationDepartmentsServiceState} from '@/pages/detailed-information-departments-service/store';
import {DebtPreviousMonthsServiceState} from '@/pages/debt-previous-months-service/store';
import {MonthlyCallsInformationServiceState} from '@/pages/monthly-calls-information-service/store';
import {InformationElectronicOrdersServiceState} from '@/pages/information-electronic-orders-service/store';
import {FeedbackAnswerState} from '@/pages/feedback-answer/store';
import {PermissionMovementServiceState} from '@/pages/permission-movement-service/store';
import {AppealRadioServiceState} from '@/pages/appeal-radio-service/store';
import {
    RegistrationRadioElectronicDevicesServiceState
} from '@/pages/registration-radio-electronic-devices-service/store';
import {CalculatorState} from '@/pages/calculator/store';
import {RecordKeepingState} from '@/pages/record-keeping-service/store';
import {IssuingCertificateConformityServiceState} from '@/pages/issuing-certificate-conformity-service/store';
import {IssuanceSpecialPermitServiceState} from '@/pages/issuance-special-permit-service/store';
// const plugins = String(environment.env) !== 'production' ? [createLogger({})] : [];
// plugins.push(createPersistedState({storage: window.localStorage}));
//

const CommonState: ICommonState = {
    newUserState: [],
    citizenshipValue: 0,
    organizationLegalFormValue: 0,
    loader: false,
    lang: 'az',
    commonService: {
        id: null,
        authRequired: false,
        paymentRequired: false,
        file: null,
        name: null,
        structureId: null,
        structureName: null,
        serviceHeader: []
    },
    serviceHeader: [],
    numbers: [],
    defaultSelect: 0,
    citizenship: [],
    documentSeriesType: [],
    organPersonType: [],
    legalPersonCarrier: false,
    serviceComponentName: '',
    applicantData: null
};

export const store = createStore({
    state: CommonState,
    getters: CommonGetters,
    mutations: CommonMutation,
    actions: CommonActions,
    // plugins,
    modules: {
        HomeState,
        OrganisationsState,
        AboutState,
        PrivacyState,
        AuthState,
        PublicState,
        SearchResultsState,
        AllServicesState,
        FaqState,
        FooterState,
        CategoriesState,
        ServiceState,
        InformationState,
        LocalTalksServiceState,
        InformationPhoneServiceState,
        ImeiTariffServiceState,
        ImeiCheckServiceState,
        ListMobileNumbersServiceState,
        FeedbackState,
        StreetSearchServiceState,
        PostalTrackingServiceState,
        SearchPhoneNumberServiceState,
        UserAccountState,
        UserFeedbackState,
        UserApplyState,
        ReceiptsPaidTelephoneServiceState,
        SearchOfficesClassifierServiceState,
        NumbersTransferredServiceState,
        DetailedInformationDepartmentsServiceState,
        DebtPreviousMonthsServiceState,
        MonthlyCallsInformationServiceState,
        InformationElectronicOrdersServiceState, FeedbackAnswerState,
        PermissionMovementServiceState,
        AppealRadioServiceState,
        RegistrationRadioElectronicDevicesServiceState,
        IssuanceSpecialPermitServiceState,
        CalculatorState, RecordKeepingState, IssuingCertificateConformityServiceState


    },
});
