import {IParcel} from '@/pages/postal-tracking-service/types/parcel';
import {IStatus} from '@/types/status';
import {ParcelsModel} from '@/pages/postal-tracking-service/models/parcels.model';
import {StatusModel} from '@/models/status.model';

export class PostTrackingResponsiveModel {
    public tracking: IParcel[] | null = [];
    public status: IStatus | null = null;

    constructor(item: any) {
        this._setTracking(item);
        this._setStatus(item);
    }

    /**
     * Set tracking
     * @param Trackings
     * @private
     */

    private _setTracking({Trackings}: any) {
        if (Trackings) {
            this.tracking = Trackings?.map((item: any) => {
                return new ParcelsModel(item);
            });
        } else {
            this.tracking = [];
        }
    }

    /**
     * Set status
     * @param status
     * @private
     */

    private _setStatus({status}: any) {
        this.status = new StatusModel(status);
    }
}