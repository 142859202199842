import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "e-services py-50",
  id: "categories",
  ref: "categories"
}
const _hoisted_2 = { class: "container-fluid v-container pt-10 pb-3" }
const _hoisted_3 = { class: "row align-center" }
const _hoisted_4 = { class: "col-md-12 mb-40" }
const _hoisted_5 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_title = _resolveComponent("v-title")!
  const _component_box_component = _resolveComponent("box-component")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_v_title, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.locale.headerServiceCategories), 1)
            ]),
            _: 1
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.serviceCategories, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "col-md-6 col-lg-4 mb-30"
          }, [
            _createVNode(_component_router_link, {
              to: {name: 'categories', params: {id: item.id}}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_box_component, {
                  icon: item.icon,
                  title: item.title,
                  direction: "row",
                  align: "center",
                  "bold-title": "",
                  "desc-size": "12"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.description), 1)
                  ]),
                  _: 2
                }, 1032, ["icon", "title"])
              ]),
              _: 2
            }, 1032, ["to"])
          ]))
        }), 128))
      ])
    ])
  ], 512))
}