import {RouteLocationNormalized} from 'vue-router';

async function checkToken(to: RouteLocationNormalized, from: RouteLocationNormalized, checkReq: boolean, redirect: boolean = true) {
    let path: { path: string } | boolean = true;
    if (checkReq) {
        const token = await localStorage.getItem('mainToken');
        if (!token) {
            path = {path: '/'};
        } else {
            if (redirect) {
                path = await checkRouter(to, from);
            } else {
                path = true;
            }
        }
    } else {
        path = checkRouter(to, from);
    }
    return path;
}

function checkRouter(to: RouteLocationNormalized, from: RouteLocationNormalized): { path: string } | boolean {
    if (from.path.indexOf('services') !== -1) {
        return {path: `services/${String(to.name)}`};
    } else {
        return true;
    }

}

export default async function totalCheckToken(to: RouteLocationNormalized, from: RouteLocationNormalized, checkReq: boolean, redirect: boolean = true) {
    if (!checkReq) {
        return checkRouter(to, from);
    } else {
        if (!from.name) {
            setTimeout(function () {
                return checkToken(to, from, checkReq, redirect);
            }, 1000);
        }
        // return checkToken(to, from, checkReq, redirect);
        if (from.name) {
            return checkToken(to, from, checkReq, redirect);
        } else {
            setTimeout(function () {
                return checkToken(to, from, checkReq, redirect);
            }, 1000);
        }
    }
}

