import {IGetFileType} from '@/pages/issuing-certificate-conformity-service/types/get-cert-appeal';
import {container} from 'tsyringe';
import {
    IssuanceSpecialPermitServiceService
} from '@/pages/issuance-special-permit-service/services/issuance-special-permit-service.service';

const service = container.resolve(IssuanceSpecialPermitServiceService);

export const IssuanceSpecialPermitServiceStateActions = {
    getAppealFileSP(context: any, payload: IGetFileType): void {
        service.getAppealFileSP(payload).then(res => context.commit('SET_PERMIT_FILES', res));
    },
    getAppealFileOptionSP(context: any, payload: IGetFileType): void {
        service.getAppealFileSP(payload).then(res => context.commit('SET_OPTIONS_FILES', res));
    },
    getRestrictedCirculationItemSP(context: any): any {
        return service.getRestrictedCirculationItemSP().then(res => context.commit('SET_RESTRICTED_CIRCULATION_ITEM_SP', res));
    },
    getRequiredCrucialDataSP(context: any): void {
        service.getRequiredCrucialDataSP().then(res => context.commit('SET_REQUIRED_CRUCIAL_DATA_SP', res));
    },
    postCreateAppealSP(context: any, payload: FormData): any {
        return service.postCreateAppealSP(payload);
    },
};