import {container, injectable} from 'tsyringe';
import {API} from '@/core/configs/api';
import {GET} from '@/packages/VHttp/GET';
import {POST} from '@/packages/VHttp/POST';
import {SelectPermissionModel} from '@/pages/permission-movement-service/models/select-permission.model';
import {CertFileTypeModel} from '@/pages/issuing-certificate-conformity-service/models/file-type.model';
import {IGetFileType} from '@/pages/issuing-certificate-conformity-service/types/get-cert-appeal';
import {ApplicantCertModel} from '@/pages/issuing-certificate-conformity-service/models/applicant-cert.model';

@injectable()
export class IssuingCertificateConformityServiceService {
    private _get: GET = container.resolve(GET);
    private _post: POST = container.resolve(POST);

    public getCitizenshipCert(token: string): Promise<any> {
        return this._get.setApi(API.certCitizenships)
            .setHeaders({cName: 'issuing-certificate-conformity-service', Authorization: `Bearer ${token}`})
            .requestPromise().then(res => {
                return res.data.map((item: any) => {
                    return new SelectPermissionModel(item);
                });
            });
    }

    public getCertFiles(payload: IGetFileType): Promise<any> {
        return this._get.setApi(API.certFiles)
            .setHeaders({
                cName: 'issuing-certificate-conformity-service',
                Authorization: `Bearer ${localStorage.getItem('mainToken')}`
            })
            .setParams(payload)
            .requestPromise().then(res => {
                return res.data.map((item: any) => {
                    return new CertFileTypeModel(item);
                });
            });
    }

    public getCertApplicantInfo(payload: IGetFileType): Promise<any> {
        return this._get.setApi(API.commonApplicantInfo)
            .setHeaders({
                cName: 'issuing-certificate-conformity-service',
                Authorization: `Bearer ${localStorage.getItem('mainToken')}`
            })
            .setParams(payload)
            .requestPromise().then(res => {
                return new ApplicantCertModel(res.data);
            });
    }


    public postCertAppeal(payload: any): Promise<any> {
        return this._post.setApi(API.certAppeal)
            .setHeaders({
                cName: 'issuing-certificate-conformity-service',
                Authorization: `Bearer ${localStorage.getItem('mainToken')}`
            })
            .setPayload(payload)
            .requestPromise().then(res => {
                return res;
            });
    }
}