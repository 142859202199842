import {container, injectable} from 'tsyringe';
import {GET} from '@/packages/VHttp/GET';
import {API} from '@/core/configs/api';
import {ManufacturerModel} from '@/pages/imei-tariff-service/models/manufacturer.model';
import {TotalManufacturerModel} from '@/pages/imei-tariff-service/models/total-manufacturer.model';

@injectable()
export class ImeiTariffServiceService {
    private _get: GET = container.resolve(GET);

    constructor() {
    }

    getManufacture(): Promise<any> {
        return this._get.setApi(API.manufacturer)
            .setHeaders({cName: 'imei-tariff-service', Authorization: `Bearer ${localStorage.getItem('mainToken')}`})
            .requestPromise().then(res => {
                return res.data.map((item: any) => {
                    return new ManufacturerModel(item);
                });
            });
    }

    getModelsByManufacturer(payload: any): Promise<any> {
        return this._get.setApi(`${API.modelsByManufacturer}/${payload.modelId}`)
            .setParams({
                word: payload.word,
                pageSize: payload.pageSize,
                pageNumber: payload.pageNumber,
            })
            .setHeaders({cName: 'imei-tariff-service', Authorization: `Bearer ${payload.token}`})
            .requestPromise().then(res => {
                return new TotalManufacturerModel(res.data);
            });
    }
}