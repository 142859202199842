import {IAuthState, IUser} from './state';

export const AuthStateGetters = {
  getUser: (state: IAuthState): string => {
    return state.user.name + ' ' + state.user.surname;
  },

  getPhoto: (state: IAuthState): string | null => {
    return state.user.image;
  },

  _getUserDocument: (state: IAuthState): string | null => {
    return state.user.document;
  },
  _getUserPin: (state: IAuthState): string | null => {
    return state.user.pin;
  },
  _getUserBirthDate: (state: IAuthState): string | null => {
    return state.user.birthDate;
  },
  _getUserAddress: (state: IAuthState): string | null => {
    return state.user.address;
  },
  _getUserPhoto: (state: IAuthState): string | null => {
    return state.user.image;
  },
  _getUserFullData: (state: IAuthState): IUser => {
    return state.user;
  },

  _getToken: (state: IAuthState): string => {
    return state.token;
  },


  _getUserNSF: (state: IAuthState): string => {
    return state.user.name + ' ' + state.user.surname + ' ' + state.user.fatherName;
  },

};
