import {IStreetSearchServiceState} from '@/pages/street-search-service/store/state';
import {ISearchSelect} from '@/core/layouts/public/types/search-select-structure';

export const StreetSearchServiceMutations = {

    SET_STREET_BY_POST_INDEX(state: IStreetSearchServiceState, payload: any): void {
        state.streetByPostIndex = payload.streets;
        state.totalCountStreetByPostIndex = payload.totalCountStreet;
    },
    SET_STREET_BY_NAME(state: IStreetSearchServiceState, payload: any): void {
        state.streetByName = payload.streets;
        state.totalCountStreetByName = payload.totalCountStreet;
    },
    SET_STREET_CITY(state: IStreetSearchServiceState, payload: ISearchSelect[]): void {
        state.streetCity = payload;
    },

    SET_STREET_BY_POST_INDEX_RESET(state: IStreetSearchServiceState): void {
        state.streetByPostIndex = [];
        state.totalCountStreetByPostIndex = 0;
    },
    SET_STREET_BY_NAME_RESET(state: IStreetSearchServiceState): void {
        state.streetByName =[];
        state.totalCountStreetByName = 0;
    },


};
