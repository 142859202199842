import {container} from 'tsyringe';
import {UserAccountService} from '@/pages/user-account/services/user-account.service';

const service = container.resolve(UserAccountService);

export const UserAccountActions = {
    getUserDetails(context: any, payload: any): any {
        return service.getUserDetails(payload).then(res => context.commit('SET_USER_DETAILS', res));
    },

    postUserDetails(context: any, payload: any): any {
        return service.postUserDetails(payload);
    },
    putUserDetails(context: any, payload: any): any {
        return service.putUserDetails(payload);
    },
};
