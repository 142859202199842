import {IMonthlyCallsInformationServiceState} from '@/pages/monthly-calls-information-service/store/state';

export const MonthlyCallsInformationServiceStateGetters = {
    _getCurrentReceipt: (state: IMonthlyCallsInformationServiceState) => {
        return state.calls;
    },
    _getGeneralInformation: (state: IMonthlyCallsInformationServiceState) => {
        return state.generalInformation;
    },
    _getTotalCountCurrentReceipt: (state: IMonthlyCallsInformationServiceState) => {
        return state.totalCountCalls;
    },
};
