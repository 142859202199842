
import { IFeedbackUser} from '@/pages/user-account/types/feedback';
import {ServiceRequestModel} from '@/pages/user-apply/models/service-request.model';
import {FeedbackUserModel} from '@/pages/user-feedback/models/feedback.model';

export class TotalCountFeedbacksModel {
    public totalCountFeedBackAll: number = 0;
    public feedBackAll: IFeedbackUser[] | null = null;

    constructor(item: any) {
        this._setTotalCountFeedBackAll(item);
        this._setFeedBackAll(item);

    }

    /**
     *
     * @param totalCount
     * @private
     */

    private _setTotalCountFeedBackAll({totalCount}: any) {
        this.totalCountFeedBackAll = totalCount;
    }

    /**
     *
     * @param items
     * @private
     */

    private _setFeedBackAll({items}: any) {
        this.feedBackAll = items?.map((item: any) => {
            return new FeedbackUserModel(item);
        });
    }
}