import {store} from '@/store';
import {Login} from '@/core/configs/api';
import {GET} from '@/packages/VHttp/GET';
import {container} from 'tsyringe';

export class AuthenticationSandbox {
    private store = store;
    private _get: GET = container.resolve(GET);

    /**
     * Guard Method
     * @description Checks if user is authenticated. If yes model load permitted else authenticates and checks again
     * @returns boolean
     */
    async initAuth(): Promise<any> {
        const token = localStorage.getItem('token');
        if (token) {
            let isChecked = await this.checkToken();
            if (isChecked) {
                this.store.dispatch('setTokenToStore', localStorage.getItem('mainToken'));
            } else {
                localStorage.removeItem('token');
                localStorage.removeItem('mainToken');
            }
        } else {
            this.checkSSO();
        }
    }

    /**
     * check token method
     * @description check sso token is available
     */
    checkToken(): Promise<boolean> {
        return new Promise((resolve) => {
            this.store.dispatch('checkToken')
                .then(() => resolve(true))
                .catch(() => resolve(false));
        });
    }

    /**
     * Method works login button click. If user hasn't session page will redirect to SSO site
     */
    async getSSOToken(link?: any): Promise<any> {
        let checkSSO = await this.checkSSO();
        if (!checkSSO) {
            this.redirectToSSO(link);
        }
    }

    /**
     * check if session exist
     */
    checkSSO(): Promise<boolean> {
        return new Promise((resolve) => {
            this.store.dispatch('checkLogin').then(res => {
                let resToken = res.data.data.token;
                localStorage.setItem('token', resToken);
                this.store.dispatch('setToken', resToken);
                return resolve(true);
            }).catch(() => resolve(false));
        });
    }

    /**
     * redirect to SSO
     */
    redirectToSSO(link?: any): void {
        let redirectLink = '';
        if (link) {
            const lang = this.store.getters._getLanguage;
            redirectLink = `${Login.redirect}${location.origin}/${lang}/${link}`;
        } else {
            redirectLink = `${Login.redirect}${document.documentURI}`;
        }
        window.location.href = redirectLink;
    }

    logout(): void {
        localStorage.removeItem('token');
        localStorage.removeItem('mainToken');
        setTimeout(() => {
            window.location.href = Login.logout;
        }, 500);
    }
}
