<style src="./header.component.scss" lang="scss"></style>

<template>
  <div class="header" :class="{'header--home': isHome}">
    <div class="container-fluid " :class="{'v-container':isVisibleSearch}">
      <div class="row align-center" :class="{'mb-60': isVisibleSearch}">
        <div class="col-10 col-lg-6">
          <div class="header__logo">
            <router-link :to="{name: 'home'}">
              <img alt="logo" src="@/assets/images/common/main-logo.svg">
            </router-link>
          </div>
        </div>
        <div class="col-2 d-lg-none d-block">
          <div class="mobile-menu__button" @click="toggleMobile">
            <img src="@/assets/images/icons/menu.svg">
          </div>
        </div>
        <div class="col-12 col-md-6 text-right d-lg-block d-none">
          <div class="header__login">
            <a href="tel:1655" class="header__call d-md-inline d-none">
              <img src="@/assets/images/icons/call.svg" alt="call">
              1655 {{ locale.callCenter }}
            </a>
            <span v-if="userInfo.length!==1" class="header__user">
              <router-link :to="{name: 'user-account'}" class="header__user-info">
                <img :src="userPhoto" class="header__user-image mr-15">
                {{ userInfo }}
              </router-link>
              <v-btn class="p-0 ml-15" @click="logout">
                <img src="@/assets/images/icons/logout.svg" alt="logout">
              </v-btn>
            </span>
            <v-btn v-else class="py-13 px-15" color="white" @click="login">
              <img src="@/assets/images/common/asan-login.svg" alt="asan-login">
            </v-btn>

          </div>
        </div>
      </div>
      <div class="row justify-center" v-if="isVisibleSearch">
        <div class="col-12 col-lg-7 header__search-col" :class="{'col-lg-12': !isHome}">
          <search-component/>
        </div>
      </div>
    </div>
  </div>
  <mobile-menu-component :isVisibility="isVisibleMobile" @closeMobileMenu="closeMobileMenu"/>
</template>

<script src="./header.component.ts"></script>
