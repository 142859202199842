import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/images/icons/modal-close.svg'


const _hoisted_1 = { class: "modal__header" }
const _hoisted_2 = { class: "modal__title" }
const _hoisted_3 = {
  key: 0,
  src: _imports_0,
  alt: ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args))),
      type: "button",
      class: "modal__close"
    }, [
      (_ctx.closeOnOutside)
        ? (_openBlock(), _createElementBlock("img", _hoisted_3))
        : _createCommentVNode("", true)
    ])
  ]))
}