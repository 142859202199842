import {IAllServicesState} from '@/pages/allservices/store/state';
import {IServices} from '@/pages/allservices/types/allservices';

export const AllServicesStateGetters = {
    _getAllServices: (state: IAllServicesState): IServices[] => {
        return state.allServices;
    },
    _getAllServicesTotalCount: (state: IAllServicesState): number | null => {
        return state.totalCount;
    },
};
