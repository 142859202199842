import {IPostalTrackingServiceState} from '@/pages/postal-tracking-service/store/state';

export const PostalTrackingServiceGetters = {
    _getTracking: (state: IPostalTrackingServiceState): any => {
        return state.tracking;
    },
    _getStatus: (state: IPostalTrackingServiceState): any => {
        return state.status;
    },
}
