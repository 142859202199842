import {container, injectable} from 'tsyringe';
import {API} from '@/core/configs/api';
import {GET} from '@/packages/VHttp/GET';
import {CurrentReceipt} from '@/pages/monthly-calls-information-service/models/current-receipt.model';

@injectable()
export class MonthlyCallsInformationServiceService {
    private _get: GET = container.resolve(GET);

    constructor() {
    }
    getCurrentReceipt(payload: any): Promise<any> {
        return this._get.setApi(API.currentReceipt)
            .setHeaders({cName: 'monthly-calls-information-service', Authorization: `Bearer ${payload.token}`})
            .setParams({
                number: payload.number,
                pageNumber: payload.pageNumber,
                pageSize: payload.pageSize
            })
            .requestPromise().then(res => {
                return new CurrentReceipt(res.data);
            });
    }
}