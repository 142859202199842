import {container} from 'tsyringe';
import {
    InformationPhoneServiceService
} from '@/pages/information-phone-service/services/information-phone-service.service';

const service = container.resolve(InformationPhoneServiceService);

export const InformationPhoneServiceStateActions = {
    getDetailedInfoService(context: any, payload: any): any {
        return service.getDetailedInfoService(payload.token, payload.params)
            .then(res => context.commit('SET_PHONE_INFORMATION', res));
    },
    getInfoNumbers(context: any, payload: string): void {
        service.getNumbers(payload).then(res => context.commit('SET_INFO_NUMBERS', res));
    },
};
