import {IMonthlyCallsInformationServiceState} from './state.d';
import {MonthlyCallsInformationServiceStateGetters} from './getters';
import {MonthlyCallsInformationServiceStateMutations} from './mutations';
import {MonthlyCallsInformationServiceStateActions} from './actions';

const MonthlyCallsInformationServiceStateInitial: IMonthlyCallsInformationServiceState = {
    calls: null,
    totalCountCalls: 0,
    generalInformation: {
        totalCountReceipts: null,
        totalCountDebts: null,
        totalCountPayments: null,
        totalReceiptsPaidAndUnPaid: null,
        totalPayments: null,
        totalDebts: null,
    }
};

export const MonthlyCallsInformationServiceState = {
    state: MonthlyCallsInformationServiceStateInitial,
    getters: MonthlyCallsInformationServiceStateGetters,
    mutations: MonthlyCallsInformationServiceStateMutations,
    actions: MonthlyCallsInformationServiceStateActions

};
