import {IHomeState} from './state.d';
import {HomeStateGetters} from './getters';
import {HomeStateMutations} from './mutations';
import {HomeStateActions} from './actions';

const HomeStateInitial: IHomeState = {
    pinnedServices: [],
    mostUsedServices: [],
    serviceCategories: [],
};

export const HomeState = {
    state: HomeStateInitial,
    getters: HomeStateGetters,
    mutations: HomeStateMutations,
    actions: HomeStateActions

};
