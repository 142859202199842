import 'reflect-metadata';
import {injectable} from 'tsyringe';
import {store} from '@/store';


@injectable()
export class PublicSandbox {
    private store = store;

    constructor() {
    }

    getSearchTag(): void {
        this.store.dispatch('getSearchTag');
    }

    getSearchExample(payload: string): void {
        this.store.dispatch('getSearchExample', payload);
    }

    getSearchStructure(): void {
        this.store.dispatch('getSearchStructure');
    }
    getSearchCategories(): void {
        this.store.dispatch('getSearchCategories');
    }


}