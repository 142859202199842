import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {container} from 'tsyringe';
import {DbService} from '@/assets/db/db.service.ts';
import {AuthenticationSandbox} from '@/core/auth/authentication.sandbox';
import {store} from '@/store';
import {locale} from '@/assets/db/db.conf.ts';
import {IContentCategory} from '@/core/layouts/public/types/footer-modal-data';
import {FooterSandbox} from '@/core/layouts/public/components/footer/sandbox/footer.sandbox';

@Options({
    name: 'mobile-menu-component',
    components: {},
    props: {
        isVisibility: {
            type: Boolean,
            required: true,
        }
    }
})

export default class MobileMenuComponent extends Vue {
    public sandbox: AuthenticationSandbox = container.resolve(AuthenticationSandbox);
    public sandboxFooter: FooterSandbox = container.resolve(FooterSandbox);
    public $route: any = '';
    public isVisibility = false;
    public store = store;
    public locale$: any = locale;
    public locale: any = null;
    public localeElements: any = null;

    get userData() {
        return this.store.getters._getUserFullData;
    }

    get aboutData(): IContentCategory[] {
        return store.getters._getAbout;
    }

    get securityPolicyData(): IContentCategory[] {
        return store.getters._getSecurityPolicyData;
    }

    get menu() {
        return [
            {
                id: 1,
                name: this.locale.footerItemAboutUs,
                content: this.aboutData,
            },
            {
                id: 2,
                name: this.locale.footerItemPrivacyAndUse,
                content: this.securityPolicyData
            },
            {
                id: 3,
                name: 'FAQ',
                link: 'faq'
            },
        ];
    }

    get information() {
        return [
            {
                id: 1,
                name: 'Şəxsi məlumatlarım',
                icon: 'user.svg',
                link: 'user-account'
            },
            {
                id: 2,
                name: 'Müraciətlərim',
                icon: 'doc.svg',
                link: 'user-apply'
            },
            {
                id: 3,
                name: 'Əks əlaqə məlumatlarım',
                icon: 'comment.svg',
                link: 'feedback'
            },
        ];
    }

    public created() {
        this.locale$.subscribe((res: any) => {
            if (res && res.home) {
                this.locale = res.home.footer;
                this.localeElements = res.elements;
            }
        });
    }

    public linkClick(id: number) {
        if (this.$route.name === 'information') {
            this.sandboxFooter.getContent(id);
        }
        this.closeMobileMenu();
    }


    public closeMobileMenu() {
        this.$emit('closeMobileMenu', false);

    }

    public login(): void {
        this.sandbox.getSSOToken();
    }

    public logout(): void {
        this.sandbox.logout();
    }

    // private localizationService = container.resolve(DbService);
    // public locale$: any = locale;
    // public locale: any = null;
    // public sandbox: AuthenticationSandbox = container.resolve(AuthenticationSandbox);
    // private store = store;
    // public isVisibleSearch = true;
    // get userInfo() {
    //     return this.store.getters.getUser;
    // }
    // get userPhoto() {
    //     return this.store.getters.getPhoto;
    // }
    //
    // public setLocale($event: any): void {
    //     this.localizationService.setNewLang($event.target.value);
    // }
    //
    // public login(): void {
    //     this.sandbox.getSSOToken();
    // }
    //
    // public logout(): void {
    //     this.sandbox.logout();
    // }
    // public created() {
    //     this.locale$.subscribe((res: any) => {
    //         if (res && res.home) {
    //             this.locale = res.home.header;
    //         }
    //     });
    // }

}
