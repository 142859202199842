export class PaymentItemModel {
    public calc: number | null = null;
    public date: string | null = null;
    public pay: number | null = null;
    public phone: number | null = null;
    public type: number | null = null;

    constructor(item: any) {
        this._setCalc(item);
        this._setDate(item);
        this._setPay(item);
        this._setPhone(item);
        this._seType(item);
    }

    /**
     * Set calc
     * @param calc
     * @private
     */

    private _setCalc({calc}: any): void {
        this.calc = calc;
    }

    /**
     * Set date
     * @param date
     * @private
     */

    private _setDate({date}: any): void {
        this.date = date;
    }

    /**
     * Set pay
     * @param pay
     * @private
     */

    private _setPay({pay}: any): void {
        this.pay = pay;
    }

    /**
     * Set phone
     * @param phone
     * @private
     */

    private _setPhone({phone}: any): void {
        this.phone = phone;
    }

    /**
     * Set type
     * @param type
     * @private
     */

    private _seType({type}: any): void {
        this.type = type;
    }
}
