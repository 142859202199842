import {container, injectable} from 'tsyringe';
import {GET} from '@/packages/VHttp/GET';
import {API, Login} from '@/core/configs/api';

@injectable()
export class AuthenticationServiceService {
    private _get: GET = container.resolve(GET)

    constructor() {
    }

    checkLogin(): Promise<any> {
        return this._get.setApi(Login.general).setConfigs({withCredentials: true})
            .requestPromise();
    }

    checkToken(): Promise<any> {
        const token = localStorage.getItem('token');
        return this._get.setApi(Login.check).setHeaders({
            Authorization: token
        })
            .requestPromise();
    }

    getUserData(token: string): Promise<any> {
        return this._get.setApi(API.userData).setHeaders({
            'Asan-Token': token
        })
            .requestPromise();
    }

}
