import {ISearchPhoneNumberServiceState} from './state.d';
import {SearchPhoneNumberServiceStateGetters} from './getters';
import {SearchPhoneNumberServiceStateMutations} from './mutations';
import {SearchPhoneNumberServiceActions} from './actions';

const SearchPhoneNumberServiceStateInitial: ISearchPhoneNumberServiceState = {
    cities: [],
    searchNumber: null,
};

export const SearchPhoneNumberServiceState = {
    state: SearchPhoneNumberServiceStateInitial,
    getters: SearchPhoneNumberServiceStateGetters,
    mutations: SearchPhoneNumberServiceStateMutations,
    actions: SearchPhoneNumberServiceActions

};
