import {IOrganisationsState} from '@/pages/organisations/store/state';
import {IOrganisation} from '@/pages/organisations/types/organisation';
import {IOrganisationDetails} from '@/pages/organisations/types/organisation-details';

export const OrganisationsStateMutations = {

    SET_ORGANISATIONS(state: IOrganisationsState, payload: IOrganisation[]): void {
        state.organisations = payload;
    },

    SET_ORGANISATION_DETAILS(state: IOrganisationsState, payload: IOrganisationDetails[]): void {
        state.organisationDetails = payload;
    },

}
