import {container, injectable} from 'tsyringe';
import {POST} from '@/packages/VHttp/POST';
import {API} from '@/core/configs/api';
import {ResponsiveImeiStatusModel} from '@/pages/services/models/responsive-imei-status.model';
import {
    ResponsiveApplicationStatusModel,
} from '@/pages/services/models/responsive-application-status.model';
import Swal from 'sweetalert2';
import {GET} from '@/packages/VHttp/GET';
import {SelectImeiModel} from '@/pages/services/models/select-imei.model';
import {McqsModel} from "@/pages/services/models/mcqs.model";

@injectable()
export class ServicesService {
    private _post: POST = container.resolve(POST);
    private _get: GET = container.resolve(GET);

    constructor() {
    }

    getCheckImei(payload: any): Promise<any> {
        return this._post.setApi(API.getCheckImei)
            .setHeaders({cName: 'mcqs-service', Authorization: `Bearer ${localStorage.getItem('mainToken')}`})
            .setPayload(payload)
            .requestPromise().then(res => {
                return new ResponsiveImeiStatusModel(res.data, res.headers);
            });
    }

    postAddApplication(payload: any): Promise<any> {
        return this._post.setApi(API.postAddApplication)
            .setHeaders({
                ServiceRequest: payload.request,
                cName: 'mcqs-service',
                Authorization: `Bearer ${localStorage.getItem('mainToken')}`
            })
            .setPayload(payload.form)
            .requestPromise().then(res => {
                return new ResponsiveApplicationStatusModel(res.data);
            });
    }

    getReceiptPayment(payload: any): Promise<any> {
        return this._get.setApi(API.receiptResponse)
            .setHeaders({
                cName: 'mcqs-service',
                Authorization: `Bearer ${localStorage.getItem('mainToken')}`
            })
            .setParams({
                transactionId: payload.transactionId
            })
            .requestPromise().then(res => {
                return new McqsModel(res.data);
            });
    }

    sendReceiptToEmail(payload: any): Promise<any> {
        return this._post.setApi(API.sendReceiptToEmail)
            .setHeaders({
                cName: 'mcqs-service',
                Authorization: `Bearer ${localStorage.getItem('mainToken')}`
            })
            .setPayload(payload)
            .requestPromise().then(res => {
                if (res.status === 200) {
                    Swal.fire({
                        title: 'Qəbz uğurla göndərildi',
                        icon: 'success',
                        showCloseButton: false,
                        showCancelButton: false,
                        showConfirmButton: false,
                        timer: 2000,
                    });
                }
            });
    }

    generatePaymentUrl(payload: any): Promise<any> {
        return this._post.setApi(API.paymentUrl)
            .setHeaders({cName: 'mcqs-service', Authorization: `Bearer ${localStorage.getItem('mainToken')}`})
            .setPayload(payload)
            .requestPromise().then(res => {
                return res.data.generatedRequestUrl;
            });
    }

    getDocumentGroups(): Promise<any> {
        return this._get.setApi(API.documentGroups)
            .requestPromise().then(res => {
                return res.data.map((item: any) => {
                    return new SelectImeiModel(item);
                });
            });
    }

    getDocumentTypes(payload: any): Promise<any> {
        return this._get.setApi(API.documentTypes)
            .setParams({
                documentTypeGroupId: payload
            })
            .requestPromise().then(res => {
                return res.data.map((item: any) => {
                    return new SelectImeiModel(item);
                });
            });
    }
}