export class OrganisationServiceModel {
    public id: number | null = null;
    public icon: string | null = null;
    public description: string | null = null;
    public title: string | null = null;
    public authRequired: boolean | null = null;
    public component: boolean | null = null;
    constructor(item: any) {
        this._setId(item);
        this._setIcon(item);
        this._setTitle(item);
        this._setDescription(item);
        this._setAuthRequired(item);
        this._setComponent(item)
    }

    /**
     * Set Id
     * @param ID
     * @private
     */
    private _setId({id}: any) {
        this.id = id;
    }

    /**
     * Set icon
     * @param file
     * @private
     */
    private _setIcon({file}: any) {
        this.icon = file.fileUrl;
    }

    /**
     * Set title
     * @param serviceLanguages
     * @private
     */
    private _setTitle({serviceLanguages}: any) {
        this.title = serviceLanguages[0]?.name;
    }

    /**
     * Set description
     * @param serviceLanguages
     * @private
     */
    private _setDescription({serviceLanguages}: any) {
        this.description = serviceLanguages[0]?.description;
    }

    /**
     *
     * @param authRequired
     * @private
     */

    private _setAuthRequired({authRequired}: any) {
        this.authRequired = authRequired;
    }

    private _setComponent({component}: any) {
        this.component = component?.name;
    }
}
