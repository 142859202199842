export class GetNumberModel {
    public number: number | [] | null = null;
    public name: string | null = null;

    constructor(item: any) {
        this._setNumber(item);
        this._setName(item);
    }

    /**
     * Set number
     * @param item
     * @private
     */

    private _setNumber(item: any): void {
        if (item) {
            this.number = item.phoneList?.map((item: string) => {
                return {
                    id: item,
                    value: item,
                };

            });
        } else {
            this.number = [];
        }

    }

    /**
     * Set name
     * @param name
     * @private
     */

    private _setName({name}: any): void {
        this.name = name;
    }
}