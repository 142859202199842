import {Options, Vue} from 'vue-class-component';

@Options({
    name: 'modal-header-component',
    /**
     * @description props for modal-components
     * @param title: String
     */
    props: {
        title: String,
        close: {
            type: Function,
            required: true,
        },
        closeOnOutside: {
            type: Boolean,
            default: true,
        },
    },
})
export default class ModalHeaderComponent extends Vue {
}
