import {container} from 'tsyringe';
import {SearchResultsService} from '@/pages/search-results/services/search-results.service';

const service = container.resolve(SearchResultsService);

export const SearchResultsActions = {
    getSearchResult(context: any, payload: any): void {
        service.getSearchResult(payload).then(res => context.commit('SET_SEARCH_RESULT', res));
    },
    getSearchResultDetailed(context: any, payload: any): void {
        service.getSearchResultDetailed(payload).then(res => context.commit('SET_SEARCH_RESULT', res));
    },

}
