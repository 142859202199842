import {container, injectable} from 'tsyringe';
import {API} from '@/core/configs/api';
import {TotalStreet} from '@/pages/street-search-service/models/total-street-by-name.model';
import {GET} from '@/packages/VHttp/GET';
import {StreetCityModel} from '@/pages/street-search-service/models/street-city.model';


@injectable()
export class StreetSearchServiceService {
    private _get: GET = container.resolve(GET);

    constructor() {
    }

    getStreetByPostIndex(payload: any): Promise<any> {
        return this._get.setApi(API.streetByPostIndex)
            .setHeaders({cName: 'street-search-service'})
            .setParams(payload)
            .requestPromise().then(res => {
                return new TotalStreet(res.data);
            });
    }

    getStreetByName(payload: any): Promise<any> {
        return this._get.setApi(API.streetByName)
            .setHeaders({cName: 'street-search-service'})
            .setParams(payload)
            .requestPromise().then(res => {
                return new TotalStreet(res.data);
            });
    }

    getStreetCity(): Promise<any> {
        return this._get.setApi(API.city)
            .setHeaders({cName: 'street-search-service'})
            .requestPromise().then(res => {
                return res.data.map((item: any) => {
                    return new StreetCityModel(item);
                });
            });
    }
}