import {container, injectable} from 'tsyringe';
import {GET} from '@/packages/VHttp/GET';
import {API} from '@/core/configs/api';
import {TotalMobileNumberModel} from '@/pages/list-mobile-numbers-service/models/total-mobile-number.model';

@injectable()
export class ListMobileNumbersService {
    private _get: GET = container.resolve(GET);

    constructor() {
    }

    getMobileNumbersList(token: string): Promise<any> {
        return this._get.setApi(API.mobileNumbersList)
            .setHeaders({cName: 'list-mobile-numbers-service', Authorization: `Bearer ${token}`})
            .requestPromise().then(res => {
                return new TotalMobileNumberModel(res.data);
            });
    }
}