import {container} from 'tsyringe';
import {CategoriesService} from "./../services/categories.service";

const service = container.resolve(CategoriesService);

export const CategoriesStateActions = {
    getCategories(context: any,payload: string): void {
        service.getCategories(payload).then(res => context.commit('SET_CATEGORIES', res));
    },
}
