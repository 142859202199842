import {ICall} from '@/pages/monthly-calls-information-service/types/call';
import {Call} from '@/pages/monthly-calls-information-service/models/call.model';
import {IGeneralInformation} from '@/pages/monthly-calls-information-service/types/ general-information';
import {GeneralInformationModel} from '@/pages/monthly-calls-information-service/models/general-information.model';

export class CurrentReceipt {
    public generalInformation: IGeneralInformation | null = null;
    public calls: ICall[] | null = null;
    public totalCountCalls: number = 0;

    constructor(item: any) {
        this._setGeneralInformation(item);
        this._setCalls(item);
        this._setTotalCountCalls(item);
    }


    private _setCalls({data, pageSize, pageNumber}: any) {
        if (data) {
            this.calls = data?.map((item: any, index: any) => {
                return new Call(item, index, pageSize, pageNumber);
            });
        } else {
            this.calls = [];
        }
    }

    private _setTotalCountCalls({totalRowCount}: any) {
        this.totalCountCalls = totalRowCount;
    }

    private _setGeneralInformation(item: any) {
        this.generalInformation = new GeneralInformationModel(item);
    }
}