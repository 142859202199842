import {container, injectable} from 'tsyringe';
import {GET} from '@/packages/VHttp/GET';
import {API} from '@/core/configs/api';
import {InformationModel} from '@/pages/information/models/information-model';

@injectable()
export class InformationService {
    private _get: GET = container.resolve(GET);

    constructor() {
    }

    getInformation(payload: number): Promise<any> {
        return this._get.setApi(`${API.content}/${payload}`)
            .requestPromise().then(res => {
                return new InformationModel(res.data.content);
            });
    }
}
