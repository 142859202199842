import 'reflect-metadata';
import {injectable} from 'tsyringe';
import {store} from '@/store';


@injectable()
export class AboutSandbox {
    private store = store;

    constructor() {
    }

    getAboutData(): void {
        this.store.dispatch('getAboutContentData');
    }
}
