import {ISearchResultsState} from './state.d'
import {SearchResultsStateGetters} from './getters'
import {SearchResultsStateMutations} from './mutations'
import {SearchResultsActions} from './actions'

const SearchResultsStateInitial: ISearchResultsState = {
    searchResults: [],
    totalCount: 0,
}

export const SearchResultsState = {
    state: SearchResultsStateInitial,
    getters: SearchResultsStateGetters,
    mutations: SearchResultsStateMutations,
    actions: SearchResultsActions

}
