import {IFeedbackState} from './state.d';
import {FeedbackStateGetters} from './getters';
import {FeedbackStateMutations} from './mutations';
import {FeedbackStateActions} from './actions';

const FeedbackStateInitial: IFeedbackState = {
    feedbackDirections: [],
    feedbackAppealTypes: [],
    feedbackServices: [],
    feedbackOrganisations: [],

}

export const FeedbackState = {
    state: FeedbackStateInitial,
    getters: FeedbackStateGetters,
    mutations: FeedbackStateMutations,
    actions: FeedbackStateActions

}
