import {container} from 'tsyringe';
import {FeedbackService} from '@/core/shared/feadback/services/feedback.service';
import {FeedbackPostModel} from '@/core/shared/feadback/models/feedback-post.model';

const feedback_service = container.resolve(FeedbackService);

export const FeedbackStateActions = {

    getFeedbackDirections(context: any): void {
        feedback_service.getFeedbackDirections().then(res => context.commit('SET_FEEDBACK_DIRECTIONS', res));
    },

    getFeedbackAppealTypes(context: any): void {
        feedback_service.getFeedbackAppealTypes().then(res => context.commit('SET_FEEDBACK_APPEAL_TYPES', res));
    },

    getFeedbackServices(context: any, payload:number): void {
        feedback_service.getFeedbackServices(payload).then(res => context.commit('SET_FEEDBACK_SERVICES', res));
    },

    getFeedbackOrganisations(context: any): void {
        feedback_service.getFeedbackOrganisations().then(res => context.commit('SET_FEEDBACK_ORGANISATIONS', res));
    },

    sendFeedback(_: any, payload: any): Promise<any> {
        return feedback_service.sendFeedback(payload);
    }

};
