import {container, injectable} from 'tsyringe';
import {API} from '@/core/configs/api';
import {GET} from '@/packages/VHttp/GET';
import {POST} from '@/packages/VHttp/POST';
import {PUT} from '@/packages/VHttp/PUT';
import {SelectPermissionModel} from '@/pages/permission-movement-service/models/select-permission.model';
import {IspAppealModel} from '@/pages/record-keeping-service/models/isp-appeal.model';

@injectable()
export class RecordKeepingService {

    private _get: GET = container.resolve(GET);
    private _post: POST = container.resolve(POST);
    private _put: PUT = container.resolve(PUT);

    postIspAppeal(payload: any): Promise<any> {
        return this._post.setApi(API.appealIsp)
            .setHeaders({
                Authorization: `Bearer ${localStorage.getItem('mainToken')}`
            })
            .setPayload(payload)
            .requestPromise().then(res => {
                return res;
            });
    }

    putIspAppeal(payload: any): Promise<any> {
        return this._put.setApi(API.appealIsp)
            .setHeaders({
                Authorization: `Bearer ${localStorage.getItem('mainToken')}`
            })
            .setPayload(payload)
            .requestPromise().then(res => {
                return res;
            });
    }

    changeAppealStatus(payload: any): Promise<any> {
        return this._put.setApi(API.changeAppealStatus)
            .setHeaders({
                Authorization: `Bearer ${localStorage.getItem('mainToken')}`
            })
            .setPayload(payload)
            .requestPromise().then(res => {
                return res;
            });
    }

    getAppeal(payload: string): Promise<any> {
        return this._get.setApi(API.appealIsp)
            .setParams(payload)
            .requestPromise().then(res => {
                if (res.data.data!==null) {
                    return  new IspAppealModel(res.data.data);
                } else  {
                    return  null
                }
            });
    }

    putAppeal(): Promise<any> {
        return this._put.setApi(API.appealIsp)
            .requestPromise().then(res => {
                return res;
            });
    }


    getActivityAddresses(): Promise<any> {
        return this._get.setApi(API.getActivityAddresses)
            .requestPromise().then(res => {
                return res.data.map((item: any) => {
                    return new SelectPermissionModel(item);
                });
            });
    }

    getActivityScopes(): Promise<any> {
        return this._get.setApi(API.getActivityScopes)
            .requestPromise().then(res => {
                return res.data.map((item: any) => {
                    return new SelectPermissionModel(item);
                });
            });
    }

    getActivityTypes(): Promise<any> {
        return this._get.setApi(API.getActivityTypes)
            .requestPromise().then(res => {
                return res.data.map((item: any) => {
                    return new SelectPermissionModel(item);
                });
            });
    }

    getAppealStatuses(): Promise<any> {
        return this._get.setApi(API.getAppealStatuses)
            .requestPromise().then(res => {
                return res.data.map((item: any) => {
                    return new SelectPermissionModel(item);
                });
            });
    }

    getInternetTelecommunicationsServiceTypes(): Promise<any> {
        return this._get.setApi(API.getInternetTelecommunicationsServiceTypes)
            .requestPromise().then(res => {
                return res.data.map((item: any) => {
                    return new SelectPermissionModel(item);
                });
            });
    }

    // getReadCertificatesFromStore(): Promise<any> {
    //     return this._get.setApi(API.readCertificatesFromStore)
    //         .requestPromise().then(res => {
    //             return res.data.output.certificates.map((item: any) => {
    //                 return new CertificateModel(item);
    //             });
    //         });
    // }
    //
    // getTsaClients(): Promise<any> {
    //     return this._get.setApi(API.tsaclients)
    //         .requestPromise().then(res => {
    //
    //             // return res.data.output.certificates.map((item: any) => {
    //             //     return new CertificateModel(item);
    //             // });
    //         });
    // }
    //
    // postFullSign(payload: any): Promise<any> {
    //     return this._post.setApi(API.fullsign)
    //         .setPayload(JSON.stringify(payload))
    //         .requestPromise().then(res => {
    //             // return res.data.output.certificates.map((item: any) => {
    //             //     return new CertificateModel(item);
    //             // });
    //         });
    // }
}
