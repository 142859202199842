
      import {IAboutState} from  './state'
      import {AboutStateGetters} from  './getters'
      import {AboutStateMutations} from  './mutations'
      import {AboutStateActions} from  './actions'

      const AboutStateInitial: IAboutState = {
        contents: []
      }

      export const AboutState = {
        state: AboutStateInitial,
        getters: AboutStateGetters,
        mutations: AboutStateMutations,
        actions: AboutStateActions
    }
