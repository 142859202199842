<style src="./breadcrumb.component.scss" lang="scss"></style>

<template>
  <section class="mt-16 breadcrumb-main">
    <div class="container-fluid v-container">
      <div class="row">
        <div class="col-12">
          <div class="action d-flex align-center">
            <a @click="$router.go(-1)">
              <v-btn >
                <img src="@/assets/images/icons/arrow.svg" alt="arrow-left">
               <span class="d-md-block d-none">{{locale.prevStep}}</span>
              </v-btn>
            </a>
            <v-breadcrumb/>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script src="./breadcrumb.component.ts"></script>
