<style src="./lead.component.scss" lang="scss"></style>

<template>
  <div class="container">
    <div class="row lead">
      <div v-for="(item, index) in leadServiceData" :key="index" class="col-lg-4 col-12 ">
        <router-link :to="{name: item.link}">
          <box-component
              direction="row"
              :title="item.title"
              :icon="item.icon"
              @click="item.content? clickModal():''"
              class="lead__box"
              static-icon
              align="center"
              bold-title
              desc-size="12"
              desc-class="lead__box-desc"
          >
            {{ item.description }}
          </box-component>
        </router-link>
      </div>
    </div>
    <feedback-component
        ref="feedbackModal"
        :serviceId="null"
        :structureId="null"
    />
  </div>
</template>

<script src="./lead.component.ts"></script>
