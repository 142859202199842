import {IInformationPhoneServiceState} from '@/pages/information-phone-service/store/state';
import {IPhoneInformation} from '@/pages/information-phone-service/types/phone-information';
import {IInfoNumber} from '@/pages/information-phone-service/types/info-number';

export const InformationPhoneServiceStateMutations = {
    SET_PHONE_INFORMATION(state: IInformationPhoneServiceState, payload: IPhoneInformation): void {
        state.phoneInformation = payload;
    },

    SET_INFO_NUMBERS(state: IInformationPhoneServiceState, payload: IInfoNumber[]): void {
        state.infoNumbers = payload;
    },
}
