import {container} from 'tsyringe';
import {
    SearchPhoneNumberServiceService
} from '@/pages/search-phone-number-service/services/search-phone-number-service.service';

const service = container.resolve(SearchPhoneNumberServiceService);

export const SearchPhoneNumberServiceActions = {

    getCities(context: any): void {
        service.getCity().then(res => context.commit('SET_CITIES', res));
    },
    getSearchNumber(context: any, payload: any): void {
        service.getSearchNumber(payload).then(res => context.commit('SET_SEARCH_NUMBER', res));
    }
};
