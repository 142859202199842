import {IInformationElectronicOrdersServiceState} from './state';
import {InformationElectronicOrdersServiceStateGetters} from './getters';
import {InformationElectronicOrdersServiceStateMutations} from './mutations';
import {InformationElectronicOrdersServiceStateActions} from './actions';

const InformationElectronicOrdersServiceStateInitial: IInformationElectronicOrdersServiceState = {
    orders: [{
        orderNumber: '234567890',
        postIndex: 'AZ1010',
        streetName: 'Cəlil Məmməqquluzadə',
        addressFirst: 'm.22 ',
        addressSecond: 'ev 28',
        selectedPhone: '012 458 55 76',
        operationType: ' Nömrə dəyişmə',
        operationStatus: 'Naryada baxılır',
        createDate: '26.05.2022',
        isOpen: false,
        operationStatusID: 1

    }],
    totalCountOrders: 0
};

export const InformationElectronicOrdersServiceState = {
    state: InformationElectronicOrdersServiceStateInitial,
    getters: InformationElectronicOrdersServiceStateGetters,
    mutations: InformationElectronicOrdersServiceStateMutations,
    actions: InformationElectronicOrdersServiceStateActions

};
