import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "breadcrumb" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("ol", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links, (link, index) => {
      return (_openBlock(), _createElementBlock("li", {
        class: "breadcrumb__item",
        key: index
      }, [
        _createVNode(_component_router_link, {
          title: _ctx.locale[`${link.name}`],
          to: {name:link.name},
          class: _normalizeClass({
            'breadcrumb__item-active' : _ctx.pageInfo.name === link.name,
            'd-md-block':index!==_ctx.links.length-1,
             'd-none':index!==_ctx.links.length-1,
          })
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.locale[`${link.name}`]), 1)
          ]),
          _: 2
        }, 1032, ["title", "to", "class"])
      ]))
    }), 128))
  ]))
}