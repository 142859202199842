import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';

@Options({
  name: 'search-example-component',
  props: {
    examples: {
      type: Array,
    },
    inputFocus: {
      type: Boolean,
    }
  }
})

export default class SearchExampleComponent extends Vue {
}
