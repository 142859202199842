import {container, injectable} from 'tsyringe';
import {API} from '@/core/configs/api';
import {GET} from '@/packages/VHttp/GET';
import {SearchResultModel} from '@/pages/search-phone-number-service/models/search-result.model';
import {CitiesModel} from '@/pages/search-phone-number-service/models/cities.model';

@injectable()
export class SearchPhoneNumberServiceService {
    private _get: GET = container.resolve(GET);

    constructor() {
    }

    getCity(): Promise<any> {
        return this._get.setApi(API.city)
            .setHeaders({cName: 'search-phone-number-service'})
            .requestPromise().then(res => {
                return new CitiesModel(res.data);
            });
    }

    getSearchNumber(payload: any): Promise<any> {
        return this._get.setApi(API.searchNumbers)
            .setParams(payload)
            .setHeaders({cName: 'search-phone-number-service'})
            .requestPromise().then(res => {
                return new SearchResultModel(res.data);
            });
    }
}
