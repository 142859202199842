import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {container} from 'tsyringe';
import {ApiInterceptorRequest, ApiInterceptorResponse} from '@/core/interceptors/api.interceptor';
import {AuthenticationSandbox} from '@/core/auth/authentication.sandbox';

@Options({
  name: 'App',
})
export default class AppComponent extends Vue {
  private interceptor = container.resolve(ApiInterceptorRequest);
  private intercept = container.resolve(ApiInterceptorResponse);
  public sandbox: AuthenticationSandbox = container.resolve(AuthenticationSandbox);
  public styles = [
    'color: #ffeb3b',
    'background: #474141',
    'font-size: 13px',
    'border: 1px solid #ffeb3b',
    'padding: 2px 5px',
    'border-radius: 5px'
  ].join(';');
  created(): void {
    console.log(`%cversion: ${require('../package.json').version}`,this.styles);

    this.sandbox.initAuth();
  }
}
