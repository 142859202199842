<style src="./mobile-menu.component.scss" lang="scss"></style>

<template>
  <div class="mobile-menu" :class="{'mobile-menu__active':isVisibility}">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="mobile-menu__wrapper">
            <div class="mobile-menu__header">
              <div class="mobile-menu__header-close">
                <img @click="closeMobileMenu" src="@/assets/images/icons/menu-blue.svg" alt="">
              </div>
              <div
                  @click="login"
                  class="mobile-menu__header-login"
                  v-if="!userData.name.length">
                <img class="mr-10" @click="closeMobileMenu" src="@/assets/images/icons/login-mobile.svg" alt="">
                <span>Daxil ol</span>
              </div>
              <div
                  @click="logout"
                  class="mobile-menu__header-login" v-else>
                <img class="mr-10" src="@/assets/images/icons/logout-mobile.svg" alt="">
                <span>Çıxış</span>
              </div>
            </div>
            <div class="mobile-menu__information" v-if="userData.name.length">
              <ul>
                <li v-for="item in information">
                  <router-link :to="{name:item.link}" @click="closeMobileMenu">
                    <img :src="require(`@/assets/images/icons/${item.icon}`)" class="mr-26" alt="icon">
                    <span>{{ item.name }}</span>
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="mobile-menu__list">
              <ul>
                <li
                    v-for="(item ) in menu"
                    :key="item.id">
                  <router-link
                      :to="{name: 'information', params:{id:item.content.id}}"
                      @click="linkClick(item.content.id)"
                      v-if="item.content">
                    {{ item.name }}
                  </router-link>
                  <router-link
                      @click="closeMobileMenu"
                      :to="{name: item.link}" v-else>
                    {{ item.name }}
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="mobile-menu__footer text-center">
              <a href="tel:1655">
                <img class="mr-16" src="@/assets/images/icons/tel.svg" alt="">
                <span>  1655 Çağrı Mərkəzi</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./mobile-menu.component.ts"></script>
