import {environment} from '@/core/configs/app.config';

export const API = {
    pinnedServices: environment.apiMain + 'home/services?pageNumber=1&pageSize=3',
    mostUsedServices: environment.apiMain + 'home/services?pageNumber=1&pageSize=6',
    serviceCategories: environment.apiMain + 'home/categories',
    services: environment.apiMain + 'home/services',
    service: environment.apiMain + 'home/services',
    serviceId: environment.apiMain + 'home/service',
    serviceName: environment.apiMain + 'home/service',
    serviceHeader: environment.apiMain + 'home/serviceHeader',
    contentCategories: environment.apiMain + 'home/contents',
    content: environment.apiMain + 'home/content',
    organisations: environment.apiMain + 'home/structures',
    organisation_details: environment.apiMain + 'home/structure/',
    categories: environment.apiMain + 'home/category/',
    search_tag: environment.apiMain + 'home/keywords',
    search_results: environment.apiMain + 'home/serviceSearch',
    search_services_key_values: environment.apiMain + 'home/servicesKeyValues',
    search_category_key_values: environment.apiMain + 'home/categoryKeyValues',
    search_services_detailed_search: environment.apiMain + 'home/serviceDetailedSearch',
    feedbackDirections: environment.apiMain + 'home/feedbackTypeKeyValues',
    feedbackAppealTypes: environment.apiMain + 'home/feedbackFormKeyValues',
    feedbackOrganisations: environment.apiMain + 'home/structuresKeyValues',
    feedbackServices: environment.apiMain + 'home/servicesKeyValues',
    feedbackPost: environment.apiMain + 'home/feedbacks',
    faq: environment.apiMain + 'home/faqs',
    userData: environment.apiMain + 'auth/GetJwtToken',
    feedbacks: environment.apiMain + 'home/feedbackGetAll',
    feedback: environment.apiMain + 'home/feedback',
    getCheckImei: environment.apiMain + 'imei/getTariffStatus',
    postAddApplication: environment.apiMain + 'imei/addApplication',
    numberList: environment.apiMain + 'phone/numberListByPin',
    infoNumberList: environment.apiMain + 'phoneList/detailedInfoService',
    electronOrders: environment.apiMain + 'phone/electronOrders',
    organizationType: environment.apiMain + 'phone/organizationtype',
    organizationSubtype: environment.apiMain + 'phone/organizationSubtype',
    organizationInfo: environment.apiMain + 'phone/info/organization',
    mobileMNPCheck: environment.apiMain + 'mobilePhone/mobileMNPCheck',
    tin: environment.apiMain + 'phone/info/voen',
    organizationNumber: environment.apiMain + 'phone/info/organizationNumber',
    commonApplicantInfo: environment.apiMain + 'home/applicant/info',

    receiptResponse: environment.apiMain + 'imei/document/receiptResponse',
    sendReceiptToEmail: environment.apiMain + 'imei/document/sendReceiptToEmail',

    getTrailerVehicleType: environment.apiMain + 'truck/getTrailerVehicleType',
    organPersonType: environment.apiMain + 'truck/organPersonType',
    getDocumentSeriesType: environment.apiMain + 'truck/getDocumentSeriesType',
    getCitizenship: environment.apiMain + 'truck/getCitizenship',

    addAppeal: environment.apiMain + 'truck/addAppeal',
    addAppealFile: environment.apiMain + 'truck/addAppealFile',
    addAppealVehicleInfo: environment.apiMain + 'truck/addAppealVehicleInfo',
    confirmAppeal: environment.apiMain + 'truck/confirmAppeal',


    callHistory: environment.apiMain + 'phone/callHistory',
    currentReceipt: environment.apiMain + 'phone/currentReceipt',
    painReceiptService: environment.apiMain + 'phone/painReceiptService',
    previousReceipt: environment.apiMain + 'phone/previousReceipt',
    detailedInfoService: environment.apiMain + 'phone/detailedInfoService',
    payment: environment.apiMain + 'phone/payment',
    paymentUrl: environment.apiMain + 'imei/generatePaymentUrl',
    manufacturer: environment.apiMain + 'imei/getManufacturer',
    documentTypes: environment.apiMain + 'imei/document/types',
    documentGroups: environment.apiMain + 'imei/document/groups',

    modelsByManufacturer: environment.apiMain + 'imei/getModelsByManufacturer',
    getStatusMessage: environment.apiMain + 'imei/getStatusMessage',
    streetByPostIndex: environment.apiMain + 'street/streetByPostIndex',
    streetByName: environment.apiMain + 'street/streetByName',
    mobileNumbersList: environment.apiMain + 'mobilePhone/mobileNumbersByPin',
    postTracking: environment.apiMain + 'post/postTracking',
    city: environment.apiMain + 'phone/cityList',
    searchNumbers: environment.apiMain + 'phone/searchByPhoneNumber',
    serviceRequests: environment.apiMain + 'home/user/serviceRequests',
    feedbackGetAll: environment.apiMain + 'home/feedbackGetAll',
    userDetails: environment.apiMain + 'home/user/details',
    newUserDetails: environment.apiMain + 'home/updateDocument',
    updateUserDetails: environment.apiMain + 'home/user/details',

    // Common

    commonCitizenship: environment.apiMain + 'home/documentTypeGroupsKeyValue',
    commonDocumentSeriesType: environment.apiMain + 'home/documentTypesKeyValue',
    commonApplicantType: environment.apiMain + 'home/serviceApplicantTypeKeyValue',

    //red
    redCreateAppeal: environment.apiMain + 'radio/red/createAppeal',
    redFileTypesKeyValues: environment.apiMain + 'radio/red/fileTypesKeyValues',
    redAppealTechnologyKeyValues: environment.apiMain + 'radio/red/appealTechnologyKeyValues',

    // Radio

    radioApplicantType: environment.apiMain + 'radio/applicantTypes',
    radioCitizenship: environment.apiMain + 'radio/citizenships',
    radioFrequencyUnits: environment.apiMain + 'radio/frequencyUnits',
    radioDocumentSeriesType: environment.apiMain + 'radio/documentSeriesType',
    radioOrganizationName: environment.apiMain + 'radio/organizationName',
    radioCreateRedAppeal: environment.apiMain + 'radio/createRedAppeal',

    radioInterferenceTechnologyTypes: environment.apiMain + 'radio/interferenceTechnologyTypes',
    radioInterferenceTypes: environment.apiMain + 'radio/interferenceTypes',
    radioEffectDurations: environment.apiMain + 'radio/effectDurations',
    radioApplicant: environment.apiMain + 'radio/applicant',
    radioCreateAppeal: environment.apiMain + 'radio/createAppeal',

    // calculator
    calculatorUserGroups: environment.apiMain + 'radio/effectDurations',
    getCalculateResult: environment.apiMain + 'radio/Calculator',

    // Isp
    appealIsp: environment.apiMain + 'isp/appeal',
    changeAppealStatus: environment.apiMain + 'isp/changeAppealStatus',
    getActivityAddresses: environment.apiMain + 'isp/getActivityAddresses',
    getActivityScopes: environment.apiMain + 'isp/getActivityScopes',
    getActivityTypes: environment.apiMain + 'isp/getActivityTypes',
    getAppealStatuses: environment.apiMain + 'isp/getAppealStatuses',
    getInternetTelecommunicationsServiceTypes: environment.apiMain + 'isp/getInternetTelecommunicationsServiceTypes',


    //  e-signature
    version: environment.apiSignature + 'version',
    readCertificatesFromStore: environment.apiSignature + '/api/v1/signer/readcertificatesfromstore',
    tsaclients: environment.apiSignature + '/api/v1/signer/tsaclients',
    fullsign: environment.apiSignature + '/api/v1/signer/fullsign',
    status: environment.apiSignature + '/api/v1/signer/status',

    // CErt

    certCitizenships: environment.apiMain + 'cert/citizenships',
    certApplicantInfo: environment.apiMain + 'cert/applicant/info',
    certFiles: environment.apiMain + 'cert/files',
    certAppeal: environment.apiMain + 'cert/appeal',

    //  SP
    SPgetAppealFileSP: environment.apiMain + 'sp/AppealFileConfiguration',
    SPgetRestrictedCirculationItem: environment.apiMain + 'sp/RestrictedCirculationItem',
    SPgetRequiredCrucialData: environment.apiMain + 'sp/RequiredCrucialData',
    SPpostCreateAppeal: environment.apiMain + 'sp/CreateAppeal',

};

export const Login = {
    general: environment.apiAsan + 'ssoauthz/api/v1/token',
    asanLog: environment.apiAsan + `auth?origin=`,
    redirect: environment.apiAsan + `auth?origin=`,
    check: environment.apiAsan + 'ssoauthz/api/v1/token/check',
    logout: environment.apiAsan + 'cdsso-logout',
};

export const ESing = {
    version: environment.apiSignature + 'version',
    readCertificatesFromStoreV1: environment.apiSignature + '/api/v1/signer/readcertificatesfromstore',
    tsaclientsV1: environment.apiSignature + '/api/v1/signer/tsaclients',
    fullsignV1: environment.apiSignature + 'api/v1/signer/fullsign',
    status: environment.apiSignature + '/api/v1/signer/status'
};
