import {container} from 'tsyringe';
import {UserApplyService} from '@/pages/user-apply/services/user-apply.service';

const service = container.resolve(UserApplyService);

export const UserApplyActions = {
    getServiceRequests(context: any, payload: any): any {
        return service.getServiceRequests(payload).then(res => context.commit('SET_SERVICE_REQUEST', res));
    },
};
