import {container} from 'tsyringe';
import {UserFeedbackService} from '@/pages/user-feedback/services/user-feedback.service';

const service = container.resolve(UserFeedbackService);

export const UserFeedbackActions = {
    getFeedBackAll(context: any, payload: any): any {
        return service.getFeedBackAll(payload).then(res => context.commit('SET_FEEDBACK_ALL', res));
    },
};
