import {container} from 'tsyringe';
import {StreetSearchServiceService} from '@/pages/street-search-service/services/street-search-service.service';

const service = container.resolve(StreetSearchServiceService);

export const StreetSearchServiceActions = {
    getStreetByPostIndex(context: any, payload: any): any {
        service.getStreetByPostIndex(payload).then(res => context.commit('SET_STREET_BY_POST_INDEX', res));;
    },
    getStreetByName(context: any, payload: any): any {
        service.getStreetByName(payload).then(res => context.commit('SET_STREET_BY_NAME', res));
    },

    getStreetCity(context: any, payload: any): any {
        service.getStreetCity().then(res => context.commit('SET_STREET_CITY', res));
    },
};
