import {IFeedbackSelect} from '@/core/shared/feadback/types/feedback-select';
import {IFeedbackState} from '@/core/shared/feadback/store/state';

export const FeedbackStateMutations = {

    SET_FEEDBACK_APPEAL_TYPES(state: IFeedbackState, payload: IFeedbackSelect[]): void {
        state.feedbackAppealTypes = payload;
    },

    SET_FEEDBACK_SERVICES(state: IFeedbackState, payload: IFeedbackSelect[]): void {
        state.feedbackServices = payload;
    },

    SET_FEEDBACK_SERVICES_RESET(state: IFeedbackState): void {
        state.feedbackServices = [];
    },

    SET_FEEDBACK_ORGANISATIONS(state: IFeedbackState, payload: IFeedbackSelect[]): void {
        state.feedbackOrganisations = payload;
    },

    SET_FEEDBACK_DIRECTIONS(state: IFeedbackState, payload: IFeedbackSelect[]): void {
        state.feedbackDirections = payload;
    },
};
