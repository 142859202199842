import {IPublicState} from './state.d'
import {PublicStateGetters} from './getters'
import {PublicStateMutations} from './mutations'
import {PublicStateActions} from './actions'

const PublicStateInitial: IPublicState = {
    searchTags:[],
    searchExample:[],
    searchStructure:[],
    searchCategories:[],
}

export const PublicState = {
    state: PublicStateInitial,
    getters: PublicStateGetters,
    mutations: PublicStateMutations,
    actions: PublicStateActions

}
