import {container, injectable} from 'tsyringe';
import {GET} from '@/packages/VHttp/GET';
import {API} from '@/core/configs/api';
import {PinnedServiceModel} from '@/pages/home/models/pinned-service.model';
import {MostUsedServiceModel} from '@/pages/home/models/most-used-service.model';
import {ServiceCategoryModel} from '@/pages/home/models/service-category.model';

@injectable()
export class HomeService {
    private _get: GET = container.resolve(GET)

    constructor() {
    }

    getPinnedServices(): Promise<any> {
        return this._get.setApi(API.pinnedServices)
            .requestPromise().then(res => {
                return res.data.services.items.map((i: any) => {
                    return new PinnedServiceModel(i);
                })
            });
    }

    getMostUsedServices(): Promise<any> {
        return this._get.setApi(API.mostUsedServices)
            .requestPromise().then(res => {
                return res.data.services.items.map((i: any) => {
                    return new MostUsedServiceModel(i);
                })
            });
    }

    getServiceCategories(): Promise<any> {
        return this._get.setApi(API.serviceCategories)
            .requestPromise().then(res => {
                return res.data.categories.items.map((i: any) => {
                    return new ServiceCategoryModel(i);
                })
            });
    }

}
