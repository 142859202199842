<style lang="scss" src="./loader.component.scss"></style>

<template>
  <div class="loader">
    <div class="lds-roller">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script src="./loader.component.ts"></script>
