import {container} from 'tsyringe';
import {LocalTalksServiceService} from '@/pages/local-talks-service/services/local-talks-service.service';

const service = container.resolve(LocalTalksServiceService);

export const LocalTalksServiceStateActions = {
    getCallHistory(context: any, payload:any): void {
        service.getCallHistory(payload).then(res => context.commit('SET_CALL_HISTORY', res));
    },
};
