import {Options, Vue} from 'vue-class-component';

@Options({
    name: 'pagination-component',
    props: {
        limit: {
            type: Number,
            required: true
        },
        dataCount: {
            type: Number,
            required: true
        },
    },

})

export default class PaginationComponent extends Vue {
    public page: number = 1;
    public dataCount: number = 0;
    public limit: number = 10;
    public width: number | null = null;
    public viewPage = 6;

    public mounted() {
        this.width = window.innerWidth;
        window.addEventListener('resize', () => {
            this.width = window.innerWidth;
            if (this.width >= 768) {
                this.viewPage = 6;
            } else {
                this.viewPage = 2;
            }
        });
    }

    public countPage(): number {
        return Math.ceil(Number(this.dataCount) / Number(this.limit));
    }

    public isMobileCountPage(): number {
        if( Math.ceil(Number(this.mobileCountPage()) / 5)>1) {
            return  5
        } else  {
            return  this.mobileCountPage()
        }
    }

    public mobileCountPage(): number {
        return Math.ceil((Number(this.dataCount) / Number(this.limit)));
    }

    public reset() {
        this.page = 1;
    }

    public definitionPayload(): any {
        let payload = {
            pageNumber: this.page,
            pageSize: this.limit,
        };
        return payload;
    }

    public changePages(pageNumber: number): void {
        if (pageNumber !== this.page) {
            this.page = pageNumber;
            this.$emit('changePage', this.definitionPayload());
        }

    }

    public nextPage(): void {
        if (this.page !== this.countPage()) {
            this.page = ++this.page;
            this.$emit('changePage', this.definitionPayload());
        }
    }

    public prevPage(): void {
        if (this.page > 1) {
            this.page = --this.page;
            this.$emit('changePage', this.definitionPayload());
        }
    }

}
