import {IUserApplyState} from '@/pages/user-apply/store/state';


export const UserApplyGetters = {
    _getServiceRequests: (state: IUserApplyState) => {
        return state.serviceRequests;
    },
    _getTotalCountServiceRequests: (state: IUserApplyState) => {
        return state.totalCountServiceRequests;
    },

};
