import {container} from 'tsyringe';
import {AuthenticationServiceService} from '@/core/auth/authentication.service';

const service = container.resolve(AuthenticationServiceService);

export const AuthStateActions = {
    setToken(context: any, payload: any): void {
        service.getUserData(payload).then(res => {
            context.commit('setToken', res.data);
            localStorage.setItem('mainToken', res.data);
        })
    },
    setTokenToStore(context: any, payload: any): void {
        context.commit('setToken', payload);
        localStorage.setItem('mainToken', payload);
    },
    checkLogin(): any {
        return service.checkLogin();
    },
    checkToken(): any {
        return service.checkToken();
    },

}
