import {ISearchOfficesClassifierServiceState} from './state';
import {SearchOfficesClassifierServiceStateGetters} from './getters';
import {SearchOfficesClassifierServiceStateMutations} from './mutations';
import {SearchOfficesClassifierServiceStateActions} from './actions';


const SearchOfficesClassifierServiceStateInitial: ISearchOfficesClassifierServiceState = {
    organizationType: [],
    organizationSubtype: [],
    organizationInfo: [],
    totalCountOrganizationInfo: 0,
};

export const SearchOfficesClassifierServiceState = {
    state: SearchOfficesClassifierServiceStateInitial,
    getters: SearchOfficesClassifierServiceStateGetters,
    mutations: SearchOfficesClassifierServiceStateMutations,
    actions: SearchOfficesClassifierServiceStateActions
};
