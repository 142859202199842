<style src="./select.component.scss" lang="scss"></style>

<template>
  <div class="select" :class="[{'select--disabled':disabled}, {'select--error':error}]">
    <div
        :class="{'select__name-active':toggleSelect}"
        @click="changeSelectItem"
        :title="selected"
        class="select__name">
      <template v-if="!value">
        <div
            class="select__select"
            v-if="selected"
            :title="selected">
          {{ selected }}
        </div>
        <div class="select__placeholder" v-else>
          {{ placeholder }}
        </div>
      </template>
      <template v-for="item in options">
        <div v-if="item.id === this.value">
          {{ item.value }}
        </div>
      </template>
    </div>
    <div
        v-if="toggleSelect"
        class="select__wrapper">
      <div
          @click="changeSelection(item)"
          v-for="(item, index) in options"
          :key="index"
          class="select__item"
      >
        {{ item.value }}
      </div>
    </div>
  </div>

</template>

<script src="./select.component.ts"></script>
