import {container} from 'tsyringe';
 import {ListMobileNumbersService} from '@/pages/list-mobile-numbers-service/services/list-mobile-numbers.service';

const service = container.resolve(ListMobileNumbersService);

export const ListMobileNumbersServiceStateActions = {
    getMobileNumbersList(context: any, payload: string): void {
        service.getMobileNumbersList(payload).then(res => context.commit('SET_LIST_MOBILE_NUMBERS', res));
    },
};
