import {container} from 'tsyringe';
import {
    DebtPreviousMonthsServiceService
} from '@/pages/debt-previous-months-service/services/debt-previous-months-service.service';

const service = container.resolve(DebtPreviousMonthsServiceService);

export const DebtPreviousMonthsServiceStateActions = {
    getDepReceipts(context: any, payload:any): void {
        service.getReceipts(payload).then(res => context.commit('SET_DEP_RECEIPTS', res));
    },
};
