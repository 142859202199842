import {IRecordKeepingState} from '@/pages/record-keeping-service/store/state';
import {ISearchSelect} from '@/core/layouts/public/types/search-select-structure';
import {IUserForm} from '@/pages/record-keeping-service/types/user-form';

export const RecordKeepingStateGetters = {

    _getUserForm: (state: IRecordKeepingState): IUserForm => {
        return state.userForm;
    },

    // dropdown  data

    _getApplicantActivityAddress: (state: IRecordKeepingState): ISearchSelect[] => {
        return state.applicantActivityAddress;
    },
    _getApplicantTypeActivities: (state: IRecordKeepingState): ISearchSelect[] => {
        return state.applicantTypeActivities;
    },
    _getApplicantTypesTelecommunicationsService: (state: IRecordKeepingState): ISearchSelect[] => {
        return state.applicantTypesTelecommunicationsService;
    },
    _getApplicantScopeActivity: (state: IRecordKeepingState): ISearchSelect[] => {
        return state.applicantScopeActivity;
    },
};
