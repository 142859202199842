<style src="./service-card.component.scss" lang="scss"></style>

<template>
  <div class="service-card">
    <box-component
        @click="followingLink(item)"
        direction="column"
        :title="item.title"
        :icon="item.icon"
        :registration="item.authRequired"
        bold-desc
        desc-size="16"
    >
      {{ item.description }}
    </box-component>
  </div>
</template>

<script src="./service-card.component.ts"></script>