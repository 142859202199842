import {ISearchTag} from '@/core/layouts/public/types/search-tag';
import {IPublicState} from '@/core/layouts/public/store/state';
import {ISearchExample} from '@/core/layouts/public/types/search-example';
import {ISearchSelect} from '@/core/layouts/public/types/search-select-structure';


export const PublicStateGetters = {
    _getSearchTag: (state: IPublicState): ISearchTag[] => {
        return state.searchTags;
    },
    _getSearchExample: (state: IPublicState): ISearchExample[] => {
        return state.searchExample;
    },
    _getSearchStructure: (state: IPublicState): ISearchSelect[] => {
        return state.searchStructure;
    },
    _getSearchCategories: (state: IPublicState): ISearchSelect[] => {
        return state.searchCategories;
    },
}
