import {IReceipt} from '@/pages/receipts-paid-telephone-service/types/receipt';

export class DebReceiptsModel {
    // public totalCountReceipt: number | null = 0;
    public receipt: IReceipt[] | null = null;
    public totalAmount: number | null = null;

    constructor(item: any) {
        // this._setTotalCountReceipt(item);
        this._setReceipt(item);
        this._setTotalAmount(item);
    }


    private _setReceipt({data}: any) {
        if (data) {
            this.receipt = data.map((item: any) => {
                return new DebReceiptModel(item);
            });
        } else {
            this.receipt = [];
        }

    }

    private _setTotalAmount({totalDebts}: any): void {
        this.totalAmount = totalDebts;
    }
}

class DebReceiptModel {
    public number: number | null = null;
    public date: number | null = null;
    public dialTheNumber: number | null = null;
    public duration: number | null = null;
    public amount: number | null = null;

    constructor(item: any) {
        this._setNumber(item);
        this._setDate(item);
        this._setDialTheNumber(item);
        this._setDuration(item);
        this._setAmount(item);
    }

    private _setNumber({phone}: any) {
        this.number = phone;
    }

    private _setDate({date}: any) {
        this.date = date;
    }

    private _setDialTheNumber({dialPhone}: any) {
        this.dialTheNumber = dialPhone;
    }

    private _setDuration({term}: any) {
        this.duration = term;
    }

    private _setAmount({totalAmount}: any) {
        this.amount = totalAmount;
    }
}
