import {IStreet} from '@/pages/street-search-service/types/street';
import {Street} from '@/pages/street-search-service/models/street.model';

export class TotalStreet {
    public totalCountStreet: number = 0;
    public streets: IStreet[] = [];

    constructor(item: any) {
        this._setTotalCountStreet(item);
        this._setStreetsByName(item);
    }

    private _setTotalCountStreet({totalRowCount}: any): void {
        this.totalCountStreet = totalRowCount;
    }

    private _setStreetsByName({data}: any): void {
        this.streets = data.map((item: any) => {
            return new Street(item);
        });
    }
}