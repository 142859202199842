import {IPhoneNumberDebt} from '@/pages/search-phone-number-service/types/phone-number-debt';
import {PhoneNumberDebtModel} from '@/pages/search-phone-number-service/models/phone-number-debt.model';

export class SearchResultModel {
    public phone: string | null = null;
    public name: string | null = null;
    public street: string | null = null;
    public address: string | null = null;
    public phoneType: string | null = null;
    public phoneNumberDebt: IPhoneNumberDebt[] | null = null;

    constructor(item: any) {
        this._setPhone(item.phoneInfo);
        this._setName(item.phoneInfo);
        this._setStreet(item.phoneInfo);
        this._setAddress(item.phoneInfo);
        this._setPhoneType(item.phoneInfo);
        this._setPhoneNumberDebt(item.phoneNumberDebt);
    }

    /**
     *
     * @param phone
     * @private
     */

    private _setPhone({phone}: any) {
        this.phone = phone;
    }

    /**
     *
     * @param name
     * @private
     */

    private _setName({name}: any) {
        this.name = name;
    }

    /**
     *
     * @param street
     * @private
     */

    private _setStreet({street}: any) {
        this.street = street;
    }

    /**
     *
     * @param address
     * @private
     */

    private _setAddress({address}: any) {
        this.address = address;
    }

    /**
     *
     * @param phoneType
     * @private
     */

    private _setPhoneType({phoneType}: any) {
        this.phoneType = phoneType;
    }

    /**
     *
     * @param item
     * @private
     */

    private _setPhoneNumberDebt(item: any) {
        if (item) {
            this.phoneNumberDebt = item.data.map((item: any) => {
                return new PhoneNumberDebtModel(item);
            });
        } else {
            this.phoneNumberDebt = null;
        }

    }
}