import {OrganisationServiceModel} from '@/pages/organisations/models/organisation-service.model';

export class CategoryModel {
    public id: number | null = null;
    public icon: string | null = null;
    public title: string | null = null;
    public services: ServiceModel[] | null = null;

    constructor(item: any) {
        this._setId(item);
        this._setIcon(item);
        this._setTitle(item);
        this._setServices(item);
    }

    /**
     * Set Id
     * @param ID
     * @private
     */
    private _setId({id}: any) {
        this.id = id;
    }

    /**
     * Set icon
     * @param file
     * @private
     */
    private _setIcon({file}: any) {
        this.icon = file?.fileUrl;
    }

    /**
     * Set title
     * @param categoryLanguages
     * @private
     */
    private _setTitle({categoryLanguages}: any) {
        this.title = categoryLanguages[0]?.name;
    }

    private _setServices({serviceCategories}: any) {
        this.services = [];
        let serviceArr: ServiceModel[] | null = [];
        serviceCategories.forEach((item: any) => {
            if (item.service) {
                // @ts-ignore
                serviceArr.push(new ServiceModel(item.service));
            }
        });
        this.services = serviceArr;
    }
}


class ServiceModel {
    public id: number | null = null;
    public icon: string | null = null;
    public title: string | null = null;
    public description: string | null = null;
    public authRequired: boolean | null = null;
    public component: boolean | null = null;

    constructor(item: any) {
        this._setId(item);
        this._setIcon(item);
        this._setTitle(item);
        this._setDescription(item);
        this._setAuthRequired(item);
        this._setComponent(item);
    }

    /**
     * Set Id
     * @param ID
     * @private
     */
    private _setId({id}: any) {
        this.id = id;
    }

    /**
     * Set icon
     * @param file
     * @private
     */
    private _setIcon({file}: any) {
        this.icon = file?.fileUrl;
    }

    /**
     * Set title
     * @param serviceStructures
     * @private
     */
    private _setTitle({serviceStructures}: any) {
        this.title = serviceStructures[0]?.structure?.title;
    }

    /**
     * Set description
     * @param serviceLanguages
     * @private
     */
    private _setDescription({serviceLanguages}: any) {
        this.description = serviceLanguages[0]?.name;
    }

    /**
     *
     * @param authRequired
     * @private
     */

    private _setAuthRequired({authRequired}: any) {
        this.authRequired = authRequired;
    }

    private _setComponent({component}: any) {
        this.component = component?.name;
    }
}

