import {container, injectable} from 'tsyringe';
import {API} from '@/core/configs/api';
import {GET} from '@/packages/VHttp/GET';
import {
    TotalCountOrganizationModel,
} from '@/pages/detailed-information-departments-service/models/voen.model';

@injectable()
export class DetailedInformationDepartmentsServiceService {
    private _get: GET = container.resolve(GET);

    constructor() {
    }

    getVoen(payload: any): Promise<any> {
        return this._get.setApi(API.tin)
            .setHeaders({cName: 'detailed-information-departments-service'})
            .setParams({
                voen: payload.voen,
                PageNumber: payload.pageNumber,
                PageSize: 10
            })
            .requestPromise().then(res => {
                return new TotalCountOrganizationModel(res.data);
            });
    }

    getPhone(payload: any): Promise<any> {
        return this._get.setApi(API.organizationNumber)
            .setHeaders({cName: 'detailed-information-departments-service'})
            .setParams({
                number: payload.number,
                pageNumber: payload.pageNumber,
                pageSize: payload.pageSize
            })
            .requestPromise().then(res => {
                return new TotalCountOrganizationModel(res.data);
            });
    }
}
