import {IDebtPreviousMonthsServiceState} from './state.d';
import {DebtPreviousMonthsServiceStateActions} from '@/pages/debt-previous-months-service/store/actions';
import {DebtPreviousMonthsServiceStateGetters} from '@/pages/debt-previous-months-service/store/getters';
import {DebtPreviousMonthsServiceStateMutations} from '@/pages/debt-previous-months-service/store/mutations';


const DebtPreviousMonthsServiceStateInitial: IDebtPreviousMonthsServiceState = {
    numbers: [],
    depReceipts: null,
    totalCountDepReceipts: 0,
    defaultSelect: 0,
    totalAmount: 0
};

export const DebtPreviousMonthsServiceState = {
    state: DebtPreviousMonthsServiceStateInitial,
    getters: DebtPreviousMonthsServiceStateGetters,
    mutations: DebtPreviousMonthsServiceStateMutations,
    actions: DebtPreviousMonthsServiceStateActions
};
