import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import {store} from '@/store';
import BreadcrumbComponent from '@/packages/VBreadcrumb/breadcrumb.component.vue';
import VBtn from '@/packages/VButton/button.component.vue';
import ToastComponent from '@/packages/VToasts/toast.component.vue';
import CardComponent from '@/packages/VCard/card.component.vue';
import AlertComponent from '@/packages/VAlert/alert.component.vue';
import ModalComponent from '@/packages/VModal/modal.component.vue';
import DividerComponent from '@/packages/VDivider/divider.component.vue';
import TableComponent from '@/packages/VTable/table.component.vue';
import Pagination from '@/packages/VPagination/pagination.component.vue';
import InlineSvg from 'vue-inline-svg';
import SectionTitleComponent from '@/core/shared/section-title/section-title.component.vue';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueTheMask from 'vue-the-mask';
import SelectComponent from '@/core/shared/select/select.component.vue';
import { Swiper, SwiperSlide } from "swiper/vue";
import Maska from 'maska'
import "swiper/swiper.min.css";
// import { createToast } from 'mosha-vue-toastify';
// import 'mosha-vue-toastify/dist/style.css'
// import Toast from "vue-toastification"
// import "vue-toastification/dist/index.css";

const app = createApp(App);
app.component('v-btn', VBtn);
app.component('v-breadcrumb', BreadcrumbComponent);
app.component('v-toast', ToastComponent);
app.component('v-alert', AlertComponent);
app.component('v-modal', ModalComponent);
app.component('v-card', CardComponent);
app.component('v-divider', DividerComponent);
app.component('inline-svg', InlineSvg);
app.component('v-title', SectionTitleComponent);
app.component('v-select', SelectComponent);
app.component('v-table', TableComponent);
app.component('v-pagination', Pagination);
app.component('swiper', Swiper);
app.component('swiper-slide', SwiperSlide);

// app.components('v-box', SharedComponent)
// @ts-ignore
app.use(store).use(router).use(VueTheMask as any).use(Maska).use(VueSweetalert2).mount('#app');


