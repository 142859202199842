import {IInformationOrganization} from '@/pages/detailed-information-departments-service/types/voen';
import determineNumber from '@/core/helpers/queue-number';

export class TotalCountOrganizationModel {
    public totalCountOrganization: number | null = null;
    public organizations: IInformationOrganization[] | null = null;
    public voen: string | number | null = null;
    public name: string | null = null;

    constructor(item: any) {
        this._setTotalCountOrganization(item);
        this._setOrganization(item);
        this._setVoen(item);
        this._setName(item);
    }

    private _setTotalCountOrganization({totalRowCount}: any): void {
        if (totalRowCount) {
            this.totalCountOrganization = totalRowCount;
        } else {
            this.totalCountOrganization = 0;
        }
    }

    private _setOrganization({data, pageNumber, pageSize}: any): void {
        if (data) {
            this.organizations = data.map((item: any, index: number) => {
                return new OrganizationModel(item, index, pageNumber, pageSize);
            });
        } else {
            this.organizations = [];
        }
    }

    private _setVoen({data}: any): void {
        if (data) {
            this.voen = data[0].organizationVoin;
        } else {
            this.voen = '';
        }

    }

    private _setName({data}: any): void {
        if (data) {
            this.name = data[0].organizationName
        } else {
            this.voen = '';
        }
    }


}

class OrganizationModel {
    public phone: string | null = null;
    public address: string | null = null;
    public queueNumber: number | null = null;

    constructor(item: any, index: any, pageNumber: any, pageSize: any,) {
        this._setPhone(item);
        this._setAddress(item);
        this._setQueueNumber(index, pageSize, pageNumber);
    }

    private _setPhone({phone}: any): void {
        this.phone = phone;
    }

    private _setAddress({street, address}: any): void {
        this.address = `${street} ${address}`;
    }

    private _setQueueNumber(index: number, pageSize: number, pageNumber: number) {
        this.queueNumber = determineNumber(index, pageSize, pageNumber);
    }
}
