import {IServiceState} from '@/pages/services/store/state';
import {IUser} from '@/core/auth/store/state';
import {loginStateMutations} from '@/pages/login/store/store/mutations';
import {ISearchSelect} from '@/core/layouts/public/types/search-select-structure';
import {IReceiptPayment} from '@/pages/services/types/receipt-mcqs';

export const ServiceMutations = {
    SET_RECEIPT_PAYMENT(state: IServiceState, payload: any): void {
        state.imeiInfo.imei.first = payload;
    },
    SET_IMEI_FIRST(state: IServiceState, payload: any): void {
        state.imeiInfo.imei.first = payload;
    },
    SET_IMEI_SECOND(state: IServiceState, payload: any): void {
        state.imeiInfo.imei.second = payload;
    },
    SET_IMEI_FIN(state: IServiceState, payload: any): void {
        state.formRegistrationData.fin = payload;
    },
    SET_IMEI_PHONE(state: IServiceState, payload: any): void {
        state.formRegistrationData.phone = payload;
    },
    SET_DOCUMENT_TYPE_NUMBER(state: IServiceState, payload: any): void {
        state.formRegistrationData.documentTypeNumber = payload;
    },
    SET_IMEI_DOCUMENT(state: IServiceState, payload: any): void {
        state.formRegistrationData.documentNumber = payload;
    },
    SET_IMEI_DOCUMENT_GROUP(state: IServiceState, payload: any): void {
        state.formRegistrationData.documentGroup = payload;
    },
    SET_RESPONSIVE_IMEI_CHECK(state: IServiceState, payload: any): void {
        state.responsiveImeiCheck = payload;
    },
    SET_FORM_IMEI(state: IServiceState, payload: IUser): void {
        if (payload.pin != null) {
            state.formRegistrationData.fin = payload.pin;
        }
        if (payload.document != null) {
            state.formRegistrationData.documentNumber = payload.document;
        }
    },
    SET_IMEI_INFO(state: IServiceState, payload: any): void {
        state.imeiInfo = payload;
    },

    CLEAR_IMEI_DATA(state: IServiceState) {
        state.imeiInfo = {
            imei: {
                first: '',
                second: '',
            },
            simCount: 1,
        };
        state.responsiveImeiCheck = {
            statusCode: null,
            statusText: null,
            description: null,
            price: null,
            request: null,
        };
        state.formRegistrationData = {
            documentType: null,
            documentNumber: '',
            fin: '',
            phone: '',
            documentTypeNumber: 1,
            documentGroup: 1
        };
    },

    SET_FORM_REGISTRATION_DATA(state: IServiceState, payload: any): void {
        state.formRegistrationData.documentType = payload.documentType;
        state.formRegistrationData.documentNumber = payload.documentNumber;
        state.formRegistrationData.fin = payload.fin;
        state.formRegistrationData.phone = payload.phone;
    },

    SET_RECEIPT_IMEI(state: IServiceState, payload: IReceiptPayment): void {
        state.receipt = payload
    },

    SET_DOCUMENT_TYPES(state: IServiceState, payload: ISearchSelect[]): void {
        state.documentTypes = payload;
        state.formRegistrationData.documentTypeNumber = payload[0]?.id
    },
    SET_DOCUMENT_GROUPS(state: IServiceState, payload: ISearchSelect[]): void {
        state.documentGroup = payload;
    },


};
