import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import FooterMenuItemComponent from '@/core/layouts/public/components/footer-menu-item/footer-menu-item.component.vue';
import {IFooterMenu} from '@/core/layouts/public/types/footer-menu';
import SocialsComponent from '@/core/layouts/public/components/socials/socials.component.vue';
import {locale} from '@/assets/db/db.conf.ts';
import {container} from 'tsyringe';
import {store} from '@/store';
import {IContentCategory} from '@/core/layouts/public/types/footer-modal-data';
import {FooterSandbox} from '@/core/layouts/public/components/footer/sandbox/footer.sandbox'
import {DbService} from '@/assets/db/db.service.ts';

@Options({
    name: 'footer-component',
    components: {
        FooterMenuItemComponent,
        SocialsComponent,
    }
})

export default class FooterComponent extends Vue {
    private sandbox: FooterSandbox = container.resolve(FooterSandbox);
    public locale$: any = locale;
    public locale: any = null;
    public localeElements: any = null;
    private localizationService = container.resolve(DbService);
    public language: string = 'az';

    public modalInfo: object = {
        title: '',
        text: ''
    };
    public $refs!: {
        modal: {
            openModal: () => void,
            closeModal: () => void
        };
    };

    public created(): void {
        this.language = store.getters._getLanguage;
        this.locale$.subscribe((res: any) => {
            if (res && res.home) {
                this.locale = res.home.footer;
                this.localeElements = res.elements;
            }
        });
        this.sandbox.getFooterModalData();
    }

    public setLocale($event: any): void {
        store.commit('SET_LANGUAGE', $event.target.value);
        this.$router.push({params: {local: $event.target.value}}).then(()=>{
            window.location.reload();
        })
    }

    get aboutData(): IContentCategory[] {
        return store.getters._getAbout
    }

    get securityPolicyData(): IContentCategory[] {
        return store.getters._getSecurityPolicyData
    }

    public footerItemClick(info: any): void {
        this.sandbox.getContent(info);
        // this.modalInfo = info.content;
        // this.$refs.modal.openModal();
    }

    public closeModal() {
        this.$refs.modal.closeModal();
    }

    get menu(): IFooterMenu[] {
        return [
            {
                id: 1,
                title: this.locale.footerTitleRabbits,
                items: [
                    {
                        id: 1,
                        name: this.locale.footerItemAboutUs,
                        content: this.aboutData,
                    },
                    {
                        id: 2,
                        name: this.locale.footerItemPrivacyAndUse,
                        content: this.securityPolicyData
                    },
                    {
                        id: 2,
                        name: "FAQ",
                        link: 'faq'
                    },
                ]
            },
            {
                id: 2,
                title: this.locale.footerTitleServices,
                items: [
                    {
                        id: 1,
                        name: this.locale.footerItemNewServices,
                        link: 'pinned-services',
                    },
                    {
                        id: 2,
                        name: this.locale.footerItemForCategories,
                        link: 'categories',
                    },
                    {
                        id: 3,
                        name: this.locale.footerItemMostUsed,
                        link: 'most-used',
                    },
                ]
            },

        ];
    }
}
