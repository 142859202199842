import {Options, Vue} from 'vue-class-component';
import ModalHeaderComponent from '@/packages/VModal/components/modal-header/modal-header.component.vue';
import ModalBodyComponent from '@/packages/VModal/components/modal-body/modal-body.component.vue';
import ModalFooterComponent from '@/packages/VModal/components/modal-footer/modal-footer.component.vue';

@Options({
    name: 'modal-component',
    components: {
        ModalHeaderComponent,
        ModalBodyComponent,
        ModalFooterComponent,
    },
    /**
     * @description props for modal-components
     * @param title: String,
     * position: String, modal__dialog--centered
     * size:String, //sm, md, lg,
     * footer:boolean
     */
    props: {
        title: String,
        position: {
            type: String,
            required: false,
        },
        size: {
            type: String,
            default: 'md',
        },
        footer: {
            type: Boolean,
            default: true,
        },
        closeOnOutside: {
            type: Boolean,
            default: true,
        },
    },
})
export default class ModalComponent extends Vue {
    public toggle: boolean = false;
    public closeOnOutside: boolean = true;

    public closeModal(): void {
        this.toggle = false;
        document.body.classList.remove('modal-open');
    }

    public openModal(): void {
        this.toggle = true;
        document.body.classList.add('modal-open');
    }

    public closeModalClick() {
        this.$emit('closeModal');
        this.closeModal();
    }

    public closeModalClickOutSide() {
        if (this.closeOnOutside) {
            this.$emit('closeModal');
            this.closeModal();
        }
    }
}
