import {IFaqsState} from './state.d'
import {FaqStateGetters} from './getters'
import {FaqStateMutations} from './mutations'
import {FaqStateActions} from './actions'

const FaqStateInitial: IFaqsState = {
    faq: [],
}

export const FaqState = {
    state: FaqStateInitial,
    getters: FaqStateGetters,
    mutations: FaqStateMutations,
    actions: FaqStateActions
}
