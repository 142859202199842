export class FeedbackUserModel {
    public structureName: string | null = null;
    public serviceName: string | null = null;
    public id: string | null = null;
    public date: string | null = null;
    public context: string | null = null;
    public feedbackFormName: string | null = null;
    public feedbackTypeName: string | null = null;
    public status: string | null = null;
    public statusId: number | null = null;

    constructor(item: any) {
        this._setStructureName(item);
        this._setServiceName(item);
        this._setDate(item);
        this._setId(item);
        this._setContext(item);
        this._setFeedbackFormName(item);
        this._setFeedbackTypeName(item);
        this._setStatus(item);
        this._setStatusId(item);
    }

    /**
     *
     * @param item
     * @private
     */

    private _setStructureName({structureName}: any) {
        this.structureName = structureName;
    }

    /**
     *
     * @param serviceName
     * @private
     */

    private _setServiceName({serviceName}: any) {
        this.serviceName = serviceName;
    }

    /**
     *
     * @param date
     * @private
     */

    private _setDate({createdDate}: any) {
        this.date = createdDate;
    }

    private _setId({id}: any) {
        this.id = id;
    }

    private _setContext({contentText}: any) {
        this.context = contentText;
    }

    private _setFeedbackFormName({feedbackFormName}: any) {
        this.feedbackFormName = feedbackFormName;
    }

    private _setFeedbackTypeName({feedbackTypeName}: any) {
        this.feedbackTypeName = feedbackTypeName;
    }

    private _setStatus({feedbackStatus}: any): void {
        if (feedbackStatus) {
            this.status = feedbackStatus;

        }
    }
    private _setStatusId({statusId}: any): void {
        if (statusId) {
            this.statusId = statusId;

        }
    }
}