import {container, injectable} from 'tsyringe';
import {GET} from '@/packages/VHttp/GET';
import {API} from '@/core/configs/api';
import {SearchResultsModel} from '@/pages/search-results/models/search-results.model';
import {ISearchResultsState} from '@/pages/search-results/store/state';
import {loginStateMutations} from "@/pages/login/store/store/mutations";

@injectable()
export class SearchResultsService {
    private _get: GET = container.resolve(GET)

    constructor() {
    }

    public result: ISearchResultsState = {
        searchResults: [],
        totalCount: 0
    };

    getSearchResult(payload: any): Promise<any> {
        return this._get.setApi(API.search_results)
            .setParams({
                ...payload
            })
            .requestPromise().then(res => {
                this.result.searchResults = res.data.services.items.map((i: any) => {
                    return new SearchResultsModel(i);
                });
                this.result.totalCount = res.data.services.totalCount;
                return this.result;
            });
    }

    getSearchResultDetailed(payload: any): Promise<any> {
        return this._get.setApi(API.search_services_detailed_search)
            .setParams({
                ...payload
            })
            .requestPromise().then(res => {
                this.result.searchResults = res.data.services.items.map((i: any) => {
                    return new SearchResultsModel(i);
                });
                this.result.totalCount = res.data.services.totalCount;
                return this.result;
            });
    }
}
