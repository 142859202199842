import {container, injectable} from 'tsyringe';
import {GET} from '@/packages/VHttp/GET';
import {API} from '@/core/configs/api';
import {NumbersTransferredStatusModel} from '@/pages/numbers-transferred-service/models/status.model';

@injectable()
export class NumbersTransferredServiceService {
    private _get: GET = container.resolve(GET);

    constructor() {
    }

    getMobileMNPCheck(payload: number): Promise<any> {
        return this._get.setApi(API.mobileMNPCheck)
            .setHeaders({cName: 'numbers-transferred-service'})
            .setParams({number: payload})
            .requestPromise().then(res => {
                return new NumbersTransferredStatusModel(res.data);
            });
    }

}
