import {IReceipt} from '@/pages/receipts-paid-telephone-service/types/receipt';
import {Login} from '@/core/configs/api';

export class ReceiptsModel {
    public totalCountReceipt: number | null = 0;
    public receipt: IReceipt[] = [];
    public totalPayments: number | null = 0;
    public totalTerm: number | null = 0;

    constructor(item: any) {
        this._setTotalCountReceipt(item);
        this._setTotalPayments(item);
        this._setTotalTerm(item);
        this._setReceipt(item);
    }

    private _setTotalCountReceipt({totalRowCount}: any) {
        if (totalRowCount) {
            this.totalCountReceipt = totalRowCount;
        } else {
            this.totalCountReceipt = 0;
        }

    }

    private _setReceipt({data}: any) {
        if (data) {
            this.receipt = data.map((item: any) => {
                return new ReceiptModel(item);
            });
        } else {
            this.receipt = [];
        }

    }

    private _setTotalPayments({totalPayments}: any) {
        this.totalPayments = totalPayments;
    }

    private _setTotalTerm({totalTerm}: any) {
        this.totalTerm = totalTerm;
    }
}

class ReceiptModel {
    public number: number | null = null;
    public date: number | null = null;
    public dialTheNumber: number | null = null;
    public duration: number | null = null;
    public amount: number | null = null;

    constructor(item: any) {
        this._setNumber(item);
        this._setDate(item);
        this._setDialTheNumber(item);
        this._setDuration(item);
        this._setAmount(item);
    }

    private _setNumber({receiptId}: any) {
        this.number = receiptId;
    }

    private _setDate({date}: any) {
        this.date = date;
    }

    private _setDialTheNumber({dialPhone}: any) {
        this.dialTheNumber = dialPhone;
    }

    private _setDuration({term}: any) {
        this.duration = term;
    }

    private _setAmount({amount}: any) {
        this.amount = amount;
    }
}