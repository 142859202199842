
      import {IPrivacyState} from './state'
      import {PrivacyStateGetters} from './getters'
      import {PrivacyStateMutations} from './mutations'
      import {PrivacyStateActions} from './actions'

      const PrivacyStateInitial: IPrivacyState = {
         contents: []
      }

      export const PrivacyState = {
        state: PrivacyStateInitial,
        getters: PrivacyStateGetters,
        mutations: PrivacyStateMutations,
        actions: PrivacyStateActions

    }
