import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "user-account" }
const _hoisted_2 = { class: "user-account__common" }
const _hoisted_3 = { class: "user-account__right mt-25 pl-20 pr-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_component = _resolveComponent("header-component")!
  const _component_loader_component = _resolveComponent("loader-component")!
  const _component_user_sidebar_component = _resolveComponent("user-sidebar-component")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_footer_component = _resolveComponent("footer-component")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_header_component, {
      isHome: false,
      isVisibleSearch: false
    }),
    (_ctx.hasLoader)
      ? (_openBlock(), _createBlock(_component_loader_component, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_user_sidebar_component, { class: "d-lg-block d-none" }),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_router_view)
        ])
      ]),
      _createVNode(_component_footer_component)
    ])
  ], 64))
}