import {IServiceState} from './state.d';
import {ServiceGetters} from './getters';
import {ServiceMutations} from './mutations';
import {ServiceActions} from './actions';

const ServiceStateInitial: IServiceState = {
    imeiInfo: {
        imei: {
            first: '',
            second: '',
        },
        simCount: 1,
    },
    responsiveImeiCheck: {
        statusCode: null,
        statusText: null,
        description: null,
        price: null,
        request: null,
    },
    formRegistrationData: {
        documentType: null,
        documentNumber: '',
        documentTypeNumber: null,
        fin: '',
        phone: '+994(',
        documentGroup: 1
    },
    receipt: {
        date: null,
        receiptNumber: null,
        amount: null,
        account: null,
        // templateForMessage: null,
        templateForPDF: null,
    },
    documentGroup: [],
    documentTypes: [],
    documentTypeNumber: ''
};

export const ServiceState = {
    state: ServiceStateInitial,
    getters: ServiceGetters,
    mutations: ServiceMutations,
    actions: ServiceActions
};
