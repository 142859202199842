import {IAllServicesState} from './state.d';
import {AllServicesStateGetters} from './getters';
import {AllServicesStateMutations} from './mutations';
import {AllServicesStateActions} from './actions';

const AllServicesStateInitial: IAllServicesState = {
    allServices: [],
    totalCount: 0
};

export const AllServicesState = {
    state: AllServicesStateInitial,
    getters: AllServicesStateGetters,
    mutations: AllServicesStateMutations,
    actions: AllServicesStateActions

};
