import 'reflect-metadata';
import {injectable} from 'tsyringe';
import {store} from '@/store';
import {FeedbackPostModel} from '@/core/shared/feadback/models/feedback-post.model';


@injectable()
export class FeedbackSandbox {
    private store = store;

    constructor() {
    }

    getFeedbackDirections(): void {
        this.store.dispatch('getFeedbackDirections');
    }

    getFeedbackAppealTypes(): void {
        this.store.dispatch('getFeedbackAppealTypes');
    }

    getFeedbackServices(payload:number): void {
        this.store.dispatch('getFeedbackServices', payload);
    }

    getFeedbackOrganisations(): void {
        this.store.dispatch('getFeedbackOrganisations');
    }

    sendFeedback(payload: any): Promise<any> {
        return this.store.dispatch('sendFeedback', payload)
    }

}
