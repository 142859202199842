import {container} from 'tsyringe';
import {AppealRadioServiceService} from '@/pages/appeal-radio-service/services/appeal-radio-service.service';

const service = container.resolve(AppealRadioServiceService);
export const AppealRadioServiceStateActions = {
    getRadioCitizenship(context: any): void {
        service.getRadioCitizenship().then(res => context.commit('SET_CITIZENSHIP', res));
    },
    getRadioOrganizationName(context: any, payload: any): any {
       return  service.getRadioOrganizationName(payload).then(res => context.commit('SET_RADIO_ORGANIZATION_NAME', res));
    },

    getRadioFrequencyUnits(context: any): void {
        service.getFrequencyUnits().then(res => context.commit('SET_FREQUENCY_UNITS', res));
    },

    getRadioDocumentSeriesType(context: any, payload: number): void {
        service.getRadioDocumentSeriesType(payload).then(res => context.commit('SET_DOCUMENT_SERIES_TYPE', res));
    },

    getRadioOrganPersonType(context: any): void {
        service.getRadioOrganPersonType().then(res => context.commit('SET_ORGAN_PERSON_TYPE', res));
    },

    getInterferenceTechnologyTypes(context: any): void {
        service.getInterferenceTechnologyTypes().then(res => context.commit('SET_INTERFERENCE_TECHNOLOGY_TYPES', res));
    },

    getInterferenceTypes(context: any): void {
        service.getInterferenceTypes().then(res => context.commit('SET_INTERFERENCE_TYPES', res));
    },

    getDurations(context: any): void {
        service.getEffectDurations().then(res => context.commit('SET_DURATIONS', res));
    },

    postApplicant(context: any, payload: any): any {
        return service.postApplicant(payload);
    },

    postAppeal(context: any, payload: any): any {
        return service.postAppeal(payload);
    }
};