export class ManufacturerModel {
    public name: string | null = null;
    public priority: string | null = null;
    public id: number | null = null;

    constructor(item: any) {
        this._setName(item);
        this._setPriority(item);
        this._setId(item);
    }

    /**
     * Set name
     * @param name
     * @private
     */
    private _setName({name}: any): void {
        this.name = name;
    }

    /**
     * Set priority
     * @param priority
     * @private
     */

    private _setPriority({priority}: any): void {
        this.priority = priority;
    }

    /**
     * set Id
     * @param id
     * @private
     */

    private _setId({id}: any): void {
        this.id = id;
    }
}