import {Options, Vue} from 'vue-class-component';
import {locale} from '@/assets/db/db.conf.ts';

@Options({
    name: 'BreadcrumbComponent',
})
export default class BreadcrumbComponent extends Vue {
    public locale$: any = locale;
    public locale: any = null;
    public links: any = [];
    public pageInfo: any = {
        path: '',
        name: '',
    };

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.breadcrumb) {
                this.locale = res.breadcrumb;
            }
        });
        this.crumbs();
        this.$watch(
            () => this.$route.matched,
            async () => {
                await this.crumbs();
            }
        );
    }

    public crumbs(): void {
        this.links =
            this.$route.matched.map((item: any) => {
                return {
                    name: item.name,
                    path: item.path
                };
            });
        this.pageInfo.path = this.$route.path;
        this.pageInfo.name = this.$route.name;
        const routerArray = this.$route.path.split('/');
        if (routerArray[routerArray.length - 2] === 'services') {
            this.links.splice(this.links.length - 1, 0, {name: 'allservices', path: '/:local/services'});
        }

    }
}
