export class ResponsiveApplicationStatusModel {
    public statusCode: number | null = null;
    public statusText: string | null = null;
    public description: string | null = null;
    public merchantPayment: string | null = null;

    constructor(item: any) {
        this._setStatusCode(item);
        this._setStatusText(item);
        this._setDescription(item);
        this._setMerchantPayment(item)
    }

    /**
     * Set statusCode
     * @param status
     * @private
     */

    private _setStatusCode({status}: any): void {
        this.statusCode = status.code;
    }

    /**
     * Set statusText
     * @param status
     * @private
     */

    private _setStatusText({status}: any): void {
        this.statusText = status.text;
    }

    /**
     * Set Description
     * @param description
     * @private
     */

    private _setDescription({description}: any): void {
        this.description = description;
    }

    private _setMerchantPayment({merchantPayment}: any) {
        this.merchantPayment = merchantPayment.generatedRequestUrl
    }
}