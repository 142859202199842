import {ICalculatorState} from '@/pages/calculator/store/state';
import {IOneTimePaymentInfo, ITernPaymentInfo} from '@/pages/calculator/types/oneTimePayment';
import {ISelectCalculator} from '@/pages/calculator/types/select-calculator';

export const CalculatorStateGetters = {
    _getUserGroups: (state: ICalculatorState): ISelectCalculator[] => {
        return state.userGroups;
    },

    _getFrequency: (state: ICalculatorState): number | null | string => {
        return state.frequency;
    },

    _getServiceTypes: (state: ICalculatorState): ISelectCalculator[] => {
        return state.serviceTypes;
    },

    _getServiceTypesOneTime: (state: ICalculatorState): ISelectCalculator[] => {
        return state.serviceTypesOneTime;
    },

    _getThroughputTypes: (state: ICalculatorState): ISelectCalculator[] => {
        return state.selectThroughputTypesOneTime;
    },

    _getBandSignificances: (state: ICalculatorState): ISelectCalculator[] => {
        return state.selectBandSignificancesOneTime;
    },

    _getGeographicalAreas: (state: ICalculatorState): ISelectCalculator[] => {
        return state.selectGeographicalAreasOneTime;
    },

    _getOneTimePaymentInfo: (state: ICalculatorState): IOneTimePaymentInfo => {
        return state.oneTimePaymentInfo;
    },
    _getMobileInputData: (state: ICalculatorState): any[] => {
        return state.mobileInputData;
    },

    _getResult: (state: ICalculatorState): number | null => {
        return state.result;
    },

    _getTotalResult: (state: ICalculatorState): number | null => {
        return state.totalResult;
    },
    _getVat: (state: ICalculatorState): number | null => {
        return state.vat;
    },


    // Tern
    _getServiceTypeTern: (state: ICalculatorState): ISelectCalculator[] => {
        return state.serviceTypes;
    },
    _getDateTern: (state: ICalculatorState): ISelectCalculator[] => {
        return state.dateTern;
    },
    _getRadioFrequenciesTypes: (state: ICalculatorState): ISelectCalculator[] => {
        return state.radioFrequenciesTypes;
    },
    _getRadioFrequencyMultiplier: (state: ICalculatorState): ISelectCalculator[] => {
        return state.radioFrequencyMultiplier;
    },


    _getSelectBandwidthFrequencyNominal: (state: ICalculatorState): ISelectCalculator[] => {
        return state.selectBandwidthFrequencyNominal;
    },
    _getSelectGeographicalAreas: (state: ICalculatorState): ISelectCalculator[] => {
        return state.selectGeographicalAreas;
    },
    _getSelectSEMTechnologyFactor: (state: ICalculatorState): ISelectCalculator[] => {
        return state.selectSEMTechnologyFactor;
    },
    _getSelectBandSignificances: (state: ICalculatorState): ISelectCalculator[] => {
        return state.selectBandSignificances;
    },
    _getSelectCoefficientSEMTechnology: (state: ICalculatorState): ISelectCalculator[] => {
        return state.selectCoefficientSEMTechnology;
    },
    _getSelectAdjustmentFactor: (state: ICalculatorState): ISelectCalculator[] => {
        return state.selectAdjustmentFactor;
    },
    _getTermInfo: (state: ICalculatorState): ITernPaymentInfo => {
        return state.termInfo;
    },
};