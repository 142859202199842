import {IRecordKeepingState} from './state';
import {RecordKeepingStateGetters} from './getters';
import {RecordKeepingStateMutations} from './mutations';
import {RecordKeepingStateActions} from './actions';

const RecordKeepingStateInitial: IRecordKeepingState = {
    userForm: {
        new: false,
        applicantId: null,
        id: null,
        juridicalAddress: null,
        activityAddress: null,
        ownerApplicantSame: null,
        juridicalPersonFullName: null,
        position: null,
        applicantActivityAddress: null,
        applicantInternetAddress: null,
        applicantTypeActivities: null,
        applicantTypesTelecommunicationsService: null,
        applicantScopeActivity: null,
        iPvFour: null,
        iPvSix: null,
        serviceDate: new Date(),
        asNumber: null,
        applicantPhoneNumber: '+994(',
        applicantFaxNumber: '+994(',
        applicantEmail: null,
        applicantAutonomousSystem: null,
        docTreaty: null,
        docDocumentAuthority: null,
        docCopyCharter: null,
        dutyNaturalPerson: null,
        rejectionReason: null,
        respondentPin: null,
        appealStatusId: null,
        appealStatusName: null,
        signFile: null,
        sendSignFile: null,
        signFileLink: null,
        answerFile: null,
        cancelFile: null,
        disabled: false,
        edit: false

    },
    applicantActivityAddress: [{id: 1, value: 'test'}],
    applicantTypeActivities: [{id: 1, value: 'test'}],
    applicantTypesTelecommunicationsService: [{id: 1, value: 'test'}],
    applicantScopeActivity: [{id: 1, value: 'test'}],
};

export const RecordKeepingState = {
    state: RecordKeepingStateInitial,
    getters: RecordKeepingStateGetters,
    mutations: RecordKeepingStateMutations,
    actions: RecordKeepingStateActions

};
