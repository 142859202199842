import {IPermissionMovementServiceState} from './state';
import {PermissionMovementServiceStateGetters} from './getters';
import {PermissionMovementServiceStateMutations} from './mutations';
import {PermissionMovementServiceStateActions} from './actions';

const PermissionMovementServiceStateInitial: IPermissionMovementServiceState = {
    citizenshipValue: 0,
    organizationLegalFormValue: 0,
    legalPersonCarrier: null,
    personalInformation: {
        documentNumber: null,
        documentTypeNumber: null,
        fin: null,
        phone: '+994(',
        email: null,
        tin: null,
        organization: null,
        position: null,
        dateOfBirth: null,
        name: null,
        surname: null,
        passportSeriesAndNumber: null,
        gender: null,
        bloodGroup: null,
        address: null,
        carrierName: null,
        carrierPhone: '+994(',
        carrierAddress: null,
        documentTypeResident: 'MY',
        documentTypeResidentNumber: null,
        finResident: null
    },
    vehicleParameters: {
        truckData: {
            brand: null,
            model: null,
            stateRegistrationMark: null,
            registrationCertificate: null,
            file: null,
        },
        trailerInformation: [{
            brand: null,
            model: null,
            typeVehicle: null,
            stateRegistrationMark: null,
            registrationCertificate: null,
        }],
        freightAndShippingInformation: {
            typeCargoTransported: null,
            loadWeight: null,
            shippingTime: null,
        },
    },
    vehicleParametersLoad: {
        lengthMeters: null,
        heightAboveGround: null,
        axleLoad: null,
        widthMeters: null,
        totalMass: null,
        distanceBetweenAxles: null,
    },
    citizenship: [],
    documentSeriesType: [],
    organPersonType: [],
    trailerVehicleType: [],
    appealId: null,
    userDataAze: {
        name: '',
        surname: '',
        fatherName: '',
        pin: '',
        birthDate: '',
        image: '',
        address: '',
        birthAddress: null,
        document: '',
        documentType: '',
        documentNumber: '',
        citizenshipId: null,
        dateOfIssue: '',
        registeredDepartmentName: '',
        loginType: '',
        certificateNumber: '',
        tin: '',
        structureName: '',
        entity: '',
    },
    documents: [],

};

export const PermissionMovementServiceState = {
    state: PermissionMovementServiceStateInitial,
    getters: PermissionMovementServiceStateGetters,
    mutations: PermissionMovementServiceStateMutations,
    actions: PermissionMovementServiceStateActions

};