import {INumbersTransferredServiceState} from './state.d';
import {NumbersTransferredServiceStateGetters} from './getters';
import {NumbersTransferredServiceStateMutations} from './mutations';
import {NumbersTransferredServiceStateActions} from './actions';

const NumbersTransferredServiceStateInitial: INumbersTransferredServiceState = {
    status: {
        isPortability: null,
        operatorName: ''
    }
};

export const NumbersTransferredServiceState = {
    state: NumbersTransferredServiceStateInitial,
    getters: NumbersTransferredServiceStateGetters,
    mutations: NumbersTransferredServiceStateMutations,
    actions: NumbersTransferredServiceStateActions

};
