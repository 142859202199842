import {ICommonState} from '@/store/state';
import {IServiceCommon} from '@/types/service-common';
import {IServiceHeader} from '@/types/service-header';
import {IGetNumber} from '@/pages/local-talks-service/types/get-number';
import {ISearchSelect} from '@/core/layouts/public/types/search-select-structure';
import {IApplicantData} from '@/types/applicant-data';
import {IUserAccountState} from "@/pages/user-account/store/state";

export const CommonMutation = {
    SET_APPLICANT_DATA(state: ICommonState, payload: IApplicantData): void {
        state.applicantData = payload;
    },
    SET_LOADER(state: ICommonState, payload: boolean): void {
        state.loader = payload;
    },
    SET_SERVICE_COMPONENT_NAME(state: ICommonState, payload: string): void {
        state.serviceComponentName = payload;
    },

    SET_LANGUAGE(state: ICommonState, payload: string): void {
        state.lang = payload;
    },

    SET_COMMON_SERVICE(state: ICommonState, payload: IServiceCommon): void {
        state.commonService = payload;
    },

    SET_COMMON_SERVICE_HEADER(state: ICommonState, payload: IServiceHeader[]): void {
        state.serviceHeader = payload;
    },

    SET_NUMBERS(state: ICommonState, payload: IGetNumber): void {
        state.numbers = payload.number;
        if (payload?.number) {
            state.defaultSelect = payload?.number[0];
        }
    },

    SET_DEFAULT_SELECT(state: ICommonState, payload: number): void {
        state.defaultSelect = payload;
    },

    SET_PERSON_CARRIER(state: ICommonState, payload: boolean) {
        state.legalPersonCarrier = payload;
    },

    SET_CITIZENSHIP_VALUE(state: ICommonState, payload: any): void {
        state.citizenshipValue = payload;
    },
    SET_ORGANIZATION_LEGAL_FORM_VALUE(state: ICommonState, payload: any): void {
        state.organizationLegalFormValue = payload;
    },

    SET_RESET_ORGANIZATION_LEGAL_FORM_VALUE(state: ICommonState): void {
        state.organizationLegalFormValue = 0;
    },

    SET_RESET_CITIZENSHIP_VALUE(state: ICommonState): void {
        state.citizenshipValue = 0;
    },
    SET_RESET_LEGAL_PERSONAL(state: ICommonState): void {
        state.legalPersonCarrier = false;
    },

    SET_CITIZENSHIP(state: ICommonState, payload: ISearchSelect[]) {
        state.citizenship = payload;
    },
    SET_DOCUMENT_SERIES_TYPE(state: ICommonState, payload: ISearchSelect[]) {
        state.documentSeriesType = payload;
    },
    SET_ORGAN_PERSON_TYPE(state: ICommonState, payload: ISearchSelect[]) {
        state.organPersonType = payload;
    },
    SET_USER_NEW_DETAILS(state: any, payload: any): void {
        state.newUserState = payload;
    },
};
