export class CityModel {
    public id: number | null = null;
    public value: string | null = null;

    constructor(item: any) {
        this._setId(item);
        this._setValue(item);

    }

    /**
     *
     * @param id
     * @private
     */

    private _setId({id}: any) {
        this.id = id;
    }

    /**
     *
     * @param name
     * @private
     */

    private _setValue({name}: any) {
        this.value = name;
    }
}