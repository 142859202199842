import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, Transition as _Transition, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "modal__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal_header_component = _resolveComponent("modal-header-component")!
  const _component_modal_body_component = _resolveComponent("modal-body-component")!
  const _component_modal_footer_component = _resolveComponent("modal-footer-component")!

  return (_openBlock(), _createBlock(_Transition, { name: "fadeIn" }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", {
        class: "modal",
        onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.closeModalClickOutSide && _ctx.closeModalClickOutSide(...args)), ["self"]))
      }, [
        _createVNode(_Transition, { name: "fade" }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("div", {
              class: _normalizeClass([[`modal--${_ctx.size}`, _ctx.position], "modal__dialog"])
            }, [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_modal_header_component, {
                  title: _ctx.title,
                  close: _ctx.closeModalClickOutSide,
                  closeOnOutside: _ctx.closeOnOutside
                }, null, 8, ["title", "close", "closeOnOutside"]),
                _createVNode(_component_modal_body_component, null, {
                  "modal-body": _withCtx(() => [
                    _renderSlot(_ctx.$slots, "body")
                  ]),
                  _: 3
                }),
                (_ctx.footer)
                  ? (_openBlock(), _createBlock(_component_modal_footer_component, { key: 0 }, {
                      "modal-footer": _withCtx(() => [
                        _renderSlot(_ctx.$slots, "footer")
                      ]),
                      _: 3
                    }))
                  : _createCommentVNode("", true)
              ])
            ], 2), [
              [_vShow, _ctx.toggle]
            ])
          ]),
          _: 3
        })
      ], 512), [
        [_vShow, _ctx.toggle]
      ])
    ]),
    _: 3
  }))
}