import {container, injectable} from 'tsyringe';
import {GET} from '@/packages/VHttp/GET';
import {API} from '@/core/configs/api';
import {OrganisationModel} from '@/pages/organisations/models/organisation.model';
import {OrganisationDetailsModel} from '@/pages/organisations/models/organisation-details.model';

@injectable()
export class OrganisationsService {
    private _get: GET = container.resolve(GET)

    constructor() {
    }

    getOrganisations(): Promise<any> {
        return this._get.setApi(API.organisations)
            .requestPromise().then(res => {
                return res.data.structure.items.map((i: any) => {
                    return new OrganisationModel(i);
                })
            });
    }

    getOrganisationDetails(id: string): Promise<any> {
        return this._get.setApi(API.organisation_details + id)
            .requestPromise().then(res => {
                return new OrganisationDetailsModel(res.data.structure);
            });
    }
}
