import {ISelectSearchOrganization} from '@/pages/search-offices-classifier-service/types/select-search-organization';

export class TotalCountOrganizationInfo {
    public totalCountOrganizationInfo: number | null = 0;
    public organizationInfo: ISelectSearchOrganization[] = [];

    constructor(item: any) {
        this._setTotalCountOrganizationInfo(item);
        this._setOrganizationInfo(item);
    }

    private _setTotalCountOrganizationInfo({totalRowCount}: any) {
        this.totalCountOrganizationInfo = totalRowCount;
    }

    private _setOrganizationInfo({data}: any) {
        this.organizationInfo = data?.map((item: any) => {
            return new SelectSearchOrganizationInfoModel(item);
        });
    }
}


class SelectSearchOrganizationInfoModel {
    public phone: number | null = null;
    public name: string | null = null;
    public street: string | null = null;
    public address: string | null = null;
    public organizationName: string | null = null;
    public organizationCode: number | null = null;
    public contractDate: string | null = null;
    public phoneType: string | null = null;
    public organizationVoin: number | null = null;
    public bankAccount: string | null = null;

    constructor(item: any) {
        this._setPhone(item);
        this._setName(item);
        this._setStreet(item);
        this._setAddress(item);
        this._setOrganizationName(item);
        this._setOrganizationCode(item);
        this._setContractDate(item);
        this._setPhoneType(item);
        this._setOrganizationVoin(item);
        this._setBankAccount(item);

    }

    /**
     * Set phone
     * @param phone
     * @private
     */


    private _setPhone({phone}: any) {
        this.phone = phone;
    }

    /**
     * Set name
     * @param name
     * @private
     */


    private _setName({name}: any) {
        this.name = name;
    }

    /**
     * Set street
     * @param street
     * @private
     */

    private _setStreet({street}: any) {
        this.street = street;
    }

    /**
     * Set address
     * @param address
     * @private
     */

    private _setAddress({address}: any) {
        this.address = address;
    }

    /**
     * Set organizationName
     * @param organizationName
     * @private
     */

    private _setOrganizationName({organizationName}: any) {
        this.organizationName = organizationName;
    }

    /**
     * Set organizationCode
     * @param organizationCode
     * @private
     */

    private _setOrganizationCode({organizationCode}: any) {
        this.organizationCode = organizationCode;
    }

    /**
     * Set contractDate
     * @param contractDate
     * @private
     */

    private _setContractDate({contractDate}: any) {
        this.contractDate = contractDate;
    }

    /**
     * Set phoneType
     * @param phoneType
     * @private
     */

    private _setPhoneType({phoneType}: any) {
        this.phoneType = phoneType;
    }

    /**
     * Set organizationVoin
     * @param organizationVoin
     * @private
     */

    private _setOrganizationVoin({organizationVoin}: any) {
        this.organizationVoin = organizationVoin;
    }

    /**
     * Set bankAccount
     * @param @bankAccount
     */

    private _setBankAccount({bankAccount}: any) {
        this.bankAccount = bankAccount;
    }
}