import {container, injectable} from 'tsyringe';
import {API} from '@/core/configs/api';
import {GET} from '@/packages/VHttp/GET';
import {POST} from '@/packages/VHttp/POST';
import {UserDetailsModel} from '@/pages/user-account/models/user-details.model';
import {PUT} from '@/packages/VHttp/PUT';

@injectable()
export class UserAccountService {
    private _get: GET = container.resolve(GET);
    private _post: POST = container.resolve(POST);
    private _put: PUT = container.resolve(PUT);

    constructor() {
    }

    getUserDetails(payload: any): Promise<any> {
        return this._get.setApi(API.userDetails)
            .setHeaders({Authorization: `Bearer ${payload}`})
            .requestPromise().then(res => {
                return res.data.usercontacts.items.map((item: any) => {
                    return new UserDetailsModel(item);
                });

            });
    }

    postUserDetails(payload: any): Promise<any> {
        return this._post.setApi(API.updateUserDetails)
            .setHeaders({Authorization: `Bearer ${payload.token}`})
            .setPayload(payload.data)
            .requestPromise().then(res => {
                return res;
            });
    }

    putUserDetails(payload: any): Promise<any> {
        return this._put.setApi(API.userDetails)
            .setHeaders({Authorization: `Bearer ${payload.token}`})
            .setPayload(payload.data)
            .requestPromise().then(res => {
                return res;
            });
    }
}
