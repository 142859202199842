export class ApplicantCertModel {
    public name: string | null = '';
    public surname: string | null = '';
    public fatherName: string | null = '';
    public pin: string | null = '';
    public birthDate: string | null = '';
    public image: string | null = '';
    public address: string | null = '';
    public birthAddress: string | null = '';
    public document: string | null = '';
    public documentType: string | null = '';
    public documentNumber: string | null = '';
    public dateOfIssue: string | null = '';
    public registeredDepartmentName: string | null = '';


    constructor(data: any) {
        this._setUser(data);
    }

    /**
     * set user
     * @param data
     * @private
     */
    private _setUser(data: any) {
        this.name = data.name;
        this.surname = data.surname;
        this.fatherName = data.patronymic;
        this.birthDate = data.birthDate?.split('T')[0];
        this.image = data.file.fileUrl;
        this.address = data.registeredAddress;
        this.birthAddress = data.birthAddress;
        this.document = data.documentNumber;
        this.pin = data.pin?.toUpperCase();
        this.dateOfIssue = data.eventDate?.split('T')[0];
        this.registeredDepartmentName = data.registeredDepartmentName;
    }
}
