import {container} from 'tsyringe';
import {CommonService} from '@/service/common.service';
import {IPayloadNumber} from '@/types/payload-number';

const service = container.resolve(CommonService);

export const CommonActions = {
    getApplicantData(context: any, payload: any): void {
        service.getApplicantData(payload).then(res => context.commit('SET_APPLICANT_DATA', res));
    },
    getServiceCommonName(context: any, payload: {componentName:string, token:string| null}): void {
        service.getServiceName(payload).then(res => context.commit('SET_COMMON_SERVICE', res));
    },
    getServiceHeaderCommon(context: any, payload: string): void {
        service.getServiceHeader(payload).then(res => context.commit('SET_COMMON_SERVICE_HEADER', res));
    },
    getNumbers(context: any, payload: IPayloadNumber): void {
        service.getNumbers(payload).then(res => context.commit('SET_NUMBERS', res));
    },

    getCitizenship(context: any): void {
        service.getCitizenship().then(res => context.commit('SET_CITIZENSHIP', res));
    },

    getCommonCitizenship(context: any): void {
        service.getCommonCitizenship().then(res => context.commit('SET_CITIZENSHIP', res));
    },
    getDocumentSeriesType(context: any): void {
        service.getDocumentSeriesType().then(res => context.commit('SET_DOCUMENT_SERIES_TYPE', res));
    },
    getOrganPersonType(context: any): void {
        service.getOrganPersonType().then(res => context.commit('SET_ORGAN_PERSON_TYPE', res));
    },
    getCommonOrganPersonType(context: any, payload:number): void {
        service.getCommonOrganPersonType(payload).then(res => context.commit('SET_ORGAN_PERSON_TYPE', res));
    },

    getCommonDocumentSeriesType(context: any, payload: number): void {
        service.getCommonDocumentSeriesType(payload).then(res => context.commit('SET_DOCUMENT_SERIES_TYPE', res));
    },

    getUserNewDetails(context: any): any {
        return service.getUserNewDetails().then(res => context.commit('setToken', res));
    },

};
