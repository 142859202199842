import {
    IDetailedInformationDepartmentsServiceState
} from '@/pages/detailed-information-departments-service/store/state';

export const DetailedInformationDepartmentsServiceMutations = {
    SET_INFORMATION_ORGANIZATION(state: IDetailedInformationDepartmentsServiceState, payload: any): void {
        state.organizations = payload.organizations;
        state.totalCountOrganization = payload.totalCountOrganization;
        state.name = payload.name;
        state.tin = payload.voen;
    },

    SET_RESET_INFORMATION_ORGANIZATION(state: IDetailedInformationDepartmentsServiceState): void {
        state.organizations = null;
        state.totalCountOrganization = 0;
        state.name = null;
        state.tin = null;
    },

};
