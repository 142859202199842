export class RadioOrganizationNameModel {
    public fullName: string | null = null;

    constructor(item: any) {
        this._setFullName(item);
    }

    private _setFullName({FullName}: any) {
        this.fullName = FullName;
    }
}