import {Options, Vue} from 'vue-class-component';

@Options({
    name: 'card-component',
    props: {
        dark: {
            type: Boolean,
            required: false,
        },
        padding: {
            type: [Number, String],
            required: false,
            default: 24
        }
    }
})
export default class CardComponent extends Vue {
}
