export class SelectSearchCategoryModel {
    public id: number | null = null;
    public value: string | null = null;

    constructor(item: any) {
        this._setId(item);
        this._setValue(item);
    }

    private _setId({phone}: any) {
        this.id = phone;
    }

    private _setValue({name}: any) {
        this.value = name;
    }
}