import {IListMobileNumbersServiceState} from '@/pages/list-mobile-numbers-service/store/state';
import {IMobileNumber} from '@/pages/list-mobile-numbers-service/types/mobile-number';
import {IStatus} from '@/types/status';

export const ListMobileNumbersServiceStateGetters = {
    _getMobileNumbersList: (state: IListMobileNumbersServiceState): IMobileNumber[] | null => {
        return state.listMobileNumbers;
    },
    _getMobileStatus: (state: IListMobileNumbersServiceState): IStatus | null => {
        return state.mobileStatus;
    },
    // _getMobileNumbersTotalCount: (state: IListMobileNumbersServiceState): number => {
    //     return state.totalCountMobileNumbers;
    // },
};
