import {
    IDetailedInformationDepartmentsServiceState
} from '@/pages/detailed-information-departments-service/store/state';
import {IInformationOrganization} from '@/pages/detailed-information-departments-service/types/voen';


export const DetailedInformationDepartmentsServiceGetters = {
    _getOrganization: (state: IDetailedInformationDepartmentsServiceState): IInformationOrganization[] | null => {
        return state.organizations;
    },
    _getTotalCountOrganization: (state: IDetailedInformationDepartmentsServiceState): number => {
        return state.totalCountOrganization;
    },

    _getOrganizationName: (state: IDetailedInformationDepartmentsServiceState): string | null => {
        return state.name;
    },
    _getOrganizationVoen: (state: IDetailedInformationDepartmentsServiceState): string | null => {
        return state.tin;
    },

};
