export class DebtModel {
    debt: string | null = null;
    description: string | null = null;

    constructor(item: any) {
        this._setDebt(item);
        this._setDescription(item);
    }

    /**
     * Set debt
     * @param debt
     * @private
     */

    private _setDebt({debt}: any): void {
        this.debt = debt;
    }

    /**
     * Set description
     * @param description
     * @private
     */

    private _setDescription({description}: any): void {
        this.description = description;
    }

}