import 'reflect-metadata';
import {store} from '@/store';
import {container} from 'tsyringe';
import {Options, Vue} from 'vue-class-component';
import {locale} from '@/assets/db/db.conf.ts';
import swal from 'sweetalert2';
import {IFeedbackPost} from '@/core/shared/feadback/types/feedback-post';
import {IFeedbackSelect} from '@/core/shared/feadback/types/feedback-select';
import {FeedbackSandbox} from '@/core/shared/feadback/sandbox/feedback.sandbox';
import {FeedbackPostModel} from '@/core/shared/feadback/models/feedback-post.model';
// import {VueRecaptcha} from 'vue-recaptcha';
import {load} from 'recaptcha-v3';
import {loginStateMutations} from '@/pages/login/store/store/mutations';

@Options({
    name: 'feedback-component',
    props: {
        structureId: {
            type: [String, null, Number],
            required: true,
        },
        serviceId: {
            type: [String, null, Number],
            required: true,
        },
    },
})

export default class FeedbackComponent extends Vue {
    private sandbox = container.resolve(FeedbackSandbox);
    public siteKey = '6LdZg8IfAAAAAEzXF_7RQY0AzNjQ4zhPPR-5Tf5P';
    public req = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
    public store = store;
    public structureId = 0;
    public serviceId = 0;
    public locale$: any = locale;
    public locale: any = null;
    public isVisibility = false;

    public validation = {
        fullname: false,
        phone: false,
        content: false,
        appealType: false,
        direction: false,
        organisation: false,
        service: false,
        email: false
    };
    public feedbackForm: IFeedbackPost = {
        fullname: null,
        phone: '+994(',
        content: null,
        appealType: null,
        direction: null,
        organisation: null,
        service: null,
        SecretKey: '',
        email: null
    };

    public onCaptchaExpired() {
        this.$refs.recaptcha.reset();
    }


    public $refs!: {
        recaptcha: { reset: () => void; execute: () => void },
        modal: { openModal: () => void; closeModal: () => void; },
        selectFeedbackService: {
            reset: () => void,
        };
        selectFeedbackStructure: {
            reset: () => void,
        };
        selectDirection: {
            reset: () => void,
        },
        selectAppealType: {
            reset: () => void,
        }
    };

    get service() {
        if (this.structureId) {
            this.sandbox.getFeedbackServices(this.structureId);
        } else {
            this.store.commit('SET_FEEDBACK_SERVICES_RESET');
        }
        return this.structureId;
    }

    get feedbackDirections(): IFeedbackSelect[] {
        return store.getters._getFeedbackDirections;
    }

    get feedbackAppealTypes(): IFeedbackSelect[] {
        return store.getters._getFeedbackAppealTypes;
    }

    get feedbackServices(): IFeedbackSelect[] {
        return store.getters._getFeedbackServices;
    }

    get feedbackOrganisations(): IFeedbackSelect[] {
        return store.getters._getFeedbackOrganisations;
    }

    get userName() {
        return store.getters._getUserNSF;
    }

    public clickModal() {
        if (!this.serviceId) {
            this.store.commit('SET_FEEDBACK_SERVICES_RESET');
        }
        this.feedbackForm.service = this.serviceId;
        this.feedbackForm.organisation = this.structureId;
        this.$refs.selectFeedbackService.reset();
        this.$refs.selectFeedbackStructure.reset();
        this.$refs.selectAppealType.reset();
        this.$refs.selectDirection.reset();
        this.$refs.modal.openModal();
    }

    public closeModal() {
        this.$refs.modal.closeModal();
    }

    public created(): void {
        this.locale$.subscribe((res: any) => {

            if (res && res.home) {
                this.locale = res.home.lead;
            }
        });
        this.sandbox.getFeedbackDirections();
        this.sandbox.getFeedbackAppealTypes();
        this.sandbox.getFeedbackOrganisations();
    }


    public selectDirection(info: any) {
        this.feedbackForm.direction = info;
        this.validation.direction = false;
        if (info === 1) {
            this.isVisibility = true;
        } else {
            this.isVisibility = false;
            this.validation.service = false;
            this.validation.organisation = false;
        }
    }

    public selectOrganisation(info: any) {
        this.feedbackForm.organisation = info;
        this.$refs.selectFeedbackService.reset();
        this.sandbox.getFeedbackServices(info);
        this.validation.organisation = false;
    }

    public selectAppealType(info: any) {
        this.feedbackForm.appealType = info;
        this.validation.appealType = false;
    }

    public selectService(info: any) {
        this.feedbackForm.service = info;
        this.validation.service = false;
    }


    public sendFeedback(token?: any): void {
        if (this.userName.length > 2) {
            this.feedbackForm.fullname = this.userName;
        }
        this.validationForm(this.feedbackForm);
        if (!this.checkValidation()) {
            load((this.siteKey), {}).then((re) => {
                re.execute('sendFeedback').then((token) => {
                    this.feedbackForm.SecretKey = token;
                    this.sandbox.sendFeedback({
                        data: new FeedbackPostModel(this.feedbackForm),
                        token: localStorage.getItem('mainToken')
                    }).then((res: any) => {
                        if (res && res.status === 200) {
                            this.closeModal();
                            this.feedbackForm = {
                                fullname: null,
                                phone: '+994(',
                                content: null,
                                appealType: null,
                                direction: null,
                                organisation: null,
                                service: null,
                                SecretKey: null,
                                email: null
                            };

                            swal.fire({
                                position: 'center',
                                icon: 'success',
                                title: 'Müraciət göndərildi',
                                showConfirmButton: false,
                                timer: 2000
                            });
                            this.$refs.selectFeedbackService.reset();
                            this.$refs.selectFeedbackStructure.reset();
                            this.$refs.selectDirection.reset();
                            this.$refs.selectAppealType.reset();
                        }
                    });
                });
            });

        }
    }

    public validationForm(data: any) {
        data.fullname ? this.validation.fullname = false : this.validation.fullname = true;
        data.phone.length === 18 ? this.validation.phone = false : this.validation.phone = true;
        data.content ? this.validation.content = false : this.validation.content = true;
        data.appealType ? this.validation.appealType = false : this.validation.appealType = true;
        data.direction ? this.validation.direction = false : this.validation.direction = true;
        this.req.test(data.email) ? this.validation.email = false : this.validation.email = true;
        if (!this.isVisibility) {
            data.organisation ? this.validation.organisation = false : this.validation.organisation = true;
            data.service ? this.validation.service = false : this.validation.service = true;
        }
    }

    public checkValidation() {
        if (!this.validation.fullname
            && !this.validation.phone
            && !this.validation.content
            && !this.validation.appealType
            && !this.validation.direction
            && !this.validation.organisation
            && !this.validation.service
            && !this.validation.email
        ) {
            return false;
        } else {
            return true;
        }
    }

    public changeInputNumber() {
        this.validation.phone = false;
    }

    public changeInputEmail() {
        this.validation.email = false;
    }

    public deleteNumber(event: any) {
        if ((event.keyCode === 8) || (event.keyCode === 46)) {
            if (this.feedbackForm.phone.length <= 5) {
                this.feedbackForm.phone = `+994(`;
            }
        }
    }

    public changeInputContent() {
        if (this.feedbackForm.content?.trim()) {
            this.feedbackForm.content = this.feedbackForm.content?.slice(0, 300);
        }
        this.validation.content = false;
    }

    public changeInputFullName() {
        this.validation.fullname = false;
    }



}
