import {ILocalTalksServiceState} from './state.d';
import {LocalTalksServiceStateGetters} from '@/pages/local-talks-service/store/getters';
import {LocalTalksServiceStateMutations} from '@/pages/local-talks-service/store/mutations';
import {LocalTalksServiceStateActions} from '@/pages/local-talks-service/store/actions';


const LocalTalksServiceStateInitial: ILocalTalksServiceState = {
    callHistory: [],
    totalCountCallHistory: 0,
};

export const LocalTalksServiceState = {
    state: LocalTalksServiceStateInitial,
    getters: LocalTalksServiceStateGetters,
    mutations: LocalTalksServiceStateMutations,
    actions: LocalTalksServiceStateActions
};
