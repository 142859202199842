import {Options, Vue} from 'vue-class-component';
import HeaderComponent from '@/core/layouts/public/components/header/header.component.vue';
import FooterComponent from '@/core/layouts/public/components/footer/footer.component.vue';
import BreadcrumbComponent from '@/core/layouts/public/components/breadcrumb/breadcrumb.component.vue';
import LoaderComponent from '@/core/shared/loader/loader.component.vue';
import {store} from '@/store';

@Options({
    name: 'public-component',
    components: {
        HeaderComponent,
        FooterComponent,
        BreadcrumbComponent,
        LoaderComponent,
    }
})
export default class PublicComponent extends Vue {
    get isHome(): boolean {
        return this.$route.name === 'home';
    }

    get hasLoader(): boolean {
        return store.getters._getLoader;
    }
}
