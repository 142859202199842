export class ParcelsModel {
    public date: string | null = null;
    public time: string | null = null;
    public status: string | null = null;
    public precinct: string | null = null;
    public country: string | null = null;

    constructor(item: any) {
        this._setDate(item);
        this._setTime(item);
        this._setStatus(item);
        this._setPrecinct(item);
        this._setCountry(item);
    }

    /**
     * Set date
     * @param date
     * @private
     */

    private _setDate({date}: any) {
        this.date = date;
    }

    /**
     * Set status
     * @param status
     * @private
     */

    private _setStatus({status}: any) {
        this.status = status;
    }

    /**
     * Set precinct
     * @param precinct
     * @private
     */

    private _setPrecinct({precinct}: any) {
        this.precinct = precinct;
    }

    /**
     * Set precinct
     * @param precinct
     * @private
     */

    private _setTime({time}: any) {
        this.time = time;
    }

    /**
     * Set Country
     * @param Country
     * @private
     */

    private _setCountry({country}: any) {
        this.country = country;
    }
}