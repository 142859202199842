import {IAllServicesState} from '@/pages/allservices/store/state';
import {IServices} from '@/pages/allservices/types/allservices';

export const AllServicesStateMutations = {
    SET_ALL_SERVICES(state: IAllServicesState, payload: { totalCount: number, services: IServices[] }): void {
        state.allServices = payload.services;
        state.totalCount = payload.totalCount
    },

};
