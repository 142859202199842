import {constructor} from 'tsyringe/dist/typings/types';
import * as buffer from 'buffer';

export class ServiceRequestModel {
    public structureName: string | null = null;
    public serviceName: string | null = null;
    public id: string | null = null;
    public date: string | null = null;
    public context: string | null = null;
    public feedbackFormName: string | null = null;
    public feedbackTypeName: string | null = null;
    public isOpen: boolean = false;

    constructor(item: any) {
        this._setStructureName(item);
        this._setServiceName(item);
        this._setId(item);
        this._setServiceDate(item);

    }

    /**
     *
     * @param item
     * @private
     */

    private _setStructureName({structureName}: any) {
        this.structureName = structureName;
    }

    /**
     *
     * @param serviceName
     * @private
     */

    private _setServiceName({serviceName}: any) {
        this.serviceName = serviceName;
    }


    /**
     *
     * @param id
     * @private
     */

    private _setId({id}: any) {
        this.id = id;
    }

    private _setServiceDate({createdDate}: any) {
        // const serviceDate = new Date(createdDate);
        // let day: string | number = serviceDate.getDate();
        // let month: string | number = serviceDate.getMonth();
        // let year: string | number = serviceDate.getFullYear();
        // let hours: string | number = serviceDate.getHours();
        // let minutes: string | number = serviceDate.getMinutes();
        // if (day < 10) {
        //     day = `0${day + 1}`;
        // }
        // if (month < 10) {
        //     month = `0${month + 1}`;
        // }
        // if(hours<10) {
        //     hours = `0${hours+1}`
        // }
        // if(minutes<10) {
        //     minutes = `0${minutes+1}`
        // }
        this.date = createdDate;

    }
}