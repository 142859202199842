<style src="./feedback.component.scss" lang="scss"></style>

<template>
  <v-modal
      :title="locale.feedback"
      ref="modal"
      size="lg"
  >
    <template v-slot:body>
      <form class="form">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <div class="form__group mb-20">
                <label class="v-label" :class="{'v-label--error':validation.direction}">
                  {{ locale.quickHelpDirection }}
                </label>
                <v-select

                    ref="selectDirection"
                    :error="validation.direction"
                    @select="selectDirection"
                    :placeholder="locale.quickHelpSelectDirection"
                    :options="feedbackDirections"/>
              </div>
              <div class="form__group mb-20">
                <label class="v-label" :class="{'v-label--error':validation.organisation}">
                  {{ locale.quickHelpInstitution }}
                </label>
                <v-select
                    ref="selectFeedbackStructure"
                    :error="validation.organisation"
                    @select="selectOrganisation"
                    :placeholder="locale.quickHelpSelectInstitution"
                    :options="feedbackOrganisations"
                    :value="structureId"
                    :disabled="service || isVisibility"
                />
              </div>
              <div class="form__group mb-20">
                <label class="v-label" :class="{'v-label--error':validation.fullname}">
                  {{ locale.quickHelpFullName }}
                </label>
                <template v-if="userName&&userName.length>2">
                  <input
                      type="text" class="v-input" :value="userName" disabled>
                </template>
                <input
                    @input="changeInputFullName"
                    type="text" class="v-input" v-model="feedbackForm.fullname"
                    :class="{'v-input--error':validation.fullname}"
                    :placeholder="locale.quickHelpFullNameInput" v-else>
              </div>
              <div class="form__group mb-20">
                <label class="v-label" :class="{'v-label--error':validation.email}">
                  E-mail
                </label>
                <input
                    :class="{'v-input--error':validation.email}"
                    placeholder="E-mail daxil edin"
                    @input="changeInputEmail"
                    type="email" class="v-input" v-model="feedbackForm.email">
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form__group mb-20">
                <label class="v-label" :class="{'v-label--error':validation.appealType}">
                  {{ locale.quickHelpTypeApplication }}
                </label>
                <v-select
                    ref="selectAppealType"
                    :error="validation.appealType"
                    @select="selectAppealType"
                    :placeholder="locale.quickHelpSelectTypeApplication"
                    :options="feedbackAppealTypes"/>
              </div>
              <div class="form__group mb-20">
                <label class="v-label"  :class="{'v-label--error':validation.service}">
                  {{ locale.quickHelpService }}
                </label>
                <v-select
                    ref="selectFeedbackService"
                    :error="validation.service"
                    @select="selectService"
                    :placeholder="locale.quickHelpService"
                    :options="feedbackServices"
                    :disabled="serviceId || !feedbackServices.length"
                    :value="serviceId"
                />
              </div>
              <div class="form__group mb-20">
                <label class="v-label" :class="{'v-label--error':validation.phone}">
                  {{ locale.quickHelpMobileNumber }}
                </label>
                <input
                    @keyup="deleteNumber($event)"
                    @keydown="deleteNumber($event)"
                    :class="{'v-input--error':validation.phone}"
                    placeholder="+994("
                    v-mask="['+994(##)-###-##-##', '+994(##)-###-##-##']"
                    @input="changeInputNumber"
                    type="text" class="v-input" v-model="feedbackForm.phone">
              </div>
            </div>

            <div class="col-12">
              <div class="form__group mb-40">
                <label
                    :class="{'v-label--error':validation.content}"
                    class="v-label">{{ locale.quickHelpText }}</label>
                <textarea
                    @input="changeInputContent"
                    :class="{'v-input--error':validation.content}"
                    class="v-textarea"
                    :placeholder="locale.quickHelpInput"
                    v-model="feedbackForm.content"></textarea>
                <div class="form__group-info">
                  {{ locale.quickHelpInputInfo }}
                </div>
              </div>
            </div>
            <div class="col-12">
              <!--              <vue-recaptcha ref="recaptcha"-->
              <!--                             :sitekey=siteKey-->
              <!--                             @verify="sendFeedback"-->
              <!--                             @expired="onCaptchaExpired" />-->
            </div>
          </div>
        </div>
      </form>
    </template>

    <template v-slot:footer>
      <div class="btn-group">
        <div class="row">
          <div class="col-12 d-flex justify-center">
            <v-btn color="black" class="mr-12 px-25 py-12" @click="closeModal">{{ locale.closeModal }}</v-btn>
            <v-btn color="dark" class="px-25 py-12" @click="sendFeedback">{{ locale.submit }}</v-btn>
          </div>
        </div>
      </div>
    </template>
  </v-modal>
</template>

<script src="./feedback.component.ts"></script>
