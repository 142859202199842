import {Options, Vue} from 'vue-class-component';
import MostUsedServices from './components/most-used-services/most-used-services.component.vue';
import EServicesComponent from './components/e-services/e-services.component.vue';
import NewServicesComponent from './components/new-services/new-services.component.vue';
import StatisticsComponent from '@/pages/home/components/statistics/statistics.component.vue';
import LeadComponent from '@/pages/home/components/lead/lead.component.vue';

@Options({
    name: 'HomeComponent',
    components: {
        EServicesComponent,
        NewServicesComponent,
        MostUsedServices,
        StatisticsComponent,
        LeadComponent
    }
})
export default class HomeComponent extends Vue {

    public count = 1;
    public step = 10;
    public $refs!: {
        toast: { openToast: () => void; },
        modal: { openModal: () => void; },
        progressBar: {
            incrementCurrent: () => void;
            decrementCurrent: () => void;
        },
        categories: HTMLElement,
        'pinned-services': HTMLElement,
        'most-used': HTMLElement,

    };

    public clickToast() {
        this.$refs.toast.openToast();
    }

    public clickModal() {
        this.$refs.modal.openModal();
    }

    public clickProgressDecrementCurrent() {
        this.$refs.progressBar.decrementCurrent();
    }

    public clickProgressIncrement() {
        this.$refs.progressBar.incrementCurrent();
    }

    public mounted(): void {
        if (this.$route.params.scroll) {
            let element = document.querySelector(`#${this.$route.params.scroll}`);
            if (element) {
                setTimeout(() =>{
                    if(element) {
                        element.scrollIntoView();
                    }
                }, 1)
            }
        }
    }
}
