import {IReceiptsPaidTelephoneServiceState} from '@/pages/receipts-paid-telephone-service/store/state';

export const ReceiptsPaidTelephoneServiceStateMutations = {
    SET_RECEIPTS(state: IReceiptsPaidTelephoneServiceState, payload: any): void {
        state.receipts = payload.receipt;
        state.totalCountReceipts = payload.totalCountReceipt;
        state.totalPayments = payload.totalPayments;
        state.totalTerm = payload.totalTerm;
    },
};
