import {container} from 'tsyringe';
import {
    DetailedInformationDepartmentsServiceService
} from '@/pages/detailed-information-departments-service/services/detailed-information-departments-service..service';

const service = container.resolve(DetailedInformationDepartmentsServiceService);

export const DetailedInformationDepartmentsServiceActions = {
    getVoen(context: any, payload: any): any {
        return service.getVoen(payload).then(res => context.commit('SET_INFORMATION_ORGANIZATION', res));
    },
    getPhone(context: any, payload: any): any {
        return service.getPhone(payload).then(res => context.commit('SET_INFORMATION_ORGANIZATION', res));
    },
};
