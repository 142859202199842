import {IPermissionMovementServiceState} from '@/pages/permission-movement-service/store/state';
import {PayloadForm} from '@/pages/permission-movement-service/types/payload-form';
import {ISearchSelect} from '@/core/layouts/public/types/search-select-structure';

export const PermissionMovementServiceStateMutations = {
    // SET_CITIZENSHIP(state: IPermissionMovementServiceState, payload: ISearchSelect[]) {
    //     state.citizenship = payload;
    // },
    // SET_DOCUMENT_SERIES_TYPE(state: IPermissionMovementServiceState, payload: ISearchSelect[]) {
    //     state.documentSeriesType = payload;
    // },
    // SET_ORGAN_PERSON_TYPE(state: IPermissionMovementServiceState, payload: ISearchSelect[]) {
    //     state.organPersonType = payload;
    // },
    SET_TRAILER_VEHICLE_TYPE(state: IPermissionMovementServiceState, payload: ISearchSelect[]) {
        state.trailerVehicleType = payload;
    },
    SET_APPEAL(state: IPermissionMovementServiceState, payload: any) {
        state.appealId = payload.appealId;
        state.userDataAze = payload.userData;
    },

    SET_DOCUMENT(state: IPermissionMovementServiceState, payload: any) {
        state.documents = payload;
    },

    // SET_CITIZENSHIP_VALUE(state: IPermissionMovementServiceState, payload: any): void {
    //     state.citizenshipValue = payload;
    // },
    // SET_ORGANIZATION_LEGAL_FORM_VALUE(state: IPermissionMovementServiceState, payload: any): void {
    //     state.organizationLegalFormValue = payload;
    // },

    SET_PERSONAL_ORGANIZATION(state: IPermissionMovementServiceState, payload: PayloadForm) {
        state.personalInformation[`${payload.type}`] = payload.value;
    },
    SET_VEHICLE_PARAMETERS(state: IPermissionMovementServiceState, payload: PayloadForm) {
        state.vehicleParameters[`${payload.parent}`][`${payload.type}`] = payload.value;
    },
    SET_VEHICLE_PARAMETERS_LOAD(state: IPermissionMovementServiceState, payload: PayloadForm) {
        state.vehicleParametersLoad[`${payload.type}`] = payload.value;
    },
    SET_TRAILER_INFORMATION_ITEM(state: IPermissionMovementServiceState, payload: PayloadForm) {
        state.vehicleParameters.trailerInformation[payload.index][`${payload.type}`] = payload.value;
    },
    SET_DELETE_TRAILER_INFORMATION_ITEM(state: IPermissionMovementServiceState, index: any) {
        state.vehicleParameters.trailerInformation.splice(index, 1);
    },
    SET_TRAILER_INFORMATION(state: IPermissionMovementServiceState) {
        state.vehicleParameters.trailerInformation.push({
            brand: null,
            model: null,
            typeVehicle: null,
            stateRegistrationMark: null,
            registrationCertificate: null,
            file: null,
        });
    },

    // SET_PERSON_CARRIER(state: IPermissionMovementServiceState, payload: boolean) {
    //     state.legalPersonCarrier = payload;
    // },

    SET_RESET_PERMISSION(state: IPermissionMovementServiceState, isUser: any) {
        // state.citizenshipValue = 0;
        // state.organizationLegalFormValue = 0;
        if (isUser) {
            state.personalInformation = {
                documentNumber: isUser.documentNumber,
                documentTypeNumber: isUser.documentType,
                fin: isUser.pin,
                phone: '+994(',
                email: null,
                tin: null,
                organization: null,
                position: null,
                dateOfBirth: null,
                name: null,
                surname: null,
                passportSeriesAndNumber: null,
                gender: null,
                bloodGroup: null,
                address: null,
                carrierName: null,
                carrierPhone: '+994(',
                carrierAddress: null,
                documentTypeResidentNumber: null,
                finResident: null
            };
        } else {
            state.personalInformation = {
                documentNumber: null,
                documentTypeNumber: null,
                fin: null,
                phone: '+994(',
                email: null,
                tin: null,
                organization: null,
                position: null,
                dateOfBirth: null,
                name: null,
                surname: null,
                passportSeriesAndNumber: null,
                gender: null,
                bloodGroup: null,
                address: null,
                carrierName: null,
                carrierPhone: '+994(',
                carrierAddress: null,
                documentTypeResidentNumber: null,
                finResident: null
            };
        }

        state.vehicleParameters = {
            truckData: {
                brand: null,
                model: null,
                stateRegistrationMark: null,
                registrationCertificate: null,
                file: null,
            },
            trailerInformation: [{
                brand: null,
                model: null,
                typeVehicle: null,
                stateRegistrationMark: null,
                registrationCertificate: null,
                file: null,
            }],
            freightAndShippingInformation: {
                typeCargoTransported: null,
                loadWeight: null,
                shippingTime: null,
            },
        };
        state.vehicleParametersLoad = {
            lengthMeters: null,
            heightAboveGround: null,
            axleLoad: null,
            widthMeters: null,
            totalMass: null,
            distanceBetweenAxles: null,
        };
        state.appealId = null;
        state.userDataAze = {
            name: '',
            surname: '',
            fatherName: '',
            pin: '',
            birthDate: '',
            image: '',
            address: '',
            birthAddress: null,
            document: '',
            documentType: '',
            documentNumber: '',
            citizenshipId:null,
            dateOfIssue: '',
            registeredDepartmentName: '',
            loginType: '',
            certificateNumber: '',
            tin: '',
            structureName: '',
            entity: '',
        };
    },

    SET_RESET_PERMISSION_FINAL(state: IPermissionMovementServiceState) {
        // state.citizenshipValue = 0;
        // state.organizationLegalFormValue = 0;
        state.personalInformation = {
            documentNumber: null,
            documentTypeNumber: null,
            fin: null,
            phone: '+994(',
            email: null,
            tin: null,
            organization: null,
            position: null,
            dateOfBirth: null,
            name: null,
            surname: null,
            passportSeriesAndNumber: null,
            gender: null,
            bloodGroup: null,
            address: null,
            carrierName: null,
            carrierPhone: '+994(',
            carrierAddress: null,
            documentTypeResidentNumber: null,
            finResident: null
        };
        state.vehicleParameters = {
            truckData: {
                brand: null,
                model: null,
                stateRegistrationMark: null,
                registrationCertificate: null,
                file: null,
            },
            trailerInformation: [{
                brand: null,
                model: null,
                typeVehicle: null,
                stateRegistrationMark: null,
                registrationCertificate: null,
                file: null,
            }],
            freightAndShippingInformation: {
                typeCargoTransported: null,
                loadWeight: null,
                shippingTime: null,
            },
        };
        state.vehicleParametersLoad = {
            lengthMeters: null,
            heightAboveGround: null,
            axleLoad: null,
            widthMeters: null,
            totalMass: null,
            distanceBetweenAxles: null,
        };
        state.userDataAze = {
            name: '',
            surname: '',
            fatherName: '',
            pin: '',
            birthDate: '',
            image: '',
            address: '',
            birthAddress: null,
            document: '',
            documentType: '',
            documentNumber: '',
            citizenshipId: null,
            dateOfIssue: '',
            registeredDepartmentName: '',
            loginType: '',
            certificateNumber: '',
            tin: '',
            structureName: '',
            entity: '',
        };
        state.appealId = null;

    }

};
