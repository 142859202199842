export class Street {
    public streetCode: number | null = null;
    public streetName: string | null = null;
    public cityCode: number | null = null;
    public cityName: string | null = null;
    public regionCode: number | null = null;
    public regionName: string | null = null;
    public postIndex: string | null = null;
    public locationCode: string | null = null;
    public address: string | null = null;
    public date: string | null = null;

    constructor(item: any) {
        this._setStreetCode(item);
        this._setStreetName(item);
        this._setCityCode(item);
        this._setCityName(item);
        this._setRegionCode(item);
        this._setRegionName(item);
        this._setPostIndex(item);
        this._setLocationCode(item);
        this._setAddress(item);
        this._setDate(item);

    }

    /**
     * Set streetCode
     * @param streetCode
     * @private
     */

    private _setStreetCode({streetCode}: any): void {
        this.streetCode = streetCode;
    }

    /**
     * Set streetName
     * @param streetName
     * @private
     */

    private _setStreetName({streetName}: any): void {
        this.streetName = streetName;
    }

    /**
     * Set cityCode
     * @param cityCode
     * @private
     */

    private _setCityCode({cityCode}: any): void {
        this.cityCode = cityCode;
    }

    /**
     * Set cityName
     * @param cityName
     * @private
     */

    private _setCityName({cityName}: any): void {
        this.cityName = cityName;
    }

    /**
     * Set regionCode
     * @param regionCode
     * @private
     */

    private _setRegionCode({regionCode}: any): void {
        this.regionCode = regionCode;
    }

    /**
     * Set regionName
     * @param regionName
     * @private
     */

    private _setRegionName({regionName}: any): void {
        this.regionName = regionName;
    }

    /**
     * Set postIndex
     * @param postIndex
     * @private
     */

    private _setPostIndex({postIndex}: any): void {
        this.postIndex = postIndex;
    }

    /**
     * Set locationCode
     * @param locationCode
     * @private
     */

    private _setLocationCode({locationCode}: any): void {
        this.locationCode = locationCode;
    }

    /**
     * Set address
     * @param address
     * @private
     */

    private _setAddress({address}: any): void {
        this.address = address;
    }

    /**
     * Set date
     * @param date
     * @private
     */

    private _setDate({date}: any): void {
        this.date = date;
    }
}