import {IReceiptsPaidTelephoneServiceState} from '@/pages/receipts-paid-telephone-service/store/state';
import {IReceipt} from '@/pages/receipts-paid-telephone-service/types/receipt';

export const ReceiptsPaidTelephoneServiceStateGetters = {
    _getReceipts: (state: IReceiptsPaidTelephoneServiceState): IReceipt[] | null => {
        return state.receipts;
    },
    _getTotalCountReceipts: (state: IReceiptsPaidTelephoneServiceState): number => {
        return state.totalCountReceipts;
    },
    _getTotalPayments: (state: IReceiptsPaidTelephoneServiceState): number => {
        return state.totalPayments;
    },
    _getTotalTerm: (state: IReceiptsPaidTelephoneServiceState): number => {
        return state.totalTerm;
    },
};
