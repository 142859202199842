import {container, injectable} from 'tsyringe';
import {GET} from '@/packages/VHttp/GET';
import {API} from '@/core/configs/api';
import {FaqModel} from '@/pages/faq/models/faq.model';

@injectable()
export class FaqService {
    private _get: GET = container.resolve(GET)

    constructor() {
    }

    getFaqs(): Promise<any> {
        return this._get.setApi(API.faq)
            .requestPromise().then(res => {
                return res.data.faqs.items.map((i: any) => {
                    return new FaqModel(i);
                });
            });
    }

}