import {container} from 'tsyringe';
import {
    NumbersTransferredServiceService
} from '@/pages/numbers-transferred-service/services/numbers-transferred-service.service';

const service = container.resolve(NumbersTransferredServiceService);

export const NumbersTransferredServiceStateActions = {
    getMobileMNPCheck(context: any, payload: number): void {
        service.getMobileMNPCheck(payload).then(res => context.commit('SET_MOBILE_MNP_CHECK', res));
    },
};
