import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {SearchResultsSandbox} from '@/pages/search-results/sandbox/search-results.sandbox';
import {container} from 'tsyringe';
import {ISearchTag} from '@/core/layouts/public/types/search-tag';
import {store} from '@/store';

@Options({
    name: 'search-tag-component',
})

export default class SearchTagComponent extends Vue {
    private sandbox: SearchResultsSandbox = container.resolve(SearchResultsSandbox);
    public renderComponent: boolean = false;
    public slide = 'auto';

    get isSearchResult(): boolean {
        return this.$route.name === 'search-results';
    }

    get isHome(): boolean {
        setTimeout(() => {
            this.renderComponent = false;
            this.renderComponent = true;
        }, 200);
        return this.$route.name === 'home';
    }

    get tags(): ISearchTag[] {
        return store.getters._getSearchTag;
    };

    public searchTag(payload: any): void {
        this.sandbox.getSearchResults(payload);
        this.$router.replace({name: 'search-results'});
        this.$router.push({name: 'search-results', query: payload});
    }

    public created() {
        this.sandbox.getSearchTag();
    }

    public updated() {
        setTimeout(() => {
            this.renderComponent = false;
            this.renderComponent = true;
        }, 300);
    }

    public mounted() {
        setTimeout(() => {
            this.renderComponent = false;
            this.renderComponent = true;
        }, 200);
    }


}
