import {IInformationState} from './state';
import {InformationStateGetters} from './getters';
import {InformationStateMutations} from './mutations';
import {InformationStateActions} from './actions';

const InformationStateInitial: IInformationState = {
    information: {
        id: null,
        title: '',
        subTitle: '',
        contentHTML: '',
    }
};

export const InformationState = {
    state: InformationStateInitial,
    getters: InformationStateGetters,
    mutations: InformationStateMutations,
    actions: InformationStateActions
};
