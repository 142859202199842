export class FeedbackSelectModel {
    public id: number | null = null;
    public value: string | null = null;

    constructor(item: any) {
        this._setId(item);
        this._setValue(item);
    }

    /**
     * Set Id
     * @param ID
     * @private
     */
    private _setId({id}: any) {
        this.id = id;
    }

    /**
     * set value
     * @param name
     * @private
     */
    private _setValue({name}: any) {
        this.value = name
    }
}
