import {ModelsManufacturerModel} from '@/pages/imei-tariff-service/models/models-manufacturer.model';

export class TotalManufacturerModel {
    public manufacturers: string | null = null;
    public totalCountManufacturers: number = 0;

    constructor(item: any) {
        this._setManufacturers(item);
        this._setTotalCountManufacturers(item);
    }

    /**
     * Set manufacturers
     * @param items
     * @private
     */
    private _setManufacturers({items}: any): void {
        this.manufacturers = items.map((item: any) => {
            return new ModelsManufacturerModel(item);
        });
    }

    /**
     * Set totalCountManufacturers
     * @param totalCount
     * @private
     */

    private _setTotalCountManufacturers({totalCount}: any): void {
        this.totalCountManufacturers = totalCount;
    }

}