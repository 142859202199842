<style src="./about.component.scss" lang="scss"> </style>

<template>
  <div class="container">
    <div class="row about">
      <v-card class="about__info mt-40 col-md-12">
          <img class="mr-8" src="@/assets/images/common/ministry-logo-blue.svg" alt="ministry-logo">
      </v-card>
      <v-card class="mt-20 mb-40 col-md-12">
        <ul class="about__list">
<!--          <li>-->
<!--            {{aboutData[0].title}}-->
<!--            <span>{{aboutData[0].data}}</span>-->
<!--          </li>-->
<!--          <li>-->
<!--            {{aboutData[1].title}}-->
<!--            <a :href="aboutData[1].data">{{aboutData[1].data}}</a>-->
<!--          </li>-->
        </ul>
        <div>
          <v-divider/>
          <h4>{{aboutDataContent &&  aboutDataContent.name}}</h4>
          <p v-html="aboutDataContent && aboutDataContent.description"></p>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script src="./about.component.ts"></script>
