import {ICategoriesState} from './state.d'
import {CategoriesStateGetters} from './getters'
import {CategoriesStateMutations} from './mutations'
import {CategoriesStateActions} from './actions'

const CategoriesStateInitial: ICategoriesState = {
    categories: [],
}

export const CategoriesState = {
    state: CategoriesStateInitial,
    getters: CategoriesStateGetters,
    mutations: CategoriesStateMutations,
    actions: CategoriesStateActions

}
