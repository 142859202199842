import {IInformationPhoneServiceState} from './state.d';
import {InformationPhoneServiceStateGetters} from '@/pages/information-phone-service/store/getters';
import {InformationPhoneServiceStateActions} from '@/pages/information-phone-service/store/actions';
import {InformationPhoneServiceStateMutations} from '@/pages/information-phone-service/store/mutations';


const InformationPhoneServiceStateInitial: IInformationPhoneServiceState = {
    payments: [],
    totalCountPayments: 0,
    phoneInformation: {
        name: null,
        address: null,
        date: null,
        provider: null,
        service: null,
        junction: null,
        debts: [],
        payment: []

    },
    infoNumbers: [],
    // overallResult:{
    //
    // }
};

export const InformationPhoneServiceState = {
    state: InformationPhoneServiceStateInitial,
    getters: InformationPhoneServiceStateGetters,
    mutations: InformationPhoneServiceStateMutations,
    actions: InformationPhoneServiceStateActions
};
