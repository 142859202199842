export class PhoneNumberDebtModel {
    public type: null | number = null;
    public description: string | null = null;
    public debt: number | null = null;
    public junctionCode: number | null = null;
    public provider: string | null = null;

    constructor(item: any) {
        this._setType(item);
        this._setDescription(item);
        this._setDebt(item);
        this._setJunctionCode(item);
        this._setProvider(item);
    }

    /**
     *
     * @param type
     * @private
     */

    private _setType({type}: any) {
        this.type = type;
    }

    /**
     *
     * @param description
     * @private
     */

    private _setDescription({description}: any) {
        this.description = description;
    }

    /**
     *
     * @param debt
     * @private
     */

    private _setDebt({debt}: any) {
        this.debt = debt;
    }

    /**
     *
     * @param junctionCode
     * @private
     */

    private _setJunctionCode({junctionCode}: any) {
        this.junctionCode = junctionCode;
    }

    /**
     *
     * @param provider
     * @private
     */

    private _setProvider({provider}: any) {
        this.provider = provider;
    }
}