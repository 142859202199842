import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {locale} from '@/assets/db/db.conf.ts';

@Options({
    name: 'user-sidebar-component',
})

export default class UserSidebarComponent extends Vue {
    public locale$: any = locale;
    public locale: any = null;

    get asideData(): any {
        return [
            {
                "title": this.locale.personalInformation,
                "icon": "user.svg",
                "class": "active",
                "name": "user",
                "link": "user-account"
            },
            {
                "title": this.locale.myAppeals,
                "icon": "doc.svg",
                "class": "",
                "name": "personal",
                "link": "user-apply"
            },
            {
                "title": this.locale.feedback,
                "icon": "comment.svg",
                "class": "",
                "name": "feedback",
                "link": "feedback"
            }
        ]
    }
    ;

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.user) {
                this.locale = res.user;
            }
        });
    }

    get isPage() {
        return this.$route.meta.refers
    }
}
