import {IApplicantForm} from '@/pages/issuing-certificate-conformity-service/types/applicant-form';
import {IIssuanceSpecialPermitServiceState} from '@/pages/issuance-special-permit-service/store/state';
import {IFileType} from '@/pages/issuing-certificate-conformity-service/types/file-type';
import {IOptions} from '@/pages/issuance-special-permit-service/types/options';
import {ISearchSelect} from '@/core/layouts/public/types/search-select-structure';

export const IssuanceSpecialPermitServiceStateGetters = {
    _getApplicantFormSpecialPermit: (state: IIssuanceSpecialPermitServiceState): IApplicantForm | null => {
        return state.applicantForm;
    },
    _getFiles: (state: IIssuanceSpecialPermitServiceState): IFileType[] | null => {
        return state.files;
    },
    _getFilesOptions: (state: IIssuanceSpecialPermitServiceState): IFileType[] | null => {
        return state.filesOptions;
    },
    _getOptions: (state: IIssuanceSpecialPermitServiceState): IOptions | null => {
        return state.options;
    },
    _getRestrictedCirculationItems: (state: IIssuanceSpecialPermitServiceState): ISearchSelect[] => {
        return state.restrictedCirculationItems;
    },
    _getRequiredCrucialData: (state: IIssuanceSpecialPermitServiceState): ISearchSelect[] => {
        return state.requiredCrucialData;
    },
};
