import {RouteRecordRaw} from 'vue-router';
import Public from '@/core/layouts/public/public.component.vue';
import Home from '@/pages/home/home.component.vue';
import About from '@/pages/about/about.component.vue';
import UserAccountLayoutComponent from '@/core/layouts/user-account-layout/user-account-layout.component.vue';
import Login from '@/pages/login/login.component.vue';
import totalCheckToken from '@/core/helpers/router-check';


const routes: Array<RouteRecordRaw> = [
    // {
    //     path: '/',
    //     name: 'app',
    //     redirect: {
    //         name: 'public',
    //         params: {
    //             local: lang
    //         }
    //     },
    // },
    {
        path: '/:local',
        // path: '/az',
        name: 'public',

        component: Public,
        redirect: {
            name: 'home'
        },
        meta: {
            breadcrumb: 'Əsas səhifə',
        },
        children: [
            {
                path: '',
                name: 'home',
                component: Home,
                meta: {
                    breadcrumb: 'Home',
                },
            },
            {
                path: 'about',
                name: 'about',
                component: About,
                meta: {
                    breadcrumb: 'About',
                },
            },
            // {
            //     path: '/privacy',
            //     name: 'privacy',
            //     components: Privacy,
            //     meta: {
            //         breadcrumb: 'Privacy',
            //     },
            // },
            {
                path: 'organisations',
                name: 'organisations',
                meta: {
                    breadcrumb: 'Bütün qurumlar',
                },
                component: () => import(/* webpackChunkName: "organisations" */ '../pages/organisations/organisations.component.vue'),
            },
            {
                path: 'organisations/:id',
                name: 'organisations-inner',
                meta: {
                    breadcrumb: 'Organisation',
                },
                props: true,
                component: () => import(/* webpackChunkName: "organisations-inner" */ '../pages/organisations-inner/organisations-inner.component.vue'),
            },
            {
                path: 'services/mcqs-service',
                name: 'services/mcqs-service',
                meta: {
                    breadcrumb: 'Xidmətlər',
                },
                component: () => import(/* webpackChunkName: "services" */ '../pages/services/services.component.vue'),
            },
            {
                path: 'mcqs-service',
                name: 'mcqs-service',
                meta: {
                    breadcrumb: 'Xidmətlər',
                },
                beforeEnter: async (to, from) => {
                    return await totalCheckToken(to, from, false);
                },
                component: () => import(/* webpackChunkName: "services" */ '../pages/services/services.component.vue'),
            },

            {
                path: 'categories/:id',
                name: 'categories',
                meta: {
                    breadcrumb: 'Kateqoriyalar',
                },
                component: () => import(/* webpackChunkName: "services" */ '../pages/categories/categories.component.vue'),
            },
            {
                path: 'services',
                name: 'allservices',
                meta: {
                    breadcrumb: 'Bütün xidmətlər',
                },
                component: () => import(/* webpackChunkName: "services" */ '../pages/allservices/allservices.component.vue'),
            },
            {
                path: 'login',
                name: 'login',
                meta: {
                    breadcrumb: 'Login',
                },
                component: () => import(/* webpackChunkName: "about" */ '../pages/login/login.component.vue'),
            },
            {
                path: 'search-results',
                name: 'search-results',
                meta: {
                    breadcrumb: 'Search results',
                },
                component: () => import(/* webpackChunkName: "search-results" */ '../pages/search-results/search-results.component.vue'),
            },
            {
                path: 'faq',
                name: 'faq',
                meta: {
                    breadcrumb: 'FAQ',
                },
                component: () => import(/* webpackChunkName: "fag" */ '../pages/faq/faq.component.vue'),
            },
            {
                path: 'information/:id',
                name: 'information',
                meta: {
                    breadcrumb: 'Information',
                },
                component: () => import(/* webpackChunkName: "information" */ '../pages/information/information.component.vue'),
            },
            {
                path: 'information-phone-service',
                name: 'information-phone-service',
                meta: {
                    breadcrumb: 'Information',
                },
                beforeEnter: async (to, from) => {
                    return await totalCheckToken(to, from, true);
                },
                component: () => import(/* webpackChunkName: "information-phone-services" */ '@/pages/information-phone-service/information-phone-service.component.vue'),
            },
            {
                path: 'services/information-phone-service',
                name: 'services/information-phone-service',
                meta: {
                    breadcrumb: 'Information',
                },
                beforeEnter: async (to, from) => {
                    return await totalCheckToken(to, from, true, false);
                },
                component: () => import(/* webpackChunkName: "information-phone-services" */ '@/pages/information-phone-service/information-phone-service.component.vue'),
            },
            {
                path: 'debt-previous-months-service',
                name: 'debt-previous-months-service',
                meta: {
                    breadcrumb: 'debt-previous-months-service',
                },
                beforeEnter: async (to, from) => {
                    return await totalCheckToken(to, from, true);
                },
                component: () => import(/* webpackChunkName: "information-phone-services" */ '@/pages/debt-previous-months-service/debt-previous-months-service.component.vue'),
            },
            {
                path: 'services/debt-previous-months-service',
                name: 'services/debt-previous-months-service',
                meta: {
                    breadcrumb: 'debt-previous-months-service',
                },
                beforeEnter: async (to, from) => {
                    return await totalCheckToken(to, from, true, false);
                },
                component: () => import(/* webpackChunkName: "information-phone-services" */ '@/pages/debt-previous-months-service/debt-previous-months-service.component.vue'),
            },
            {
                path: 'local-talks-service',
                name: 'local-talks-service',
                meta: {
                    breadcrumb: 'local-talks-service',
                },
                beforeEnter: async (to, from) => {
                    return await totalCheckToken(to, from, true);
                    // setTimeout(async () => {
                    //     const token =  await localStorage.getItem('mainToken');
                    //     if (!token) {
                    //         return {path: '/'};
                    //     } else {
                    //         return true;
                    //     }
                    // }, 1000);
                },
                component: () => import(/* webpackChunkName: "local-talks-services" */ '@/pages/local-talks-service/local-talks-service.component.vue'),
            },
            {
                path: 'services/local-talks-service',
                name: 'services/local-talks-service',
                meta: {
                    breadcrumb: 'local-talks-service',
                },
                beforeEnter: async (to, from) => {
                    return await totalCheckToken(to, from, true, false);
                },
                component: () => import(/* webpackChunkName: "local-talks-services" */ '@/pages/local-talks-service/local-talks-service.component.vue'),
            },
            {
                path: 'imei-tariff-service',
                name: 'imei-tariff-service',
                meta: {
                    breadcrumb: 'imei-tariff-service',
                },
                beforeEnter: async (to, from) => {
                    return await totalCheckToken(to, from, false);
                },
                component: () => import(/* webpackChunkName: "imei-tariff-services" */ '@/pages/imei-tariff-service/imei-tariff-service.component.vue'),
            },
            {
                path: 'services/imei-tariff-service',
                name: 'services/imei-tariff-service',
                meta: {
                    breadcrumb: 'imei-tariff-service',
                },
                component: () => import(/* webpackChunkName: "imei-tariff-services" */ '@/pages/imei-tariff-service/imei-tariff-service.component.vue'),
            },
            {
                path: 'numbers-transferred-service',
                name: 'numbers-transferred-service',
                meta: {
                    breadcrumb: 'numbers-transferred-service',
                },
                beforeEnter: async (to, from) => {
                    return await totalCheckToken(to, from, false);
                },
                component: () => import(/* webpackChunkName: "imei-tariff-services" */ '@/pages/numbers-transferred-service/numbers-transferred-service.component.vue'),
            },

            {
                path: 'services/numbers-transferred-service',
                name: 'services/numbers-transferred-service',
                meta: {
                    breadcrumb: 'numbers-transferred-service',
                },
                component: () => import(/* webpackChunkName: "imei-tariff-services" */ '@/pages/numbers-transferred-service/numbers-transferred-service.component.vue'),
            },
            {
                path: 'imei-check-service',
                name: 'imei-check-service',
                meta: {
                    breadcrumb: 'imei-check-service',
                },
                beforeEnter: async (to, from) => {
                    return await totalCheckToken(to, from, false);
                },
                component: () => import(/* webpackChunkName: "imei-check-services" */ '@/pages/imei-check-service/imei-check-service.component.vue'),
            },
            {
                path: 'services/imei-check-service',
                name: 'services/imei-check-service',
                meta: {
                    breadcrumb: 'imei-check-service',
                },
                component: () => import(/* webpackChunkName: "imei-check-services" */ '@/pages/imei-check-service/imei-check-service.component.vue'),
            },
            {
                path: 'list-mobile-numbers-service',
                name: 'list-mobile-numbers-service',
                meta: {
                    breadcrumb: 'list-mobile-numbers-service',
                },
                beforeEnter: async (to, from) => {
                    return await totalCheckToken(to, from, true);
                },
                component: () => import(/* webpackChunkName: "list-mobile-numbers-services" */ '@/pages/list-mobile-numbers-service/list-mobile-numbers-service.component.vue'),
            },
            {
                path: 'services/list-mobile-numbers-service',
                name: 'services/list-mobile-numbers-service',
                meta: {
                    breadcrumb: 'list-mobile-numbers-service',
                },
                beforeEnter: async (to, from) => {
                    return await totalCheckToken(to, from, true, false);
                },
                component: () => import(/* webpackChunkName: "list-mobile-numbers-service" */ '@/pages/list-mobile-numbers-service/list-mobile-numbers-service.component.vue'),
            },
            {
                path: 'receipts-paid-telephone-service',
                name: 'receipts-paid-telephone-service',
                meta: {
                    breadcrumb: 'receipts-paid-telephone-service',
                },
                beforeEnter: async (to, from) => {
                    return await totalCheckToken(to, from, true);
                },
                component: () => import(/* webpackChunkName: "receipts-paid-telephone-service" */ '@/pages/receipts-paid-telephone-service/receipts-paid-telephone-service.component.vue'),
            },
            {
                path: 'services/receipts-paid-telephone-service',
                name: 'services/receipts-paid-telephone-service',
                meta: {
                    breadcrumb: 'receipts-paid-telephone-service',
                },
                beforeEnter: async (to, from) => {
                    return await totalCheckToken(to, from, true, false);
                },
                component: () => import(/* webpackChunkName: "receipts-paid-telephone-service" */ '@/pages/receipts-paid-telephone-service/receipts-paid-telephone-service.component.vue'),
            },
            {
                path: 'search-offices-classifier-service',
                name: 'search-offices-classifier-service',
                meta: {
                    breadcrumb: 'search-offices-classifier-service',
                },
                beforeEnter: async (to, from) => {
                    return await totalCheckToken(to, from, false);
                },
                component: () => import(/* webpackChunkName: "search-offices-classifier-service" */ '@/pages/search-offices-classifier-service/search-offices-classifier-service.component.vue'),
            },
            {
                path: 'services/search-offices-classifier-service',
                name: 'services/search-offices-classifier-service',
                meta: {
                    breadcrumb: 'search-offices-classifier-service',
                },
                component: () => import(/* webpackChunkName: "search-offices-classifier-service" */ '@/pages/search-offices-classifier-service/search-offices-classifier-service.component.vue'),
            },
            {
                path: 'street-search-service',
                name: 'street-search-service',
                meta: {
                    breadcrumb: 'street-search-service',
                },
                beforeEnter: async (to, from) => {
                    return await totalCheckToken(to, from, false);
                },
                component: () => import(/* webpackChunkName: "street-search-services" */ '@/pages/street-search-service/street-search-service.component.vue'),
            },
            {
                path: 'services/street-search-service',
                name: 'services/street-search-service',
                meta: {
                    breadcrumb: 'street-search-service',
                },
                component: () => import(/* webpackChunkName: "street-search-service" */ '@/pages/street-search-service/street-search-service.component.vue'),
            },
            {
                path: 'search-phone-number-service',
                name: 'search-phone-number-service',
                meta: {
                    breadcrumb: 'search-phone-number-service',
                },
                beforeEnter: async (to, from) => {
                    return await totalCheckToken(to, from, false);
                },
                component: () => import(/* webpackChunkName: "search-phone-number-service" */ '@/pages/search-phone-number-service/search-phone-number-service.component.vue'),
            },
            {
                path: 'services/search-phone-number-service',
                name: 'services/search-phone-number-service',
                meta: {
                    breadcrumb: 'search-phone-number-service',
                },

                component: () => import(/* webpackChunkName: "search-phone-number-service" */ '@/pages/search-phone-number-service/search-phone-number-service.component.vue'),
            },
            {
                path: 'postal-tracking-service',
                name: 'postal-tracking-service',
                meta: {
                    breadcrumb: 'postal-tracking-service',
                },
                beforeEnter: async (to, from) => {
                    return await totalCheckToken(to, from, false);
                },
                component: () => import(/* webpackChunkName: "postal-tracking-services" */ '@/pages/postal-tracking-service/postal-tracking-service.component.vue'),
            },
            {
                path: 'services/postal-tracking-service',
                name: 'services/postal-tracking-service',
                meta: {
                    breadcrumb: 'postal-tracking-service',
                },
                component: () => import(/* webpackChunkName: "postal-tracking-services" */ '@/pages/postal-tracking-service/postal-tracking-service.component.vue'),
            },
            {
                path: 'detailed-information-departments-service',
                name: 'detailed-information-departments-service',
                meta: {
                    breadcrumb: 'detailed-information-departments-service',
                },
                beforeEnter: async (to, from) => {
                    return await totalCheckToken(to, from, false);
                },

                component: () => import(/* webpackChunkName: "detailed-information-departments-service" */ '@/pages/detailed-information-departments-service/detailed-information-departments-service.component.vue'),
            },
            {
                path: 'services/detailed-information-departments-service',
                name: 'services/detailed-information-departments-service',
                meta: {
                    breadcrumb: 'detailed-information-departments-service',
                },
                // beforeEnter: async (to, from) => {
                //     await totalCheckToken(to, from, false);
                // },

                component: () => import(/* webpackChunkName: "detailed-information-departments-service" */ '@/pages/detailed-information-departments-service/detailed-information-departments-service.component.vue'),
            },
            {
                path: 'monthly-calls-information-service',
                name: 'monthly-calls-information-service',
                meta: {
                    breadcrumb: 'monthly-calls-information-service',
                },
                beforeEnter: async (to, from) => {
                    return await totalCheckToken(to, from, true);
                },
                component: () => import(/* webpackChunkName: "monthly-calls-information-service" */ '@/pages/monthly-calls-information-service/monthly-calls-information-service.component.vue'),
            },
            {
                path: 'services/monthly-calls-information-service',
                name: 'services/monthly-calls-information-service',
                meta: {
                    breadcrumb: 'monthly-calls-information-service',
                },
                beforeEnter: async (to, from) => {
                    return await totalCheckToken(to, from, true, false);
                },
                component: () => import(/* webpackChunkName: "monthly-calls-information-service" */ '@/pages/monthly-calls-information-service/monthly-calls-information-service.component.vue'),
            },
            {
                path: 'information-electronic-orders-service',
                name: 'information-electronic-orders-service',
                meta: {
                    breadcrumb: 'information-electronic-orders-service',
                },
                beforeEnter: async (to, from) => {
                    return await totalCheckToken(to, from, true);
                },
                component: () => import(/* webpackChunkName: "information-electronic-orders-service" */ '@/pages/information-electronic-orders-service/information-electronic-orders-service.component.vue'),
            },
            {
                path: 'services/information-electronic-orders-service',
                name: 'services/information-electronic-orders-service',
                meta: {
                    breadcrumb: 'information-electronic-orders-service',
                },
                beforeEnter: async (to, from) => {
                    return await totalCheckToken(to, from, true, false);
                },
                component: () => import(/* webpackChunkName: "information-electronic-orders-service" */ '@/pages/information-electronic-orders-service/information-electronic-orders-service.component.vue'),
            },

            {
                path: 'permission-movement-service',
                name: 'permission-movement-service',
                meta: {
                    breadcrumb: 'permission-movement-service',
                },
                beforeEnter: async (to, from) => {
                    return await totalCheckToken(to, from, false);
                },
                component: () => import(/* webpackChunkName: "permission-movement-service" */ '@/pages/permission-movement-service/permission-movement-service.component.vue'),
            },
            {
                path: 'services/permission-movement-service',
                name: 'services/permission-movement-service',
                meta: {
                    breadcrumb: 'permission-movement-service',
                },
                component: () => import(/* webpackChunkName: "permission-movement-service" */ '@/pages/permission-movement-service/permission-movement-service.component.vue'),
            },
            {
                path: 'appeal-radio-service',
                name: 'appeal-radio-service',
                meta: {
                    breadcrumb: 'appeal-radio',
                },
                beforeEnter: async (to, from) => {
                    return await totalCheckToken(to, from, false);
                },
                component: () => import(/* webpackChunkName: "appeal-radio-service" */ '@/pages/appeal-radio-service/appeal-radio-service.component.vue'),
            },
            {
                path: 'services/appeal-radio-service',
                name: 'services/appeal-radio-service',
                meta: {
                    breadcrumb: 'appeal-radio',
                },
                component: () => import(/* webpackChunkName: "appeal-radio-service" */ '@/pages/appeal-radio-service/appeal-radio-service.component.vue'),
            },
            {
                path: 'registration-radio-electronic-devices-service',
                name: 'registration-radio-electronic-devices-service',
                meta: {
                    breadcrumb: 'registration-radio-electronic-devices-service',
                },
                beforeEnter: async (to, from) => {
                    return await totalCheckToken(to, from, false);
                },
                component: () => import(/* webpackChunkName: "registration-radio-electronic-devices-service" */ '@/pages/registration-radio-electronic-devices-service/registration-radio-electronic-devices-service.component.vue'),
            },
            {
                path: 'services/registration-radio-electronic-devices-service',
                name: 'services/registration-radio-electronic-devices-service',
                meta: {
                    breadcrumb: 'services/registration-radio-electronic-devices-service',
                },
                component: () => import(/* webpackChunkName: "registration-radio-electronic-devices-service" */ '@/pages/registration-radio-electronic-devices-service/registration-radio-electronic-devices-service.component.vue'),
            },
            {
                path: 'calculator',
                name: 'calculator',
                meta: {
                    breadcrumb: 'calculator',
                },
                beforeEnter: async (to, from) => {
                    return await totalCheckToken(to, from, false);
                },
                component: () => import(/* webpackChunkName: "registration-radio-electronic-devices-service" */ '@/pages/calculator/calculator.component.vue'),
            },
            {
                path: 'services/calculator',
                name: 'services/calculator',
                meta: {
                    breadcrumb: 'services/calculator',
                },
                component: () => import(/* webpackChunkName: "registration-radio-electronic-devices-service" */ '@/pages/calculator/calculator.component.vue'),
            },
            {
                path: 'internet-provider-service',
                name: 'record-keeping-service',
                meta: {
                    breadcrumb: 'record-keeping',
                },
                beforeEnter: async (to, from) => {
                    return await totalCheckToken(to, from, false);
                },
                component: () => import(/* webpackChunkName: "registration-radio-electronic-devices-service" */ '@/pages/record-keeping-service/record-keeping-service.component.vue'),
            },
            {
                path: 'services/internet-provider-service',
                name: 'services/record-keeping-service',
                meta: {
                    breadcrumb: 'services/record-keeping',
                },
                beforeEnter: async (to, from) => {
                    return await totalCheckToken(to, from, true);
                },
                component: () => import(/* webpackChunkName: "registration-radio-electronic-devices-service" */ '@/pages/record-keeping-service/record-keeping-service.component.vue'),
            },
            {
                path: 'issuing-certificate-conformity-service',
                name: 'issuing-certificate-conformity-service',
                meta: {
                    breadcrumb: 'issuing-certificate-conformity-service',
                },
                beforeEnter: async (to, from) => {
                    return await totalCheckToken(to, from, false);
                },
                component: () => import(/* webpackChunkName: "registration-radio-electronic-devices-service" */ '@/pages/issuing-certificate-conformity-service/issuing-certificate-conformity-service.component.vue'),
            },
            {
                path: 'services/issuing-certificate-conformity-service',
                name: 'services/issuing-certificate-conformity-service',
                meta: {
                    breadcrumb: 'services/issuing-certificate-conformity-service',
                },
                component: () => import(/* webpackChunkName: "registration-radio-electronic-devices-service" */ '@/pages/issuing-certificate-conformity-service/issuing-certificate-conformity-service.component.vue'),
            },
            {
                path: 'issuance-special-permit-service',
                name: 'issuance-special-permit-service',
                meta: {
                    breadcrumb: 'issuance-special-permit-service',
                },
                beforeEnter: async (to, from) => {
                    return await totalCheckToken(to, from, false, true);
                },
                component: () => import(/* webpackChunkName: "registration-radio-electronic-devices-service" */ '@/pages/issuance-special-permit-service/issuance-special-permit-service.component.vue'),
            },
            {
                path: 'services/issuance-special-permit-service',
                name: 'services/issuance-special-permit-service',
                meta: {
                    breadcrumb: 'services/issuance-special-permit-service',
                },
                component: () => import(/* webpackChunkName: "registration-radio-electronic-devices-service" */ '@/pages/issuance-special-permit-service/issuance-special-permit-service.component.vue'),
            },
        ],
    },
    {
        path: '/:local/user',
        name: 'user',
        component: UserAccountLayoutComponent,
        meta: {
            breadcrumb: 'e-rabita',
        },
        children: [
            {
                path: 'account',
                name: 'user-account',
                component: () => import(/* webpackChunkName: "about" */ '@/pages/user-account/user-account.component.vue'),
                meta: {
                    breadcrumb: 'UserAccountComponent',
                },
                beforeEnter: async (to, from) => {
                    await totalCheckToken(to, from, true);
                },
            },
            {
                path: 'apply',
                name: 'user-apply',
                meta: {
                    breadcrumb: 'user',
                },
                component: () => import(/* webpackChunkName: "services" */ '@/pages/user-apply/user-apply.component.vue'),
                beforeEnter: async (to, from) => {
                    await totalCheckToken(to, from, true);
                },
            },
            {
                path: 'feedback-answer/:id',
                name: 'feedback-answer',
                meta: {
                    breadcrumb: 'feedback-answer',
                    refers: 'feedback'
                },
                beforeEnter: async (to, from) => {
                    await totalCheckToken(to, from, true);
                },
                component: () => import(/* webpackChunkName: "feedback-answer" */ '@/pages/feedback-answer/feedback-answer.component.vue'),
            },
            {
                path: 'feedback',
                name: 'feedback',
                meta: {
                    breadcrumb: 'feedback',
                },
                component: () => import(/* webpackChunkName: "feedback" */ '@/pages/user-feedback/user-feedback.component.vue'),
                beforeEnter: async (to, from) => {
                    await totalCheckToken(to, from, true);
                },
            },

        ],
    },
    {
        path: '/:local/not-found',
        name: '404',
        component: () => import(/* webpackChunkName: "about" */ '../core/layouts/not-found/not-found.component.vue'),
    },
    {
        path: '/:local/:pathMatch(.*)',
        redirect: {name: '404'},
    }
];

export default routes;
