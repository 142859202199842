import {MobileNumberModel} from '@/pages/list-mobile-numbers-service/models/mobile-number.model';
import {IStatus} from '@/types/status';
import {StatusModel} from '@/models/status.model';

export class TotalMobileNumberModel {
    public numbers: string | null = null;
    public totalCountMobile: string | null = null;
    public status: IStatus | null = null;


    constructor(item: any) {
        this._setNumbers(item);
        // this._setTotalCountMobile(item);
        this._setStatus(item);
    }

    /**
     * Set Numbers
     * @param items
     * @private
     */

    private _setNumbers({MobileNumbers}: any): void {
        this.numbers = MobileNumbers.map((item: any) => {
            return new MobileNumberModel(item);
        });
    }

    // /**
    //  * Set TotalCountMobile
    //  * @param totalCount
    //  * @private
    //  */
    //
    // private _setTotalCountMobile({totalCount}: any): void {
    //     this.totalCountMobile = totalCount;
    // }

    /**
     * Set status
     * @param status
     * @private
     */

    private _setStatus({Status}: any) {
        this.status = new StatusModel(Status);
    }
}