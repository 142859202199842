import 'reflect-metadata';
import {injectable} from 'tsyringe';
import {store} from '@/store';


@injectable()
export class SearchResultsSandbox {
  private store = store;

  constructor() {
  }
  getSearchTag(): void {
    this.store.dispatch('getSearchTag');
  }

  getSearchResults(payload:object): void {
    this.store.dispatch('getSearchResult', payload);
  }
  getSearchResultsDetailed(payload:any): void {
    this.store.dispatch('getSearchResultDetailed', payload);
  }

}
