import {ServiceHeaderUrlModel} from '@/models/service-header-url.model';
import {ServiceHeaderFileModel} from '@/models/service-header-file.model';

export class ServiceHeaderModel {
    public title: string | null = null;
    public icon: string | null = null;
    public serviceHeaderFiles: [] | null = null;
    public serviceHeaderUrls: [] | null = null;
    public id: number | null = null;

    constructor(item: any) {
        this._setTitle(item);
        this._setServiceHeaderFiles(item);
        this._setServiceHeaderUrls(item);
        this._setId(item);
        this._setIcon(item);
    }

    /**
     * set title
     * @param title
     * @private
     */

    private _setTitle({title}: any) {
        this.title = title;
    }

    /**
     * set serviceHeaderFiles
     * @param serviceHeaderFiles
     * @private
     */

    private _setServiceHeaderFiles({files}: any) {
        this.serviceHeaderFiles = files.map((item: any) => {
            return new ServiceHeaderUrlModel(item);
        });
    }

    /**
     * set serviceHeaderUrls
     * @param serviceHeaderUrls
     * @private
     */

    private _setServiceHeaderUrls({urls}: any) {
        this.serviceHeaderUrls = urls.map((item: any) => {
            return new ServiceHeaderFileModel(item);
        });
    }

    /**
     * set id
     * @param id
     * @private
     */

    private _setId({id}: any) {
        this.id = id;
    }

    /**
     * set icon
     * @param header
     * @private
     */

    private _setIcon({icon}: any) {
        this.icon = icon.fileUrl;
    }
}