import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';

@Options({
    name: 'select-component',
    props: {
        options: {
            type: Array,
            require: true,
        },
        placeholder: {
            type: String,
        },
        disabled: {
            type: [String, null, Number, Boolean],
        },
        value: {
            type: [String, null, Number, Boolean],
        },
        error: {
            type: [null, Boolean],
            required: false
        },
        allData: {
            type: [null, Boolean],
            required: false
        }
    },
    watch: {
        value: [
            {
                handler: 'changeSelected',
                immediate: false,
                deep: false,
            },
        ],
    }
})

export default class SelectComponent extends Vue {
    public value = '';
    public options = [];
    public disabled: boolean = false;
    public placeholder: string = '';
    public selected: string = '';
    public selectedNumber: string = '';
    public toggleSelect = false;
    public allData = false;

    get selectOptions() {
        return this.options;
    }

    public changeSelected(val: any) {
        this.selected = val;
    }

    public changeSelection(item: any) {
        this.selected = item.value;
        this.selectedNumber = item.id;
        if (this.allData) {
            this.$emit('select', item);
        } else {
            this.$emit('select', this.selectedNumber);
        }
        this.toggleSelect = false;
    }

    public changeSelectItem() {
        if (!this.disabled) {
            this.toggleSelect = !this.toggleSelect;
        }
    }

    public onClickOutsideSelect($event: any) {
        if (!this.$el.contains($event.target)) {
            this.toggleSelect = false;
        }
    }


    public mounted() {
        document.addEventListener('click', this.onClickOutsideSelect);
    }

    public unmounted() {
        document.removeEventListener('click', this.onClickOutsideSelect);
    }

    public reset() {
        this.selected = '';
    }

}
