import {container} from 'tsyringe';
import {PostalTrackingServiceService} from '@/pages/postal-tracking-service/services/postal-tracking-service.service';

const service = container.resolve(PostalTrackingServiceService);

export const PostalTrackingServiceActions = {
    getPostTracking(context: any, payload: any): void {
        service.getPostTracking(payload).then(res => context.commit('SET_POST_TRACKING', res));
    }
};
