import {ImeiTariffServiceStateMutations} from '@/pages/imei-tariff-service/store/mutations';
import {ImeiTariffServiceStateActions} from '@/pages/imei-tariff-service/store/actions';
import {ImeiTariffServiceStateGetters} from '@/pages/imei-tariff-service/store/getters';
import {IImeiTariffServiceState} from '@/pages/imei-tariff-service/store/state';

const ImeiTariffServiceStateInitial: IImeiTariffServiceState = {
    manufacturer: [],
    modelsByManufacturer: [],
    modelsByManufacturerTotalCount:0,
    marketTariffs: [
        {
            id: 1,
            description: 'Foto, videoçəkiliş və internetə çıxış funksiyaları olmayan mobil cihazların qeydiyyata alınması',
            price: '15',
        },
        {
            id: 2,
            description: '100 ABŞ dollarına qədər',
            price: '20'
        },
        {
            id: 3,
            description: '101 ABŞ dollarından 200 ABŞ dollarına qədər',
            price: '30'
        },
        {
            id: 4,
            description: '201 ABŞ dollarından 400 ABŞ dollarına qədər',
            price: '40'
        },
        {
            id: 5,
            description: '401 ABŞ dollarından 700 ABŞ dollarına qədər\t',
            price: '50'
        },
        {
            id: 6,
            description: '701 ABŞ dollarından 1 000 ABŞ dollarına qədər\t',
            price: '70'
        },
        {
            id: 7,
            description: '1001 ABŞ dollarından yuxarı olduqda\t',
            price: '100'
        },

    ],
    marketTariffsInfo: [
        {
            id: 1,
            title: 'Marka və modeli, habelə bazar qiyməti müəyyənləşdirilməyən mobil cihazların qeydiyyata alınmasına görə 15 manat dövlət rüsumu ödənilir.'
        },
        {
            id: 2,
            title: 'İki simkartlı mobil cihazların qeydiyyata alınması zamanı 2 IMEI üzrə bir dövlət rüsumu tələb edilir.'
        },
    ]
};

export const ImeiTariffServiceState = {
    state: ImeiTariffServiceStateInitial,
    getters: ImeiTariffServiceStateGetters,
    mutations: ImeiTariffServiceStateMutations,
    actions: ImeiTariffServiceStateActions
};
