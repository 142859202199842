import {ICommonState} from '@/store/state';
import {IServiceCommon} from '@/types/service-common';
import {IServiceHeader} from '@/types/service-header';
import {IUserAccountState} from "@/pages/user-account/store/state";

export const CommonGetters = {

    _getCitizenshipValue: (state: ICommonState) => {
        return state.citizenshipValue;
    },
    _getApplicantData: (state: ICommonState) => {
        return state.applicantData;
    },

    _getServiceComponentName: (state: ICommonState) => {
        return state.serviceComponentName;
    },

    _getLegalPersonCarrier: (state: ICommonState) => {
        return state.legalPersonCarrier;
    },

    _getOrganizationLegalFormValue: (state: ICommonState) => {
        return state.organizationLegalFormValue;
    },

    _getLoader: (state: ICommonState): boolean => {
        return state.loader;
    },

    _getLanguage: (state: ICommonState): string => {
        return state.lang;
    },

    _getCommonService: (state: ICommonState): IServiceCommon => {
        return state.commonService;
    },

    _getCommonServiceHeader: (state: ICommonState): IServiceHeader[] => {
        return state.serviceHeader;
    },

    _getNumbers: (state: ICommonState): string [] | null => {
        return state.numbers;
    },

    _getSelect: (state: ICommonState): number | null | string => {
        return state.defaultSelect;
    },

    _getCitizenship: (state: ICommonState) => {
        return state.citizenship;
    },
    _getDocumentSeriesType: (state: ICommonState) => {
        return state.documentSeriesType;
    },
    _getOrganPersonType: (state: ICommonState) => {
        return state.organPersonType;
    },
    _getUserNewDetails: (state: ICommonState) => {
        return state.newUserState;
    },
};
