import {IOrganisationsState} from '@/pages/organisations/store/state';
import {IOrganisation} from '@/pages/organisations/types/organisation';

export const OrganisationsStateGetters = {
    _getOrganisations: (state: IOrganisationsState): IOrganisation[] => {
        return state.organisations;
    },

    _getOrganisationDetails: (state: IOrganisationsState): Object => {
        return state.organisationDetails;
    },
}
