import {container} from 'tsyringe';
import {FooterService} from '@/core/layouts/public/components/footer/services/footer.service';


const service = container.resolve(FooterService);

export const FooterStateActions = {
    getFooterModalData(context: any): void {
        service.getFooterModalData().then(res => context.commit('SET_FOOTER_MODAL_DATA', res));
    }
};
