import {IReceiptsPaidTelephoneServiceState} from './state.d';
import {ReceiptsPaidTelephoneServiceStateGetters} from '@/pages/receipts-paid-telephone-service/store/getters';
import {ReceiptsPaidTelephoneServiceStateMutations} from '@/pages/receipts-paid-telephone-service/store/mutations';
import {ReceiptsPaidTelephoneServiceStateActions} from '@/pages/receipts-paid-telephone-service/store/actions';


const ReceiptsPaidTelephoneServiceStateInitial: IReceiptsPaidTelephoneServiceState = {
    receipts: [],
    totalCountReceipts: 0,
    totalPayments: 0,
    totalTerm: 0,
};

export const ReceiptsPaidTelephoneServiceState = {
    state: ReceiptsPaidTelephoneServiceStateInitial,
    getters: ReceiptsPaidTelephoneServiceStateGetters,
    mutations: ReceiptsPaidTelephoneServiceStateMutations,
    actions: ReceiptsPaidTelephoneServiceStateActions
};
