export class FaqModel {
    public id: number | null = null;
    public name: string | null = null;
    public content: string | null = null;

    constructor(item: any) {
        this._setId(item);
        this._setTitle(item);
        this._setContent(item)
    }

    /**
     * Set Id
     * @param ID
     * @private
     */
    private _setId({id}: any) {
        this.id = id;
    }

    /**
     * Set title
     * @param name
     * @private
     */
    private _setTitle({faqLanguages}: any) {
        this.name = faqLanguages[0].name;
    }
    /**
     * Set description
     * @param content
     * @private
     */
    private _setContent({faqLanguages}: any) {
        this.content = faqLanguages[0].contentText;
    }

}
