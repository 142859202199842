import {IPaymentItem} from '@/pages/information-phone-service/types/payment-item';
import {IPaymentItemTotal} from '@/pages/information-phone-service/types/payment-item-total';
import {PaymentItemModel} from '@/pages/information-phone-service/models/payment-item.model';
import {PaymentItemTotalModel} from '@/pages/information-phone-service/models/payment-item-total.model';

export class PaymentsModel {
    public type: string | null = null;
    public totalCalc: number | null = null;
    public totalPay: number | null = null;
    public payments: IPaymentItem[] | null = [];
    public paymentsTotal: IPaymentItemTotal | null = null;

    constructor(item: any) {
        this._setType(item);
        this._setTotalCalc(item);
        this._setTotalPay(item);
        this._setPayments(item);
        this._setPaymentsTotal(item);
    }

    /**
     * Set type
     * @param type
     * @private
     */

    private _setType({type}: any): void {
        this.type = type;
    }

    /**
     * Set totalCalc
     * @param totalCalc
     * @private
     */

    private _setTotalCalc({totalCalc}: any): void {
        this.totalCalc = totalCalc;
    }

    /**
     * Set totalPay
     * @param totalPay
     * @private
     */

    private _setTotalPay({totalPay}: any): void {
        this.totalPay = totalPay;
    }

    /**
     * Set payments
     * @param paymentsGrp
     * @private
     */

    private _setPayments({paymentsGrp}: any): void {
        if (paymentsGrp) {
            this.payments = paymentsGrp.map((item: any) => {
                return new PaymentItemModel(item);
            });
        } else {
            this.payments = [];
        }
    }

    /**
     * Set paymentsTotal
     * @param paymentsGrpTotal
     * @private
     */

    private _setPaymentsTotal({paymentsGrpTotal}: any): void {
        this.paymentsTotal = new PaymentItemTotalModel(paymentsGrpTotal);
    }

}
