import {IAppealRadioServiceState} from '@/pages/appeal-radio-service/store/state';
import {ICommonState} from '@/store/state';

export const AppealRadioServiceStateGetters = {
    _getAppealPersonalInformation: (state: IAppealRadioServiceState) => {
        return state.radioPersonalInformation;
    },

    _getRadioInformation: (state: IAppealRadioServiceState) => {
        return state.radioInformation;
    },

    _getInterferenceTechnologyTypes: (state: IAppealRadioServiceState) => {
        return state.interferenceTechnologyTypes;
    },
    _getInterferenceTypes: (state: IAppealRadioServiceState) => {
        return state.interferenceTypes;
    },
    _getDurations: (state: IAppealRadioServiceState) => {
        return state.durations;
    },

    _getApplicantId(state: IAppealRadioServiceState) {
        return state.applicantId;
    },

    _getUserDataRadio(state: IAppealRadioServiceState) {
        return state.userInfo;
    },
    _getFrequencyUnits(state: IAppealRadioServiceState) {
        return state.frequencyUnits;
    },
    _getRadioOrganizationName(state: IAppealRadioServiceState) {
        return state.organizationName;
    },


};