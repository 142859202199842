import {IImeiTariffServiceState} from '@/pages/imei-tariff-service/store/state';
import {IManufacturer} from '@/pages/imei-tariff-service/types/manufacturer';

export const ImeiTariffServiceStateMutations = {
    SET_MANUFACTURER(state: IImeiTariffServiceState, payload: IManufacturer[]): void {
        state.manufacturer = payload;
    },
    SET_MODELS_BY_MANUFACTURER(state: IImeiTariffServiceState, payload: any): void {
        state.modelsByManufacturer = payload.manufacturers;
        state.modelsByManufacturerTotalCount = payload.totalCountManufacturers;
    },
    SET_RESET_MODELS_BY_MANUFACTURER(state: IImeiTariffServiceState, payload: any): void {
        state.modelsByManufacturer = [];
        state.modelsByManufacturerTotalCount = 0;
    },
};
