import {container, injectable} from 'tsyringe';
import {API} from '@/core/configs/api';
import {GET} from '@/packages/VHttp/GET';
import {SearchSelectCategoriesModel} from '@/core/layouts/public/models/seacrh-select-category.model';
import {locale} from '@/assets/db/db.conf';

@injectable()
export class CalculatorService {
    private _get: GET = container.resolve(GET);

    getUserGroups(): Promise<any> {
        return this._get.setApi(API.calculatorUserGroups)
            .requestPromise().then(res => {
                return res.data.calculatorUserGroups.items.map((i: any) => {
                    return new SearchSelectCategoriesModel(i);
                });
            });
    }

    getCalculateResult(): Promise<any> {
        return this._get.setApi(API.getCalculateResult)
            .setHeaders({cName: 'calculator', Authorization: `Bearer ${localStorage.getItem('mainToken')}`})
            .requestPromise().then(res => {
                return res;
            });
    }
}