export class Call {
    public queueNumber: number | null = null;
    public date: string | null = null;
    public time: string | null = null;
    public duration: string | null = null;
    public dialNumber: string | null = null;
    public amount: string | null = null;
    public pay: boolean | null = null;
    public isOpen: boolean = false;

    constructor(item: any, index: any, pageSize: any, pageNumber: any) {
        this._setDate(item);
        this._setTime(item);
        this._setDuration(item);
        this._setDialNumber(item);
        this._setAmount(item);
        this._setQueueNumber(index, pageSize, pageNumber);
        this._setPay(item);
    }

    private _setDate({date}: any) {
        this.date = date;
    }

    private _setPay({pay}: any) {
        this.pay = pay;
    }

    private _setTime({time}: any) {
        this.time = time;
    }

    private _setDuration({duration}: any) {
        this.duration = duration;
    }

    private _setDialNumber({dialPhone}: any) {
        this.dialNumber = dialPhone;
    }

    private _setAmount({amount}: any) {
        this.amount = amount;
    }

    private _setQueueNumber(index: any, pageSize: any, pageNumber: any) {
        if (pageNumber === 1) {
            this.queueNumber = index + 1;
        } else if (pageNumber > 1) {
            this.queueNumber = (((pageNumber - 1) * pageSize) + (index + 1));
        }
    }

}