import {ILocalTalksServiceState} from '@/pages/local-talks-service/store/state';
import {IGetCallHistory} from '@/pages/local-talks-service/types/get-call-history';

export const LocalTalksServiceStateGetters = {

    _getCallHistory: (state: ILocalTalksServiceState): IGetCallHistory[] | null => {
        return state.callHistory;
    },
    _getTotalCountCallHistory: (state: ILocalTalksServiceState): number => {
        return state.totalCountCallHistory;
    },
};
