import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import HeaderComponent from "@/core/layouts/public/components/header/header.component.vue";
import FooterComponent from "@/core/layouts/public/components/footer/footer.component.vue";
import UserSidebarComponent from "@/core/layouts/user-account-layout/user-sidebar/user-sidebar.component.vue";
import {store} from '@/store';
import LoaderComponent from '@/core/shared/loader/loader.component.vue';

@Options({
  name: 'user-account-layout-component',
  components: {
    HeaderComponent,
    FooterComponent,
    UserSidebarComponent,
    LoaderComponent
  }
})

export default class UserAccountLayoutComponent extends Vue {
  get hasLoader(): boolean {
    return store.getters._getLoader;
  }
}
