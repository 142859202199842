import {container, injectable} from 'tsyringe';
import {API} from '@/core/configs/api';
import {GET} from '@/packages/VHttp/GET';
import {FeedbackAnswerModel} from '@/pages/feedback-answer/models/feedback-answer.model';

@injectable()
export class FeedbackAnswerService {
    private _get: GET = container.resolve(GET);

    constructor() {
    }

    getFeedbackId(payload: any): Promise<any> {
        return this._get.setApi(`${API.feedback}/${payload}`)
            .setHeaders({Authorization: `Bearer ${localStorage.getItem('mainToken')}`})
            .requestPromise().then(res => {
                return new FeedbackAnswerModel(res.data.feedback);
            });
    }

}
