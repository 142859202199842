<style lang="scss" src="./footer.component.scss"></style>

<template>
  <div class="footer d-lg-block d-none" ref="footer">
    <div class="container-fluid v-container">
      <div class="row">
        <div ref="div" class="col-lg-9">
          <div class="footer__menu">
            <div class="row">
              <footer-menu-item-component
                  v-for="(item,index) in menu"
                  :key="index"
                  :item="item"
                  @itemInfo="footerItemClick"
              />
            </div>
          </div>
        </div>
        <!--        <div class="col-lg-1">-->
        <!--          <div class="footer-menu-item__title mb-12">-->
        <!--            &lt;!&ndash;            {{ locale.footerTitleFollowUs }}&ndash;&gt;-->
        <!--          </div>-->
        <!--          <div class="footer__socials mb-12">-->
        <!--            &lt;!&ndash;            <socials-component/>&ndash;&gt;-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="col-lg-3 d-block  d-lg-flex justify-end">
          <div>
            <div class="footer-menu-item__title mb-8">
              Əlaqə
            </div>
            <div class="mb-12 mr-40">
              <a href="tel:1655" class="footer__call footer-menu-item__link">
                <img src="@/assets/images/icons/call.svg" alt="call">
                <span>1655 {{ locale.footerCall }}</span>
              </a>
            </div>
            <div class="mb-12 ">
            <span class="footer__call footer-menu-item__link">
              <img src="@/assets/images/icons/email.svg" alt="call">
              <a class="email " href="mailto:info@azintelecom.az">mincom@mincom.gov.az</a>
            </span>
            </div>
          </div>
          <div>
          </div>
          <!--          <div class="footer__lang v-select&#45;&#45;lang">-->
          <!--            <select @change="setLocale($event)" v-model="language">-->
          <!--              <option value="en">English</option>-->
          <!--              <option value="az">Azerbaycan dili</option>-->
          <!--              <option value="ru">Pусский</option>-->
          <!--            </select>-->
          <!--          </div>-->
        </div>
        <div class="col-12">
          <v-divider/>
          <div class="footer__logo py-14 d-block d-md-flex justify-between">
            <img src="@/assets/images/common/footer-logo.svg" alt="logo-footer">
            <div class="mt-20 footer__copyright">
              &copy; {{new Date().getFullYear()}}. {{ locale.rights }}.
            </div>
          </div>
          <!--          <v-divider/>-->
          <!--          <div class="mt-20 footer__copyright">-->
          <!--            &copy; 2022. {{ locale.rights }}.-->
          <!--          </div>-->
        </div>
      </div>
    </div>
    <v-modal
        ref="modal"
        :footer="true"
        :title="modalInfo.name"
        size="lg"
    >
      <template v-slot:body>
        <div ref="div" class="container">
          <div class="row">
            <div class="col-12">
              <div class="modal__content">
                <div class="modal-text">
                  {{ modalInfo.description }}
                </div>
                <div class="modal-bottom"></div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="modal-button d-flex justify-center">
          <v-btn @click="closeModal()" color="--white">
            {{ localeElements.close }}
          </v-btn>
        </div>
      </template>
    </v-modal>

  </div>

</template>

<script src="./footer.component.ts"></script>
