import {IPostalTrackingServiceState} from './state.d';
import {PostalTrackingServiceGetters} from './getters';
import {PostalTrackingServiceMutations} from './mutations';
import {PostalTrackingServiceActions} from './actions';

const PostalTrackingServiceStateInitial: IPostalTrackingServiceState = {
    tracking:[],
    status:{
        code:"",
        text:""
    }
};

export const PostalTrackingServiceState = {
    state: PostalTrackingServiceStateInitial,
    getters: PostalTrackingServiceGetters,
    mutations: PostalTrackingServiceMutations,
    actions: PostalTrackingServiceActions
};
