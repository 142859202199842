import {ISearchOfficesClassifierServiceState} from '@/pages/search-offices-classifier-service/store/state';

export const SearchOfficesClassifierServiceStateGetters = {
    _getOrganizationType: (state: ISearchOfficesClassifierServiceState): any => {
        return state.organizationType;
    },
    _getOrganizationSubtype: (state: ISearchOfficesClassifierServiceState): any => {
        return state.organizationSubtype;
    },
    _getOrganizationInfo: (state: ISearchOfficesClassifierServiceState): any => {
        return state.organizationInfo;
    },
    _getOrganizationInfoTotalCount: (state: ISearchOfficesClassifierServiceState): any => {
        return state.totalCountOrganizationInfo;
    }
};
