import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {AboutSandbox} from "@/pages/about/sandbox/about.sandbox";
import {container} from "tsyringe";
import {store} from "@/store";
import {IAboutContentCategory} from "@/pages/about/types/about-data";

@Options({
  name: 'about-component',
})

export default class AboutComponent extends Vue {
  private sandbox: AboutSandbox = container.resolve(AboutSandbox)
  public aboutData: Array<object> = [
    {
      "title": "Telefon",
      "data": "155 Çağrı Mərkəzi"
    },
    {
      "title": "Email",
      "data": "https://azintelecom.az/az/"
    }
  ]
  get aboutDataContent(): IAboutContentCategory[] {
    return store.getters._getAboutContent
  }
  public created(): void {
    this.sandbox.getAboutData();
  }
}
