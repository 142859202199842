import {ISearchOfficesClassifierServiceState} from '@/pages/search-offices-classifier-service/store/state';
import {ISearchSelect} from '@/core/layouts/public/types/search-select-structure';

export const SearchOfficesClassifierServiceStateMutations = {
    SET_ORGANISATION_TYPE(state: ISearchOfficesClassifierServiceState, payload: ISearchSelect[]): void {
        state.organizationType = payload;
    },
    SET_ORGANISATION_SUBTYPE(state: ISearchOfficesClassifierServiceState, payload: ISearchSelect[]): void {
        state.organizationSubtype = payload;
    },
    SET_ORGANISATION_INFO(state: ISearchOfficesClassifierServiceState, payload: any): void {
        state.organizationInfo = payload.organizationInfo;
        state.totalCountOrganizationInfo = payload.totalCountOrganizationInfo;
    },
    SET_RESET_ORGANISATION_INFO(state: ISearchOfficesClassifierServiceState, payload: any): void {
        state.organizationInfo = [];
    },
};
