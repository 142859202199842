import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import BoxComponent from '@/core/shared/box/box.component.vue';
import {locale} from '@/assets/db/db.conf.ts';
import FeedbackComponent from '@/core/shared/feadback/feedback.component.vue';
import choiceRouter from '@/core/helpers/routers';

@Options({
    name: 'lead-component',
    components: {
        BoxComponent,
        FeedbackComponent,
    }
})

export default class LeadComponent extends Vue {
    public locale$: any = locale;
    public locale: any = null;
    public $refs!: {
        feedbackModal: { clickModal: () => void; closeModal: () => void; },
    };

    get leadServiceData(): Array<object> {
        return [
            {
                icon: 'services.svg',
                title: this.locale.services,
                description: this.locale.servicesDescription,
                link: 'allservices',
            },
            {
                icon: 'institutions.svg',
                title: this.locale.institutions,
                description: this.locale.institutionsDescription,
                link: 'organisations',
            },
            {
                icon: 'question.svg',
                title: this.locale.quickHelp,
                description: this.locale.quickHelpDescription,
                link: '',
                content: true
            },
        ];
    }

    public clickModal() {
        this.$refs.feedbackModal.clickModal();
    }

    public created(): void {
        this.locale = {title: 'Test'};
        this.locale$.subscribe((res: any) => {
            if (res && res.home) {
                this.locale = res.home.lead;
            }
        });
    }

}
