import {IListMobileNumbersServiceState} from './state.d';
import {ListMobileNumbersServiceStateGetters} from '@/pages/list-mobile-numbers-service/store/getters';
import {ListMobileNumbersServiceStateMutations} from '@/pages/list-mobile-numbers-service/store/mutations';
import {ListMobileNumbersServiceStateActions} from '@/pages/list-mobile-numbers-service/store/actions';


const ListMobileNumbersServiceStateInitial: IListMobileNumbersServiceState = {
    listMobileNumbers: [],
    mobileStatus: {
        code: '',
        text: ''
    }
    // totalCountMobileNumbers: 0,
};

export const ListMobileNumbersServiceState = {
    state: ListMobileNumbersServiceStateInitial,
    getters: ListMobileNumbersServiceStateGetters,
    mutations: ListMobileNumbersServiceStateMutations,
    actions: ListMobileNumbersServiceStateActions
};
