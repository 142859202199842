import {container, injectable} from 'tsyringe';
import {GET} from '@/packages/VHttp/GET';
import {API} from '@/core/configs/api';
import {CategoryModel} from '@/pages/categories/models/category.model';

@injectable()
export class CategoriesService {
    private _get: GET = container.resolve(GET)

    constructor() {
    }

    getCategories(id: string): Promise<any> {
        return this._get.setApi(API.categories+id)
            .requestPromise().then(res => {
                return new CategoryModel(res.data.category);
            });
    }

}
