export class StatusModel {
    public code: string | null = null;
    public text: string | null = null;

    constructor(item: any) {
        this._setCode(item);
        this._setText(item);
    }

    /**
     * Set Code
     * @param code
     * @private
     */

    private _setCode({code}: any) {
        this.code = code;
    }

    /**
     * Set  text
     * @param text
     * @private
     */

    private _setText({text}: any) {
        this.text = text;
    }
}