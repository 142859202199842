<style src="./search-advanced.component.scss" lang="scss"></style>

<template>
  <div class="search__advanced">
    <form action="" class="form">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form__group mt-30">
            <label class="v-label mb-4">{{ locale.institutions }}</label>
            <v-select
                ref="selectStructure"
                @select="selectStructure"
                :value="filter.structureId"
                :placeholder="locale.institutionSelect"
                :options="structureSelect"/>
            <!--              <v-select-->
            <!--                  @select="structureSelect"-->
            <!--                  :placeholder="locale.institutionSelect"-->
            <!--                  :options="structureSelect"/>-->
            <!--              <select-->
            <!--                  v-model="filter.structureId"-->
            <!--                  value=""-->
            <!--              >-->
            <!--                <option :value="null" disabled selected>{{locale.institutionSelect}}</option>-->
            <!--                <option-->
            <!--                    v-for="str in structureSelect"-->
            <!--                    :key="str.id"-->
            <!--                    :value="str.id">{{ str.value }}-->
            <!--                </option>-->
            <!--              </select>-->
          </div>
          <div class="form__group d-block d-lg-none mt-24">
            <label class="v-label mb-4">{{ locale.category }}</label>
            <v-select
                ref="selectCategory"
                @select="selectCategory"
                :value="filter.categoryId"
                :placeholder="locale.categorySelect"
                :options="categorySelect"/>
          </div>
          <div class="form__checkbox ">
            <div class="row">
              <div class="col-6">
                <div class="form__group">
                  <input
                      v-model="filter.paymentRequired"
                      value="true"
                      type="checkbox" name="paid" id="paid" class="v-checkbox v-checkbox--square v-checkbox--dark-blue">
                  <label for="paid" class="v-label">{{ locale.paid }}</label>
                </div>
                <div class="form__group">
                  <input
                      v-model="filter.noPaymentRequired"
                      value="false"
                      type="checkbox" name="dontPaid" id="dontPaid"
                      class="v-checkbox v-checkbox--square v-checkbox--dark-blue">
                  <label for="dontPaid" class="v-label">{{ locale.noPaid }}</label>
                </div>
              </div>
              <div class="col-6">
                <div class="form__group">
                  <input
                      v-model="filter.authRequired"
                      value="true"
                      type="checkbox" name="registered" id="registered"
                      class="v-checkbox v-checkbox--square v-checkbox--dark-blue">
                  <label for="registered" class="v-label">{{ locale.registered }}</label>
                </div>
                <div class="form__group">
                  <input
                      v-model="filter.noAuthRequired"
                      value="false"
                      type="checkbox" name="dontRegistered" id="dontRegistered"
                      class="v-checkbox v-checkbox--square v-checkbox--dark-blue">
                  <label for="dontRegistered" class="v-label">{{ locale.noRegistered }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 d-none d-lg-block mt-30">
          <div class="form__group">
            <label class="v-label mb-4">{{ locale.category }}</label>
            <v-select
                ref="selectCategory"
                @select="selectCategory"
                :value="filter.categoryId"
                :placeholder="locale.categorySelect"
                :options="categorySelect"/>
          </div>
        </div>
        <div class="col-12">
          <v-divider/>
          <div class="button-group">
            <v-btn color="black" @click.prevent="clearFilter">{{ locale.clear }}</v-btn>
            <v-btn @click.prevent="filterDispatch" color="dark">{{ locale.search }}</v-btn>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script src="./search-advanced.component.ts"></script>
