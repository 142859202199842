export class ResponsiveImeiStatusModel {
    public statusCode: number | null = null;
    public statusText: string | null = null;
    public description: string | null = null;
    public price: string | null = null;
    public request: string | null = null;

    constructor(item: any, headers:any) {
        this._setStatusCode(item);
        this._setStatusText(item);
        this._setDescription(item);
        this._setPrice(item);
        this._setRequest(headers);
    }

    /**
     * Set statusCode
     * @param status
     * @private
     */

    private _setStatusCode({status}: any): void {
        this.statusCode = status.code;
    }

    /**
     * Set statusText
     * @param status
     * @private
     */

    private _setStatusText({status}: any): void {
        this.statusText = status.text;
    }

    /**
     * Set Description
     * @param description
     * @private
     */

    private _setDescription({description}: any): void {
        this.description = description;
    }

    /**
     * Set price
     * @param price
     * @private
     */

    private _setPrice({price}: any): void {
        this.price = price;
    }

    /**
     * Set request
     * @param request
     * @private
     */

    private _setRequest({servicerequest}: any): void {
        this.request = servicerequest;
    }
}