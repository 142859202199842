import {IDetailedInformationDepartmentsServiceState} from './state.d';
import {DetailedInformationDepartmentsServiceGetters} from './getters';
import {DetailedInformationDepartmentsServiceMutations} from './mutations';
import {DetailedInformationDepartmentsServiceActions} from './actions';

const DetailedInformationDepartmentsServiceStateInitial: IDetailedInformationDepartmentsServiceState = {
    totalCountOrganization: 0,
    organizations: null,
    name:'',
    tin:'',
};

export const DetailedInformationDepartmentsServiceState = {
    state: DetailedInformationDepartmentsServiceStateInitial,
    getters: DetailedInformationDepartmentsServiceGetters,
    mutations: DetailedInformationDepartmentsServiceMutations,
    actions: DetailedInformationDepartmentsServiceActions
};
