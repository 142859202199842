import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {ISocials} from '@/core/layouts/public/types/socials';

@Options({
  name: 'socials-component',
})

export default class SocialsComponent extends Vue {
  get socials(): ISocials[] {
    return [
      {
        id: 1,
        icon: require('@/assets/images/icons/facebook.svg'),
        url: 'facebook.com',
      },
      {
        id: 2,
        icon: require('@/assets/images/icons/telegram.svg'),
        url: 'telegram',
      },
      {
        id: 3,
        icon: require('@/assets/images/icons/instagram.svg'),
        url: 'instagram',
      },
      {
        id: 4,
        icon: require('@/assets/images/icons/twitter.svg'),
        url: 'twitter',
      },
      {
        id: 5,
        icon: require('@/assets/images/icons/linkedin.svg'),
        url: 'linkedin',
      },
    ]
  }
}
