import {IUserData} from '@/pages/appeal-radio-service/types/user-data';
import {UserDataModel} from '@/pages/appeal-radio-service/models/user-data.model';

export class ApplicantInfoModel {
    public applicantId: number | null = null;
    public userData: IUserData | null = null;

    constructor(data: any) {
        this._setApplicantId(data);
        this._setUserData(data);
    }

    private _setApplicantId({applicant}: any) {
        this.applicantId = applicant?.id;
    }

    private _setUserData({applicantData}: any) {
        if(applicantData) {
            this.userData = new UserDataModel(applicantData);

        }

    }
}