import {container} from 'tsyringe';
import {
    RegistrationRadioElectronicDevicesServiceService
} from '@/pages/registration-radio-electronic-devices-service/services/registration-radio-electronic-devices-service.service';

const service = container.resolve(RegistrationRadioElectronicDevicesServiceService);
export const RegistrationRadioElectronicDevicesServiceStateActions = {
    getRadioRegistrationCitizenship(context: any): void {
        service.getRadioRegistrationCitizenship().then(res => context.commit('SET_CITIZENSHIP', res));
    },
    getRadioRegistrationOrganizationName(context: any, payload: any): void {
        service.getRadioRegistrationOrganizationName(payload).then(res => context.commit('SET_RADIO_ORGANIZATION_NAME', res));
    },
    getRadioRegistrationDocumentSeriesType(context: any, payload: number): void {
        service.getRadioRegistrationDocumentSeriesType(payload).then(res => context.commit('SET_DOCUMENT_SERIES_TYPE', res));
    },
    getRadioRegistrationOrganPersonType(context: any): void {
        service.getRadioRegistrationOrganPersonType().then(res => context.commit('SET_ORGAN_PERSON_TYPE', res));
    },

    postApplicantRegistration(context: any, payload: any): any {
       return  service.postApplicantRegistration(payload);
    },
    postRedCreateAppeal(context: any, payload: any): any {
        return service.postRedCreateAppeal(payload);
    },

    getFileTypesKeyValues(context: any, payload: number): void {
        service.getFileTypesKeyValues().then(res => context.commit('SET_FILE_TYPES_KEY_VALUES', res));
    },
    getAppealTechnologyKeyValues(context: any): void {
        service.getAppealTechnologyKeyValues().then(res => context.commit('SET_APPEAL_TECHNOLOGY_KEY_VALUES', res));
    },
};