import jwt_decode from 'jwt-decode';

export class AuthenticationModel {
    public name: string | null = '';
    public surname: string | null = '';
    public fatherName: string | null = '';
    public pin: string | null = '';
    public birthDate: string | null = '';
    public image: string | null = '';
    public address: string | null = '';
    public birthAddress: string | null = '';
    public document: string | null = '';
    public documentType: string | null = '';
    public documentNumber: string | null = '';
    public citizenshipId: number | null = 0;
    public dateOfIssue: string | null = '';
    public registeredDepartmentName: string | null = '';
    public loginType: string | null = null;
    public certificateNumber: string | null = null;
    public tin: string | null = null;
    public structureName: string | null = null;
    public entity: number |string | null = null;

    constructor(data: any) {
        this._setUser(data);
        this._setDocumentType(data);
        this._setDocumentNumber(data);
        this._setCitizenshipId(data);
        this._setCertificateData(data);
    }

    /**
     * set user
     * @param data
     * @private
     */
    private _setUser({userInfo}: any) {
        this.name = userInfo.Name;
        this.surname = userInfo.Surname;
        this.fatherName = userInfo.Patronymic;
        this.birthDate = userInfo.BirthDate?.split('T')[0];
        this.image = userInfo.PictureUrl;
        this.address = userInfo.RegisteredAddress;
        this.birthAddress = userInfo.BirthAddress;
        this.document = userInfo.IdentityCardNo;
        this.pin = userInfo.UserPin?.toUpperCase();
        this.dateOfIssue = userInfo.DateOfIssue?.split('T')[0];
        this.registeredDepartmentName = userInfo.RegisteredDepartmentName;
        this.loginType = userInfo.LoginType
    }

    private _setCertificateData({userInfo}: any) {
        let token: any = jwt_decode(userInfo.AsanToken);
        this.loginType = token?.main?.loginType;
        if (this.loginType === 'loginWithMhmSign') {
            this.certificateNumber = token?.main?.person?.selectedCertNumber;
            this.tin = token?.main.person?.structureData.voen;
            this.structureName = token?.main.person?.structureData.structureName;

            // if entity = 1 ==>  juridical person
            // if entity = 2 ==>  individual person
            if (this.tin) {
                this.entity = Number(token?.main.person?.structureData.voen[9]);
            }
        }
    }

    private _setDocumentType({userInfo}: any) {
        if (userInfo.IdentityCardNo?.length === 9) {
            this.documentType = userInfo.IdentityCardNo?.substr(0, 2);
        } else {
            this.documentType = 'AZE';
        }
    }

    private _setDocumentNumber({userInfo}: any) {
        if (userInfo.IdentityCardNo?.length === 9) {
            this.documentNumber = userInfo.IdentityCardNo?.substr(2, userInfo.IdentityCardNo.length);
        } else {
            this.documentNumber = userInfo.IdentityCardNo;
        }
    }

    private _setCitizenshipId({userInfo}: any) {
        if (userInfo.UserPin.length === 7) {
            this.citizenshipId = 1;
        } else {
            this.citizenshipId = 2;
        }
    }
}
