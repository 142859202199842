import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {locale} from '@/assets/db/db.conf.ts';
import SearchTagComponent from '@/core/layouts/public/components/search-tag/search-tag.component.vue';
import SearchAdvancedComponent from '@/core/layouts/public/components/search-advanced/search-advanced.component.vue';
import SearchExampleComponent from '@/core/layouts/public/components/search-example/search-example.component.vue';
import {ISearchTag} from '@/core/layouts/public/types/search-tag';
import {store} from '@/store';
import {container} from 'tsyringe';
import {PublicSandbox} from '@/core/layouts/public/sandbox/public.sandbox';
import {ISearchExample} from '@/core/layouts/public/types/search-example';
import {SearchResultsSandbox} from '@/pages/search-results/sandbox/search-results.sandbox';

@Options({
    name: 'search-component',
    components: {
        SearchTagComponent,
        SearchAdvancedComponent,
        SearchExampleComponent,
    }
})

export default class SearchComponent extends Vue {
    public locale$: any = locale;
    public locale: any = null;
    private sandbox: PublicSandbox = container.resolve(PublicSandbox);
    private sandboxResult: SearchResultsSandbox = container.resolve(SearchResultsSandbox);
    public checkFocus = false;
    public isAdvanced: boolean = false;
    public searchValue: string = '';
    public loaderCheck = false;
    public searchResult: any = [];
    public debounce: number = 0;

    get tags(): ISearchTag[] {
        return store.getters._getSearchTag;
    };

    get searchExample(): ISearchExample[] {
        if (this.searchValue === '') {
            return []
        } else {
            return store.getters._getSearchExample;
        }

    }

    public onClickOutside($event: any) {
        if(!$event.target.classList.contains('select__item')) {
            this.isAdvanced = this.$el.contains($event.target) && this.isAdvanced
        }
    }


    public created(): void {
        document.addEventListener('click', this.onClickOutside);

        this.locale$.subscribe((res: any) => {
            if (res && res.home) {
                this.locale = res.home.search;
            }
        });
        if (this.$route.params.word) {
            this.searchValue = this.$route.params.word.toString();
        }
    }

    public unmounted() {
        document.removeEventListener('click', this.onClickOutside);
    }

    public getSearch(param: any) {
        this.$router.replace({name: "search-results"});
        this.$router.push({
            name: 'search-results',
            params: param,
            query: param,
        });
        if (this.searchValue.trim()) {
            this.$route.params.word = this.searchValue;
        }

    }

    public searchInput(): void {
        if (this.searchValue.trim() && this.$route.name != "search-results") {
            this.getSearch({
                word: this.searchValue,
            });
        } else if (this.searchValue.trim()) {
            this.sandboxResult.getSearchResults({
                word: this.searchValue,
            });
            this.$router.replace({name: "search-results"});
            this.$router.push({name: "search-results", query: {
                    word: this.searchValue,
                }});
        }
        this.checkFocus = false;
        this.closeAdvanced();
    }

    public closeAdvanced() {
        this.isAdvanced = false;
    }

    public detailedSearch(info: any): void {
        if (this.searchValue.trim()) {
            info.word = this.searchValue.trim();
        }
        if (this.$route.name != "search-results") {
            this.getSearch(info);

        } else {
            this.sandboxResult.getSearchResultsDetailed(info);
        }
        this.closeAdvanced();
    }

    public searchFilter() {
        clearTimeout(this.debounce);
        this.debounce = setTimeout(() => {
            this.sandbox.getSearchExample(this.searchValue);
        }, 600);
    }

    public unFocus() {
        setTimeout(() => {
            this.checkFocus = false;
        }, 300);
    }
    get isHome(): boolean {
        return this.$route.name === 'home';
    }

}
