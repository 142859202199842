export class UserDetailsModel {
    public title: string | null = null;
    public value: string | null = null;
    public userContactTypeId: number | null = null;
    public id: number | null = null;
    public userId: number | null = null;

    constructor(item: any) {
        this._setId(item);
        this._setUserId(item);
        this._setTitle(item);
        this._setValue(item);
        this._setUserContactTypeId(item);

    }

    /**
     *
     * @param email
     * @private
     */

    private _setTitle({userContactType}: any) {
        this.title = userContactType?.userContactTypeLanguages[0].name;
    }

    /**
     *
     * @param number
     * @private
     */

    private _setValue({value}: any) {
        this.value = value;
    }

    private _setUserContactTypeId({userContactType}: any) {
        this.userContactTypeId = userContactType.id;
    }

    private _setId({id}: any) {
        this.id = id;
    }

    private _setUserId({user}: any) {
        this.userId = user.id;
    }
}