export class GeneralInformationModel {
    public totalCountReceipts: number | null = null;
    public totalCountDebts: number | null = null;
    public totalCountPayments: number | null = null;
    public totalReceiptsPaidAndUnPaid: number | null = null;
    public totalPayments: number | null = null;
    public totalDebts: number | null = null;

    constructor(item: any) {
        this._setTotalCountReceipts(item);
        this._setTotalCountDebts(item);
        this._setTotalCountPayments(item);
        this._setTotalReceiptsPaidAndUnPaid(item);
        this._setTotalPayments(item);
        this._setTotalDebts(item);
    }

    private _setTotalCountReceipts({totalReceipts}: any) {
        this.totalCountReceipts = totalReceipts;
    }

    private _setTotalCountDebts({totalCountDebts}: any) {
        this.totalCountDebts = totalCountDebts;
    }

    private _setTotalCountPayments({totalCountPayments}: any) {
        this.totalCountPayments = totalCountPayments;
    }

    private _setTotalReceiptsPaidAndUnPaid({totalReceipsPaidAndUnPaid}: any) {
        this.totalReceiptsPaidAndUnPaid = totalReceipsPaidAndUnPaid;
    }

    private _setTotalPayments({totalPayments}: any) {
        this.totalPayments = totalPayments;
    }

    private _setTotalDebts({totalDebts}: any) {
        this.totalDebts = totalDebts;
    }
}