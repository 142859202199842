import {createMcqsTemplate, createMcqsTemplateForPDF} from '@/core/email-templates/template/mcqs';

export class McqsModel {
    public date: string | null = '';
    public receiptNumber: string | null = null;
    public amount: string | null = null;
    public account: string | null = null;
    // public templateForMessage: string = '';
    public templateForPDF: string = '';
    public imeis: string[] = [];

    constructor(data: any) {
        this._setImeis(data);
        this._setDate(data);
        this._setReceiptNumber(data);
        this._setAmount(data);
        this._setAccount(data);
        this._setTemplate();
    }

    private _setDate({receiptDate}: any) {
        this.date = receiptDate?.split('T')[0];
    }

    private _setReceiptNumber({receiptNumber}: any) {
        this.receiptNumber = receiptNumber;
    }

    private _setAmount({orderSum}: any) {
        this.amount = orderSum.toFixed(2);
    }

    private _setAccount({paymentTransactionId}: any) {
        this.account = paymentTransactionId;
    }

    private _setImeis({imeis}: any) {
        this.imeis = imeis;
    }

    private _setTemplate() {
        // this.templateForMessage = createMcqsTemplate(this.date, this.account, this.receiptNumber, this.amount, this.imeis[0], this.imeis[1]);
        this.templateForPDF = createMcqsTemplateForPDF(this.date, this.account, this.receiptNumber, this.amount, this.imeis[0], this.imeis[1]);
    }


}
