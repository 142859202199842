import {PayloadForm} from '@/pages/permission-movement-service/types/payload-form';
import {IAppealRadioServiceState} from '@/pages/appeal-radio-service/store/state';
import {ISearchSelect} from '@/core/layouts/public/types/search-select-structure';

export const AppealRadioServiceStateMutations = {
    SET_RADIO_PERSONAL_ORGANIZATION(state: IAppealRadioServiceState, payload: PayloadForm) {
        state.radioPersonalInformation[`${payload.type}`] = payload.value;
    },
    SET_RADIO_ORGANIZATION_NAME(state: IAppealRadioServiceState, payload: any) {
        state.organizationName = payload.fullName;
        state.radioPersonalInformation.organization = payload.fullName;
    },

    SET_FREQUENCY_UNITS(state: IAppealRadioServiceState, payload: ISearchSelect[]) {
        state.frequencyUnits = payload;
    },
    SET_RADIO_INFO_ORGANIZATION(state: IAppealRadioServiceState, payload: PayloadForm) {
        state.radioInformation[`${payload.type}`] = payload.value;
    },
    SET_NEW_DATES(state: IAppealRadioServiceState, payload: PayloadForm) {
        state.radioInformation.dates?.push({
            startDate: new Date(),
            startTime: '00:00',
            endDate: new Date(),
            endTime: '00:00'
        });
    },

    SET_DELETE_DATES(state: IAppealRadioServiceState, payload: number) {
        state.radioInformation.dates?.splice(payload, 1);
    },

    SET_OBSTACLE_AREA(state: IAppealRadioServiceState, payload: any) {
        state.radioInformation.obstacleArea = payload.value;
    },

    // SET_NEW_OBSTACLE_AREA(state: IAppealRadioServiceState) {
    //     state.radioInformation.obstacleArea?.push('');
    // },
    //
    // SET_DELETE_OBSTACLE_AREA(state: IAppealRadioServiceState, payload: number) {
    //     state.radioInformation.obstacleArea?.splice(payload, 1);
    // },

    SET_DURATIONS(state: IAppealRadioServiceState, payload: ISearchSelect[]) {
        state.durations = payload;
    },
    SET_INTERFERENCE_TECHNOLOGY_TYPES(state: IAppealRadioServiceState, payload: ISearchSelect[]) {
        state.interferenceTechnologyTypes = payload;
    },
    SET_INTERFERENCE_TYPES(state: IAppealRadioServiceState, payload: ISearchSelect[]) {
        state.interferenceTypes = payload;
    },

    SET_APPLICANT(state: IAppealRadioServiceState, payload: any) {
        state.applicantId = payload.applicantId;
        state.userInfo = payload.userData;
    },

    SET_DATA_RESET(state: IAppealRadioServiceState, payload: any) {
        state.radioInformation = {
            duration: null,
            dates: [{
                startDate: new Date(),
                startTime: '00:00',
                endDate: new Date(),
                endTime: '00:00'
            }],
            frequencyBand: null,
            typeTechnology: null,
            obstacleDirection: null,
            natureObstacle: [],
            obstacleArea: null,
            technicalIndicators: null,
            file: null,
            frequencyUnits: null
        };
        state.radioPersonalInformation = {
            documentNumber: null,
            documentTypeNumber: null,
            fin: null,
            phone: '+994(',
            email: null,
            tin: null,
            organization: null,
            position: null,
            registration: null,
        };

    },
};