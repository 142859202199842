import {SelectPermissionModel} from '@/pages/permission-movement-service/models/select-permission.model';

export class FileTypeModel extends SelectPermissionModel {
    public label: string | null = null;

    constructor(item: any) {
        super(item);
        this._setLabel(item);

    }

    private _setLabel({label}: any) {
        this.label = label;
    }
}