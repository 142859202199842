import {container, injectable} from 'tsyringe';
import {GET} from '@/packages/VHttp/GET';
import {API} from '@/core/configs/api';
import {
    TotalCountOrganizationInfo
} from '@/pages/search-offices-classifier-service/models/select-search-organization-info.model';
import {SelectSearchCategoryModel} from '@/pages/search-offices-classifier-service/models/select-search-category.model';
import {
    SelectSearchOrganizationModel,
} from '@/pages/search-offices-classifier-service/models/select-search-organization.model';

@injectable()
export class SearchOfficesClassifierServiceService {
    private _get: GET = container.resolve(GET);

    constructor() {
    }

    getOrganizationType(): Promise<any> {
        return this._get.setApi(API.organizationType)
            .setHeaders({cName: 'search-offices-classifier-service'})
            .requestPromise().then(res => {
                return res.data.map((i: any) => {
                    return new SelectSearchCategoryModel(i);
                });
            });
    }

    getOrganizationSubtype(payload: any): Promise<any> {
        return this._get.setApi(API.organizationSubtype)
            .setHeaders({cName: 'search-offices-classifier-service'})
            .setParams({number: payload})
            .requestPromise().then(res => {
                return res.data.map((i: any) => {
                    return new SelectSearchOrganizationModel(i);
                });
            });
    }

    getOrganizationInfo(payload: any): Promise<any> {
        return this._get.setApi(API.organizationInfo)
            .setHeaders({
                cName: 'search-offices-classifier-service'
            }).setParams({
                organizationCode: payload.organizationCode,
                pageNumber: payload.pageNumber,
                pageSize: payload.pageSize
            }).requestPromise().then(res => {
                return new TotalCountOrganizationInfo(res.data);
            });
    }
}
