<style src="./search-example.component.scss" lang="scss"></style>

<template>
  <div class="search__example" v-if="examples.length && inputFocus">
    <div
        v-for="exp in examples"
        class="search__example-item d-flex align-center"
    >
      <router-link
          :to="{name:`services`, params:{
                id:exp.id,
              }}"
      >
        <button class="search-button">
          <img src="@/assets/images/icons/search.svg" alt="search">
        </button>
        <div class="search__example-text">
          {{ exp.name }}
        </div>
      </router-link>

    </div>
  </div>
</template>

<script src="./search-example.component.ts"></script>
