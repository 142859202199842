import {IHomeState} from '@/pages/home/store/state';
import {IPinnedService} from '@/pages/home/types/pinned-service';
import {IMostUsedService} from '@/pages/home/types/most-used-service';
import {IServiceCategory} from '@/pages/home/types/service-category';

export const HomeStateMutations = {

    //services
    SET_PINNED_SERVICES(state: IHomeState, payload: IPinnedService[]): void {
        state.pinnedServices = payload;
    },

    SET_MOST_USED_SERVICES(state: IHomeState, payload: IMostUsedService[]): void {
        state.mostUsedServices = payload;
    },

    SET_SERVICE_CATEGORIES(state: IHomeState, payload: IServiceCategory[]): void {
        state.serviceCategories = payload;
    },

}
