export class SearchExampleModel {
    public id: number | null = null;
    public name: string | null = null;

    constructor(item: any) {
        this._setId(item);
        this._setName(item);
    }

    /**
     * Set Id
     * @param ID
     * @private
     */
    private _setId({parentId}: any) {
        this.id = parentId;
    }

    /**
     * Set name
     * @param serviceCategories
     * @private
     */
    private _setName({name}: any) {
        this.name = name;
    }

}