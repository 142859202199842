export class SearchTagModel {
    public id: number | null = null;
    public name: string | null = null;
    public searchCounts: string | null = null;

    constructor(item: any) {
        this._setId(item);
        this._setName(item);
        this._setSearchCounts(item);
    }

    /**
     * Set Id
     * @param ID
     * @private
     */
    private _setId({id}: any) {
        this.id = id;
    }

    /**
     * Set name
     * @param serviceCategories
     * @private
     */
    private _setName({name}: any) {
        this.name = name;
    }

    /**
     * Set name
     * @param serviceCategories
     * @private
     */
    private _setSearchCounts({searchCounts}: any) {
        this.searchCounts = searchCounts;
    }


}