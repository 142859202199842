import {IOrganisationsState} from './state.d'
import {OrganisationsStateGetters} from './getters'
import {OrganisationsStateMutations} from './mutations'
import {OrganisationsStateActions} from './actions'

const OrganisationsStateInitial: IOrganisationsState = {
    organisations: [],
    organisationDetails: {}
}

export const OrganisationsState = {
    state: OrganisationsStateInitial,
    getters: OrganisationsStateGetters,
    mutations: OrganisationsStateMutations,
    actions: OrganisationsStateActions

}
