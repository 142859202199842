import {Options, Vue} from 'vue-class-component';
import CardComponent from "@/packages/VCard/card.component.vue";
import {locale} from '@/assets/db/db.conf.ts';

@Options({
    name: 'statistics-component',
    // props: {
    //     icon: {
    //         type: String,
    //         required: true, // image name
    //     },
    //     count: {
    //         type: Number,
    //         required: true,
    //     },
    //     subData: {
    //         type: String,
    //         required: true,
    //     },
    // },
    components: {
        CardComponent
    }
})
export default class StatisticsComponent extends Vue {
    locale$: any = locale;
    locale: any = null;

    created() {
        this.locale = {title: "Test"}
        this.locale$.subscribe((res: any) => {
            if (res && res.home) {
                this.locale = res.home.static;
            }
        });
    }

    get statisticsData(): Array<object> {
        return [
            {
                icon: 'persons.svg',
                count: 874.027,
                subData: this.locale.staticRegisteredUser,
            },
            {
                icon: 'services.svg',
                count: 3.145,
                subData: this.locale.staticNumberOfServices,
            },
            {
                icon: 'icontown-hall.svg',
                count: 246,
                subData: this.locale.staticInstitutions,
            }
        ]
    }
}
