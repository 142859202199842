import {container} from 'tsyringe';
import {CalculatorService} from '@/pages/calculator/services/calculator.service';

const service = container.resolve(CalculatorService);
export const CalculatorStateActions = {
    getUserGroups(context: any): void {
        service.getUserGroups().then(res => context.commit('SET_USER_GROUPS', res));
    },
    getCalculateResult(): any {
        return service.getCalculateResult();
    },
};