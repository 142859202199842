import {Options, Vue} from 'vue-class-component';

@Options({
    name: 'button-component',
    /**
     * @description props for button-components
     *
     */
    props: {
        color: {
            type: String,
            required: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    },
})
export default class ButtonComponent extends Vue {
    public color: string = '';

    get buttonColor(): string {
        return this.color ? `btn--${this.color}` : '';
    }
}
