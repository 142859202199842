
import {IReceipt} from '@/pages/receipts-paid-telephone-service/types/receipt';
import {IDebtPreviousMonthsServiceState} from '@/pages/debt-previous-months-service/store/state';

export const DebtPreviousMonthsServiceStateGetters = {
    _getDepReceipts: (state: IDebtPreviousMonthsServiceState): IReceipt[] | null => {
        return state.depReceipts;
    },
    _getTotalAmount: (state: IDebtPreviousMonthsServiceState): number | null => {
        return state.totalAmount;
    },
};
