// @ts-ignore
import * as db from '../db/db.json';
import {locale} from '@/assets/db/db.conf.ts';
import {store} from "@/store";

export class DbService {
    // private lang: 'az' | 'en' | 'ru' | string = 'az';
    private lang: string = 'az';

    constructor() {
        this.setLang()
        this.populateDB();
    }

    // public setNewLang(lang: 'az' | 'en' | 'ru') {
    //     this.lang = lang;
    //     // localStorage.setItem('lang', lang);
    //     store.commit('SET_LANGUAGE',lang);
    //     this.populateDB();
    // }

    public setNewLang(lang: 'az') {
        this.lang = lang;
        // localStorage.setItem('lang', lang);
        // store.commit('SET_LANGUAGE',lang);
        store.commit('SET_LANGUAGE','az');
        this.populateDB();
    }

    private populateDB() {
        const dbData = JSON.parse(JSON.stringify(db));
        // locale.next(dbData['default'][this.lang]);
        locale.next(dbData['default']['az']);
    }

    private setLang() {
        // const lang = store.getters._getLanguage;
        // // const lang = localStorage.getItem('lang');
        // this.lang = lang ? lang : this.lang;
    }
}
