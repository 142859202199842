import {Vue} from 'vue-class-component';
import {AuthenticationSandbox} from '@/core/auth/authentication.sandbox';
import {container} from 'tsyringe';
import choiceRouter from '@/core/helpers/routers';

export class CheckAuthService extends Vue {
    public sandboxAuth: AuthenticationSandbox = container.resolve(AuthenticationSandbox);

    public checkAuthService(service: any): void {
        if (service.authRequired && !localStorage.getItem('mainToken')) {
            // if (this.$route.name === 'home') {
            //     this.sandboxAuth.getSSOToken(service.component);
            // }
            if (this.$route.name === 'allservices') {
                this.sandboxAuth.getSSOToken(`services/${service.component}`);
            } else {
                this.sandboxAuth.getSSOToken(service.component);
            }
        } else {
            this.$router.push({
                name: service.component,
            });
        }
    }
}

