import {IIssuingCertificateConformityServiceState} from './state';
import {IssuingCertificateConformityServiceStateGetters} from './getters';
import {IssuingCertificateConformityServiceStateMutations} from './mutations';
import {IssuingCertificateConformityServiceStateActions} from './actions';

const IssuingCertificateConformityServiceStateInitial: IIssuingCertificateConformityServiceState = {
    applicantForm: {
        documentNumber: null,
        documentTypeNumber: null,
        fin: null,
        phone: '+994(',
        foreignPhone: '',
        email: null,
        tin: null,
        organizationName: null,
        position: null,
        registration: null,
        authorityFile: null,
        legalEntityFullName: null,
        legalEntityAddress: null,
        positionLegalEntity: null,
        copyExtract: null,
        copyExtractFile: null,
        poweroftheattorneydoc: null,
        govregistercopy: null,
        name: null,
        secondName: null

    },
    certificateStatus: 1,
    filesForCertificate: null,
    filesForStep3: null,
    certificateType: 4,
    userInfo: {
        name: null,
        surname: null,
        fatherName: null,
        pin: null,
        birthDate: null,
        image: null,
        address: null,
        document: null,
    }
};

export const IssuingCertificateConformityServiceState = {
    state: IssuingCertificateConformityServiceStateInitial,
    getters: IssuingCertificateConformityServiceStateGetters,
    mutations: IssuingCertificateConformityServiceStateMutations,
    actions: IssuingCertificateConformityServiceStateActions

};