import {ISearchResultsState} from '@/pages/search-results/store/state';
import {ISearchResult} from '@/pages/search-results/types/search-results';

export const SearchResultsStateGetters = {
    _getSearchResult: (state: ISearchResultsState): ISearchResult[] => {
        return state.searchResults;
    },
    _getSearchResultCount: (state: ISearchResultsState): number | null => {
        return  state.totalCount;
    }

}
