import {ICalculatorState} from './state';
import {CalculatorStateGetters} from './getters';
import {CalculatorStateMutations} from './mutations';
import {CalculatorStateActions} from './actions';

const CalculatorStateInitial: ICalculatorState = {
    userGroups: [
        {id: 1, value: 'Əhali və büdcədən maliyyələşən təşkilatlar', mean: '0.5'},
        {id: 2, value: 'Digər təşkilatlar', mean: '1'},
    ],
    selectBandwidthFrequencyNominal: [],
    selectGeographicalAreas: [],
    selectSEMTechnologyFactor: [],
    selectBandSignificances: [],
    selectCoefficientSEMTechnology: [],
    selectAdjustmentFactor: [],
    serviceTypes: [
        {id: 26, value: 'Sellülar (mobil) rabitə', mean: '5'},
        {id: 1, value: 'Radio yayımı (FM)', mean: '2'},
        {id: 2, value: 'Radio yayımı (T_DAB)', mean: '2'},
        {id: 3, value: 'Radio yayımı (OD, QD)', mean: '2'},
        {id: 4, value: 'TV yayımı (DVB-T)', mean: '2'},
        {id: 5, value: 'TV yayımı (Efir kabel TV)', mean: '2'},
        {id: 6, value: 'Radiorele xətti (RRX nöqtə-nöqtə)', mean: '3'},
        {id: 7, value: 'Səyyar radiorabitə şəbəkəsi-radiostansiya UQD (OD və QD, PMR 446 istisna)', mean: '5'},
        {id: 8, value: 'Səyyar radiorabitə şəbəkəsi-radiostansiya  (QD və OD)', mean: '5'},
        {id: 9, value: 'Səyyar radiorabitə şəbəkəsi-radiostansiya  (PMR 446)', mean: '5'},
        {id: 10, value: 'Trank radiorabitə sistemi ', mean: '2'},
        {id: 11, value: 'Ümumistifadəli simsiz telefon rabitə sistemi CDMA', mean: '5'},
        {id: 12, value: 'Simsiz radiorabitə sistemi Wi-Fi (RRX)', mean: '2'},
        {id: 13, value: 'Simsiz radiorabitə sistemi Wi-Fi (Baza stansiyası)', mean: '2'},
        {id: 14, value: 'Genişzolaqlı radiorabitə sistemi LTE (sabit)', mean: '2'},
        {id: 15, value: 'Genişzolaqlı radiorabitə sistemi Wi-MAX', mean: '2'},
        {id: 16, value: 'Simsiz telefon sistemi WLL', mean: '2'},
        {id: 17, value: 'Simsiz telefon sistemi DECT', mean: '2'},
        {id: 18, value: 'Yerüstü peyk stansiyası (TV-uplink)', mean: '5'},
        {id: 19, value: 'Yerüstü peyk stansiyası (VSAT-HUB, VSAT-abunəçi)', mean: '5'},
        {
            id: 20,
            value: 'Yerüstü peyk stansiyası (Respublika daxili yerüstü peyk şəbəkəsinin VSAT abunəçi terminalları (VSAT-HUB stansiyası Azərbaycanda yerləşdiyi halda))',
            mean: '5'
        },
        {id: 21, value: 'Yerüstü peyk stansiyası (Səyyar peyk rabitəsi terminalları (1 MHs-dək))', mean: '5'},
        {id: 22, value: 'Yerüstü peyk stansiyası (SCPC YPS)', mean: '5'},
        {id: 23, value: 'Mobil peyk telefonları', mean: '5'},
        {id: 24, value: 'Radionaviqasiya sistemi', mean: '2'},
        {id: 25, value: 'Radiohəvəskarlar', mean: '1'},
    ],
    bandwidthFrequencyNominal: [
        {id: 1, value: 'Radio yayımı (FM) (300 kHs)', mean: '4.0', typesIds: [1]},
        {id: 2, value: 'Radio yayımı (T-DAB) - (1,536 MHs)', mean: '10.0', typesIds: [2]},
        {id: 3, value: 'Radio yayımı (QD və OD) – (9 kHs)', mean: '0.2', typesIds: [3]},
        {id: 4, value: 'Televiziya yayımı (DVB-T)  (8 MHs)', mean: '18', typesIds: [4]},
        {id: 5, value: 'Efir kabel TV yayımı (42 MHs)', mean: '90', typesIds: [5]},
        {id: 6, value: 'Efir kabel TV yayımı (8 MHs)', mean: '18', typesIds: [5]},
        {id: 8, value: 'Tezlik zolağı 0-2 MHs', mean: '5', typesIds: [6]},
        {id: 9, value: 'Tezlik zolağı 2-7 MHs', mean: '10', typesIds: [6]},
        {id: 10, value: 'Tezlik zolağı 7-28 MHs', mean: '15', typesIds: [6]},
        {id: 11, value: 'Tezlik zolağı 28-56 MHs', mean: '25', typesIds: [6]},
        {id: 12, value: 'Tezlik zolağı 56-140 MHs', mean: '40', typesIds: [6]},
        {id: 13, value: 'Tezlik zolağı 140-500 MHs', mean: '70', typesIds: [6]},
        {id: 14, value: 'Tezlik zolağı 500-1000 MHs', mean: '120', typesIds: [6]},
        {id: 15, value: 'Tezlik zolağı 1000-2000 MHs', mean: '200', typesIds: [6]},
        {id: 41, value: 'Tezlik zolağı 2000 MHz-dən yuxarı', mean: '250', typesIds: [6]},
        {id: 15, value: 'Səyyar radiorabitə şəbəkəsi-radiostansiya ( 6,25 kHs)', mean: '0.2', typesIds: [7, 8, 9]},
        {id: 16, value: 'Səyyar radiorabitə şəbəkəsi-radiostansiya  (12,5 kHs)', mean: '0.3', typesIds: [7, 8, 9]},
        {id: 17, value: 'Səyyar radiorabitə şəbəkəsi-radiostansiya (25,0 kHs)', mean: '0.4', typesIds: [7, 8]},
        {id: 18, value: 'Trank radiorabitə sistem  (25 kHs)', mean: '0.4', typesIds: [10]},
        {id: 19, value: 'Ümumistifadəli simsiz telefon rabitə sistemi (CDMA) –(1,25 MHs)', mean: '10', typesIds: [11]},
        {id: 20, value: 'Tezlik zolağı 7-28 MHs', mean: '6', typesIds: [12]},
        {id: 21, value: 'Tezlik zolağı 28-56 MHs', mean: '8', typesIds: [12]},
        {id: 22, value: 'Tezlik zolağı 56-140 MHs', mean: '10', typesIds: [12]},
        {id: 23, value: 'Tezlik zolağı 7-28 MHs', mean: '30', typesIds: [13]},
        {id: 24, value: 'Tezlik zolağı 28-56 MHs', mean: '35', typesIds: [13]},
        {id: 25, value: 'Tezlik zolağı 56-140 MHs', mean: '45', typesIds: [13]},
        {id: 26, value: 'Genişzolaqlı radiorabitə sistemi (sabit LTE)  - (1÷10 MHs)', mean: '15', typesIds: [14]},
        {id: 27, value: 'Genişzolaqlı radiorabitə sistemi (Wi-MAX)  - (1÷10 MHs) ', mean: '15', typesIds: [15]},
        {id: 28, value: 'Simsiz telefon sistemi (WLL)  - (1÷10 MHs) ', mean: '15', typesIds: [16]},
        {id: 29, value: 'Simsiz telefon sistemi (DECT) –  (1,728 MHs)', mean: '13', typesIds: [17]},
        {id: 30, value: 'Tezlik zolağı 0-2 MHs', mean: '13', typesIds: [18, 19, 22]},
        {id: 31, value: 'Tezlik zolağı 2-7 MHs', mean: '25', typesIds: [18, 19, 22]},
        {id: 32, value: 'Tezlik zolağı 7-28 MHs', mean: '35', typesIds: [18, 19, 22]},
        {id: 33, value: 'Tezlik zolağı 28-56 MHs', mean: '40', typesIds: [18, 19, 22]},
        {id: 34, value: 'Tezlik zolağı 56-140 MHs', mean: '42', typesIds: [18, 19, 22]},
        {id: 35, value: 'Tezlik zolağı 140-500 MHs', mean: '45', typesIds: [18, 19, 22]},
        {
            id: 36,
            value: 'Respublikadaxili yerüstü peyk rabitəsi şəbəkəsinin (VSAT) abunəçi terminalları (VSAT-HUB stansiyası Azərbaycanda yerləşdiyi halda)  (7 MHs-dək)',
            mean: '11',
            typesIds: [20]
        },
        {id: 37, value: 'Səyyar peyk rabitəsi terminalları (1 MHs-dək)', mean: '6', typesIds: [21]},
        {id: 38, value: 'Mobil peyk telefonları (0,5 MHs-dək)', mean: '4', typesIds: [23]},
        {id: 39, value: 'Radionaviqasiya sistemi', mean: '1', typesIds: [24]},
        {id: 40, value: 'Radiohəvəskarlar', mean: '1', typesIds: [25]},
    ],
    geographicalAreas: [
        {
            id: 1,
            value: 'Respublika ərazisi',
            mean: '7',
            typesIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25]
        },
        {
            id: 2,
            value: 'Bakı və Sumqayıt şəhəri Abşeron rayonu və  Xəzər akvatoriyası',
            mean: '4',
            typesIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25]
        },
        {
            id: 3,
            value: 'Gəncə şəhəri',
            mean: '3.5',
            typesIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25]
        },
        {
            id: 5,
            value: 'Qarabağ və Şərqi Zəngəzur iqtisadi rayonlarının ərazisi',
            mean: '1.5',
            typesIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25]
        },
        {
            id: 4,
            value: 'Digər şəhər və rayonların ərazisi',
            mean: '3',
            typesIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25]
        },
    ],
    SEMTechnologyFactor: [
        {id: 1, value: 'Radio yayımı (FM) üzrə', typesIds: [1], mean: null},
        {id: 2, value: 'Radio yayımı (T_DAB) üzrə', typesIds: [2], mean: null},
        {id: 3, value: 'Radio yayımı (OD, QD) üzrə', typesIds: [3], mean: null},
        {id: 4, value: 'Televiziya yayımı (DVB-T)', typesIds: [4], mean: null},
        {id: 5, value: 'Efir kabel TV yayımı üzrə', typesIds: [5], mean: '7'},
        {id: 6, value: 'RRX  (Nöqtə-nöqtə)', typesIds: [6], mean: '1'},
        {
            id: 7,
            value: 'Səyyar radiorabitə şəbəkəsi -radiostansiya (UQD) (PMR 446 səyyar radiorabitə şəbəkəsi istisna olmaqla)\n'
            , typesIds: [7]
            , mean: null

        },
        {
            id: 8,
            value: 'Səyyar radiorabitə şəbəkəsi -radiostansiya (OD və QD) '
            , typesIds: [8]
            , mean: null

        },
        {
            id: 9,
            value: 'PMR 446 səyyar radiorabitə şəbəkəsi'
            , typesIds: [9]
            , mean: null
        },
        {id: 10, value: 'Trank radiorabitə sistemi ', typesIds: [10], mean: '7'},
        {id: 11, value: 'Umumistifadəli simsiz telefon rabitə sitemi (CDMA) ', typesIds: [11], mean: '1'},
        {id: 12, value: 'Simsiz radiorabitə sistemi (Wi-Fi RRX) ', typesIds: [12], mean: '1'},
        {id: 27, value: 'Simsiz radiorabitə sistemi (Wi-Fi Baza stansiyası) ', typesIds: [13], mean: '1'},
        {id: 14, value: 'Genişzolaqlı radiorabitə sistemi (sabit LTE)', typesIds: [14], mean: '1'},
        {id: 15, value: 'Genişzolaqlı radiorabitə sistemi (Wi-MAX)', typesIds: [15], mean: '1'},
        {id: 16, value: 'Simsiz telefon sistemi (WLL)', typesIds: [16], mean: '1'},
        {id: 17, value: 'Simsiz telefon sistemi (DECT)', typesIds: [17], mean: '1'},
        {id: 18, value: 'Yerüstü peyk stansiyası (TV Uplink)', typesIds: [18], mean: '1'},
        {id: 19, value: 'Yerüstü peyk stansiyası (VSAT-HUB, VSAT-abunəçi)', typesIds: [19], mean: '1'},
        {id: 20, value: 'Yerüstü peyk stansiyası (VSAT-HUB, VSAT-abunəçi)', typesIds: [20], mean: '1'},
        {id: 21, value: 'Yerüstü peyk stansiyası (Səyyar peyk rabitəsi terminalları)', typesIds: [21], mean: '1'},
        {id: 22, value: 'Yerüstü peyk stansiyası (SCPC)', typesIds: [22], mean: '1'},
        {id: 23, value: 'Mobil peyk telefonları', typesIds: [23], mean: '1'},
        {id: 24, value: 'Radionaviqasiya sistemi', typesIds: [24], mean: null},
        {id: 25, value: 'Radiohəvəskarlar', typesIds: [25], mean: '100'},
    ],
    bandSignificances: [
        {id: 1, value: '87,5 MHs - 470 MHs (470 MHs daxil olmaqla)', mean: '0.8', typesIds: [1, 2, 7, 9, 10, 24]},
        {id: 2, value: '10 kHs – 28 MHs (28 MHs daxil olmaqla)', mean: '0.1', typesIds: [3, 8, 25]},
        {
            id: 4,
            value: '694 MHs – 2,7 QHs  (2,7 QHs daxil olmaqla)',
            mean: '1.0',
            typesIds: [4, 5, 11, 12, 13, 14, 17, 21, 23, 15]
        },
        {id: 5, value: '470 MHs – 694 MHs (694 MHs daxil olmaqla)', mean: '0.7', typesIds: [4]},
        {
            id: 7,
            value: '2,7 QHs –10 QHs  (10 QHs daxil olmaqla)',
            mean: '0.9',
            typesIds: [5, 6, 12, 13, 15, 16, 18, 19, 21, 22, 24]
        },
        {id: 8, value: '10 QHs – 20 QHs  (20 QHs daxil olmaqla)', mean: '0.7', typesIds: [5, 15, 18, 19, 20, 22, 6]},
        {id: 9, value: '20 QHs –26,5 QHs (26,5 QHs daxil olmaqla)', mean: '0.6', typesIds: [6, 18, 19, 20, 22]},
        {id: 10, value: '26,5 QHs – 40 QHs (40 QHs daxil olmaqla)', mean: '0.3', typesIds: [6, 19, 20, 22]},
        {id: 11, value: '40 QHs – 50 QHs (50 QHs daxil olmaqla)', mean: '0.2', typesIds: [6, 19, 20, 22]},
        {id: 12, value: '50 QHs – 300 QHs (300 QHs daxil olmaqla)', mean: '0.08', typesIds: [6, 19, 20, 22]},
    ],
    coefficientSEMTechnology: [
        {id: 1, value: 'Radio yayımı (FM) üzrə', typesIds: [1], mean: '2'},
        {id: 2, value: 'Radio yayımı (T_DAB) üzrə', typesIds: [2], mean: '2'},
        {id: 3, value: 'Radio yayımı (OD, QD) üzrə', typesIds: [3], mean: '2'},
        {id: 4, value: 'TV yayımı (DVB-T) üzrə', typesIds: [4], mean: '2'},
        {id: 5, value: 'Efir kabel TV yayımı üzrə', typesIds: [5], mean: '2.20'},
        {id: 6, value: 'RRX  (Nöqtə-nöqtə)', typesIds: [6], mean: '2.20'},
        {
            id: 7,
            value: 'Səyyar radiorabitə şəbəkəsində radiostansiyaların (UQD) bir coğrafi zona üzrə toplam gücünə görə',
            typesIds: [7],
            mean: '2.20'
        },
        {
            id: 8,
            value: 'Səyyar radiorabitə şəbəkəsində radiostansiyaların (QD və OD) bir coğrafi zona üzrə toplam gücünə görə',
            typesIds: [8],
            mean: '2.20'
        },
        {
            id: 9,
            value: 'Səyyar radiorabitə şəbəkəsində radiostansiyaların (PMR) bir coğrafi zona üzrə toplam gücünə görə',
            typesIds: [9],
            mean: '1.5'
        },
        {id: 10, value: 'Trank radiorabitə sistemi ', typesIds: [10], mean: '2.30'},
        {id: 11, value: 'Umumistifadəli simsiz telefon rabitə sitemi (CDMA', typesIds: [11], mean: '2.50'},
        {id: 12, value: 'Simsiz radiorabitə sistemi (Wi-Fi - 2,4 QHs) ', typesIds: [12, 13], mean: '2.10'},
        {id: 13, value: 'Simsiz radiorabitə sistemi (Wi-Fi - 5 QHs) ', typesIds: [12, 13], mean: '2.50'},
        {id: 14, value: 'Genişzolaqlı radiorabitə sistemi (sabit LTE)', typesIds: [14], mean: '2.50'},
        {id: 15, value: 'Genişzolaqlı radiorabitə sistemi (Wi-MAX)', typesIds: [15], mean: '2.50'},
        {id: 16, value: 'Simsiz telefon sistemi (WLL)', typesIds: [16], mean: '2.50'},
        {id: 17, value: 'Simsiz telefon sistemi (DECT)', typesIds: [17], mean: '2.50'},
        {id: 18, value: 'Yerüstü peyk stansiyası (TV Uplink)', typesIds: [18], mean: '2.50'},
        {id: 19, value: 'Yerüstü peyk stansiyası (VSAT-HUB, VSAT-abunəçi)', typesIds: [19], mean: '2.50'},
        {id: 20, value: 'Yerüstü peyk stansiyası (VSAT-HUB, VSAT-abunəçi)', typesIds: [20], mean: '2.50'},
        {id: 21, value: 'Yerüstü peyk stansiyası (Səyyar peyk rabitəsi terminalları)', typesIds: [21], mean: '2.50'},
        {id: 22, value: 'Yerüstü peyk stansiyası (SCPC)', typesIds: [22], mean: '2.50'},
        {id: 23, value: 'Mobil peyk telefonları', typesIds: [23], mean: '1.50'},
        {id: 24, value: 'Radionaviqasiya sistemi', typesIds: [24], mean: '0.80'},
        {id: 25, value: 'Radiohəvəskarlar', typesIds: [25], mean: '0.40'},
    ],
    adjustmentFactor: [
        {id: 1, value: 'Əmsal tətbiq edilmir', typesIds: [2, 4, 6, 7, 8, 9, 10, 19, 21, 22, 23, 24], mean: '1'},
        {id: 2, value: 'SFN rejimində işləyən T-DAB və DVB-T şəbəkəsi üçün ', typesIds: [2, 4], mean: '0.2'},
        {id: 3, value: 'Xarici şirkətlər üçün', typesIds: [6, 23], mean: '10'},
        {
            id: 4,
            value: '(Xarici* şirkətlər üçün) Səyyar radiorabitə şəbəkəsi (yalnız UQD radiostansiyalar)',
            typesIds: [7, 8, 9, 10],
            mean: '36'
        },
        {
            id: 5,
            value: '(Dip nümayəndəlik, Səfirliklər üçün) səyyar radiorabitə şəbəkəsi (yalnız UQD radiostansiyalar) ',
            typesIds: [7, 8, 9, 10],
            mean: '3'
        },
        {id: 6, value: 'Yerüstü peyk stansiyası (TV-Uplink) ', typesIds: [18], mean: '4'},
        {id: 7, value: 'Xarici şirkətlər üçün', typesIds: [19, 22], mean: '12'},
        {id: 8, value: 'NEFT-QAZ sahəsi üzrə  fəaliyyət göstərən şirkətlər üçün', typesIds: [19, 22], mean: '12'},
        {
            id: 9,
            value: 'Respublikadaxili yerüstü peyk şəbəkəsinin (VSAT) abunəçi terminaları üzrə (VSAT-HUB stansiyası Azərbaycan ərazisində yerləşdiyi halda) ',
            typesIds: [20],
            mean: '0.2'
        },
        {id: 10, value: 'Səyyar peyk rabitəsi terminalları (Xarici* şirkətlər üçün) ', typesIds: [21], mean: '2'},
        {id: 11, value: 'Radionaviqasiya sistemi (Xarici* şirkətlər üçün) ', typesIds: [24], mean: '30'},
    ],
    termInfo: {
        userGroup: {
            id: null, value: null, mean: null
        },
        serviceType: {
            id: null, value: null, mean: null
        },
        frequenciesCount: null,
        frequenciesCountMean: null,
        bandwidthFrequencyNominal: {
            id: null, value: null, mean: null
        },
        geographicalAreas: {
            id: null, value: null, mean: null
        },
        date: {
            id: null, value: null, mean: ''
        },
        startDate: null,
        endDate: null,
        adjustmentFactor: {
            id: null, value: null, mean: null
        },
        bandSignificances: {
            id: null, value: null, mean: null
        },
        coefficientSEMTechnology: {
            id: null, value: null, mean: null
        },
        broadcastCoverageArea: null,
        SEMTechnologyFactor: {
            id: null, value: null, mean: null
        },
        radioFrequencyMultiplier: null,
        radioFrequencyChannels: {
            id: 1, value: 'RRX (simpleks)', mean: null, factor: '1'
        },
        frequenciesPropertyFirst: null,
        frequenciesPropertySecond: null,
        SEMTechnologyFactorValue: null,
    },
    dateTern: [
        {
            id: 14,
            value: '1 ay',
            mean: '0.08333333333'
        },
        {
            id: 2,
            value: '2 ay',
            mean: '0.16666666667'
        },
        {
            id: 3,
            value: '3 ay',
            mean: '0.25000'
        },
        {
            id: 4,
            value: '4 ay',
            mean: '0.333333333'
        },
        {
            id: 5,
            value: '5 ay',
            mean: '0.4166666667'
        },
        {
            id: 6,
            value: '6 ay',
            mean: '0.50'
        },
        {
            id: 7,
            value: '7 ay',
            mean: '0.5833333333'
        },
        {
            id: 8,
            value: '8 ay',
            mean: '0.6666666667'
        },
        {
            id: 9,
            value: '9 ay',
            mean: '0.75'
        },
        {
            id: 10,
            value: '10 ay',
            mean: '0.8333333333'
        },
        {
            id: 11,
            value: '11 ay',
            mean: '0.9166666667'
        },
        {
            id: 12,
            value: '12 ay',
            mean: '1'
        },

        {
            id: 13,
            value: 'Digər',
            mean: '',
            startDate: '',
            endDAte: ''
        }
    ],
    radioFrequenciesTypes: [
        {
            id: 1,
            value: 'RRX (simpleks)',
            factor: '1'
        },
        {
            id: 2,
            value: 'RRX (dupleks)',
            factor: '2'
        },
    ],
    radioFrequencyMultiplier: [
        {
            id: 1,
            typesIds: [1, 2, 3, 4, 5, 18, 24, 25],
            factor: 1
        },
        {
            id: 2,
            typesIds: [11, 12, 13, 14, 15, 16, 17, 19, 20, 21, 22, 23],
            factor: 2
        }, {
            id: 2,
            typesIds: [7, 8, 9, 10],
            factor: 5
        }

    ],

    /// ONE TIME
    serviceTypesOneTime: [
        {id: 1, value: 'Radio yayımı (FM)', mean: '50', countFrequencies: '1'},
        {id: 2, value: 'Radio yayımı (OD, QD)', mean: '50', countFrequencies: '1'},
        {id: 3, value: 'Radio yayımı (T-DAB)', mean: '50', countFrequencies: '1'},
        {id: 4, value: 'TV yayımı (DVB-T)', mean: '50', countFrequencies: '1'},
        {id: 5, value: 'TV yayımı (Efir kabel TV)', mean: '50', countFrequencies: '1'},
        {id: 6, value: 'Radiorele xətti (RRX)', mean: '45', countFrequencies: '1'},
        {id: 7, value: 'Səyyar radiorabitə şəbəkəsi-radiostansiya  (UQD)', mean: '30', countFrequencies: '1'},
        {id: 8, value: 'Səyyar radiorabitə şəbəkəsi-radiostansiya  (QD və OD)', mean: '30', countFrequencies: '1'},
        {id: 9, value: 'Trank radiorabitə sistemi ', mean: '50', countFrequencies: '1'},
        {id: 10, value: 'Ümumistifadəli simsiz telefon rabitə sistemi CDMA', mean: '30', countFrequencies: '1'},
        {id: 11, value: 'Simsiz radiorabitə sistemi (Wi-Fi)', mean: '45', countFrequencies: '1'},
        {id: 12, value: 'Genişzolaqlı radiorabitə sistemi Wi-MAX', mean: '100', countFrequencies: '1'},
        {id: 13, value: 'Genişzolaqlı radiorabitə sistemi LTE (sabit)', mean: '100', countFrequencies: '1'},
        {id: 14, value: 'Simsiz telefon sistemi DECT', mean: '30', countFrequencies: '1'},
        {id: 15, value: 'Simsiz telefon sistemi WLL', mean: '30', countFrequencies: '1'},
        {id: 16, value: 'Radionaviqasiya sistemi', mean: '30', countFrequencies: '1'},
        {id: 17, value: 'Sellülar (mobil) radiorabitə şəbəkəsi', mean: '250', countFrequencies: '1'},
    ],
    throughputTypesOneTime: [
        {id: 1, value: 'Radio yayımı (FM)', mean: '8', typesIds: [1]},
        {id: 2, value: 'Radio yayımı (OD, QD)', mean: '8', typesIds: [2]},
        {id: 3, value: 'Radio yayımı (T-DAB) (1,536 MHs)', mean: '10', typesIds: [3]},
        // {
        //     id: 4,
        //     value: 'Səyyar radiorabitə şəbəkəsi-radiostansiya (UQD, QD və OD) - (6,25-25,0 kHs); trank radiorabitəsi, radionaviqasiya -(25,0 kHs);  radio yayımı (OD, QD) – (9,0 kHs); radio yayımı (FM) - (300,0 kHs) ',
        //     mean: '8',
        //     typesIds: [3]
        // },
        {id: 5, value: 'Televiziya  yayımı (DVB-T)', mean: '25', typesIds: [4]},
        {id: 6, value: 'Televiziya  yayımı (Efir kabel TV) (8,0 MHs)', mean: '25', typesIds: [5]},
        {id: 7, value: '7-28 MHs (dax-r)', mean: '25', typesIds: [6]},
        {id: 8, value: '28-56 MHs (dax-r)', mean: '40', typesIds: [6]},
        {id: 9, value: '56-140 MHs (dax-r)', mean: '55', typesIds: [6]},
        {id: 26, value: '140-500 MHs (dax-r)', mean: '70', typesIds: [6]},
        {id: 10, value: '500-1000 MHs (dax-r)', mean: '120', typesIds: [6]},
        {id: 11, value: '1000-2000 MHs (dax-r)', mean: '200', typesIds: [6]},
        {id: 12, value: '2000 MHz-dən yuxarı', mean: '250', typesIds: [6]},
        {id: 13, value: 'Səyyar Radiostansiya (UQD)', mean: '8', typesIds: [7]},
        {id: 14, value: 'Səyyar Radiostansiya (QD və OD)', mean: '8', typesIds: [8]},
        {id: 15, value: 'Trank radiorabitə sistemi ', mean: '8', typesIds: [9]},
        {id: 16, value: 'CDMA (1,25 MHs)', mean: '10', typesIds: [10]},
        {id: 17, value: '7-28 MHs (dax-r)', mean: '25', typesIds: [11]},
        {id: 18, value: '28-56 MHs (dax-r)', mean: '40', typesIds: [11]},
        {id: 19, value: '56-140 MHs (dax-r)', mean: '55', typesIds: [11]},
        {id: 20, value: 'Genişzolaqlı radiorabitə sistemi ( Wi-MAX) (5 MHs) ', mean: '18', typesIds: [12]},
        {id: 21, value: 'Genişzolaqlı radiorabitə sistemi (LTE (sabit)) (5 MHs) ', mean: '18', typesIds: [13]},
        {id: 22, value: 'Simsiz telefon sistemi (DECT) - (1,728 MHs)', mean: '10', typesIds: [14]},
        {id: 23, value: 'Genişzolaqlı radiorabitə sistemi (WLL) (5 MHs) ', mean: '18', typesIds: [15]},
        {id: 24, value: 'Radionaviqasiya sistemi', mean: '8', typesIds: [16]},
        {id: 25, value: 'Sellülar (mobil) radiorabitə şəbəkəsi (5,0 MHs)', mean: '18', typesIds: [17]},

    ],
    geographicalAreasOneTime: [
        {
            id: 1,
            value: 'Respublika ərazisi',
            mean: '7',
            typesIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17]
        },
        {
            id: 2,
            value: 'Bakı və Sumqayıt şəhəri Abşeron rayonu və  Xəzər akvatoriyası',
            mean: '4',
            typesIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16]
        },
        {id: 3, value: 'Gəncə şəhəri', mean: '3.5', typesIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16]},

        {
            id: 5,
            value: 'Qarabağ və Şərqi Zəngəzur iqtisadi rayonlarının ərazisi',
            mean: '1.5',
            typesIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16]
        },
        {
            id: 4,
            value: 'Digər şəhər və rayonların ərazisi',
            mean: '3',
            typesIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16]
        },
    ],
    bandSignificancesOneTime: [
        {id: 1, value: '87,5MHs - 470 MHs daxil olmaqla', mean: '0.8', typesIds: [1, 3, 9, 16, 7]},
        {id: 2, value: '10kHs - 28 MHs daxil olmaqla', mean: '0.1', typesIds: [2, 8]},
        {id: 4, value: '694MHs - 2.7QHs  daxil olmaqla', mean: '1.0', typesIds: [4, 5, 10, 13, 14, 17, 11]},
        {id: 5, value: '470MHs - 694 MHs daxil olmaqla', mean: '0.7', typesIds: [4]},
        {id: 7, value: '2.7QHs - 10 QHs  daxil olmaqla', mean: '0.9', typesIds: [5, 6, 11, 17, 15, 12, 16]},
        {id: 8, value: '10QHs - 20QHs  daxil olmaqla', mean: '0.7', typesIds: [5, 6, 12, 17]},
        {id: 9, value: '20QHs - 26,5 QHs daxil olmaqla', mean: '0.6', typesIds: [6, 17]},
        {id: 10, value: '26,5QHs - 40QHs daxil olmaqla', mean: '0.3', typesIds: [6, 17]},
        {id: 11, value: '40QHs - 50QHs daxil olmaqla', mean: '0.2', typesIds: [6]},
        {id: 12, value: '50QHs - 300QHs daxil olmaqla', mean: '0.08', typesIds: [6]},
    ],
    selectThroughputTypesOneTime: [],
    selectGeographicalAreasOneTime: [],
    selectBandSignificancesOneTime: [],
    frequency: '',
    oneTimePaymentInfo: {
        userGroup: {
            id: null, value: null, mean: null
        },
        serviceType: {
            id: null, value: null, mean: null
        },
        frequency: {
            value: ''
        },
        bandSignificance: {
            id: null, value: null, mean: null
        },
        throughputType: {
            id: null, value: null, mean: null
        },
        geographicalArea: {
            id: null, value: null, mean: null
        },
        frequencyCount:''
    },
    mobileInputData: [
        {
            title: 'Ayrılmış sellülar radiotezlik zolağı (ZG sel), MHs',
            default: false,
            type: 'input',
            inputs: [
                {
                    label: '694 MHs - 2.7 QHs (daxil)',
                    titleMean: 'ZG1',
                    mean: '0',
                    error: false,
                    disabled: false,
                    value: '0'

                },
                {
                    label: '2.7 QHs - 10 QHs (daxil)',
                    titleMean: 'ZG2',
                    mean: '0',
                    error: false,
                    disabled: false,
                    value: '0'
                },
                {
                    label: '10 QHs - 20 QHs (daxil)',
                    titleMean: 'ZG3',
                    mean: '0',
                    error: false,
                    disabled: false,
                    value: '0'
                },
                {
                    label: '20 QHs - 26.5 QHs (daxil)',
                    titleMean: 'ZG4',
                    mean: '0',
                    error: false,
                    disabled: false,
                    value: '0'
                },
                {
                    label: '26.5 QHs - 40 QHs (daxil)',
                    titleMean: 'ZG5',
                    mean: '0',
                    error: false,
                    disabled: false,
                    value: '0'
                },
                {
                    label: '40 QHs - 50 QHs (daxil)',
                    titleMean: 'ZG6',
                    mean: '0',
                    error: false,
                    disabled: false,
                    value: '0'
                },
                {
                    label: '50 QHs - 300 QHs (daxil)',
                    titleMean: 'ZG7',
                    mean: '0',
                    error: false,
                    disabled: false,
                    value: '0'
                }
            ]
        },
        {
            title: 'Transmissiya üzrə ayrılmış digər radiotezlik zolağı (ZG dig), MHs',
            default: false,
            type: 'input',
            inputs: [
                {
                    label: '694 MHs - 2.7 QHs (daxil)',
                    titleMean: 'ZG1',
                    value: '0',
                    mean: '0',
                    error: false,
                    disabled: false,

                },
                {
                    label: '2.7 QHs - 10 QHs (daxil)',
                    titleMean: 'ZG2',
                    mean: '0',
                    error: false,
                    disabled: false,
                    value: '0',
                },
                {
                    label: '10 QHs - 20 QHs (daxil)',
                    titleMean: 'ZG3',
                    mean: '0',
                    error: false,
                    disabled: false,
                    value: '0',
                },
                {
                    label: '20 QHs - 26.5 QHs (daxil)',
                    titleMean: 'ZG4',
                    mean: '0',
                    error: false,
                    disabled: false,
                    value: '0',
                },
                {
                    label: '26.5 QHs - 40 QHs (daxil)',
                    titleMean: 'ZG5',
                    mean: '0',
                    error: false,
                    disabled: false,
                    value: '0',
                },
                {
                    label: '40 QHs - 50 QHs (daxil)',
                    titleMean: 'ZG5',
                    mean: '0',
                    error: false,
                    disabled: false,
                    value: '0',
                },
                {
                    label: '50 QHs - 300 QHs (daxil)',
                    titleMean: 'ZG7',
                    mean: '0',
                    error: false,
                    disabled: false,
                    value: '0',
                }
            ]
        },
        {
            title: 'Radiotezlik zolağından il ərzində istifadə müddəti (T)',
            default: false,
            type: 'select',
            list: [
                {
                    id: 1,
                    value: '1 ay',
                    mean: '0.08333333333'
                },
                {
                    id: 2,
                    value: '2 ay',
                    mean: '0.16666666667'
                },
                {
                    id: 3,
                    value: '3 ay',
                    mean: '0.25000'
                },
                {
                    id: 4,
                    value: '4 ay',
                    mean: '0.333333333'
                },
                {
                    id: 5,
                    value: '5 ay',
                    mean: '0.4166666667'
                },
                {
                    id: 6,
                    value: '6 ay',
                    mean: '0.50'
                },
                {
                    id: 7,
                    value: '7 ay',
                    mean: '0.5833333333'
                },
                {
                    id: 8,
                    value: '8 ay',
                    mean: '0.6666666667'
                },
                {
                    id: 9,
                    value: '9 ay',
                    mean: '0.75'
                },
                {
                    id: 10,
                    value: '10 ay',
                    mean: '0.8333333333'
                },
                {
                    id: 11,
                    value: '11 ay',
                    mean: '0.9166666667'
                },
                {
                    id: 12,
                    value: '12 ay',
                    mean: '1'
                },

                {
                    id: 13,
                    value: 'Digər',
                    mean: '',
                    startDate: '',
                    endDAte: ''
                }
            ],
            label: 'Tarix aralığı',
            meanTitle: 'T',
            error: false,
            errorStart: false,
            errorEnd: false,
            dateMean: '',
            value: {
                id: null,
                value: '',
                mean: ''
            }
        },
        {
            title: 'Radiotezlik zolağının əhəmiyyətlilik əmsalı (ZƏ)',
            default: true,
            type: 'input',
            inputs: [
                {
                    label: '694 MHs - 2.7 QHs (daxil)',
                    titleMean: 'ZƏ1',
                    mean: '1',
                    error: false,
                    disabled: true,
                    value: '1',

                },
                {
                    label: '2.7 QHs - 10 QHs (daxil)',
                    titleMean: 'ZƏ2',
                    mean: '0.90',
                    error: false,
                    disabled: true,
                    value: '0.90',
                },
                {
                    label: '10 QHs - 20 QHs (daxil)',
                    titleMean: 'ZƏ3',
                    mean: '0.70',
                    error: false,
                    disabled: true,
                    value: '0.70',
                },
                {
                    label: '20 QHs - 26.5 QHs (daxil)',
                    titleMean: 'ZƏ4',
                    mean: '0.60',
                    error: false,
                    disabled: true,
                    value: '0.60',
                },
                {
                    label: '26.5 QHs - 40 QHs (daxil)',
                    titleMean: 'ZƏ5',
                    mean: '0.30',
                    error: false,
                    disabled: true,
                    value: '0.30',
                },
                {
                    label: '40 QHs - 50 QHs (daxil)',
                    titleMean: 'ZƏ6',
                    mean: '0.20',
                    error: false,
                    disabled: true,
                    value: '0.20',
                },
                {
                    label: '50 QHs - 300 QHs (daxil)',
                    titleMean: 'ZƏ7',
                    mean: '0.08',
                    value: '0.08',
                    error: false,
                    disabled: true,
                }
            ]
        },
        {
            title: 'Digər əmsallar',
            default: true,
            type: 'input',
            inputs: [
                {
                    label: 'Sabit dəyər kəmiyyəti',
                    titleMean: 'SD',
                    mean: '600',
                    error: false,
                    disabled: true,
                    value: '600'

                },
                {
                    label: '<strong>Tə</strong> (sellülar)',
                    titleMean: 'TƏS',
                    mean: '100',
                    error: false,
                    disabled: true,
                    value: '100'
                },
                {
                    label: `${'<strong>Tə</strong> (digər)'}`,
                    titleMean: 'TƏD',
                    mean: '1',
                    error: false,
                    disabled: true,
                    value: '1'
                },
            ]
        },
    ],
    result: null,
    totalResult: null,
    vat: null,


};

export const CalculatorState = {
    state: CalculatorStateInitial,
    getters: CalculatorStateGetters,
    mutations: CalculatorStateMutations,
    actions: CalculatorStateActions

};
