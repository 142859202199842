export class InfoNumberModel {
    public number: string | null = null;
    public address: string | null = null;
    public street: string | null = null;
    public organizationName: string | null = null;
    public provider: string | null = null;
    public isOpen: boolean | null = null;
    public allDebt: boolean | null = null;
    public contractDate: boolean | null = null;

    constructor(item: any) {
        this._setNumber(item);
        this._setAddress(item);
        this._setStreet(item);
        this._setOrganizationName(item);
        this._setProvider(item);
        this._setIsOpen();
        this._setAllDebt(item);
        this._setContractDate(item);
    }

    /**
     *
     * @param number
     * @private
     */


    private _setNumber({number}: any) {
        this.number = number;
    }

    /**
     *
     * @param address
     * @private
     */

    private _setAddress({address}: any) {
        this.address = address;
    }

    /**
     *
     * @param street
     * @private
     */

    private _setStreet({street}: any) {
        this.street = street;
    }

    /**
     *
     * @param organizationName
     * @private
     */

    private _setOrganizationName({organizationName}: any) {
        this.organizationName = organizationName;
    }

    /**
     *
     * @param provider
     * @private
     */

    private _setProvider({provider}: any) {
        this.provider = provider;
    }

    /**
     *
     * @private
     */

    private _setIsOpen() {
        this.isOpen = null;
    }

    /**
     *
     * @param allDebt
     * @private
     */

    private _setAllDebt({allDebt}: any) {
        this.allDebt = allDebt;
    }

    /**
     *
     * @param contractDate
     * @private
     */

    private _setContractDate({contractDate}: any) {
        this.contractDate = contractDate;
    }
}