import {ISearchSelect} from '@/core/layouts/public/types/search-select-structure';
import {CityModel} from '@/pages/search-phone-number-service/models/city.model';

export class CitiesModel {
    public cities: ISearchSelect[] | null = null;

    constructor(item: any) {
        this._setCities(item);

    }

    /**
     *
     * @param item
     * @private
     */

    private _setCities(item: any) {
        this.cities = item.map((item: any) => {
            return new CityModel(item);
        });
    }
}