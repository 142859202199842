export class ServiceHeaderFileModel {
    public id: string | null = null;
    public url: string | null = null;

    constructor(item: any) {
        this._setId(item);
        this._setUrl(item);
        // this._setTitle(item);
    }

    /**
     * set file
     * @param item
     * @private
     */

    private _setUrl(item: any) {
        this.url = item;
    }

    /**
     * set id
     * @param item
     * @private
     */

    private _setId(item: any) {
        this.id = item;
    }
}