export class ModelsManufacturerModel {
    public name: string | null = null;
    public model: string | null = null;
    public cardTypeId: null | number = null;
    public deviceTypeId: null | number = null;
    public price: null | number = null;
    public tariff: null | number = null;

    constructor(item: any) {
        this._setName(item);
        this._setModel(item);
        this._setCardTypeId(item);
        this._setDeviceTypeId(item);
        this._setPrice(item);
        this._setTariff(item);
    }

    /**
     * Set name
     * @param name
     * @private
     */

    private _setName({name}: any): void {
        this.name = name;
    }

    /**
     * Set model
     * @param model
     * @private
     */

    private _setModel({model}: any): void {
        this.model = model;
    }

    /**
     * Set cardTypeId
     * @param cardTypeId
     * @private
     */

    private _setCardTypeId({cardTypeId}: any): void {
        this.cardTypeId = cardTypeId;
    }

    /**
     * Set deviceTypeId
     * @param deviceTypeId
     * @private
     */

    private _setDeviceTypeId({deviceTypeId}: any): void {
        this.deviceTypeId = deviceTypeId;
    }

    /**
     * Set price
     * @param price
     * @private
     */

    private _setPrice({price}: any): void {
        this.price = price;
    }

    /**
     * Set tariff
     * @param tariff
     * @private
     */

    private _setTariff({tariff}: any): void {
        this.tariff = tariff;
    }
}