import {container, injectable} from 'tsyringe';
import {API} from '@/core/configs/api';
import {RadioOrganizationNameModel} from '@/pages/appeal-radio-service/models/radio-organization-name.model';
import {POST} from '@/packages/VHttp/POST';
import {GET} from '@/packages/VHttp/GET';
import {SelectPermissionModel} from '@/pages/permission-movement-service/models/select-permission.model';
import {IPostCreateRedAppeal} from '@/pages/registration-radio-electronic-devices-service/types/post-create-red-appeal';
import {ApplicantInfoModel} from '@/pages/appeal-radio-service/models/applicant-info.model';
import {FileTypeModel} from '@/pages/registration-radio-electronic-devices-service/models/file-type.model';

@injectable()
export class RegistrationRadioElectronicDevicesServiceService {
    private _post: POST = container.resolve(POST);
    private _get: GET = container.resolve(GET);

    getRadioRegistrationOrganizationName(payload: any): Promise<any> {
        return this._get.setApi(API.radioOrganizationName)
            .setHeaders({cName: 'registration-radio-electronic-devices-service'})
            .setParams(payload)
            .requestPromise().then(res => {
                return new RadioOrganizationNameModel(res.data);
            });
    }

    getRadioRegistrationCitizenship(): Promise<any> {
        return this._get.setApi(API.commonCitizenship)
            .setHeaders({cName: 'registration-radio-electronic-devices-service'})
            .requestPromise().then(res => {
                return res.data.items.map((item: any) => {
                    return new SelectPermissionModel(item);
                });
            });
    }

    getRadioRegistrationDocumentSeriesType(payload: number): Promise<any> {
        return this._get.setApi(`${API.commonDocumentSeriesType}/${payload}`)
            .setHeaders({cName: 'registration-radio-electronic-devices-service',})
            .requestPromise().then(res => {
                return res.data.items.map((item: any) => {
                    return new SelectPermissionModel(item);
                });
            });
    }

    getRadioRegistrationOrganPersonType(): Promise<any> {
        return this._get.setApi(API.commonApplicantType)
            .setHeaders({cName: 'registration-radio-electronic-devices-service',})
            .setParams({filterId: 2})
            .requestPromise().then(res => {
                return res.data.items.map((item: any) => {
                    return new SelectPermissionModel(item);
                });
            });
    }

    radioPostCreateRedAppeal(payload: IPostCreateRedAppeal): Promise<any> {
        return this._post.setApi(API.radioCreateRedAppeal)
            .setHeaders({cName: 'registration-radio-electronic-devices-service', Authorization: `Bearer ${localStorage.getItem('mainToken')}`})
            .setParams(payload)
            .requestPromise().then(res => {
                return res;
            });
    }

    postApplicantRegistration(payload: any): Promise<any> {
        return this._post.setApi(API.radioApplicant)
            .setHeaders({cName: 'registration-radio-electronic-devices-service', Authorization: `Bearer ${localStorage.getItem('mainToken')}`})
            .setPayload(payload)
            .requestPromise().then(res => {
                return new ApplicantInfoModel(res.data);
            });
    }

    postRedCreateAppeal(payload: any): Promise<any> {
        return this._post.setApi(API.redCreateAppeal)
            .setHeaders({cName: 'registration-radio-electronic-devices-service', Authorization: `Bearer ${localStorage.getItem('mainToken')}`})
            .setPayload(payload)
            .requestPromise().then(res => {
                return res;
            });
    }

    getFileTypesKeyValues(): Promise<any> {
        return this._get.setApi(API.redFileTypesKeyValues)
            .setHeaders({cName: 'registration-radio-electronic-devices-service', Authorization: `Bearer ${localStorage.getItem('mainToken')}`})
            .requestPromise().then(res => {
                let data = res.data.redAppealFileTypes.map((item: any) => {
                    return new FileTypeModel(item);
                });
                let thirdElement = data[data.length-3];
                let secondElement = data[data.length-2];
                let firstElement = data[data.length-1]
                data[data.length-1] = thirdElement;
                data[data.length-2] = firstElement;
                data[data.length-3] = secondElement;
                return data;
            });
    }

    getAppealTechnologyKeyValues(): Promise<any> {
        return this._get.setApi(API.redAppealTechnologyKeyValues)
            .setHeaders({cName: 'registration-radio-electronic-devices-service', Authorization: `Bearer ${localStorage.getItem('mainToken')}`})
            .requestPromise().then(res => {
                return res.data.items.map((item: any) => {
                    return new SelectPermissionModel(item);
                });
            });
    }
}