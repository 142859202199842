import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {locale} from '@/assets/db/db.conf.ts';

@Options({
    name: 'box-component',
    props: {
        title: {
            type: String,
            required: false,
        },
        icon: {
            type: String,
            required: true, // image name
        },
        direction: {
            type: String,
            default: 'row', // column
        },
        marginLeft: {
            type: String,
            default: '16', // 20
        },

        staticIcon: {
            type: Boolean,
            default: false,
        },

        align: {
            type: String,
            default: 'start'
        },

        titleSize: {
            type: [Number, String],
            default: 14,
        },

        descSize: {
            type: [Number, String],
            default: 14,
        },

        boldTitle: {
            type: Boolean,
            default: false
        },

        boldDesc: {
            type: Boolean,
            default: false
        },

        descClass: {
            type: String,
            default: ''
        },
        registration: {
            type: Boolean,
            default: false
        },
    }
})

export default class BoxComponent extends Vue {
    public locale$: any = locale;
    public locale: any = null;

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.elements) {
                this.locale = res.elements;
            }
        });
    }
}
