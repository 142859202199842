import {IImeiTariffServiceState} from '@/pages/imei-tariff-service/store/state';
import {IManufacturer} from '@/pages/imei-tariff-service/types/manufacturer';
import {IModelsManufacturer} from '@/pages/imei-tariff-service/types/models-manufacturer';
import {IMarketTariff} from '@/pages/imei-tariff-service/types/market-tariff';
import {IMarketTariffsInfo} from '@/pages/imei-tariff-service/types/market-tariffs-info';

export const ImeiTariffServiceStateGetters = {
    _getManufacture: (state: IImeiTariffServiceState): IManufacturer[] => {
        return state.manufacturer;
    },
    _getModelsByManufacturer: (state: IImeiTariffServiceState): IModelsManufacturer[] => {
        return state.modelsByManufacturer;
    },
    _getModelsByManufacturerTotalCount: (state: IImeiTariffServiceState): number => {
        return state.modelsByManufacturerTotalCount;
    },
    _getMarketTariffs: (state: IImeiTariffServiceState): IMarketTariff[] => {
        return state.marketTariffs;
    },
    _getMarketTariffsInfo: (state: IImeiTariffServiceState): IMarketTariffsInfo[] => {
        return state.marketTariffsInfo;
    },
};
