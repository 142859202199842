<style lang="scss" src="./search.component.scss"></style>

<template>
  <div
      :class="{'search-active':isAdvanced,
      'search-active-example': (searchExample.length && checkFocus),
      'margin-home':isHome
  }"
      @click.self="closeAdvanced"

      class="search ">
    <div class="search__input mb-20">
      <input
          v-model="searchValue"
          :placeholder="locale.searchPlaceholder"
          class="v-input"
          type="text"
          @blur="unFocus"
          @focus="checkFocus = true"
          @keyup.enter="searchInput"
          @input="searchFilter">
      <button class="search__button" @click="searchInput">
        <img src="@/assets/images/icons/search.svg" alt="search">
      </button>
      <div
          @click="isAdvanced =!isAdvanced"
          class="search__advanced-icon">
        <img src="@/assets/images/icons/filter.svg" alt="filter">
       <span class="d-none d-md-block">{{ locale.advancedSearch }}</span>
      </div>
    </div>
    <search-example-component
        :examples="searchExample"
        :inputFocus="checkFocus"
    />
    <search-tag-component :tags="tags"/>
    <transition
        name="fade-opacity"
        :duration="500"
    >
      <div>
        <search-advanced-component
            v-if="isAdvanced"
            @filterSearch="detailedSearch"/>
      </div>

    </transition>
  </div>
</template>

<script src="./search.component.ts"></script>
