import {OrganisationContactModel} from '@/pages/organisations/models/organisation-contact.model';
import {IOrganisationContact} from '@/pages/organisations/types/organisation-contact';
import {OrganisationServiceModel} from '@/pages/organisations/models/organisation-service.model';

export class OrganisationDetailsModel {
    public id: number | null = null;
    public icon: string | null = null;
    public description: string | null = null;
    public url: string | null = null;
    public address: string | null = null;
    public title: string | null = null;
    public contacts: IOrganisationContact[] | null = null;
    public location: string | null = null;
    public serviceCount: number | null = null;
    public services: OrganisationServiceModel[] | null = null;

    constructor(item: any) {
        this._setId(item);
        this._setIcon(item);
        this._setTitle(item);
        this._setDescription(item);
        this._setUrl(item);
        this._setAddress(item);
        this._setContacts(item);
        this._setLocation(item);
        this._setServiceCount(item);
        this._setServices(item);
    }

    /**
     * Set Id
     * @param ID
     * @private
     */
    private _setId({id}: any) {
        this.id = id;
    }

    /**
     * Set icon
     * @param file
     * @private
     */
    private _setIcon({file}: any) {
        this.icon = file.fileUrl;
    }

    /**
     * Set title
     * @param structureLanguages
     * @private
     */
    private _setTitle({structureLanguages}: any) {
        this.title = structureLanguages[0]?.name;
    }

    /**
     * Set description
     * @param structureLanguages
     * @private
     */
    private _setDescription({structureLanguages}: any) {
        this.description = structureLanguages[0]?.description;
    }

    /**
     * Set url
     * @param structureLanguages
     * @private
     */
    private _setUrl({structureLanguages}: any) {
        this.url = structureLanguages[0]?.url;
    }

    /**
     * Set address
     * @param structureLanguages
     * @private
     */
    private _setAddress({structureLanguages}: any) {
        this.address = structureLanguages[0]?.address;
    }

    /**
     * set contacts
     * @param structureContacts
     * @private
     */
    private _setContacts({structureContacts}: any) {
        this.contacts = structureContacts.map((i: any)=>{
            return new OrganisationContactModel(i);
        });
    }

    /**
     * set location
     * @param locationURL
     * @private
     */
    private _setLocation({locationURL}: any) {
        this.location = locationURL;
    }

    /**
     * set services count
     * @param serviceStructuresCount
     * @private
     */
    private _setServiceCount({serviceStructuresCount}: any) {
        this.serviceCount = serviceStructuresCount;
    }

    /**
     * set services
     * @param serviceStructures
     * @private
     */
    private _setServices({serviceStructures}: any) {
        let serviceArr: OrganisationServiceModel[] | null = [];
        serviceStructures.forEach((item: any)=>{
            if (item.service) {
                // @ts-ignore
                serviceArr.push(new OrganisationServiceModel(item.service))
            }
        })
        this.services = serviceArr;
    }
}

