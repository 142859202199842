import {IIssuanceSpecialPermitServiceState} from './state';
import {IssuanceSpecialPermitServiceStateGetters} from './getters';
import {IssuanceSpecialPermitServiceStateMutations} from './mutations';
import {IssuanceSpecialPermitServiceStateActions} from './actions';

const IssuanceSpecialPermitServiceStateInitial: IIssuanceSpecialPermitServiceState = {
    applicantForm: {
        documentNumber: null,
        documentTypeNumber: null,
        fin: null,
        phone: '+994(',
        email: null,
        tin: null,
        organizationName: null,
        position: null,
        registration: null,
        authorityFile: null,
        legalEntityFullName: null,
        legalEntityAddress: null,
        positionLegalEntity: null,
        copyExtractFile: null,
        poweroftheattorneydoc: null,
        govregistercopy: null,
        addressLegalEntity: null,
        legalPersonPosition: null,
        address: null
    },
    files: [],
    options: {
        typeEntrepreneurialActivity: null,
        restrictedCivilCirculation: null,
        requiredCrucialData:null,
        commonInformation: [{
            name: null,
            factorySerialNumber: null
        }],
        signFile: null,
        singFileUrl: null,
        singFileName: null,
        generateFile: null
    },
    filesOptions: [],
    restrictedCirculationItems: [],
    requiredCrucialData: []

};

export const IssuanceSpecialPermitServiceState = {
    state: IssuanceSpecialPermitServiceStateInitial,
    getters: IssuanceSpecialPermitServiceStateGetters,
    mutations: IssuanceSpecialPermitServiceStateMutations,
    actions: IssuanceSpecialPermitServiceStateActions

};
