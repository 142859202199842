import {ISearchSelect} from '@/core/layouts/public/types/search-select-structure';

export class IspAppealModel {
    public applicantId: number | null = null;
    public id: number | null = null;
    public juridicalAddress: string | null = null;
    public activityAddress: string | null = null;
    public ownerApplicantSame: boolean | null = null;
    public juridicalPersonFullName: string | null = null;
    public applicantActivityAddress: string | null = null;
    public applicantInternetAddress: string | null = null;
    public applicantTypeActivities: ISearchSelect[] | null = null;
    public applicantTypeActivitiesText: string | null = null;
    public applicantTypesTelecommunicationsService: ISearchSelect[] | null = null;
    public applicantScopeActivity: ISearchSelect[] | null = null;
    public applicantScopeActivityText: string | null = null;
    public iPvFour: string | null = null;
    public iPvSix: string | null = null;
    public serviceDate: Date | null = null;
    public asNumber: string | null = null;
    public applicantPhoneNumber: string | null = null;
    public applicantFaxNumber: string | null = null;
    public applicantEmail: string | null = null;
    public position: string | null = null;
    public appealStatusId: number | null = null;
    public appealStatusName: string | null = null;
    public rejectionReason: string | null = null;
    public respondentPin: string | null = null;
    public answerFile: File | null = null;
    public signFile: { url: string } | null = null;
    public cancelFile: File | null = null;
    public cancelFileAnswer: File | null = null;
    public disabled: boolean | null = true;
    public applicantTypesTelecommunicationsServiceText: string | null = null;
    public organizationName: string | null = null;
    public tin: string | null = null;
    public edit: boolean | null = false;
    public new: boolean | null = false;

    constructor(data: any) {
        this._setApplicantScopeActivityText(data);
        this._setId(data);
        this._setJuridicalAddress(data);
        this._setActivityAddress(data);
        this._setOwnerApplicantSame(data);
        this._setPosition(data);
        this._setJuridicalPersonFullName(data);
        this._setApplicantActivityAddress(data);
        this._setApplicantInternetAddress(data);
        this._setApplicantTypeActivities(data);
        this._setApplicantTypesTelecommunicationsService(data);
        this._setApplicantTypesTelecommunicationsServiceText(data);
        this._setApplicantScopeActivity(data);
        this._setIPvFour(data);
        this._setIPvSix(data);
        this._setServiceDate(data);
        this._setAsNumber(data);
        this._setApplicantPhoneNumber(data);
        this._setApplicantFaxNumber(data);
        this._setApplicantEmail(data);
        this._setApplicantEmail(data);
        this._setSignFile(data);
        this._setAnswerFile(data);
        this._setCancelFile(data);
        this._setRespondentPin(data);
        this._setAppealStatusId(data);
        this._setAppealStatusName(data);
        this._setRejectionReason(data);
        this._setRespondentPin(data);
        this._setApplicantId(data);
        this._setApplicantTypeActivitiesText(data);
        this._setOrganizationName(data);
    }

    private _setId(data: any) {
        if (data?.id) {
            this.id = data?.id;
        }
    }

    private _setApplicantId({applicantId}: any) {
        this.applicantId = applicantId;
    }

    private _setJuridicalAddress({juridicalAddress}: any) {
        this.juridicalAddress = juridicalAddress;
    }

    private _setActivityAddress({activityAddressName}: any) {
        this.activityAddress = activityAddressName;
    }

    private _setOwnerApplicantSame({juridicalPersonFullName}: any) {
        juridicalPersonFullName ? this.ownerApplicantSame = false : this.ownerApplicantSame = true;
    }

    private _setJuridicalPersonFullName({juridicalPersonFullName}: any) {
        this.juridicalPersonFullName = juridicalPersonFullName;
    }

    private _setApplicantActivityAddress({applicantActivityAddress}: any) {
        this.applicantActivityAddress = applicantActivityAddress;
    }

    private _setApplicantInternetAddress({internetResourceLink}: any) {
        this.applicantInternetAddress = internetResourceLink;
    }

    private _setApplicantTypeActivities({activityTypes}: any) {
        this.applicantTypeActivities = activityTypes.map((item: any) => {
            return item.id;
        });
    }

    private _setApplicantTypesTelecommunicationsService({internetTelecommunicationsServiceTypes}: any) {
        this.applicantTypesTelecommunicationsService = internetTelecommunicationsServiceTypes.map((item: any) => {
            return item.id;
        });
    }

    private _setApplicantScopeActivity(data: any) {
        if (data?.id) {
            this.applicantScopeActivity = data.activityScopes.map((item: any) => {
                return item.id;
            });
        }
    }

    private _setIPvFour({iPvFour}: any) {
        this.iPvFour = iPvFour;
    }

    private _setIPvSix({iPvSix}: any) {
        this.iPvSix = iPvSix;
    }

    private _setServiceDate({serviceDate}: any) {
        this.serviceDate = new Date(serviceDate);
    }

    private _setAsNumber({asNumber}: any) {
        this.asNumber = asNumber;
    }

    private _setApplicantPhoneNumber({applicantPhoneNumber}: any) {
        this.applicantPhoneNumber = applicantPhoneNumber;
    }

    private _setApplicantFaxNumber({applicantFaxNumber}: any) {
        this.applicantFaxNumber = applicantFaxNumber;
    }

    private _setApplicantEmail({applicantMailAddress}: any) {
        this.applicantEmail = applicantMailAddress;
    }

    private _setPosition({position}: any) {
        this.position = position;
    }

    private _setAppealStatusId({appealStatusId}: any) {
        this.appealStatusId = appealStatusId;
    }

    private _setAppealStatusName({appealStatusName}: any) {
        this.appealStatusName = appealStatusName;
    }

    private _setRejectionReason({rejectionReason}: any) {
        this.rejectionReason = rejectionReason;
    }

    private _setRespondentPin({respondentPin}: any) {
        this.respondentPin = respondentPin;
    }


    private _setAnswerFile({files}: any) {
        this.answerFile = this.filterFiles(files, 2)[0];
    }

    private _setSignFile({files}: any) {
        this.signFile = {
            url: this.filterFiles(files, 1)[0].imageUrl
        };
    }

    private _setCancelFile({files}: any) {
        this.cancelFile = this.filterFiles(files, 3)[0];
    }

    public filterFiles(files: any[], filter: number) {
        return files.filter((item: any) => {
            if (item.appealFileTypeId === filter) {
                return {
                    name: item.name,
                    url: item.imageUrl
                };
            }
        });
    }

    private _setApplicantTypeActivitiesText({activityTypes}: any) {
        let text = '';
        activityTypes.forEach((item: any) => {
            text = `${text}, ${item.name}`;
        });
        this.applicantTypeActivitiesText = text.substr(1, text.length);
    }

    private _setApplicantTypesTelecommunicationsServiceText({internetTelecommunicationsServiceTypes}: any) {
        let text = '';
        internetTelecommunicationsServiceTypes.forEach((item: any) => {
            text = `${text}, ${item.name}`;
        });
        this.applicantTypesTelecommunicationsServiceText = text.substr(1, text.length);
    }

    private _setApplicantScopeActivityText({activityScopes}: any) {
        if (activityScopes) {
            let text = '';
            activityScopes?.forEach((item: any) => {
                text = `${text}, ${item.name}`;
            });
            this.applicantScopeActivityText = text.substr(1, text.length);
        }
    }

    private _setOrganizationName({applicant}: any) {
        this.organizationName = applicant.organizationName;
        this.tin = applicant.taxIdNumber;
    }
}
