import {container, injectable} from 'tsyringe';
import {GET} from '@/packages/VHttp/GET';
import {API} from '@/core/configs/api';
import {GetStatusMessageModel} from '@/pages/imei-check-service/models/get-status-message.model';

@injectable()
export class ImeiCheckServiceService {
    private _get: GET = container.resolve(GET);

    constructor() {
    }

    getStatusMessage(payload: any): Promise<any> {
        return this._get.setApi(API.getStatusMessage)
            .setParams({
                imei: payload.imei
            })
            .setHeaders({cName: 'imei-check-service', Authorization: `Bearer ${payload.token}`},)
            .requestPromise().then(res => {
                return new GetStatusMessageModel(res.data);
            });
    }


}