import {IAuthState} from './state';
import {AuthStateGetters} from './getters';
import {AuthStateMutations} from './mutations';
import {AuthStateActions} from './actions';

const AuthStateInitial: IAuthState = {
    user: {
        name: '',
        surname: '',
        fatherName: '',
        pin: '',
        birthDate: '',
        image: '',
        address: '',
        birthAddress: null,
        document: '',
        documentType: '',
        documentNumber: '',
        citizenshipId: null,
        dateOfIssue: '',
        registeredDepartmentName: '',
        loginType: '',
        certificateNumber: '',
        tin: '',
        structureName: '',
        entity: 2,

    },
    token: ''
};
export const AuthState = {
    state: AuthStateInitial,
    getters: AuthStateGetters,
    mutations: AuthStateMutations,
    actions: AuthStateActions,
};
