import {IFeedbackPost} from '@/core/shared/feadback/types/feedback-post';

export class FeedbackPostModel {
    public fullName: string | null = null;
    public phone: string | null = null;
    public contentText: string | null = null;
    public feedbackFormId: number | null = null;
    public feedbackTypeId: number | null = null;
    public serviceId: number | null = null;
    public structureId: number | null = null;
    public SecretKey: string | null = null;
    public email: string | null = null;


    constructor(item: IFeedbackPost) {
        this._setFullName(item);
        this._setPhone(item);
        this._setContentText(item);
        this._setFeedbackFormId(item);
        this._setFeedbackTypeId(item);
        this._setServiceId(item);
        this._setStructureId(item);
        this._setToken(item);
        this._setEmail(item);
    }


    private _setFullName({fullname}: IFeedbackPost) {
        this.fullName = fullname;
    }


    private _setPhone({phone}: IFeedbackPost) {
        this.phone = phone;
    }


    private _setContentText({content}: IFeedbackPost) {
        this.contentText = content;
    }


    private _setFeedbackFormId({appealType}: IFeedbackPost) {
        this.feedbackFormId = appealType;
    }


    private _setFeedbackTypeId({direction}: IFeedbackPost) {
        this.feedbackTypeId = direction;
    }


    private _setServiceId({service}: IFeedbackPost) {
        this.serviceId = service;
    }


    private _setStructureId({organisation}: IFeedbackPost) {
        this.structureId = organisation;
    }

    private _setToken({SecretKey}: IFeedbackPost) {
        this.SecretKey = SecretKey;
    }

    private _setEmail({email}: IFeedbackPost) {
        this.email = email;
    }
}
