import {IInputPayload} from '@/types/input-payload';
import {IFileType} from '@/pages/issuing-certificate-conformity-service/types/file-type';
import {IIssuanceSpecialPermitServiceState} from '@/pages/issuance-special-permit-service/store/state';
import {container} from 'tsyringe';
import {
    IssuingCertificateConformityServiceService
} from '@/pages/issuing-certificate-conformity-service/services/issuing-certificate-conformity-service.service';
import {ISearchSelect} from '@/core/layouts/public/types/search-select-structure';

const service = container.resolve(IssuingCertificateConformityServiceService);
export const IssuanceSpecialPermitServiceStateMutations = {
    SET_APPLICANT_SPECIAL_PERMIT(state: IIssuanceSpecialPermitServiceState, payload: IInputPayload) {
        state.applicantForm[`${payload.type}`] = payload.value;
    },
    SET_APPLICANT_SPECIAL_PERMIT_RESET(state: IIssuanceSpecialPermitServiceState, payload: IInputPayload) {
        state.applicantForm = {
            documentNumber: null,
            documentTypeNumber: null,
            fin: null,
            phone: '+994(',
            email: null,
            tin: null,
            organizationName: null,
            position: null,
            registration: null,
            authorityFile: null,
            legalEntityFullName: null,
            legalEntityAddress: null,
            positionLegalEntity: null,
            copyExtractFile: null,
            poweroftheattorneydoc: null,
            govregistercopy: null,
            addressLegalEntity: null,
            legalPersonPosition: null,
            address: null
        };
    },
    SET_OPTIONS(state: IIssuanceSpecialPermitServiceState, payload: IInputPayload) {
        state.options[`${payload.type}`] = payload.value;

    },
    SET_OPTIONS_RESET(state: IIssuanceSpecialPermitServiceState, payload: IInputPayload) {
        state.options = {
            typeEntrepreneurialActivity: null,
            restrictedCivilCirculation: null,
            requiredCrucialData:null,
            commonInformation: [{
                name: null,
                factorySerialNumber: null
            }],
            signFile: null,
            singFileUrl: null,
            singFileName: null,
            generateFile: null
        };
    },
    SET_OPTIONS_COMMON_INFO(state: IIssuanceSpecialPermitServiceState, payload: { index: number, type: string, value: string }) {
        state.options.commonInformation[payload.index][payload.type] = payload.type;
    },
    SET_PERMIT_FILES(state: IIssuanceSpecialPermitServiceState, payload: IFileType[]) {
        state.files = payload;
    },
    SET_OPTIONS_FILES(state: IIssuanceSpecialPermitServiceState, payload: IFileType[]) {
        state.filesOptions = payload;
    },

    SET_REQUIRED_CRUCIAL_DATA_SP(state: IIssuanceSpecialPermitServiceState, payload: ISearchSelect[]) {
        state.requiredCrucialData = payload;
    },
    SET_RESTRICTED_CIRCULATION_ITEM_SP(state: IIssuanceSpecialPermitServiceState, payload: ISearchSelect[]) {
        state.restrictedCirculationItems = payload;
    },

};
