import {IPermissionMovementServiceState} from '@/pages/permission-movement-service/store/state';

export const PermissionMovementServiceStateGetters = {

    _getPersonalInformation: (state: IPermissionMovementServiceState) => {
        return state.personalInformation;
    },
    _getUserData_Aze: (state: IPermissionMovementServiceState) => {
        return state.userDataAze;
    },

    _getAppealId: (state: IPermissionMovementServiceState) => {
        return state.appealId;
    },

    _getDocuments: (state: IPermissionMovementServiceState) => {
        return state.documents;
    },

    _getVehicleParameters: (state: IPermissionMovementServiceState) => {
        return state.vehicleParameters;
    },

    _getVehicleParametersLoad: (state: IPermissionMovementServiceState) => {
        return state.vehicleParametersLoad;
    },

    _getTrailerVehicleType: (state: IPermissionMovementServiceState) => {
        return state.trailerVehicleType;
    },
};