export class FeedbackAnswerModel {
    public feedbackTypeName: string | null = null;
    public feedbackService: string | null = null;
    public feedbackStructure: string | null = null;
    public date: string | null = null;
    public mobileNumber: string | null = null;
    public email: string | null = null;
    public text: string | null = null;
    public answerText: string | null = null;
    public answerFile: string | null = null;
    public answerFileName: string | null = null;

    constructor(item: any) {
        this._setFeedbackTypeName(item);
        this._setFeedbackService(item);
        this._setFeedbackStructure(item);
        this._setDate(item);
        this._setMobileNumber(item);
        this._setEmail(item);
        this._setText(item);
        this._setAnswerText(item);
        this._setAnswerFile(item);
        this._setAnswerFileName(item);

    }


    private _setFeedbackTypeName({feedbackForm}: any): void {
        if (feedbackForm) {
            this.feedbackTypeName = feedbackForm.feedbackFormLanguages[0]?.name;
        }
    }

    private _setFeedbackStructure({structure}: any): void {
        if (structure) {
            this.feedbackStructure = structure.structureLanguages[0]?.name;
        }
    }

    private _setFeedbackService({service}: any): void {
        if (service) {
            this.feedbackService = service.serviceLanguages[0]?.name;
        }
    }

    private _setDate({createdDate}: any): void {
        if (createdDate) {
            this.date = `${new Date(createdDate).getDay()}.${new Date(createdDate).getMonth()}.${new Date(createdDate).getFullYear()}  ${new Date(createdDate).getHours()}:${new Date(createdDate).getMinutes()}`;
        }
    }

    private _setMobileNumber({phone}: any): void {
        this.mobileNumber = phone;
    }

    private _setEmail({email}: any): void {
        if (email) {
            this.email = email;
        }
    }

    private _setText({contentText}: any): void {
        this.text = contentText;
    }

    private _setAnswerText({feedbackResponse}: any) {
        if (feedbackResponse) {
            this.answerText = feedbackResponse?.text;
        }
    }

    private _setAnswerFile({feedbackResponse}: any) {
        if (feedbackResponse) {
            this.answerFile = feedbackResponse?.file?.fileUrl;
        }
    }

    private _setAnswerFileName({feedbackResponse}: any) {
        if(feedbackResponse) {
            this.answerFileName = feedbackResponse?.file?.name
        }

    }
}
