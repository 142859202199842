export class FooterDataModel {
    public description: string | null = null;
    public name: string | null = null;
    public id: number | null = null;

    constructor(item: any) {
        this._setId(item);
        this._setName(item);
        this._setDescription(item);
       }

    /**
     * Set Id
     * @param ID
     * @private
     */
    private _setId({id}: any) {
        this.id = id;
    }
    private _setName({contentLanguages}: any) {
        this.name = contentLanguages[0]?.title;
    }

    /**
     * Set description
     * @param contentLanguages
     * @private
     */
    private _setDescription({contentLanguages}: any) {
        this.description = contentLanguages[0]?.contentHTML;
    }
}
