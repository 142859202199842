import {container, injectable} from 'tsyringe';
import {API} from '@/core/configs/api';
import {GET} from '@/packages/VHttp/GET';
import {TotalElectronOrderModel} from '@/pages/information-electronic-orders-service/models/total-electron-order.model';

@injectable()
export class InformationElectronicOrdersServiceService {
    private _get: GET = container.resolve(GET);

    constructor() {
    }

    getElectronOrders(payload: any): Promise<any> {
        return this._get.setApi(API.electronOrders)
            .setHeaders({cName: 'information-electronic-orders-service', Authorization: `Bearer ${payload.token}`})
            .setParams({
                docNumber: payload.docNumber,
                docSerial: payload.docSerial,
            })
            .requestPromise().then(res => {
                return new TotalElectronOrderModel(res.data);
            });
    }
}
