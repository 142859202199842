import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "statistics py-20" }
const _hoisted_2 = { class: "container-fluid v-container statistics-data" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "statistics__list d-flex" }
const _hoisted_5 = { class: "statistics__list__common" }
const _hoisted_6 = { class: "statistics__list__icon" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "statistics__list__info mr-10 ml-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("ul", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.statisticsData, (item, index) => {
            return (_openBlock(), _createElementBlock("li", {
              key: index,
              class: "mt-20 mb-20"
            }, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("img", {
                    src: require(`@/assets/images/icons/${item.icon}`),
                    alt: ""
                  }, null, 8, _hoisted_7)
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", null, _toDisplayString(item.count), 1),
                  _createElementVNode("span", null, _toDisplayString(item.subData), 1)
                ])
              ])
            ]))
          }), 128))
        ])
      ])
    ])
  ]))
}