import {ISearchPhoneNumberServiceState} from '@/pages/search-phone-number-service/store/state';
import {ISearchSelect} from '@/core/layouts/public/types/search-select-structure';
import {ISearchNumberResult} from '@/pages/search-phone-number-service/types/search-number-result';

export const SearchPhoneNumberServiceStateGetters = {
    getCities: (state: ISearchPhoneNumberServiceState): ISearchSelect[] | null => {
        return state.cities;
    },
    getInformation: (state: ISearchPhoneNumberServiceState): ISearchNumberResult | null => {
        return state.searchNumber;
    },
};
