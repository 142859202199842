import {ServiceHeaderModel} from '@/models/service-header.model';
import {IServiceHeader} from '@/types/service-header';

export class ServiceCommonModel {
    public id: number | null = null;
    public authRequired: boolean | null = null;
    public paymentRequired: boolean | null = null;
    public file: string | null = null;
    public name: string | null = null;
    public structureId: number | null = null;
    public structureName: string | null = null;
    public serviceHeader: IServiceHeader[] = [];

    constructor(item: any) {
        this._setId(item);
        this._setAuthRequired(item);
        this._setPaymentRequired(item);
        this._setFile(item);
        this._setName(item);
        this._setStructureId(item);
        this._setStructureName(item);
        this._setServiceHeader(item);
    }

    /**
     * Set Id
     * @param ID
     * @private
     */
    private _setId({id}: any): void {
        this.id = id;
    }

    /**
     * Set authRequired
     * @param AuthRequired
     * @private
     */

    private _setAuthRequired({authRequired}: any): void {
        this.authRequired = authRequired;
    }

    /**
     * Set paymentRequired
     * @param paymentRequired
     * @private
     */

    private _setPaymentRequired({paymentRequired}: any): void {
        this.paymentRequired = paymentRequired;
    }

    /**
     * Set File
     * @param file
     * @private
     */

    private _setFile({file}: any): void {
        this.file = file?.fileUrl;
    }

    /**
     * Set name
     * @param serviceLanguages
     * @private
     */

    private _setName({serviceLanguages}: any): void {
        this.name = serviceLanguages[0]?.name;
    }

    /**
     * Set structureId
     * @param serviceStructures
     * @private
     */

    private _setStructureId({serviceStructures}: any): void {
        this.structureId = serviceStructures[0]?.structure.id;
    }

    /**
     * Set structureName
     * @param serviceStructures
     * @private
     */

    private _setStructureName({serviceStructures}: any): void {
        this.structureName = serviceStructures[0]?.structure?.structureLanguages[0]?.name;
    }

    private _setServiceHeader({headers}: any) {
        this.serviceHeader = headers.map((item: any) => {
            return new ServiceHeaderModel(item);

        });
    }
}