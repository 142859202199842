import {IRecordKeepingState} from '@/pages/record-keeping-service/store/state';
import {PayloadForm} from '@/pages/permission-movement-service/types/payload-form';
import {ISearchSelect} from '@/core/layouts/public/types/search-select-structure';
import {IUserForm} from '@/pages/record-keeping-service/types/user-form';

export const RecordKeepingStateMutations = {

    SET_IN_USER_FORM(state: IRecordKeepingState, payload: PayloadForm): void {
        state.userForm[`${payload.type}`] = payload.value;
    },
    SET_RESET_USER_FORM(state: IRecordKeepingState): void {
        state.userForm = {
            new:false,
            applicantId: null,
            id: null,
            juridicalAddress: null,
            activityAddress: null,
            ownerApplicantSame: null,
            juridicalPersonFullName: null,
            position: null,
            applicantActivityAddress: null,
            applicantInternetAddress: null,
            applicantTypeActivities: null,
            applicantTypesTelecommunicationsService: null,
            applicantScopeActivity: null,
            iPvFour: null,
            iPvSix: null,
            serviceDate: new Date(),
            asNumber: null,
            applicantPhoneNumber: '+994(',
            applicantFaxNumber: '+994(',
            applicantEmail: null,
            applicantAutonomousSystem: null,
            docTreaty: null,
            docDocumentAuthority: null,
            docCopyCharter: null,
            dutyNaturalPerson: null,
            rejectionReason: null,
            respondentPin: null,
            appealStatusId:  state.userForm.appealStatusId,
            appealStatusName: null,
            signFile: null,
            answerFile: null,
            cancelFile: null,
            disabled: false,
            edit:false,
            signFileLink:null,
            sendSignFile:null
        };
    },

    SET_ACTIVITY_ADDRESSES(state: IRecordKeepingState, payload: ISearchSelect[]): void {
        state.applicantActivityAddress = payload;
    },

    SET_ACTIVITY_SCOPES(state: IRecordKeepingState, payload: ISearchSelect[]): void {
        state.applicantScopeActivity = payload;
    },

    SET_ACTIVITY_TYPES(state: IRecordKeepingState, payload: ISearchSelect[]): void {
        state.applicantTypeActivities = payload;
    },

    SET_INTERNET_TELECOMMUNICATIONS_SERVICE_TYPES(state: IRecordKeepingState, payload: ISearchSelect[]): void {
        state.applicantTypesTelecommunicationsService = payload;
    },
    SET_APPEAL_ISP(state: IRecordKeepingState, payload: any): void {
        if (payload!==null) {
            state.userForm = payload
        }
    },


};
