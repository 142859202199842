import {IMonthlyCallsInformationServiceState} from '@/pages/monthly-calls-information-service/store/state';
import {ICurrentReceipt} from '@/pages/monthly-calls-information-service/types/current-receipt';


export const MonthlyCallsInformationServiceStateMutations = {
    SET_CURRENT_RECEIPT(state: IMonthlyCallsInformationServiceState, payload: ICurrentReceipt): void {
        state.calls = payload.calls;
        state.generalInformation = payload.generalInformation;
        state.totalCountCalls = payload.totalCountCalls;
    },
};
