import {container} from 'tsyringe';
import {ImeiCheckServiceService} from '@/pages/imei-check-service/services/imei-check-service.service';

const service = container.resolve(ImeiCheckServiceService);

export const ImeiCheckServiceStateActions = {
    getStatusMessage(context: any, payload: any): void {
        service.getStatusMessage(payload).then(res => context.commit('SET_STATUS_MESSAGE', res));
    },
};
