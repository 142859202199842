import {container, injectable} from 'tsyringe';
import {GET} from '@/packages/VHttp/GET';
import {API} from '@/core/configs/api';
import {ServiceCommonModel} from '@/models/service-common.model';
import {ServiceHeaderModel} from '@/models/service-header.model';
import {GetNumberModel} from '@/pages/local-talks-service/models/get-number.model';
import {IPayloadNumber} from '@/types/payload-number';
import {SelectPermissionModel} from '@/pages/permission-movement-service/models/select-permission.model';
import {ApplicantDataModel} from '@/models/applicantData.model';
import {ApplicantCertModel} from '@/pages/issuing-certificate-conformity-service/models/applicant-cert.model';
import {UserDetailsModel} from "@/pages/user-account/models/user-details.model";
import {PUT} from "@/packages/VHttp/PUT";
import jwt_decode from "jwt-decode";
import {AuthenticationModel} from "@/core/auth/authentication.model";
import swal from "sweetalert2";

@injectable()
export class CommonService {
    private _get: GET = container.resolve(GET);
    private _put: PUT = container.resolve(PUT);

    constructor() {
    }

    getApplicantData(payload: { componentName: string, data: any }): Promise<any> {
        return this._get.setApi(API.commonApplicantInfo)
            .setHeaders({
                cName: payload.componentName,
                Authorization: `Bearer ${localStorage.getItem('mainToken')}`
            })
            .setParams(payload.data)
            .requestPromise().then(res => {
                return new ApplicantCertModel(res.data);
            });
    }

    getCommonDocumentSeriesType(payload: number): Promise<any> {
        return this._get.setApi(`${API.commonDocumentSeriesType}/${payload}`)
            .requestPromise().then(res => {
                return res.data.items.map((item: any) => {
                    return new SelectPermissionModel(item);
                });
            });
    }

    getServiceName(payload: { componentName: string, token: string | null }): Promise<any> {
        return this._get.setApi(`${API.serviceName}`)
            .setHeaders({cName: payload.componentName, Authorization: `Bearer ${payload.token}`})
            .requestPromise().then(res => {
                return new ServiceCommonModel(res.data.service);
            });
    }

    getServiceHeader(payload: string): Promise<any> {
        return this._get.setApi(`${API.serviceHeader}`)
            .setHeaders({cName: payload})
            .requestPromise().then(res => {
                return res.data.serviceHeader.items.map((item: any) => {
                    return new ServiceHeaderModel(item);
                });
            });
    }

    getNumbers({service, token}: IPayloadNumber): Promise<any> {
        return this._get.setApi(API.numberList)
            .setHeaders({cName: service, Authorization: `Bearer ${token}`})
            .requestPromise().then(res => {
                return new GetNumberModel(res.data);
            });
    }

    getCitizenship(): Promise<any> {
        return this._get.setApi(API.getCitizenship)
            .requestPromise().then(res => {
                return res.data.map((item: any) => {
                    return new SelectPermissionModel(item);
                });
            });
    }

    getCommonCitizenship(): Promise<any> {
        return this._get.setApi(API.commonCitizenship)
            .requestPromise().then(res => {
                return res.data.items.map((item: any) => {
                    return new SelectPermissionModel(item);
                });
            });
    }

    getDocumentSeriesType(): Promise<any> {
        return this._get.setApi(API.getDocumentSeriesType)
            .requestPromise().then(res => {
                return res.data.map((item: any) => {
                    return new SelectPermissionModel(item);
                });
            });
    }

    getCommonOrganPersonType(payload: number): Promise<any> {
        return this._get.setApi(API.commonApplicantType)
            .setParams({filterId: payload})
            .requestPromise().then(res => {
                return res.data.items.map((item: any) => {
                    return new SelectPermissionModel(item);
                });
            });
    }

    getOrganPersonType(): Promise<any> {
        return this._get.setApi(API.organPersonType)
            .requestPromise().then(res => {
                return res.data.map((item: any) => {
                    return new SelectPermissionModel(item);
                });
            });
    }

    getUserNewDetails(): Promise<any> {
        return this._put.setApi(API.newUserDetails)
            .setHeaders({
                Authorization: `Bearer ${localStorage.getItem('mainToken')}`
            })
            .requestPromise().then(res => {
                localStorage.setItem('mainToken', res.data);
                swal.fire({
                    position: 'center',
                    icon: 'info',
                    html: 'Məlumatlarınız uğurla yeniləndi.',
                    showConfirmButton: false,
                    timer: 5000
                });
                return res.data;


            });
    }
}