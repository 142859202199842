export class TotalServicesModel {
    public totalCount: string | number | null = null;
    public services: AllServicesModel[] | null = null;

    constructor(data: any) {
        this._setTotalCount(data);
        this._setServices(data);
    }

    private _setTotalCount({totalCount}: any) {
        this.totalCount = totalCount
    }

    private _setServices({items}: any) {
        this.services = items?.map((item: any) => {
            return new AllServicesModel(item);
        });
    }
}

export class AllServicesModel {
    public id: number | null = null;
    public count: number | null = null;
    public icon: string | null = null;
    public title: string | null = null;
    public description: string | null = null;
    public authRequired: string | null = null;
    public component: string | null = null;

    constructor(item: any) {
        this._setId(item);
        this._setCount(item);
        this._setIcon(item);
        this._setTitle(item);
        this._setDescription(item);
        this._setAuthRequired(item);
        this._setComponent(item);

    }

    /**
     * Set Id
     * @param ID
     * @private
     */
    private _setId({id}: any) {
        this.id = id;
    }

    /**
     * Set icon
     * @param file
     * @private
     */
    private _setIcon({file}: any) {
        this.icon = file.fileUrl;
    }

    /**
     * Set title
     * @param serviceStructures
     * @private
     */
    private _setTitle({serviceStructures}: any) {
        this.title = serviceStructures[0]?.structure?.title;
    }

    /**
     * Set description
     * @param serviceLanguages
     * @private
     */
    private _setDescription({serviceLanguages}: any) {
        this.description = serviceLanguages[0]?.name;
    }

    /**
     * set count
     * @param serviceStructuresCount
     * @private
     */
    private _setCount({serviceStructuresCount}: any) {
        this.count = serviceStructuresCount;
    }

    /**
     * set AuthRequired
     * @param AuthRequired
     * @private
     */

    private _setAuthRequired({authRequired}: any) {
        this.authRequired = authRequired;
    }

    private _setComponent({component}: any) {
        this.component = component?.name;
    }
}
