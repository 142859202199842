export class GetCallHistoryModel {
    public number: number | null = null;
    public date: string | null = null;
    public time: number | null = null;
    public period: number | null = null;

    constructor(item: any) {
        this._setNumber(item);
        this._setDate(item);
        this._setTime(item);
        this._setPeriod(item);
    }


    /**
     * Set number
     * @param item
     * @private
     */

    private _setNumber({phoneB}: any): void {
        this.number = phoneB;
    }

    /**
     * Set date
     * @param date
     * @private
     */


    private _setDate({year, month, day}: any): void {
        if (day < 10) {
            day = `0${day}`;
        }
        if (month < 10) {
            month = `0${month}`;
        }
        if (year < 10) {
            year = `200${year}`;
        } else {
            year = `20${year}`;
        }
        this.date = `${day}.${month}.${year}`;
    }

    /**
     * Set time
     * @param time
     * @private
     */

    private _setTime({time}: any): void {
        this.time = time;
    }

    /**
     * Set period
     * @param period
     * @private
     */

    private _setPeriod({seconds}: any): void {
        this.period = seconds;
    }


}