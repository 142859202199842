export class StreetCityModel {
    public id: number | null = null;
    public value: string | null = null;

    constructor(item: any) {
        this._setId(item);
        this._setValue(item);
    }

    /**
     *
     * @param Id
     * @private
     */

    private _setId({cityCode}: any) {
        this.id = cityCode;
    }

    /**
     *
     * @param cityCode
     * @private
     */

    private _setValue({name}: any) {
        this.value = name;
    }
}