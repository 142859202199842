import {ICalculatorState} from '@/pages/calculator/store/state';
import {ISelectCalculator} from '@/pages/calculator/types/select-calculator';
import {PayloadForm} from '@/pages/permission-movement-service/types/payload-form';

function checkFiler(item: ISelectCalculator, typeId: number) {
    let check = item.typesIds?.some((itemType: any) => {
        return itemType === typeId;
    });
    if (check) {
        return item;
    }
}

export const CalculatorStateMutations = {
    SET_USER_GROUPS(state: ICalculatorState, payload: ISelectCalculator[]): void {
        state.userGroups = payload;
    },
    SET_ONE_TIME_PAYMENT(state: ICalculatorState, payload: PayloadForm): void {

        state.oneTimePaymentInfo[`${payload.type}`] = payload.value;
    },
    SET_FREQUENCY(state: ICalculatorState, payload: any): void {
        state.frequency = payload;
    },
    SET_RESET_ONE_TIME_PAYMENT(state: ICalculatorState): void {
        state.oneTimePaymentInfo = {
            userGroup: {
                id: null, value: null, mean: null
            },
            serviceType: {
                id: null, value: null, mean: null
            },
            frequency: null,
            bandSignificance: {
                id: null, value: null, mean: null
            },
            throughputType: {
                id: null, value: null, mean: null
            },
            geographicalArea: {
                id: null, value: null, mean: null
            },
            frequencyCount:''
        };
        state.frequency = '';
    },
    SET_RESET_TERM(state: ICalculatorState): void {
        state.termInfo = {
            userGroup: {
                id: null, value: null, mean: null
            },
            serviceType: {
                id: null, value: null, mean: null
            },
            frequenciesCount: null,
            frequenciesCountMean: null,
            bandwidthFrequencyNominal: {
                id: null, value: null, mean: null
            },
            geographicalAreas: {
                id: null, value: null, mean: null
            },
            date: {
                id: null, value: null, mean: ''
            },
            startDate: null,
            endDate: null,
            adjustmentFactor: {
                id: null, value: null, mean: null
            },
            bandSignificances: {
                id: null, value: null, mean: null
            },
            coefficientSEMTechnology: {
                id: null, value: null, mean: null
            },
            broadcastCoverageArea: null,
            SEMTechnologyFactor: {
                id: null, value: null, mean: null
            },
            radioFrequencyMultiplier: null,
            radioFrequencyChannels: {
                id: 1, value: 'RRX (simpleks)', mean: null, factor: '1'
            },
            frequenciesPropertyFirst: null,
            frequenciesPropertySecond: null,
            SEMTechnologyFactorValue: null
        };
    },
    SET_RESET_ONE_TIME_PAYMENT_DETAILS(state: ICalculatorState): void {
        state.oneTimePaymentInfo.throughputType = {
            id: null, value: null, mean: null
        };
        state.oneTimePaymentInfo.frequency = null;
        state.oneTimePaymentInfo.geographicalArea = {
            id: null, value: null, mean: null
        };
        state.oneTimePaymentInfo.bandSignificance = {
            id: null, value: null, mean: null
        };
    },
    SET_MOBILE_INPUTS_DATA(state: ICalculatorState, payload: any): void {
        if (payload.type === 'input') {
            state.mobileInputData[payload.mainIndex].inputs[payload.index].value = payload.value;
            state.mobileInputData[payload.mainIndex].inputs[payload.index].mean = payload.value;
        }
        if (payload.type === 'select') {
            state.mobileInputData[payload.index].value = payload.value;
        }
    },
    SET_MOBILE_DATE_INPUTS(state: ICalculatorState, payload: any): void {
        state.mobileInputData[2].startDate = payload.startDate;
        state.mobileInputData[2].endDate = payload.endDate;
        state.mobileInputData[2].dateMean = payload.mean;
    },
    SET_RESULTS(state: ICalculatorState, payload: any): void {
        state.result = payload.result;
        state.totalResult = payload.totalResult;
        state.vat = payload.vat;
    },
    SET_RESET_MOBILE_INPUTS_DATA(state: ICalculatorState): void {
        state.mobileInputData[0].inputs.forEach((item: any) => {
            item.value = '0';
            item.mean = '0';
        });
        state.mobileInputData[1].inputs.forEach((item: any) => {
            item.value = '0';
            item.mean = '0';
        });
        state.mobileInputData[2].value = {
            id: null,
            value: '',
            mean: ''
        };
        state.mobileInputData[2].dateMean = '';
        state.mobileInputData[2].startDate = null;
        state.mobileInputData[2].endDate = null;
    },

    ///one time
    SET_SELECT_THROUGHPUT_TYPES_ONE_TIME(state: ICalculatorState, typeId: number): void {
        state.selectThroughputTypesOneTime = state.throughputTypesOneTime.filter((item: ISelectCalculator) => {
            return checkFiler(item, typeId);
        });
        state.selectBandSignificancesOneTime = state.bandSignificancesOneTime.filter((item: ISelectCalculator) => {
            return checkFiler(item, typeId);
        });
        state.selectGeographicalAreasOneTime = state.geographicalAreasOneTime.filter((item: ISelectCalculator) => {
            return checkFiler(item, typeId);
        });
    },

    // tern

    SET_TERN_INFO(state: ICalculatorState, payload: PayloadForm): void {
        state.termInfo[`${payload.type}`] = payload.value;
    },
    SET_TERN_INFO_DATE(state: ICalculatorState, payload: PayloadForm): void {
        state.termInfo.startDate = payload.startDate;
        state.termInfo.endDate = payload.endDate;
        if (payload.mean) {
            state.termInfo.date =
                {
                    id: 13, value: 'Digər', mean: payload.mean
                };

        }
    },

    SET_TERN_INFO_PROPERTY(state: ICalculatorState, payload: PayloadForm): void {
        state.termInfo[`${payload.parent}`][`${payload.type}`] = payload.value;
    },

    SET_SELECTS_FOR_TERN_PAYMENT(state: ICalculatorState, typeId: number): void {

        state.selectBandwidthFrequencyNominal = state.bandwidthFrequencyNominal.filter((item: ISelectCalculator) => {
            return checkFiler(item, typeId);
        });
        state.selectGeographicalAreas = state.geographicalAreas.filter((item: ISelectCalculator) => {
            return checkFiler(item, typeId);
        });
        state.selectSEMTechnologyFactor = state.SEMTechnologyFactor.filter((item: ISelectCalculator) => {
            return checkFiler(item, typeId);
        });
        state.selectBandSignificances = state.bandSignificances.filter((item: ISelectCalculator) => {
            return checkFiler(item, typeId);
        });
        state.selectCoefficientSEMTechnology = state.coefficientSEMTechnology.filter((item: ISelectCalculator) => {
            return checkFiler(item, typeId);
        });
        state.selectAdjustmentFactor = state.adjustmentFactor.filter((item: ISelectCalculator) => {
            return checkFiler(item, typeId);
        });
    },

    SET_RESET_TERN_DETAILS(state: ICalculatorState): void {
        state.termInfo.bandwidthFrequencyNominal = {
            id: null, value: null, mean: null
        };
        state.termInfo.geographicalAreas = {
            id: null, value: null, mean: null
        };
        state.termInfo.adjustmentFactor = {
            id: null, value: null, mean: null
        };
        state.termInfo.bandSignificances = {
            id: null, value: null, mean: null
        };
        state.termInfo.coefficientSEMTechnology = {
            id: null, value: null, mean: null
        };
        state.termInfo.SEMTechnologyFactor = {
            id: null, value: null, mean: null
        };
        state.termInfo.date = {
            id: null, value: null, mean: null
        };
        state.termInfo.frequenciesCount = null;
        state.termInfo.frequenciesCount = null;
        state.termInfo.frequenciesCountMean = null;
        state.termInfo.startDate = null;
        state.termInfo.endDate = null;
        state.termInfo.broadcastCoverageArea = null;
        state.termInfo.radioFrequencyMultiplier = null;
        state.termInfo.SEMTechnologyFactorValue = null;
        state.termInfo.radioFrequencyChannels = {
            id: 1, value: 'RRX (simpleks)', mean: null, factor: '1'
        };
    },

};
