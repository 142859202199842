import {IUserApplyState} from './state.d';
import {UserApplyGetters} from './getters';
import {UserApplyMutations} from './mutations';
import {UserApplyActions} from './actions';


const UserApplyStateInitial: IUserApplyState = {
    serviceRequests: [],
    totalCountServiceRequests: 0,
};

export const UserApplyState = {
    state: UserApplyStateInitial,
    getters: UserApplyGetters,
    mutations: UserApplyMutations,
    actions: UserApplyActions
};
