import 'reflect-metadata';
import {injectable} from 'tsyringe';
import {store} from '@/store';


@injectable()
export class FooterSandbox {
    private store = store;

    constructor() {
    }

    getFooterModalData(): void {
        this.store.dispatch('getFooterModalData');
    }

    getContent(payload: number): void {
        this.store.dispatch('getInformation', payload);
    }
}
