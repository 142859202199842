<style lang="scss" src="./socials.component.scss"></style>

<template>
  <ul class="socials">
    <li v-for="(item,index) in socials" :key="index" class="socials__item mr-8">
      <a :href="item.url" class="socials__link">
        <inline-svg :src="item.icon" class="socials__icon"/>
      </a>
    </li>
  </ul>
</template>

<script src="./socials.component.ts"></script>
