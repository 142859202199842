export class CertFileTypeModel {
    public id: number | null = null;
    public sxemId: number | null = null;
    public appealTypeId: number | null = null;
    public label: string | null = null;
    public name: string | null = null;
    public isRequired: boolean | null = null;
    public file: File | null = null;
    public error: boolean | null = false;
    public uploadLimit: number | null = null;

    constructor(item: any) {
        this._setId(item);
        this._setSxemId(item);
        this._setAppealTypeId(item);
        this._setLabel(item);
        this._setName(item);
        this._setIsRequired(item);
        this._setUploadLimit(item);
    }

    private _setId({id}: any) {
        this.id = id;
    }

    private _setSxemId({sxemId}: any) {
        this.sxemId = sxemId;
    }

    private _setAppealTypeId({appealTypeId}: any) {
        this.appealTypeId = appealTypeId;
    }

    private _setLabel({name}: any) {
        this.label = name;
    }

    private _setName({label}: any) {
        this.name = label;
    }

    private _setIsRequired({isRequired}: any) {
        this.isRequired = isRequired;
    }

    private _setUploadLimit({uploadLimit}: any) {
        this.uploadLimit = uploadLimit;
    }
}