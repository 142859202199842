import {container} from 'tsyringe';
import {FeedbackAnswerService} from '@/pages/feedback-answer/services/feedback-answer.service';

const service = container.resolve(FeedbackAnswerService);
export const FeedbackAnswerStateActions = {

    getFeedbackId(context: any, payload: any) {
        return service.getFeedbackId(payload).then(res => context.commit('SET_FEEDBACK', res));
    },
};
