import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lead_component = _resolveComponent("lead-component")!
  const _component_new_services_component = _resolveComponent("new-services-component")!
  const _component_e_services_component = _resolveComponent("e-services-component")!
  const _component_most_used_services = _resolveComponent("most-used-services")!
  const _component_statistics_component = _resolveComponent("statistics-component")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_lead_component),
    _createVNode(_component_new_services_component, { ref: "pinned-services" }, null, 512),
    _createVNode(_component_e_services_component, { ref: "categories" }, null, 512),
    _createVNode(_component_most_used_services, { ref: "most-used" }, null, 512),
    _createVNode(_component_statistics_component, { class: "d-none" })
  ]))
}