import 'reflect-metadata';
import {injectable} from 'tsyringe';
import {store} from '@/store';


@injectable()
export class HomeSandbox {
  private store = store;

  constructor() {
  }

  getPinnedServices(): void {
    this.store.dispatch('getPinnedServices');
  }
  getMostUsedServices(): void {
    this.store.dispatch('getMostUsedServices');
  }
  getServiceCategories(): void {
    this.store.dispatch('getServiceCategories');
  }
}
