import {container} from 'tsyringe';
import {ImeiTariffServiceService} from '@/pages/imei-tariff-service/services/imei-tariff-service.service';

const service = container.resolve(ImeiTariffServiceService);

export const ImeiTariffServiceStateActions = {
    getManufacture(context: any): void {
        service.getManufacture().then(res => context.commit('SET_MANUFACTURER', res));
    },
    getModelsByManufacturer(context: any, payload:any): void {
        service.getModelsByManufacturer(payload).then(res => context.commit('SET_MODELS_BY_MANUFACTURER', res));
    }
};
