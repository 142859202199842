import 'reflect-metadata';
import {mixins, Options, Vue} from 'vue-class-component';
import BoxComponent from '@/core/shared/box/box.component.vue';
import choiceRouter from '@/core/helpers/routers';
import {AuthenticationSandbox} from '@/core/auth/authentication.sandbox';
import {container} from 'tsyringe';
import {CheckAuthService} from '@/mixin/mixin';

@Options({
    name: 'service-card-component',
    props: {
        item: {
            type: Object,
            require: true
        }
    },
    components: {
        BoxComponent,
    }
})

export default class ServiceCardComponent extends mixins(CheckAuthService) {
    public sandboxAuth: AuthenticationSandbox = container.resolve(AuthenticationSandbox);

    public followingLink(service: any): void {
        this.checkAuthService(service);
    }

}