import {container, injectable} from 'tsyringe';
import {GET} from '@/packages/VHttp/GET';
import {API} from '@/core/configs/api';
import {GetNumberModel} from '@/pages/local-talks-service/models/get-number.model';
import {DebReceiptsModel} from '@/pages/debt-previous-months-service/models/deb-receipt.model';

@injectable()
export class DebtPreviousMonthsServiceService {
    private _get: GET = container.resolve(GET);

    constructor() {
    }

    getReceipts(payload: any): Promise<any> {
        return this._get.setApi(API.previousReceipt)
            .setHeaders({cName: 'debt-previous-months-service', Authorization: `Bearer ${payload.token}`})
            .setParams({
                number: payload.number,
                pageNumber: payload.pageNumber,
                pageSize: payload.pageSize
            })
            .requestPromise().then(res => {
                return new DebReceiptsModel(res.data);
            });
    }

}
