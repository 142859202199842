import {IIssuingCertificateConformityServiceState} from '@/pages/issuing-certificate-conformity-service/store/state';
import {IApplicantForm} from '@/pages/issuing-certificate-conformity-service/types/applicant-form';
import {IFileType} from '@/pages/issuing-certificate-conformity-service/types/file-type';
import {IUserData} from '@/pages/appeal-radio-service/types/user-data';

export const IssuingCertificateConformityServiceStateGetters = {
    _getApplicantForm: (state: IIssuingCertificateConformityServiceState): IApplicantForm | null => {
        return state.applicantForm;
    },
    _getApplicantCertificateInfo: (state: IIssuingCertificateConformityServiceState): IUserData | null => {
        return state.userInfo;
    },
    _getCertificateStatus: (state: IIssuingCertificateConformityServiceState): number | null => {
        return state.certificateStatus;
    },

    _getFilesForForCertificate: (state: IIssuingCertificateConformityServiceState): IFileType[] | null => {
        return state.filesForCertificate;
    },
    _getFilesForForStep3: (state: IIssuingCertificateConformityServiceState): IFileType[] | null => {
        return state.filesForStep3;
    },
    _getCertificateType: (state: IIssuingCertificateConformityServiceState): number  | null=> {
        return state.certificateType;
    },

};