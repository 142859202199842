import {IUserFeedbackState} from './state.d';
import {UserFeedbackGetters} from './getters';
import {UserFeedbackMutations} from './mutations';
import {UserFeedbackActions} from './actions';


const UserFeedbackStateInitial: IUserFeedbackState = {
    feedBackAll: [],
    totalCountFeedBackAll: 0,
};

export const UserFeedbackState = {
    state: UserFeedbackStateInitial,
    getters: UserFeedbackGetters,
    mutations: UserFeedbackMutations,
    actions: UserFeedbackActions
};
