export class OrganisationModel {
    public id: number | null = null;
    public count: number | null = null;
    public icon: string | null = null;
    public title: string | null = null;

    constructor(item: any) {
        this._setId(item);
        this._setCount(item);
        this._setIcon(item);
        this._setTitle(item);
    }

    /**
     * Set Id
     * @param ID
     * @private
     */
    private _setId({id}: any) {
        this.id = id;
    }

    /**
     * Set icon
     * @param file
     * @private
     */
    private _setIcon({fileUrl}: any) {
        this.icon = fileUrl;
    }

    /**
     * Set title
     * @param structureLanguages
     * @private
     */
    private _setTitle({name}: any) {
        this.title = name;
    }

    /**
     * set count
     * @param servicesCount
     * @private
     */
    private _setCount({servicesCount}: any) {
        this.count = servicesCount;
    }
}
