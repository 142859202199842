import {container} from 'tsyringe';
import {OrganisationsService} from '@/pages/organisations/services/organisations.service';

const service = container.resolve(OrganisationsService);

export const OrganisationsStateActions = {
    getOrganisations(context: any): void {
        service.getOrganisations().then(res => context.commit('SET_ORGANISATIONS', res));
    },

    getOrganisationDetails(context: any,payload: string): void {
        service.getOrganisationDetails(payload).then(res => context.commit('SET_ORGANISATION_DETAILS', res));
    },
}
