import {container} from "tsyringe";
import {PrivacyService} from "@/pages/privacy/servises/service.service";

const service = container.resolve(PrivacyService);

export const PrivacyStateActions = {
   getPrivacyContentData(context: any):void{
       service.getPrivacyContentData().then(res => context.commit('SET_PRIVACY_CONTENT_DATA', res))
   }
}
