import {container, injectable} from 'tsyringe';
import {API} from '@/core/configs/api';
import {GET} from '@/packages/VHttp/GET';
import {TotalCountServiceRequestModel} from '@/pages/user-apply/models/total-count-service-request.model';

@injectable()
export class UserApplyService {
    private _get: GET = container.resolve(GET);

    constructor() {
    }

    getServiceRequests(payload: any): Promise<any> {
        return this._get.setApi(API.serviceRequests)
            .setHeaders({Authorization: `Bearer ${payload.token}`})
            .setParams({
                pageSize: payload.pageSize,
                pageNumber: payload.pageNumber,
                word: payload.word
            })
            .requestPromise().then(res => {
                return new TotalCountServiceRequestModel(res.data.serviceRequest);
            });
    }
}
