import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "public" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loader_component = _resolveComponent("loader-component")!
  const _component_header_component = _resolveComponent("header-component")!
  const _component_breadcrumb_component = _resolveComponent("breadcrumb-component")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_footer_component = _resolveComponent("footer-component")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_Transition, {
      "enter-active-class": "animate__animated animate__fadeIn animate__faster",
      "leave-active-class": "animate__animated animate__fadeOut animate__faster",
      mode: "out-in"
    }, {
      default: _withCtx(() => [
        (_ctx.hasLoader)
          ? (_openBlock(), _createBlock(_component_loader_component, { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_header_component, {
      "is-home": _ctx.isHome,
      isVisibleSearch: true
    }, null, 8, ["is-home"]),
    (!_ctx.isHome)
      ? (_openBlock(), _createBlock(_component_breadcrumb_component, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view, null, {
      default: _withCtx(({ Component, route}) => [
        _createVNode(_Transition, {
          "enter-active-class": "animate__animated animate__fadeInRight animate__faster",
          "leave-active-class": "animate__animated animate__fadeOutLeft animate__faster",
          mode: "out-in"
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createElementBlock("div", {
              key: route.name
            }, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
            ]))
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    }),
    _createVNode(_component_footer_component)
  ]))
}