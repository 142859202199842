<style lang="scss" src="./footer-menu-item.component.scss"></style>

<template>
  <div class="col-lg-6">
    <div class="footer-menu-item mb-40">
      <div class="footer-menu-item__title mb-8">
        {{ item.title }}
      </div>
      <ul class="footer-menu-item__container">
        <li v-for="(i,index) in item.items"
            :key="index"
            class="footer-menu-item__content mb-8">
          <template v-if="item.id===1">
            <router-link :to="{name: 'information', params:{id:i.content.id}}"
                         class="footer-menu-item__link"
                         @click="linkClick(i.content.id)"
                         v-if="i.content">
              {{ i.name }}
            </router-link>
            <router-link :to="{name: i.link}" class="footer-menu-item__link" v-else>
              {{ i.name }}
            </router-link>
          </template>
          <div v-else>
            <a @click.prevent="moveToPage(i.link)" class="footer-menu-item__link a" v-if="isHome !== 'home'">
              {{ i.name }}
            </a>
            <a @click="scroll(i.link)" class="footer-menu-item__link a" v-else>
              {{ i.name }}
            </a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script src="./footer-menu-item.component.ts"></script>
