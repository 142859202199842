import {injectable} from 'tsyringe';
import {RequestInterceptor} from '@/packages/VHttp/interceptors/request.interceptor';
import {ResponseInterceptor} from '@/packages/VHttp/interceptors/response.interceptor';
import swal from 'sweetalert2';
import {environment} from '@/core/configs/app.config';
import {store} from '@/store';
import {API} from '@/core/configs/api';
import {isArray} from 'rxjs/internal-compatibility';


@injectable()
export class ApiInterceptorRequest extends RequestInterceptor {
    constructor() {
        super();
        this.request();
    }

    request() {
        let token = localStorage.getItem('mainToken');
        this.intercept().use((req) => {
            let url = req.url;
            let searchApi = API.search_services_key_values;

            // let lang = store.getters._getLanguage;
            // let currentLang = lang ? lang : 'az';
            // if(url?.indexOf('fullsign')!==-1) {
            if (!url?.includes(searchApi)) {
                store.commit('SET_LOADER', true);
            }
            // }

            if (token) {
                req.headers = {
                    Authorization: token ? `Bearer ${token}` : '',
                    ...req.headers,
                };
            }


            // req.params = {
            //     ...req.params,
            //     lang: currentLang
            // }
            return req;
        });
    }
}

export class ApiInterceptorResponse extends ResponseInterceptor {
    constructor() {
        super();
        this.response();
    }

    response() {
        this.interceptor().use((res) => {
            store.commit('SET_LOADER', false);
            return res;
        }, (error) => {
            store.commit('SET_LOADER', false);
            let url = error.response.config.url;
            if (!url.includes(environment.apiAsan)) {
                let errorData = error.response.data;
                let errorMessage = 'Xəta baş verdi';
                // if (errorData.title) {
                //     errorMessage = errorData.title;
                // } else if (errorData.name) {
                //     let key1 = Object.keys(errorData.errors)[0];
                //     errorMessage = errorData.errors[key1];
                // }
                if (errorData.errors) {
                    errorMessage = '';
                    Object.entries(errorData.errors).forEach(([key, value], index) => {
                        if (isArray(value)) {
                            if (index === 0) errorMessage = `<div>${value[0]}</div>`;
                            else errorMessage = `<div>${errorMessage}</br>${value[0]}</div>`;
                        }
                    });
                } else if (errorData.title) {
                    errorMessage =`<div>${errorData.title}</div>` ;
                }
                if (error.response.status === 500) {
                    swal.fire({
                        position: 'center',
                        icon: 'error',
                        html: errorMessage,
                        showConfirmButton: false,
                        timer: 3000
                    });
                } else {
                    swal.fire({
                        position: 'center',
                        icon: 'info',
                        html: errorMessage,
                        showConfirmButton: false,
                        timer: 3000
                    });
                }
            }
            return Promise.reject(error);
        });
    }
}
