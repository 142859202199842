import {IGetCallHistory} from '@/pages/local-talks-service/types/get-call-history';
import {GetCallHistoryModel} from '@/pages/local-talks-service/models/get-call-history.model';

export class TotalGetCallHistory {
    public totalCountCallHistory: number | null = null;
    public callHistory: IGetCallHistory[] = [];

    constructor(item: any) {
        this._setTotalCountCallHistory(item);
        this._setCallHistory(item);
    }

    /**
     * Set totalCountNumbers
     * @param totalRowCount
     * @private
     */

    private _setTotalCountCallHistory({totalRowCount}: any): void {
        this.totalCountCallHistory = totalRowCount;
    }

    /**
     * Set callHistory
     * @param data
     * @private
     */

    private _setCallHistory({data}: any): void {
        this.callHistory = data.map((item: any) => {
            return new GetCallHistoryModel(item);
        });
    }
}