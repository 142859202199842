import {IUser} from '@/core/auth/store/state';
import {dataFormat} from '@/core/helpers/data-format';

export class ResponseAddAppealModel {
    public appealId: null | number = null;
    public userData: any | null = null;

    constructor(item: any) {
        this._setAppealId(item);
        this._setUserData(item);

    }


    private _setAppealId(item: any) {
        this.appealId = item.appealId;
    }

    private _setUserData(item: any) {
        this.userData = new UserData(item);
    }
}

class UserData {
    public name: null | string = null;
    public surname: null | string = null;
    public fatherName: null | string = '';
    public pin: null | string = null;
    public birthDate: null | string = null;
    public image: null | string = null;
    public address: null | string = null;
    public document: null | string = null;

    constructor(item: any) {
        this._setName(item);
        this._setSurname(item);
        this._setFatherName(item);
        this._setPin(item);
        this._setBirthDate(item);
        this._setImage(item);
        this._setAddress(item);
        this._setDocument(item);
    }

    private _setName({userData}: any) {
        this.name = userData?.Name;
    }

    private _setSurname({userData}: any) {
        this.surname = userData?.Surname;
    }

    private _setFatherName({userData}: any) {

        if (userData?.Patronymic) {
            this.fatherName = userData?.Patronymic;
        }
    }

    private _setPin({userData}: any) {
        this.pin = userData?.PIN;
    }

    private _setBirthDate({userData}: any) {
        // this.birthDate = dataFormat(userData?.BirthDate);
        this.birthDate = userData?.BirthDate?.split('T')[0];
    }


    private _setImage({userData}: any) {
        this.image = userData?.Image;
    }

    private _setAddress({userData}: any) {
        this.address = userData?.RegisteredAddressDetail?.Address;
    }

    private _setDocument({userData}: any) {
        this.document = userData?.DocumentNumber;
    }
}

