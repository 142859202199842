import {IServiceRequest} from '@/pages/user-account/types/service-request';
import {ServiceRequestModel} from '@/pages/user-apply/models/service-request.model';

export class TotalCountServiceRequestModel {
    public totalCountServiceRequests: number = 0;
    public serviceRequests: IServiceRequest[] | null = null;

    constructor(item: any) {
        this._setTotalCountServiceRequests(item);
        this._setServiceRequests(item);
    }

    /**
     *
     * @param totalCount
     * @private
     */

    private _setTotalCountServiceRequests({totalCount}: any) {
        this.totalCountServiceRequests = totalCount;
    }

    /**
     *
     * @param items
     * @private
     */

    private _setServiceRequests({items}: any) {
        this.serviceRequests = items?.map((item: any) => {
            return new ServiceRequestModel(item);
        });
    }
}