import {ISearchPhoneNumberServiceState} from '@/pages/search-phone-number-service/store/state';
import {IPhoneNumberDebt} from '@/pages/search-phone-number-service/types/phone-number-debt';

export const SearchPhoneNumberServiceStateMutations = {
    SET_CITIES(state: ISearchPhoneNumberServiceState, payload: any): void {
        state.cities = payload.cities;
    },
    SET_SEARCH_NUMBER(state: ISearchPhoneNumberServiceState, payload: any): void {
        state.searchNumber = payload;
    },

    SET_SEARCH_NUMBER_RESET(state: ISearchPhoneNumberServiceState): void {
        state.searchNumber = {
            phone: null,
            name: null,
            street: null,
            address: null,
            phoneType: null,
            phoneNumberDebt: null,
        };
    },
};
