<style src="./user-sidebar.component.scss" lang="scss"></style>

<template>
  <v-card class="user-sidebar">
    <ul>
      <li v-for="(item, index) in asideData" :key="index" :class="item.class" class="mb-20 mt-20">
        <router-link
            :to="{name: item.link}"
            active-class="active"
            class="nav-link"
            :class="{'active':
           isPage ===item.link }"
        >
          <img :src="require(`@/assets/images/icons/${item.icon}`)" alt="icon">
          {{ item.title }}
        </router-link>
      </li>
    </ul>
  </v-card>
</template>

<script src="./user-sidebar.component.ts"></script>
