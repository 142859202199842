import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "most-used py-50",
  id: "most-used",
  ref: "most-used"
}
const _hoisted_2 = { class: "container-fluid v-container" }
const _hoisted_3 = { class: "row align-center" }
const _hoisted_4 = { class: "col-md-12 mb-40" }
const _hoisted_5 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_title = _resolveComponent("v-title")!
  const _component_service_card_component = _resolveComponent("service-card-component")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_v_title, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.locale.headerMostUsedServices), 1)
            ]),
            _: 1
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mostUsedServices, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "col-md-6 col-lg-4 mb-30",
            key: index
          }, [
            _createVNode(_component_service_card_component, { item: item }, null, 8, ["item"])
          ]))
        }), 128))
      ])
    ])
  ], 512))
}