import {container} from 'tsyringe';
import {ServicesService} from '@/pages/services/services/services.service';

const service = container.resolve(ServicesService);

export const ServiceActions = {
    getCheckImei(context: any, payload: any): any {
        return service.getCheckImei(payload);
    },
    getReceiptPayment(context: any, payload: any): any {
        return service.getReceiptPayment(payload).then(res => context.commit('SET_RECEIPT_IMEI', res));
    },
    sendReceiptToEmail(context: any, payload: any): any {
        return service.sendReceiptToEmail(payload);
    },
    postAddApplication(context: any, payload: any): any {
        return service.postAddApplication(payload);
    },
    generatePaymentUrl(context: any, payload: any): any {
        return service.generatePaymentUrl(payload);
    },
    getDocumentGroups(context: any): any {
        return service.getDocumentGroups().then(res => context.commit('SET_DOCUMENT_GROUPS', res));
    },
    getDocumentTypes(context: any, payload: any): any {
        return service.getDocumentTypes(payload).then(res => context.commit('SET_DOCUMENT_TYPES', res));
    },
};
