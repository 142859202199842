import {container, injectable} from 'tsyringe';
import {map} from 'rxjs/operators';
import {API} from '@/core/configs/api';
import {SelectPermissionModel} from '@/pages/permission-movement-service/models/select-permission.model';
import {GET} from '@/packages/VHttp/GET';
import {POST} from '@/packages/VHttp/POST';
import {CertFileTypeModel} from '@/pages/issuing-certificate-conformity-service/models/file-type.model';
import {IGetFileType} from '@/pages/issuing-certificate-conformity-service/types/get-cert-appeal';
import {FileTypePermitModel} from '@/pages/issuance-special-permit-service/models/file-type-permit.model';
import {ISPApplicant} from '@/pages/issuance-special-permit-service/types/sp-applicant';

@injectable()
export class IssuanceSpecialPermitServiceService {
    private _get: GET = container.resolve(GET);
    private _post: POST = container.resolve(POST);

    public getAppealFileSP(payload: IGetFileType): Promise<any> {
        return this._get.setApi(API.SPgetAppealFileSP)
            .setHeaders({cName: '', Authorization: `Bearer ${localStorage.getItem('mainToken')}`})
            .setParams(payload)
            .requestPromise().then(res => {
                return res.data.appealFileConfigurations.map((item: any) => {
                    return new FileTypePermitModel(item);
                });
            });
    }

    public getRestrictedCirculationItemSP(): Promise<any> {
        return this._get.setApi(API.SPgetRestrictedCirculationItem)
            .setHeaders({cName: '', Authorization: `Bearer ${localStorage.getItem('mainToken')}`})
            .requestPromise().then(res => {
                return res.data.restrictedCirculationItems.map((item: any) => {
                    return new SelectPermissionModel(item);
                });
            });
    }

    public getRequiredCrucialDataSP(): Promise<any> {
        return this._get.setApi(API.SPgetRequiredCrucialData)
            .setHeaders({cName: '', Authorization: `Bearer ${localStorage.getItem('mainToken')}`})
            .requestPromise().then(res => {

                return res.data.requiredCrucialDatas.map((item: any) => {
                    return new SelectPermissionModel(item);
                });
            });
    }

    public postCreateAppealSP(payload: FormData): Promise<any> {
        return this._post.setApi(API.SPpostCreateAppeal)
            .setHeaders({cName: 'issuance-special-permit-service', Authorization: `Bearer ${localStorage.getItem('mainToken')}`})
            .setPayload(payload)
            .requestPromise().then(res => {
                return res;
            });
    }
}