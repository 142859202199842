export class ServiceHeaderUrlModel {
    public id: string | null = null;
    public url: string | null = null;
    public title: string | null = null;

    constructor(item: any) {
        this._setId(item);
        this._setUrl(item);
        this._setTitle(item);
    }

    /**
     * set url
     * @param url
     * @private
     */

    private _setUrl({fileUrl}: any) {
        this.url = fileUrl;
    }

    /**
     * set id
     * @param id
     * @private
     */

    private _setId({id}: any) {
        this.id = id;
    }

    /**
     * set file
     * @param file
     * @private
     */

    private _setTitle({name}: any) {
        this.title = name
    }
}