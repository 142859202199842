import {container} from "tsyringe";
import {AboutService} from "@/pages/about/services/about.service";


const service = container.resolve(AboutService);

export const AboutStateActions = {
    getAboutContentData(context: any):void{
           service.getAboutContentData().then(res => context.commit('SET_ABOUT_CONTENT_DATA', res));
       }
}
