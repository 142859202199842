import {IFeedbackAnswerState} from '@/pages/feedback-answer/store/state';
import {FeedbackAnswerStateActions} from '@/pages/feedback-answer/store/actions';
import {FeedbackAnswerStateGetters} from '@/pages/feedback-answer/store/getters';
import {FeedbackAnswerStateMutations} from '@/pages/feedback-answer/store/mutations';


const FeedbackAnswerStateInitial: IFeedbackAnswerState = {
    feedbackAnswer:{
        feedbackTypeName:  null,
        feedbackService: null,
        date: null,
        mobileNumber:  null,
        email:  null,
        text: null,
        answer:  null,
        document:  null,
    }
};
export const FeedbackAnswerState = {
    state: FeedbackAnswerStateInitial,
    getters: FeedbackAnswerStateGetters,
    mutations: FeedbackAnswerStateMutations,
    actions: FeedbackAnswerStateActions,
};
